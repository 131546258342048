<template>
 <v-dialog v-model="dialogSMKPlan">
   <v-app id="inspire">
    <v-card width="100%">
      <v-toolbar flat color="grey lighten-2" height="30">
      <v-toolbar-title>プランマスタ検索(popPLAN)</v-toolbar-title>
      </v-toolbar> 
    <v-row>
      <v-col cols="2"  md="2" sm="2" xsmd="2">
        <v-alert>popPLAN</v-alert>
      </v-col>
      <v-col cols="6"  md="6" sm="6" xsmd="6">
        <div >
          <v-btn x-large color="success" dark v-on:click="agreeok" block><v-icon left>mdi-gavel</v-icon>プランマスタマスタ確定</v-btn>
        </div>
      </v-col>
       <v-col cols="4"  md="4" sm="4" xsmd="4">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    <v-row>
      <v-subheader></v-subheader>
      <v-subheader><strong>プラン</strong></v-subheader>
      <v-subheader><v-alert dense>{{ PlanCD }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ PlanNM }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ PlanURGK }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ PlanTGUR }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ PlanBIKO }}</v-alert></v-subheader>
    </v-row>
  </v-row>
  <v-card-title>プラン詳細
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="プラン検索" single-line hide-details></v-text-field>
  </v-card-title>
  <v-data-table :headers="headers" :items="SMKPlan" :search="search" @click:row="clickRow">
    <template v-slot:[`item.actions`]="{ item }">
          <v-img :src="item.PlanIMG" style="max-width: 150px; max-height: 100px" />
    </template>
  </v-data-table>
  </v-card>
  </v-app>
</v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "SMKPlan_DSP",
  data() {
    return {
      search: '',
      dialogSMKPlan:false,
      SMKPlan: [],
      PlanCD:   "",  //プランコード
      PlanNM:   "",  //プラン名称
      PlanURGK: "",  //販売金額
      PlanTGUR: "",  //パンフレット価格
      PlanBIKO: "",  //備考
      PlanIMG:  "",  //プランイメージ
      PlanHTML: "",  //プランHtml
      KgyCd:    "",  //企業コード
      text: "",
      headers: [
        { text: 'コード', align: 'start',value: 'PlanCD',width: '80'},
        { text: 'プラン名称', value: 'PlanNM',width: '100'},
        { text: '販売金額',  value: 'PlanURGK',width: '80' },
        { text: 'Image', value: 'PlanIMG', sortable: false,width: '150' },
        { text: 'Html', value: 'PlanHTML',width: '120' },
        { text: '備考',  value: 'PlanBIKO',width: '80' }
      ],
     }
  },
  methods: {
    async SMKPlanShow() {
        axios.post('/api/PLANALL')
        .then(response => {
          this.SMKPlan = response.data
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogSMKPlan = true;
    },
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------
    open() {
     this.SMKPlanShow();
     this.dialogSMKPlan = true;
    
    },
    close() {
      this.$emit('result', {'res': false, 'PlanCD':'--','PlanNM':'--'})
      this.dialogSMKPlan = false;
    },
    agreeok() {
      this.$emit('result', {'res': true,'PlanCD':this.PlanCD,'PlanNM':this.PlanNM,'PlanURGK':this.PlanURGK})
      this.dialogSMKPlan = false;
     },
    clickRow(row) {
      this.PlanCD      = row.PlanCD;      //プランコード
      this.PlanNM      = row.PlanNM;      //プラン名称
      this.PlanURGK    = row.PlanURGK;    //販売金額
      this.PlanTGUR    = row.PlanTGUR;    //パンフレット価格
      this.PlanBIKO    = row.PlanBIKO;    //提携区分名称
      this.PlanIMG     = row.PlanIMG;     //プランイメージ
      this.PlanHTML    = row.PlanHTML;    //プランHtml
      },
     //----------------------------------------
    //テーブル検索マスタ表示
    //----------------------------------------
    /* eslint-disable */
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  },
} 
</script>