<template>
 <v-container>
 <v-dialog v-model="dialogUP" fullscreen>
    <v-app id="inspire">
      <v-card width="100%">
        <v-tabs fixed-tabs background-color="black" dark show-arrows>
          <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab><v-icon left>mdi-home</v-icon>基本</v-tab>
            <v-tab><v-icon left>mdi-account-circle-outline</v-icon>対応表</v-tab>
            <v-tab><v-icon left>mdi-account-outline</v-icon>担当者選択</v-tab>
            <v-tab><v-icon left>mdi-account-outline</v-icon>施行予定</v-tab>
            <!-- 基本 -->
            <v-tab-item>
              <v-row>
                <v-alert><strong>受付№：{{ KoTNO }}</strong></v-alert>
                <v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
                <v-col cols="12" lg="4" md="4" sm="4" xsmd="4"><v-spacer>{{ message }}</v-spacer></v-col>
                <v-col cols="12" lg="4" md="4" sm="4" xsmd="4"><v-btn v-on:click="ERRORConfirm">エラー確認</v-btn></v-col>
              </v-alert>
              </v-row>
              <v-row>
                <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5"/>
                <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5"/>
                <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2">
                  <v-btn  fab dark small color="pink" v-on:click="close">
                    <v-icon dark>mdi-minus</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-btn color="primary" v-on:click="JCJDialogshow" background-color="#fafad2"><v-icon left>mdi-compass-outline</v-icon>受注状況</v-btn>
                  <appDialogJK  :text="text" ref="dialogJC" @result="responseJC"></appDialogJK>
                </v-col>
                <v-col cols="2" xs="6" sm="4" md="4" lg="4" xl="2">
                    <v-alert>{{ KoTJJK }} </v-alert> 
                </v-col>
              </v-row>
              <v-row>
               <v-col cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
                <v-btn color="primary" v-on:click="KIDialogshow"><v-icon left> mdi-account-search</v-icon>会員検索</v-btn>
                <appDialogKA :text="text" ref="dialogKAGET" @result="responseKA"></appDialogKA>
               </v-col>
              <v-col cols="2" xs="6" sm="4" md="4" lg="4" xl="2">
                <v-alert dense>{{ KoTKANO }}</v-alert>
              </v-col>
              <v-col cols="2" xs="6" sm="4" md="4" lg="4" xl="2">
                <v-alert dense>{{ KoTKANM }}</v-alert>
               </v-col>
              </v-row>
              <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>受付</v-alert>
                  <v-menu ref="menu" v-model="menuzz"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTUKDT" label="受付日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTUKDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                  <v-alert> </v-alert>
                <v-text-field dense type="time"  v-model="KoTUKJI" clearable label="受付時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert> </v-alert>
               </v-row>
              <v-row dense>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>喪家</v-alert>
                <v-text-field dense type="text" clearable v-model="KoTMOKE" label="喪家" suffix="家" background-color="#fafad2"/>
                <v-alert> </v-alert>
              </v-row>
              <v-row dense>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>売上日</v-alert>
                <v-menu :return-value.sync="date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTURDT" label="売上日" prepend-icon="mdi-calendar-plus" readonly v-bind="attrs" v-on="on" background-color="#fafad2"/>
                    </template>
                    <v-date-picker v-model="KoTURDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"/>
                </v-menu>
                  <v-alert> </v-alert>
              </v-row>
               <!-- 故人 -->
              <v-row dense>
                <v-alert> </v-alert>
                  <v-toolbar flat dark height="25"><v-toolbar-title style="font-size:0.7em">故人情報</v-toolbar-title></v-toolbar>
                <v-divider></v-divider>
              </v-row>
              <v-row dense>
                <v-alert> </v-alert>
                <v-text-field dense v-model="KoTTNM" clearable label="故人名(漢字）" background-color="#fafad2"/>
                 <v-alert> </v-alert>
                <v-text-field  dense v-model="KoTTKNM"  clearable label="故人(全角ひらがな)" background-color="#fafad2"/>
                 <v-alert> </v-alert>
            </v-row>
            <v-row dense>
                <v-alert>{{ KoTTSEX }}</v-alert>
                <v-btn-toggle  color="red"  dense>
                  <v-btn v-on:click="SEXMAN">男</v-btn><v-btn v-on:click="SEXWMAN">女</v-btn>
                </v-btn-toggle>
                <v-alert>{{ KoTTNEX }}</v-alert>
                <v-btn  color="primary" v-on:click="KZDialogshow" >続柄</v-btn>
               <appDialogKZ :text="text" ref="dialogBKZ" @result="KZresponseBR"></appDialogKZ>
           </v-row>
          <v-row dense>
            <v-alert> </v-alert>
           <v-select dense :items="nengouitem" label="故人生年号" v-model="KoTTNG_KNJ"  background-color="#fafad2"/>
           <v-alert> </v-alert>
           <v-text-field dense type="number" v-mask="'##'" maxlength="2" v-model="KoTTBSYY" clearable label="年" style="width: 80px;" background-color="#fafad2"/>
           <v-alert> </v-alert>
           <v-text-field dense type="number" v-mask="'##'"  v-model="KoTTBSMM" clearable label="月" style="width: 80px;" background-color="#fafad2"/>
           <v-alert> </v-alert>
           <v-text-field dense type="number" v-mask="'##'"  v-model="KoTTBSDD" clearable label="日" style="width: 80px;" background-color="#fafad2"/>
           <v-alert> </v-alert>
           <v-text-field dense type="number" v-mask="'###'" v-model="KoTTEG" clearable label="歳" style="width: 80px;" background-color="#fafad2"/>
           <v-alert> </v-alert>
          </v-row>
          <v-row dense>
            <v-alert> </v-alert>
            <v-text-field dense type="number" v-mask="'#######'" v-model="KoTTYU" style="width:80px" label="〒(故人)" clearable required background-color="#fafad2"></v-text-field>
            <v-alert> </v-alert>
            <v-btn color="primary" v-on:click="KOJINAddress">〒検索</v-btn>
            <v-alert> </v-alert>
          </v-row>
          <v-row dense>
            <v-alert> </v-alert>
           <v-text-field dense v-model="KoTTJYU1" label="都道府県" clearable  required background-color="#fafad2"></v-text-field>
           <v-alert> </v-alert>
           <v-text-field dense v-model="KoTTJYU2" label="市町村区" clearable  required background-color="#fafad2"></v-text-field>
           <v-alert> </v-alert>
          </v-row>
          <v-row dense>
            <v-alert> </v-alert>
           <v-text-field  dense v-model="KoTTJYU3" label="町域" clearable required background-color="#fafad2"></v-text-field>
           <v-alert> </v-alert>
          </v-row>
          <v-row dense> 
             <v-alert dense> </v-alert>
            <v-text-field  dense type="text" v-mask="'(###) ###-####'" v-model="KoTTTEL" label="故人電話" clearable required background-color="#fafad2"/>
          </v-row>
           <!-- 喪主 -->
          <v-row dense>
            <v-alert> </v-alert>
            <v-toolbar flat dark height="25"><v-toolbar-title style="font-size:0.7em">喪主情報</v-toolbar-title></v-toolbar>
            <v-divider></v-divider>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-text-field dense v-model="KoTMNM" clearable label="喪主名" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-text-field dense v-model="KoTMKNM" clearable label="喪主よみがな(全角ひらがな)" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> {{ KoTMSEX }}</v-alert>
             <v-btn-toggle  color="red" dense>
                <v-btn v-on:click="MSSEXMAN">男</v-btn><v-btn v-on:click="MSSEXWMAN">女 </v-btn>
              </v-btn-toggle>
              <v-alert dense>{{ KoTMNEX }}</v-alert>
              <v-btn color="primary" v-on:click="MZDialogshow">続柄</v-btn>
              <appDialogMZ :text="text" ref="dialogMSZ" @result="MSresponseBR"></appDialogMZ>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense type="number" v-mask="'#######'" v-model="KoTMYU" style="width: 160px height:60px" label="〒" clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
            <v-btn color="primary" v-on:click="MOSYUAddress"  >〒</v-btn>
            <v-alert dense> </v-alert>
            <v-btn  v-on:click="COPYKMAddress">故人からCOPY</v-btn>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense v-model="KoTMJYU1" label="都道府県" clearable  required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
            <v-text-field dense v-model="KoTMJYU2" label="市町村区"  clearable   required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
            
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense v-model="KoTMJYU3" label="町域"  clearable   required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense  type="text" v-mask="'(###)###-####'" v-model="KoTMTEL" label="喪主電話" clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
            <v-text-field dense  type="text" v-mask="'(###)####-####'" v-model="KoTMTELK" label="喪主携帯" clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
           <v-text-field dense type="text" v-model="KoTMTMail" label="喪主Mail" clearable required background-color="#fafad2"/>
           <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense  type="text"  v-model="KoTMBIKO" label="備考" outlined  clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
          </v-row>
        <!-- お支払責任者 -->
          <v-row dense>
            <v-alert> </v-alert>
            <v-toolbar flat dark height="25"><v-toolbar-title style="font-size:0.7em">お支払責任者</v-toolbar-title></v-toolbar>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense v-model="KoTSNM"  clearable label="お支払責任者名" background-color="#fafad2"/>
            <v-alert dense> </v-alert>
            <v-text-field dense v-model="KoTSKNM"  clearable label="ふりがな" background-color="#fafad2"/>
            <v-alert dense> </v-alert>
            <v-btn  v-on:click="COPYMSAddress" >喪主からCOPY</v-btn>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
               <v-menu  ref="menu3" v-model="moda3"  :return-value.sync="date" persistent  width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense v-model="KoTSHYODT" label="お支払予定日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                </template>
                <v-date-picker v-model="KoTSHYODT" scrollable locale="jp-ja" :day-format="date => new Date(date).getDate()"></v-date-picker>
              </v-menu>
              <v-alert dense> </v-alert>
              <v-alert dense>{{ KoTSPNM }}</v-alert>             
              <v-btn color="primary" v-on:click="NKNDialogshow">ご入金方法</v-btn>
              <appDialogNK  :text="text" ref="dialogBRNK" @result="responseNK"></appDialogNK>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
              <v-text-field dense type="number" v-mask="'#######'" v-model="KoTSYU" style="width: 160px height:60px" label="〒" clearable required background-color="#fafad2"></v-text-field>
              <v-alert dense> </v-alert>
              <v-btn color="primary" v-on:click="SIHARAddress">〒検索</v-btn>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field v-model="KoTSJYU1" label="都道府県" clearable  required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
            <v-text-field v-model="KoTSJYU2" label="市町村区" clearable   required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field v-model="KoTSJYU3" label="町域" clearable   required background-color="#fafad2"></v-text-field>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
              <v-text-field dense type="text" v-mask="'(###) ###-####'" v-model="KoTSTEL" label="お支払電話"  clearable   required background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" v-mask="'(###) ####-####'" v-model="KoTSTELK" label="お支払携帯" clearable   required background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense  type="text" v-model="KoTSMail" label="お支払Mail" clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense  type="text" v-model="KoTSBIKO" label="備考" outlined clearable required background-color="#fafad2"/>
            <v-alert dense> </v-alert>
          </v-row>
          <!-- 宗旨 -->
          <v-row dense>
            <v-alert dense> </v-alert>
            <v-toolbar flat dark height="25"><v-toolbar-title style="font-size:0.7em">宗旨</v-toolbar-title></v-toolbar>
            <v-alert dense> </v-alert>
          </v-row>
 
          <v-row>
            <v-alert dense> </v-alert>
            <v-text-field dense type="text" clearable v-model="KoSGICNM" label="葬儀委員長" background-color="#fafad2"/>
             <v-alert dense> </v-alert>
            <v-text-field dense type="text" clearable v-model="KoSGICKN" label="フリガナ" background-color="#fafad2"/>
             <v-alert dense> </v-alert>
            <v-text-field dense type="text" clearable v-model="KoSGICZK" label="属性" background-color="#fafad2"/>
             <v-alert dense> </v-alert>
          </v-row>
          <v-row>
             <v-alert dense> </v-alert>
            <v-text-field dense type="text" clearable v-model="KoTSSKSA1" label="会葬挨拶" background-color="#fafad2"/>
            <v-alert dense> </v-alert>
            <v-text-field dense type="text" clearable v-model="KoTSSKMNM" label="家紋・紋章" background-color="#fafad2"/>
             <v-alert dense> </v-alert>
          </v-row>
          <v-row>
            <v-alert dense> </v-alert>
            <v-textarea outlined name="Bikou" v-model="KoTSSBIKO" label="引継ぎ事項" value  clearable background-color="#fafad2"></v-textarea>
            <v-alert dense> </v-alert>
          </v-row>
          <!-- ボタン -->
          <v-row>
                <v-col cols="6"  md="6" sm="6" xsmd="6"><div class="my-2"><v-btn x-large color="success" v-on:click="addKoMstdata" dark block><v-icon left>mdi-gavel</v-icon>{{ INUPBTN }}</v-btn></div></v-col>
                <v-col cols="6"  md="6" sm="6" xsmd="6"><div class="my-2"><v-btn x-large color="error" v-on:click="DELETEKoMstdata" dark v-if="DELBOshow"><v-icon left>mdi-delete</v-icon>削除処理</v-btn></div></v-col>
          </v-row>
          <v-row>
                <v-col sm="3" md="3"> 
                  <div class="my-2"><v-btn x-large  dark block v-on:click="PLDialogshow" v-if="DELBOshow"><v-icon left>mdi-checkbox-multiple-marked-outline</v-icon>施行プラン設定</v-btn></div>
                  <appDialogPLAND :text="text" ref="dialogPL" @result="responsePL"></appDialogPLAND>
                </v-col>
                <v-col sm="3" md="4">
                  <div class="my-2"><v-btn x-large  dark block v-on:click="INDialogshow" v-if="DELBOshow"><v-icon left>mdi-printer</v-icon>施行見積印刷</v-btn></div>
                  <appDialogIN :text="text" ref="dialogIN" @result="responseIN"></appDialogIN>
                </v-col>
                <v-col sm="3" md="3"> 
                  <div class="my-2"><v-btn x-large  dark block v-on:click="KMDialogshow" v-if="DELBOshow"><v-icon left>mdi-checkbox-multiple-marked-outline</v-icon>供物処理</v-btn></div>
                  <appDialogKM :text="text" ref="dialogKM" @result="responseKM"></appDialogKM>
                </v-col>
            </v-row>
          <v-row>
            <v-alert> </v-alert>
            <v-alert> </v-alert>
          </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
               <v-alert><strong>受付№</strong></v-alert>
               <v-alert>{{ KoTNO }}</v-alert>
              <v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
                <v-col cols="12" lg="12" md="12" sm="12" xsmd="12"><v-spacer>{{ message }}</v-spacer></v-col>
                <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12"><v-btn v-on:click="ERRORConfirm">エラー確認</v-btn></v-col>
              </v-alert>
            </v-row>
            <v-row>
              <v-alert dense> </v-alert>
              <v-btn color="primary"  v-on:click="RDDialogshow">リード</v-btn>
              <appDialogLD :text="text" ref="dialogRD" @result="responseRD"></appDialogLD>
              <v-alert dense> {{ KoTRDCD }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTRDNM }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTRDNMS }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTRDNM }}</v-alert>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTRDTN" label="リード備考" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
            </v-row>
            <v-row>
              <v-alert dense> </v-alert>
              <v-btn color="primary"  v-on:click="SALLUKTDialogshow">受付担</v-btn>
              <appDialogSALL :text="text" ref="dialogSYALL" @result="responseSYALL"></appDialogSALL>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTUKBCD }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTUKBNM }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTUKTCD }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTUKTNM }}</v-alert>
              <v-alert dense> </v-alert>
              <v-alert dense> {{ KoTUKTNMS }}</v-alert>
              <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert> </v-alert>
                <v-text-field dense v-model="KoTIRNM" clearable label="依頼人(漢字）" background-color="#fafad2"/>
                <v-alert dense>様</v-alert>
                <v-alert dense>{{ KoTIRZK }}</v-alert>
                <v-btn color="primary" v-on:click="ZKDialogshow">続柄</v-btn>
                <app-dialog :text="text" ref="dialogBR" @result="IRresponseBR"></app-dialog>
                <v-alert dense> </v-alert>
                <v-text-field dense  type="text" v-mask="'(###)####-####'" v-model="KoTMTELK" label="依頼人電話" clearable required/>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row dense>
                <v-alert> </v-alert>
                <v-text-field dense v-model="KoTDESBS" clearable label="搬送先(FROM)" background-color="#fafad2"/>
                <v-alert  dense>～</v-alert>
                <v-text-field dense v-model="KoTIRTO" clearable label="搬送先(TO)" background-color="#fafad2"/>
                <v-alert> </v-alert>
                <v-btn color="primary" v-on:click="KoTIRTO_JITK">自宅</v-btn>
                <v-alert dense> </v-alert>
                <v-alert> </v-alert>
                <v-btn color="primary" v-on:click="KoTIRTO_KAIA">会館</v-btn>
                <v-alert dense> </v-alert>
                <v-btn color="primary" v-on:click="KoTIRTO_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
            </v-row>
                <v-row dense>
                <v-alert> </v-alert>
                  <v-menu ref="menuUCDT" v-model="menuUCDT"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTIRDT" label="打合日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTIRDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                  <v-alert> </v-alert>
                <v-text-field dense type="time"  v-model="KoTIRJI" clearable label="時" style="width: 80px;" background-color="#fafad2"/>
                     <v-alert> </v-alert>
               </v-row>
               <v-row>
                <v-alert dense> </v-alert>
                <v-textarea outlined name="Bikou" v-model="KoTSSBIKO" label="その他" value  clearable background-color="#fafad2"></v-textarea>
                <v-alert dense> </v-alert>
              </v-row>
              <v-row>
                <v-alert dense> </v-alert>
                <v-textarea outlined name="Bikou" v-model="KoTIRKM" label="搬送・打合せ・その他（）" value  clearable background-color="#fafad2"></v-textarea>
                <v-alert dense> </v-alert>
              </v-row>
              <v-row>
                <v-alert dense> </v-alert>
                <v-textarea outlined name="Bikou" v-model="KoTIRSH" label="試用品目" value  clearable background-color="#fafad2"></v-textarea>
                <v-alert dense> </v-alert>
              </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
               <v-alert><strong>受付№</strong></v-alert>
               <v-alert>{{ KoTNO }}</v-alert>
               <v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
               <v-col cols="12" lg="12" md="12" sm="12" xsmd="12"><v-spacer>{{ message }}</v-spacer></v-col>
               <v-col cols="12" lg="12" md="12" sm="12" xsmd="12"><v-btn v-on:click="ERRORConfirm">エラー確認</v-btn></v-col>
               </v-alert>
               <v-alert> </v-alert>
            </v-row>
            <v-row>
              <v-col cols="3" xs="3" sm="3" md="3" lg="3" xl="3">
                <v-btn color="primary"  v-on:click="SEIGUKTDialogshow">主 担 当</v-btn>
                <appDialogSEIG :text="text" ref="dialogSYEIG" @result="responseSEIG"></appDialogSEIG>
              </v-col>
               <v-col cols="2" xs="1" sm="1" md="1" lg="1" xl="1">
                <v-alert dense> {{ KoTBLOKCD }}</v-alert>
              </v-col>
              <v-col cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
                <v-alert dense> {{ KoTBLOKNM }}</v-alert>
              </v-col>
             <v-col cols="1" xs="2" sm="2" md="2" lg="1" xl="1">
              <v-alert dense> {{ KoETNTNCD }}</v-alert>
             </v-col> 
             <v-col cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-alert dense> {{ KoETNTNNM }}</v-alert>
            </v-col>
               </v-row>
            <v-row>
              <v-col cols="3" xs="3" sm="3" md="3" lg="3" xl="3">
                <v-btn color="primary"  v-on:click="SSKIDialogshow" dense>司 会 者</v-btn>
                <appDialogSSKI :text="text" ref="dialogSYSKI" @result="responseSSKI" dense></appDialogSSKI>
               </v-col>
               <v-col cols="2" xs="1" sm="1" md="1" lg="1" xl="1">
              </v-col>
              <v-col cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
              </v-col>
              <v-col cols="1" xs="2" sm="2" md="2" lg="1" xl="1">
              <v-alert dense> {{ KoSKICD }}</v-alert>
              </v-col> 
             <v-col cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-alert dense> {{ KoSKINM }}</v-alert>
             </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" xs="3" sm="3" md="3" lg="3" xl="3">
                <v-btn color="primary"  v-on:click="SEIGUsKTDialogshow">サブ担当</v-btn>
                <appDialogSEIGs :text="text" ref="dialogSYEIGS" @result="responseSEIGs"></appDialogSEIGs>
              </v-col>
               <v-col cols="2" xs="1" sm="1" md="1" lg="1" xl="1">
              </v-col>
              <v-col cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
              </v-col>
              <v-col cols="1" xs="2" sm="2" md="2" lg="1" xl="1">
                <v-alert> {{ KoSVTNCD }}</v-alert>
              </v-col> 
             <v-col cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
              <v-alert> {{ KoSVNTNM }}</v-alert>
             </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" xs="3" sm="3" md="3" lg="3" xl="3">
                <v-btn color="primary"  v-on:click="SALLsDialogshow">経理担当</v-btn>
                <appDialogSALLs :text="text" ref="dialogSYALLs" @result="responseSYALLs"></appDialogSALLs>
               </v-col>
              <v-col cols="2" xs="1" sm="1" md="1" lg="1" xl="1">
              </v-col>
              <v-col cols="3" xs="2" sm="2" md="2" lg="2" xl="2">
              </v-col>
              <v-col cols="1" xs="2" sm="2" md="2" lg="1" xl="1">
                <v-alert> {{ KoKRTNCD }}</v-alert>
              </v-col>
              <v-col cols="1" xs="2" sm="2" md="2" lg="1" xl="1">
              <v-alert> {{ KoKRNTNM }}</v-alert>
             </v-col>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>M確定</v-alert>
                <v-alert> </v-alert>
                <v-select dense v-model="KoTMGKT" item-text="KoTMGKT" item-value="KoTMGKT" :items="KoTMGKTTB"  return-object/>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
                <v-alert> </v-alert>
                <v-alert border="left"  dense height="36" color="blue"  text>受付搬送</v-alert>
                <v-alert> {{ KoTDESBS }}</v-alert>
                <v-alert dense>～</v-alert>
                <v-alert dense> {{ KoTIRTO }}</v-alert>
                <v-alert dense> 打合日</v-alert>
                <v-alert> {{ KoTIRDT }}</v-alert>
                <v-alert> </v-alert>
                <v-alert> {{ KoTIRJI }}</v-alert>
                <v-alert><strong>：</strong></v-alert>
                <v-alert> {{ KoTIRFN }}</v-alert>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>式場</v-alert>
                <v-btn color="primary"  v-on:click="KoTHRJKBOT">{{ KoTHRJK }}</v-btn>
                <v-alert> {{ KoTKKCD }}</v-alert>
                <v-alert> </v-alert>
                <v-alert> {{ KoTKKNM }}</v-alert>
                <v-alert> </v-alert>
                <v-alert> {{ KoTHRCD }}</v-alert>
                <v-alert> </v-alert>
                <v-alert> {{ KoTHRNM }}</v-alert>
                <v-alert> </v-alert>
                <v-btn color="primary" v-on:click="KKHRshow">会館ホール</v-btn>
                <appDialogKKHR :text="text" ref="dialogKKHR" @result="responseKKHR"></appDialogKKHR>
                
                <!-- <v-btn color="primary" v-on:click="SAOJOshow">斎場HP状況</v-btn>
                <appDialogSAIJYO :text="text" ref="dialogSAOJO" @result="responseSAOJO"></appDialogSAIJYO> -->
                <v-alert> </v-alert>
                <v-btn color="primary" v-on:click="moveLink(`https://chibacity-funeralhall.jp/open/index.aspx`)">斎場HP状況</v-btn>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>通夜</v-alert>
                <v-btn color="primary"  v-on:click="KoTTYSJKBOT">{{ KoTTYSJK }}</v-btn>
                <v-menu ref="menu2" v-model="menuzz2"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTTYSDT" label="通夜日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTTYSDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert dense> </v-alert>
                <v-text-field dense type="time" v-model="KoTTYSJI" clearable label="開始" style="width: 80px;" background-color="#fafad2"/>
                <v-alert dense>～</v-alert>
                <v-text-field dense type="time" v-model="KoTTYEJI" clearable label="終了" style="width: 80px;" background-color="#fafad2"/>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
               <v-alert dense> </v-alert>
                <v-text-field dense v-model="KoTTYSBS" clearable label="通夜場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTTYSBS_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTTYSBS_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTTYSBS_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
                <v-select dense  class = "inputField input-name p-3 styled-input" v-model="KoTTYSSK" label="司会者有無"  :items="['','無','有']"/>
                <v-alert dense> </v-alert>
                <v-select dense class = "inputField input-name p-3 styled-input" v-model="KoTTYSAS" label="アシスタント有無" :items="['','無','有']"/>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>告別</v-alert>
                <v-btn color="primary"  v-on:click="KoTSSOGJKBOT">{{ KoTSSOGJK }}</v-btn>
                <v-menu ref="menu2" v-model="menuzz3"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTSSOGDT" label="告別日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTSSOGDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert> </v-alert>
                <v-text-field dense type="time" v-model="KoTSSOGSJI" clearable label="時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert>～</v-alert>
                <v-text-field dense type="time" v-model="KoTSSOGEJI" clearable label="時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
               <v-alert> </v-alert>
                <v-text-field dense v-model="KoTSSOSBS" clearable label="告別場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTSSOSBS_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTSSOSBS_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTSSOSBS_SAIJ">斎場</v-btn>
                <v-alert dense>司</v-alert>
                <v-select dense class = "inputField input-name p-3 styled-input"  v-model="KoTSSOSSK" label="司会者有無"  :items="['','無','有']"/>
                <v-alert dense>ア</v-alert>
                <v-select dense class = "inputField input-name p-3 styled-input"  v-model="KoTSSOGAS" label="アシスタント有無" :items="['','無','有']"/>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>本膳</v-alert>
                <v-btn color="primary"  v-on:click=" KoTHZJKBOT">{{ KoTHZJK }}</v-btn>
                <v-alert dense> </v-alert>
                <v-select dense class = "inputField input-name p-3 styled-input"  v-model="KoTSJD7" label="初七日" :items="['--','収骨後','式中']"/>
                <v-alert dense>場所</v-alert>
                <v-text-field dense v-model="KoTHZBS" clearable label="初七日場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTHZBS_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTHZBS_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTHZBS_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
           </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-text-field dense v-model="KoTHZNY" clearable label="枕飯・枕団子、供養膳" background-color="#fafad2"/>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>火葬</v-alert>
                <v-alert dense>{{ KoTSJCD }}</v-alert>
                <v-alert dense>{{ KoTSJNM }}</v-alert>
                <v-alert dense>{{ KoTSJNMS }}</v-alert>
                <!-- <v-btn color="primary" v-on:click="KKHRSJshow">斎場検索</v-btn>
                <appDialogKKHRSJ  :text="text" ref="dialogKKHRSJ" @result="responseKKHRSJ"></appDialogKKHRSJ> -->
                <v-btn color="primary" v-on:click="moveLink(`https://chibacity-funeralhall.jp/open/index.aspx`)">斎場HP状況</v-btn>
                <v-alert> </v-alert>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert dense>火葬日</v-alert>
                <v-menu ref="menu4" v-model="menuzz4"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTSJDT" label="火葬日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTSJDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert> </v-alert>
                <v-text-field dense type="time" v-model="KoTSJJI" clearable label="開始時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert>～</v-alert>
                <v-text-field dense type="time" v-model="KoTSJJI" clearable label="終了時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
               <v-select dense  class = "inputField input-name p-3 styled-input"   v-model="KoTSJDS" label="同行"  :items="['--','釜入迄','収骨迄']"/>
                  <v-select dense  class = "inputField input-name p-3 styled-input"   v-model="KoTSJHS" label="控室有無"  :items="['','無','有']"/>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>納棺</v-alert>
                <v-btn color="primary"  v-on:click="KoTNKJKBOT">{{ KoTNKJK }}</v-btn>
                <v-menu ref="menu2" v-model="menuzzNK"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTNKDT" label="納棺日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTNKDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert> </v-alert>
                <v-text-field dense type="time" v-model="KoTNKJI" clearable label="納棺時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert dense>メーク</v-alert>
                <v-text-field dense v-model="KoTNKMK" clearable label="メーク" background-color="#fafad2"/>
                <v-alert dense> </v-alert>
                <v-alert dense>場所</v-alert>
                <v-text-field dense v-model="KoTNKBS" clearable label="納棺場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTNKBS_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTNKBS_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTNKBS_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>通夜移動</v-alert>
                <v-btn color="primary"  v-on:click="KoTISU1JKBOT">{{ KoTISU1JK }}</v-btn>
                <v-menu ref="menu2" v-model="menuzzID1"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTISU1DT" label="通夜移動日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTISU1DT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert> </v-alert>
                <v-text-field dense type="time"   v-model="KoTISU1JI" clearable label="時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert dense>場所</v-alert>
                <v-text-field dense v-model="KoTISU1FR" clearable label="通夜移動場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTISU1FR_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTISU1FR_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTISU1FR_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
                <v-alert> </v-alert>
                <v-text-field dense type="number" v-mask="'##'"  v-model="KoTISU1BS" clearable label="バス" style="width: 80px;" background-color="#fafad2"/>
                <v-alert><strong>：</strong></v-alert>
                <v-text-field dense type="number" v-mask="'##'"  v-model="KoTISU1HY" clearable label="タクシー" style="width: 80px;" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>出棺移動</v-alert>
                <v-btn color="primary"  v-on:click="KoTISU2JKBOT">{{ KoTISU2JK }}</v-btn>
                <v-menu ref="menu2" v-model="menuzzID2"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTISU2DT" label="出棺移動日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTISU2DT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert> </v-alert>
                <v-text-field dense type="time" v-model="KoTISU2JI" clearable label="時" style="width: 80px;" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert dense>場所</v-alert>
                <v-text-field dense v-model="KoTISU2FR" clearable label="出棺移動場所" background-color="#fafad2"/>
                <v-btn color="primary" v-on:click="KoTISU2FR_JITK">自宅</v-btn>
                <v-btn color="primary" v-on:click="KoTISU2FR_KAIA">会館</v-btn>
                <v-btn color="primary" v-on:click="KoTISU2FR_SAIJ">斎場</v-btn>
                <v-alert dense> </v-alert>
                <v-alert> </v-alert>
                <v-text-field dense type="number" v-mask="'##'"  v-model="KoTISU1BS" clearable label="バス" style="width: 80px;" background-color="#fafad2"/>
                <v-alert><strong>：</strong></v-alert>
                <v-text-field dense type="number" v-mask="'##'"  v-model="KoTISU1HY" clearable label="タクシー" style="width: 80px;" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>霊柩車</v-alert>
                <v-btn color="primary"  v-on:click="KoRICJKBOT">{{ KoRICJK }}</v-btn>
                <v-text-field dense v-model="KoRICTK" clearable label="霊柩車" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>写真</v-alert>
                <v-btn color="primary"  v-on:click="KoTAZKPHJKBOT">{{ KoTAZKPHJK }}</v-btn>
                <v-text-field dense v-model="KoTAZKPB" clearable label="写真備考" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>許可証</v-alert>
                <v-btn color="primary"  v-on:click="KoTAZKKKJKBOT">{{ KoTAZKKKJK }}</v-btn>
                <v-text-field dense v-model="KoTAZKKB" clearable label="許可証備考" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>ドライ</v-alert>
                <v-btn color="primary"  v-on:click="KoTDRYJKBOT">{{ KoTDRYJK }}</v-btn>
                    <v-menu ref="menuDR1" v-model="menuzzDE1"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTDRY1DT" label="ドライ施行日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTDRY1DT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                    <v-menu ref="menuDR1" v-model="menuzzDE2"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTDRY2DT" label="ドライ施行日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTDRY2DT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                    <v-menu ref="menu21" v-model="menuzzDE3"  :return-value.sync="date"  persistent  width="150px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="KoTDRY3DT" label="ドライ施行日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on" background-color="#fafad2"></v-text-field>
                     </template>
                    <v-date-picker v-model="KoTDRY3DT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>礼状</v-alert>
                <v-btn color="primary"  v-on:click="KoREIJKBOT">{{ KoREIJK }}</v-btn>
                <v-text-field dense v-model="KoREIBK" clearable label="礼状備考" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>返礼品</v-alert>
                <v-btn color="primary"  v-on:click="KoHENJKBOT">{{ KoHENJK }}</v-btn>
                <v-text-field dense v-model="KoRIHINM" clearable label="返礼品備考" background-color="#fafad2"/>
                <v-alert></v-alert>
            </v-row>
            <v-row>
                <v-alert dense> </v-alert>
                <v-alert border="left"  dense height="36" width="100" color="blue"  text>果物</v-alert>
                <v-btn color="primary"  v-on:click="KoTKUDAMJBOT">{{ KoTKUDAMJ }}</v-btn>
                <v-alert></v-alert>
            </v-row>
            <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>寺院</v-alert>
              <v-text-field dense type="text" clearable v-model="KoTSSTRNM" label="寺院" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-btn color="primary"  v-on:click="JNDialogshow">寺院検索</v-btn>
              <app-dialogJN :text="text" ref="dialogJN" @result="responseJN"></app-dialogJN>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTSSTRBS" label="菩提寺" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTSSSONM" label="宗旨" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-btn color="primary" v-on:click="SHDialogshow">宗旨検索</v-btn>
              <app-dialog  :text="text" ref="dialogBR" @result="responseSH"></app-dialog>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>家紋</v-alert>
              <v-text-field dense type="text" clearable v-model="KoTSSKMNM" label="家紋・紋章" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>戒(法)名</v-alert>
              <v-alert dense> </v-alert>
              <v-select dense v-model="KoTMGKT" item-text="KoTSSTRHM" item-value="KoTSSTRHM" :items="KoTSSTRHMTB"  return-object background-color="#fafad2"/>
              <v-alert dense> </v-alert>
              <v-text-field dense type="number" v-mask="'###'"  v-model="KoTSSTROF" clearable label="お布施 万円" style="width: 80px;" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>備考1</v-alert>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTBIKO1" label="備考1(漢字)" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>備考2</v-alert>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTBIKO2" label="備考2(漢字)" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          <v-row>
              <v-alert dense> </v-alert>
              <v-alert border="left"  dense height="36" width="100" color="blue"  text>備考3</v-alert>
              <v-alert dense> </v-alert>
              <v-text-field dense type="text" clearable v-model="KoTBIKO3" label="備考3(漢字)" background-color="#fafad2"/>
              <v-alert dense> </v-alert>
          </v-row>
          </v-tab-item>
                <v-tab-item>
            <v-row>
               <v-alert><strong>受付№</strong></v-alert>
               <v-alert>{{ KoTNO }}</v-alert>
            </v-row>
            <v-row>
              <v-btn color="primary" dark class="mb-2"  v-on:click="SEKOUYOTEI_SHOW">施行予定明細表示</v-btn>
            </v-row>
             <v-app id="inspire"> 
          <v-data-table :headers="headers" :items="SKYOTrn" :sort-by="['SKYTDT', 'SKYTHH']">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="SKYTNM" label="施行名称"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu ref="menu" v-model="menuSKYTDT"  :return-value.sync="date"  persistent  width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="SKYTDT" label="施行日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="SKYTDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12"  sm="6" md="4"><v-text-field   v-model="SKYTHH"  label="開始時"/></v-col>
                    <v-col cols="12"  sm="6" md="4"><v-text-field   v-model="SKYSHH"  label="終了時"></v-text-field></v-col>
                    <v-col cols="12" sm="12" md="12"><v-text-field  v-model="SKYTFBNM" label="場所名称"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP1NM" label="名称"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP1DT" label="値"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP1TN" label="単位"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP2NM" label="名称"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP2DT" label="値"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP2TN" label="単位"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP3NM" label="名称"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP3DT" label="値"></v-text-field></v-col>
                    <v-col cols="12" sm="6" md="4"><v-text-field    v-model="SKYTP3TN" label="単位"></v-text-field></v-col>
                    <v-col cols="12" sm="12" md="12"><v-text-field  v-model="SKYTNY" label="備考"></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-data-table>
            </v-app>
          </v-tab-item>
      </v-tabs>
    </v-card>
  </v-app>
  </v-dialog>
  </v-container>
</template>
<script>
 //   Vue.use(VueMask.VueMaskPlugin);
</script>
<script>
import axios from 'axios';
import moment from "moment";
//import * as AutoKana from 'vanilla-autokana';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import { getKoTrn }     from "../graphql/queries";     //施行ヘッダー取得
//import { updateKoTrn }  from "../graphql/mutations";   //施行ヘッダー更新
//import { createKoTrn }  from "../graphql/mutations";   //施行ヘッダー追加
//import { deleteKoTrn }  from "../graphql/mutations";   //施行ヘッダー削除
//import axiosJsonpAdapter from 'axios-jsonp'
import BRMSDialog       from "./popBRMS.vue";             //分類マスタ
import BRJKDialog       from "./pop_jcBRMS";              //分類マスタ(状況)
import BRKJZDialog      from "./popBRKGZ";                //分類マスタ(故人続柄)
import BRMSZDialog      from "./popBRMSZ";                //分類マスタ(喪主続柄)
import BRNKDialog       from "./popBRNK";                 //分類マスタ(入金)
import SYAINALLDialog   from "./popSYAINALL";             //社員マスタ（全員）
import SYAINALLsDialog  from "./popSYAINALLs";            //社員マスタ（経理）
import SYAINEIGDialog   from "./popSYAINEIG";             //社員マスタ(営業)
import SYAINEIGsDialog  from "./popSYAINEIGS";            //社員マスタ(営業 2)
import SYAINSKIDialog   from "./popSYAINSKI";             //社員マスタ(司会)
import SMJNNDialog      from "./popSMJNN.vue";            //寺院マスタ
import LeadDialog       from "./popLead.vue";             //リード
import KKHRDialog       from "./popKaikanHR.vue";         //会館ホール
import KKHRSJDialog     from "./popKaikanSJ.vue";         //会館ホール
import KaiinSHDialog    from "./popKaiin.vue";            //会員マスタ
import PLANDialog       from "./SekoPLANinput.vue";       //プラン設定
import INSTDialog       from "./SekoInsatuiinp.vue";      //施行印刷マスタ
import KMDialog         from "./SekoKUMTICHR.vue";        //供物一覧
//import TESdialog        from "./SekoCyuhy3.vue";          //TEST帳票
export default { 
  data () {
    // テンプレート内で保有するデータ  
    return {
      dialogERROR:false,
      dialogUP:false,
      autokana:'', 
      ziptb: [],
      ziptb: [],
      KoTMGKTTB: ['--', '確定'],
      KoTTB: ['--','無','有'],
      KoTTB7: ['--','収骨後','式中'],
      KoTDKTB: ['--','釜入迄','収骨迄'],
      KoTSSTRHMTB: ['--','俗名','信士','信女','居士','大姉','院号','法名'],
      PL_Tanmtid:"",    //グローバル端末ID
      KoTNO:'',       //受付番号
      KoTURDT:'',     //売上日
      KoTUKDT:'',     //受付日
      KoTUKJI:'',     //受付時
      KoTUKFN:'',     //受付分
      KoTBLOKCD:'',   //部署コード
      KoTBLOKNM:'',   //部署名称
      KoETNTNCD:'',   //営業担当コード
      KoETNTNNM:'',   //営業担当名称
      KoETNTNNMS:'',  //主担当略称
      KoTKANO:'',     //会員番号
      KoTKANM:'',     //会員名称
      KoTMOKE:'',     //喪家
      KoThCD:'',      //顧客取引先コード
      KoThNM:'',      //顧客取引先名称
      KoTCD:'',       //顧客提携区分コード
      KoTNM:'',       //顧客提携区分名称
      KoTTKNM:'',     //故人(カナ）
      KoTTNM:'',      //故人名前
      KoTTBSDT:'',    //故人生年月日(西暦)
      KoTTBSDTJ:'',   //故人生年月日(和暦)
      KoTTEG:'',      //故人年齢
      KoTTSEX:'',     //故人性別
      KoTTNEX:'--',   //故人続柄
      testk:'--',     //故人続柄
      KoTTYU:'',      //故人郵便番号
      KoTTJYU1:'',    //故人住所1
      KoTTJYU2:'',    //故人住所2
      KoTTJYU3:'',    //故人住所3
      KoTTTEL:'',     //故人電話番号
      KoTMKNM:'',     //喪主(カナ）
      KoTMNM:'',      //喪主名前
      KoTMNEX:'',     //喪主続柄
      KoTMSEX:'',     //喪主性別
      KoTMYU:'',      //喪主郵便番号
      KoTMJYU1:'',    //喪主住所1
      KoTMJYU2:'',    //喪主住所2
      KoTMJYU3:'',    //喪主住所3
      KoTMTEL:'',     //喪主電話番号
      KoTMTELK:'',    //喪主携帯
      KoTMTMail:'',   //喪主Mail
      KoTMBIKO:'',    //喪主備考
      KoTSNM:'',      //お支払責任者名前
      KoTSKNM:'',     //お支払カナ
      KoTSHYODT:'',   //お支払予定日
      KoTSPNM:'',     //入金方法名
      KoTSYU:'',      //支払責任者郵便番号
      KoTSJYU1:'',    //お支払責任者住所1
      KoTSJYU2:'',    //お支払責任者住所2
      KoTSJYU3:'',    //お支払責任者住所3
      KoTSTEL:'',     //お支払責任者電話番号
      KoTSTELK:'',    //お支払責任者携帯
      KoTSMail:'',    //お支払責任者Mail
      KoTSBIKO:'',    //お支払責任者備考
      KoTSSTRNM:'',   //寺院名称
      KoTSSSONM:'',   //宗旨名称
      KoTSSKSA1:'',   //会葬挨拶1
      KoTSSKMNM:'',   //家紋・紋章名称
      KoTSSBIKO:'',   //備考
      KoTUUKBDT:'',   //受付簿印刷日
      KoTUUKBTM:'',   //受付簿印時間
      KoTUMSKSDT:'',  //請求書印刷日
      KoTUMSKSTM:'',  //請求書印刷時間
      KoTUTKSSDT:'',  //立替請求書印刷日
      KoTUTKSSTM:'',  //立替請求書印刷時間
      KoTPLCD:'',     //プランコード
      KoTPLNM:'',     //プラン名称
      KoTPLGK:'',     //プラン金額
      KoTKKCD:'',     //会館CD
      KoTKKNM:'',     //会館名
      KoTHRCD:'',     //ホールCD
      KoTHRNM:'',     //ホール名
      KoTHRJK:'〇',   //会館ホール状況
      KoTHRNMS:'',    //会館ホール略称
      KoTSEYNM:'',    //施行予定名
      KoTDESDT:'',    //死亡日(西暦)
      KoTDESDTJ:'',   //死亡日(和暦)
      KoTDESBS:'',    //死亡場所
      KoTDESJI:'',    //死亡時
      KoTDESFN:'',    //死亡分 
      KoTTYSDT:'',    //通夜日
      KoTTYSJI:'',    //通夜時
      KoTTYSFN:'',    //通夜分
      KoTTYEJI:'',    //通夜終了時
      KoTTYEFN:'',    //通夜終了分
      KoTTYSBS:'',    //通夜場所
      KoTTYSJK:'〇',  //通夜状況
      KoTTYSSK:'',    //通夜司会
      KoTTYSAS:'',    //通夜アシスタント
      KoTSSOGDT:'',   //葬儀日
      KoTSSOGSJI:'',  //葬儀時
      KoTSSOGSFN:'',  //葬儀分
      KoTSSOGEJI:'',  //出棺時
      KoTSSOGEFN:'',  //出棺分
      KoTSSOSBS:'',   //葬儀場所
      KoTSSOSSK:'',   //葬儀司会
      KoTSSOGJK:'〇', //葬儀状況
      KoTSSOGAS:'',   //葬儀アシスタント
      KoTSJDT:'',     //火葬日
      KoTSJCD:'',     //斎場コード
      KoTSJNM:'',     //斎場名称
      KoTSJNMS:'',    //斎場略称
      KoTSJJI:'',     //火葬時
      KoTSJFN:'',     //火葬分
      KoTSJEJI:'',	  //火葬終了時
      KoTSJEFN:'',	  //火葬終了分
      KoTSJDS:'',     //導師
      KoTSJD7:'',     //初七日
      KoTHZJK:'〇',   //本膳状況
      KoTSJHS:'',     //控室
      KoTYOYK:'',     //斎場予約
      KoTURSMST:'',   //祭事標準税売上(税抜)
      KoTURSMKG:'',   //祭事軽減税売上(税抜)
      KoTURSMHK:'',   //祭事非課税売上(税抜)
      KoTSNEBST:'',   //祭事標準税値引
      KoTSNEBKG:'',   //祭事軽減税値引
      KoTSNEBHK:'',   //祭事非課税値引
      KoTSKAZST:'',   //祭事標準税対象額計
      KoTSKAZKG:'',   //祭事軽減税対象額計
      KoTSKAZHK:'',   //祭事非課税対象額計
      KoTSSYOST:'',   //祭事標準税消費税
      KoTSSYOKG:'',   //祭事軽減税消費税
      KoTSURKGK:'',   //祭事売上(税込み)
      KoTURTMST:'',   //立替標準税売上(税抜)
      KoTURTMKG:'',   //立替軽減税売上(税抜)
      KoTURTMHK:'',   //立替非課税売上(税抜)
      KoTTNEBST:'',   //立替標準税値引
      KoTTNEBKG:'',   //立替軽減税値引
      KoTTNEBHK:'',   //立替非課税値引
      KoTTKAZST:'',   //立替標準税対象額計
      KoTTKAZKG:'',   //立替軽減税対象額計
      KoTTKAZHK:'',   //立替非課税対象額計
      KoTTSYOST:'',   //立替標準税消費税
      KoTTSYOKG:'',   //立替軽減税消費税
      KoTTURKGK:'',   //立替売上(税込み)
      KoTSGK:'',      //売掛金総額
      KoTSHNKG:'',    //売掛入金金額
      KoTSHZNG:'',    //売掛残高
      KoSGICNM:'',    //葬儀委員長名
      KoSGICKN:'',    //葬儀委員長かな名
      KoSGICZK:'',    //葬儀委員長属性
      KoDMKB:'',      //DM区分
      KoHJGK:'',      //標準原価計
      KoARRI:'',      //粗利益計
      KoTJJK:'',      //受注進捗状況
      KoTIRNM:'',     //依頼人
      KoTIRZK:'',     //依頼人続柄
      KoTIRTL:'',     //依頼人電話番号
      KoTIRTO:'',     //搬送先()
      KoTIRDT:'',     //打合日
      KoTIRJI:'',     //打合時
      KoTIRFN:'',     //打合分
      KoTIRBK:'',     //その他･備考
      KoTIRKM:'',     //搬送・打合せ・その他（）
      KoTIRSH:'',     //使用品目
      KoTRDCD:'',     //リードコード
      KoTRDNM:'',     //リード名
      KoTRDNMS:'',    //リード略称
      KoTRDTN:'',     //リード担当名
      KoTRDRV:'',     //リードレベル
      KoSKICD:'',     //司会者コード
      KoSKINM:'',     //司会者名
      KoSKINMS:'',    //司会者略称
      KoSVTNCD:'',    //サブ担当コード
      KoSVNTNM:'',    //サブ担当名
      KoSVNTNMS:'',   //サブ担当略称
      KoKRTNCD:'',    //経理担当コード
      KoKRNTNM:'',    //経理担当名
      KoTNKDT:'',     //納棺日
      KoTNKJI:'',     //納棺時
      KoTNKFN:'',     //納棺分
      KoTNKMK:'',     //納棺メーク
      KoTNKJK:'〇',   //納棺状況
      KoTNKBS:'',     //納棺場所
      KoTISU1DT:'',   //移動1日
      KoTISU1JI:'',   //移動1時
      KoTISU1FN:'',   //移動1分
      KoTISU1FR:'',   //F移動1移動元
      KoTISU1BS:'',   //バス台数
      KoTISU1HY:'',   //ハイヤー台数
      KoTISU1JK:'〇', //移動1状況
      KoTISU2DT:'',   //移動2日
      KoTISU2JI:'',   //移動2時
      KoTISU2FN:'',   //移動2分
      KoTISU2FR:'',   //F移動2移動元
      KoTISU2BS:'',   //バス台数
      KoTISU2HY:'',   //ハイヤー台数
      KoTISU2JK:'〇', //移動2状況
      KoTDRY1DT:'',   //ドライ1
      KoTDRY2DT:'',   //ドライ2
      KoTDRY3DT:'',   //ドライ3
      KoTDRYJK:'〇',  //ドライ状況
      KoREIJK:'〇',   //礼状状況
      KoREIBK:'',     //礼状備考
      KoHENJK:'〇',   //返礼品状況
      KoRIHINM:'',    //返礼品品目
      KoRICJK:'〇',   //霊柩車状況
      KoRICTK:'',     //霊柩車立替
      KoTSSTRJK:'',   //寺院状況
      KoTSSTRBS:'',   //菩提寺
      KoTSSTRHM:'',   //戒(法)名
      KoTSSTROF:'',   //お布施
      KoTKUDAMJ:'〇', //果物状況
      KoTHZBS:'',     //本膳場所
      KoTHZNY:'',     //枕飯・枕団子、供養膳
      KoTAZKPHJK:'〇',//写真状況
      KoTAZKPB:'',    //写真備考
      KoTAZKKKJK:'〇',//許可書状況
      KoTAZKKB:'',    //許可備考
      KoTHCDT:'',     //発注日
      KoTHCJI:'',     //発注時
      KoTHCFN:'',     //発注分
      KoTMGKT:'',     //担当者確定
      KoTBIKO1:'',    //備考1
      KoTBIKO2:'',    //備考2
      KoTBIKO3:'',    //備考3
      KoMUKBDT:'',    //見積受付簿印刷日
      KoMUKBTM:'',    //見積受付簿印刷時間
      KoMSKSDT:'',    //見積祭事印刷日
      KoMSKSTM:'',    //見積祭事印刷時間
      KoMTKSDT:'',    //見積立替印刷日
      KoMTKSTM:'',    //見積立替印刷時間
      KoTHCENDF:'',    //発注完了日
      KoTTTELN:'',    //故人電話番号CTI
      KoTMTELN:'',    //祭事喪主電話番号CTI
      KoTMTELKN:'',   //祭事喪主携帯CTI
      KoTSTELN:'',    //祭事お支払責任者電話番号CTI
      KoTSTELKN:'',   //祭事お支払責任者携帯CTI
      KoTIRTLN:'',    //祭事依頼人CTI
      RInstDt:'',      //作成日時
      InstId:'',      //作成者
      UpdtDt:'',      //更新日時
      UpdtId:'',      //更新者
      KoTTBSYY:"",
      KoTTBSMM:"",
      KoTTBSDD:"",
      KoTUKBCD:"",
      KoTUKBNM:"",
      KoTUKTCD:"",
      KoTUKTNM:"",
      KoTUKTNMS:"",
      zipcode: '',
      prefecture: '',
      address1: '',
      address2: '',
      address3:'',
      error_msg:'',
      kojintel:'',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,
      menuzz:false,
      menuUCDT:false,
      modal: false,
      moda2:false,
      moda3:false,
      modal11:false,
      modal12:false,
      modal13:false,
      modal14:false,
      menuzzDE1:false,
      menuzzDE2:false,
      menuzzDE3:false,
      menuzz2:false,
      menuzz3:false,
      menuzz4:false,
      menuzzNK:false,
      menuzzID1:false,
      menuzzID2:false,
      menuSKYTDT:false,
      KoTTNG_KNJ: '',
      title: '',
      TANNM: '',
      Kojinnm: '',
      KoTKGDT:'',     //KoTSSOGDT
      NENsuuji:'',
      TUKIsuuji:'',
      HIsuuji:'',
      TOSOsuuji:'',
      search: '',
      select: null,
      NENGOU:'S',
      SEXFLG: false, //false:女 true:男
      nengouitem: ['----','大正','昭和','平成', '令和'],
      MSGTEST:'',
      message:'',
      starttm: false,
      endtm: false,
      dialogx:"",
      dialoged:"",
      text: "",
      editedIndex: -1,
      editedItem:  {SKYTGYO:"",SKYTKB:"",SKYTNM:"",SKYTDT:"",name:"",calories:"",fat:"",carbs:"",protein:""},
      defaultItem: {SKYTGYO:"",SKYTKB:"",SKYTNM:"",SKYTDT:"",name:"",calories:"",fat:"",carbs:"",protein:""},
      GT_CNTNO:0,
      UP_CNTNO:0,
      KgyCd:'',
      KgyNM:'',
      BLCKCD:'',
      BLCKNM:'',
      SynCd:'',
      SynNm:'',
      TANID:'',
      DENNO:'',
      INUPFLG:'IN',
      INUPBTN:'施行基本情報 登録 処理',
      DELBOshow: true,
      PS_KoTNO:"",
      FLGZK:"",
      SKYTDT:"",
     headers: [
        { text: '施行名称', value: 'SKYTNM',width: '150' },
        { text: '施行日', value: 'SKYTDT',width: '120' },
        { text: '開始時', value: 'SKYTHH',width: '90' },
        { text: '終了時', value: 'SKYSHH',width: '90' },
        { text: '場所名称', value: 'SKYTFBNM',width: '200' },
        { text: '名称', value: 'SKYTP1NM',width: '150' },
        { text: '値', value: 'SKYTP1DT',width: '80' },
        { text: '単位', value: 'SKYTP1TN',width: '80' },
        { text: '名称', value: 'SKYTP2NM',width: '150' },
        { text: '値', value: 'SKYTP2DT',width: '80' },
        { text: '単位', value: 'SKYTP2TN',width: '80' },
        { text: '名称', value: 'SKYTP3NM',width: '150' },
        { text: '値', value: 'SKYTP3DT',width: '80' },
        { text: '単位', value: 'SKYTP3TN',width: '80' },
        { text: '備考', value: 'SKYTNY',width: '250' },
      ],
      defaultItem: {
        SKYTGYO: "",             //行
        SKYTKB:  "",             //区分
        SKYTNM:  "",             //施行名称
        SKYTDT:  "",             //施行日
        SKYTHH:  "",             //開始時
        SKYTMM:  "",             //開始分
        SKYSHH:  "",             //終了時
        SKYSMM:  "",             //終了分
        SKYTFBNM:"",             //場所名称
        SKYTP1NM:"",             //名称
        SKYTP1DT:"",	            //値
        SKYTP1TN:"",             //単位
        SKYTP2NM:"",             //名称
        SKYTP2DT:"",             //値
        SKYTP2TN:"",	            //単位
        SKYTP3NM:"",             //名称
        SKYTP3DT:"",             //値
        SKYTP3TN:"",	          //単位
        SKYTNY: "",              //備考
      }, 
        SKYTGYO: "",             //行
        SKYTKB:  "",             //区分
        SKYTNM:  "",             //施行名称
        SKYTDT:  "",             //施行日
        SKYTHH:  "",             //開始時
        SKYTMM:  "",             //開始分
        SKYSHH:  "",             //終了時
        SKYSMM:  "",             //終了分
        SKYTFBNM:"",             //場所名称
        SKYTP1NM:"",             //名称
        SKYTP1DT:"",	            //値
        SKYTP1TN:"",             //単位
        SKYTP2NM:"",             //名称
        SKYTP2DT:"",             //値
        SKYTP2TN:"",	            //単位
        SKYTP3NM:"",             //名称
        SKYTP3DT:"",             //値
        SKYTP3TN:"",	          //単位
        SKYTNY: "",              //備考
      SKYOTrn: [],
    }
   },
  methods: {
      //オープン時
    open() {
      //autokana = AutoKana.bind('#KoTSNM', '#KoTSKNM');
      this.dialogUP = true;
      this.PL_KIGYOUID = this.$store.getters.GET_kigyoucd;  //グローバル企業コード
      this.PL_Tanmtid  = this.$store.getters.GET_Tanmtid;   //グローバル端末ID
    
      if(localStorage.SOGI_KoTNO)       this.KoTNO      = localStorage.SOGI_KoTNO;           //受付番号
      if(localStorage.SOGI_KoTURDT)     this.KoTURDT    = localStorage.SOGI_KoTURDT;         //売上日
      if(localStorage.SOGI_KoTUKDT)     this.KoTUKDT    = localStorage.SOGI_KoTUKDT;         //受付日
      if(localStorage.SOGI_KoTUKJI)     this.KoTUKJI    = localStorage.SOGI_KoTUKJI;         //受付時
      if(localStorage.SOGI_KoTUKFN)     this.KoTUKFN    = localStorage.SOGI_KoTUKFN;         //受付分
      if(localStorage.SOGI_KoTBLOKCD)   this.KoTBLOKCD  = localStorage.SOGI_KoTBLOKCD;       //部署コード
      if(localStorage.SOGI_KoTBLOKNM)   this.KoTBLOKNM  = localStorage.SOGI_KoTBLOKNM;       //部署名称
      if(localStorage.SOGI_KoETNTNCD)   this.KoETNTNCD  = localStorage.SOGI_KoETNTNCD;       //営業担当コード
      if(localStorage.SOGI_KoETNTNNM)   this.KoETNTNNM  = localStorage.SOGI_KoETNTNNM;       //営業担当名称
      if(localStorage.SOGI_KoETNTNNMS)  this.KoETNTNNMS = localStorage.SOGI_KoETNTNNMS;      //主担当略称
      if(localStorage.SOGI_KoTKANO)     this.KoTKANO    = localStorage.SOGI_KoTKANO;         //会員番号
      if(localStorage.SOGI_KoTKANM)     this.KoTKANM    = localStorage.SOGI_KoTKANM;         //会員名称
      if(localStorage.SOGI_KoTMOKE)     this.KoTMOKE    = localStorage.SOGI_KoTMOKE;         //喪家
      if(localStorage.SOGI_KoThCD)      this.KoThCD     = localStorage.SOGI_KoThCD;          //顧客取引先コード
      if(localStorage.SOGI_KoThNM)      this.KoThNM     = localStorage.SOGI_KoThNM;          //顧客取引先名称
      if(localStorage.SOGI_KoTCD)       this.KoTCD      = localStorage.SOGI_KoTCD;           //顧客提携区分コード
      if(localStorage.SOGI_KoTNM)       this.KoTNM      = localStorage.SOGI_KoTNM;           //顧客提携区分名称
      if(localStorage.SOGI_KoTTKNM)     this.KoTTKNM    = localStorage.SOGI_KoTTKNM;         //故人(カナ）
      if(localStorage.SOGI_KoTTNM)      this.KoTTNM     = localStorage.SOGI_KoTTNM;          //故人名前
      if(localStorage.SOGI_KoTTBSDTJ)   this.KoTTBSDTJ  = localStorage.SOGI_KoTTBSDTJ;       //故人生年月日(和暦）
      if(localStorage.SOGI_KoTTEG)      this.KoTTEG     = localStorage.SOGI_KoTTEG;          //故人年齢
      if(localStorage.SOGI_KoTTSEX)     this.KoTTSEX    = localStorage.SOGI_KoTTSEX;         //故人性別
      if(localStorage.SOGI_KoTTNEX)     this.KoTTNEX    = localStorage.SOGI_KoTTNEX;         //故人続柄
      if(localStorage.SOGI_KoTTYU)      this.KoTTYU     = localStorage.SOGI_KoTTYU;          //故人郵便番号
      if(localStorage.SOGI_KoTTJYU1)    this.KoTTJYU1   = localStorage.SOGI_KoTTJYU1;        //故人住所1
      if(localStorage.SOGI_KoTTJYU2)    this.KoTTJYU2   = localStorage.SOGI_KoTTJYU2;        //故人住所2
      if(localStorage.SOGI_KoTTJYU3)    this.KoTTJYU3   = localStorage.SOGI_KoTTJYU3;        //故人住所3
      if(localStorage.SOGI_KoTTTEL)     this.KoTTTEL    = localStorage.SOGI_KoTTTEL;         //故人電話番号
      if(localStorage.SOGI_KoTMKNM)     this.KoTMKNM    = localStorage.SOGI_KoTMKNM;         //喪主(カナ）
      if(localStorage.SOGI_KoTMNM)      this.KoTMNM     = localStorage.SOGI_KoTMNM;          //喪主名前
      if(localStorage.SOGI_KoTMNEX)     this.KoTMNEX    = localStorage.SOGI_KoTMNEX;         //喪主続柄
      if(localStorage.SOGI_KoTMSEX)     this.KoTMSEX    = localStorage.SOGI_KoTMSEX;         //喪主性別
      if(localStorage.SOGI_KoTMYU)      this.KoTMYU     = localStorage.SOGI_KoTMYU;          //喪主郵便番号
      if(localStorage.SOGI_KoTMJYU1)    this.KoTMJYU1   = localStorage.SOGI_KoTMJYU1;        //喪主住所1
      if(localStorage.SOGI_KoTMJYU2)    this.KoTMJYU2   = localStorage.SOGI_KoTMJYU2;        //喪主住所2
      if(localStorage.SOGI_KoTMJYU3)    this.KoTMJYU3   = localStorage.SOGI_KoTMJYU3;        //喪主住所3
      if(localStorage.SOGI_KoTMTEL)     this.KoTMTEL    = localStorage.SOGI_KoTMTEL;         //喪主電話番号
      if(localStorage.SOGI_KoTMTELK)    this.KoTMTELK   = localStorage.SOGI_KoTMTELK;        //喪主携帯
      if(localStorage.SOGI_KoTMTMail)   this.KoTMTMail  = localStorage.SOGI_KoTMTMail;       //喪主Mail
      if(localStorage.SOGI_KoTMBIKO)    this.KoTMBIKO   = localStorage.SOGI_KoTMBIKO;        //喪主備考
      if(localStorage.SOGI_KoTSNM)      this.KoTSNM     = localStorage.SOGI_KoTSNM;          //お支払責任者名前
      if(localStorage.SOGI_KoTSKNM)     this.KoTSKNM    = localStorage.SOGI_KoTSKNM;         //お支払カナ
      if(localStorage.SOGI_KoTSHYODT)   this.KoTSHYODT  = localStorage.SOGI_KoTSHYODT;       //お支払予定日
      if(localStorage.SOGI_KoTSPNM)     this.KoTSPNM    = localStorage.SOGI_KoTSPNM;         //入金方法名
      if(localStorage.SOGI_KoTSYU)      this.KoTSYU     = localStorage.SOGI_KoTSYU;          //支払責任者郵便番号
      if(localStorage.SOGI_KoTSJYU1)    this.KoTSJYU1   = localStorage.SOGI_KoTSJYU1;        //お支払責任者住所1
      if(localStorage.SOGI_KoTSJYU2)    this.KoTSJYU2   = localStorage.SOGI_KoTSJYU2;        //お支払責任者住所2
      if(localStorage.SOGI_KoTSJYU3)    this.KoTSJYU3   = localStorage.SOGI_KoTSJYU3;        //お支払責任者住所3
      if(localStorage.SOGI_KoTSTEL)     this.KoTSTEL    = localStorage.SOGI_KoTSTEL;         //お支払責任者電話番号
      if(localStorage.SOGI_KoTSTELK)    this.KoTSTELK   = localStorage.SOGI_KoTSTELK;        //お支払責任者携帯
      if(localStorage.SOGI_KoTSMail)    this.KoTSMail   = localStorage.SOGI_KoTSMail;        //お支払責任者Mail
      if(localStorage.SOGI_KoTSBIKO)    this.KoTSBIKO   = localStorage.SOGI_KoTSBIKO;        //お支払責任者備考
      if(localStorage.SOGI_KoTSSTRNM)   this.KoTSSTRNM  = localStorage.SOGI_KoTSSTRNM;       //寺院名称
      if(localStorage.SOGI_KoTSSSONM)   this.KoTSSSONM  = localStorage.SOGI_KoTSSSONM;       //宗旨名称
      if(localStorage.SOGI_KoTSSKSA1)   this.KoTSSKSA1  = localStorage.SOGI_KoTSSKSA1;       //会葬挨拶1
      if(localStorage.SOGI_KoTSSKMNM)   this.KoTSSKMNM  = localStorage.SOGI_KoTSSKMNM;       //家紋・紋章名称
      if(localStorage.SOGI_KoTSSBIKO)    this.KoTSSBIKO  = localStorage.SOGI_KoTSSBIKO;        //引継ぎ備考 
      if(localStorage.SOGI_KoTUUKBDT)   this.KoTUUKBDT  = localStorage.SOGI_KoTUUKBDT;       //受付簿印刷日
      if(localStorage.SOGI_KoTUUKBTM)   this.KoTUUKBTM  = localStorage.SOGI_KoTUUKBTM;       //受付簿印時間
      if(localStorage.SOGI_KoTUMSKSDT)  this.KoTUMSKSDT = localStorage.SOGI_KoTUMSKSDT;      //請求書印刷日
      if(localStorage.SOGI_KoTUMSKSTM)  this.KoTUMSKSTM = localStorage.SOGI_KoTUMSKSTM;      //請求書印刷時間
      if(localStorage.SOGI_KoTUTKSSDT)  this.KoTUTKSSDT = localStorage.SOGI_KoTUTKSSDT;      //立替請求書印刷日
      if(localStorage.SOGI_KoTUTKSSTM)  this.KoTUTKSSTM = localStorage.SOGI_KoTUTKSSTM;      //立替請求書印刷時間
      if(localStorage.SOGI_KoTPLCD)     this.KoTPLCD    = localStorage.SOGI_KoTPLCD;         //プランコード
      if(localStorage.SOGI_KoTPLNM)     this.KoTPLNM    = localStorage.SOGI_KoTPLNM;         //プラン名称
      if(localStorage.SOGI_KoTPLGK)     this.KoTPLGK    = localStorage.SOGI_KoTPLGK;         //プラン金額
      if(localStorage.SOGI_KoTKKCD)     this.KoTKKCD    = localStorage.SOGI_KoTKKCD;         //会館CD
      if(localStorage.SOGI_KoTKKNM)     this.KoTKKNM    = localStorage.SOGI_KoTKKNM;         //会館名
      if(localStorage.SOGI_KoTHRCD)     this.KoTHRCD    = localStorage.SOGI_KoTHRCD;         //ホールCD
      if(localStorage.SOGI_KoTHRNM)     this.KoTHRNM    = localStorage.SOGI_KoTHRNM;         //ホール名
      if(localStorage.SOGI_KoTHRJK)     this.KoTHRJK    = localStorage.SOGI_KoTHRJK;         //会館ホール略称
      if(localStorage.SOGI_KoTHRNMS)    this.KoTHRNMS   = localStorage.SOGI_KoTHRNMS;        //会館ホール略称
      if(localStorage.SOGI_KoTDESDTJ)   this.KoTDESDTJ  = localStorage.SOGI_KoTDESDTJ;       //死亡日(和暦
      if(localStorage.SOGI_KoTDESBS)    this.KoTDESBS   = localStorage.SOGI_KoTDESBS;        //死亡場所
      if(localStorage.SOGI_KoTDESJI)    this.KoTDESJI   = localStorage.SOGI_KoTDESJI;        //死亡時
      if(localStorage.SOGI_KoTDESFN)    this.KoTDESFN   = localStorage.SOGI_KoTDESFN;        //死亡分 
      if(localStorage.SOGI_KoTTYSDT)    this.KoTTYSDT   = localStorage.SOGI_KoTTYSDT;        //通夜日
      if(localStorage.SOGI_KoTTYSJI)    this.KoTTYSJI   = localStorage.SOGI_KoTTYSJI;        //通夜時
      if(localStorage.SOGI_KoTTYSFN)    this.KoTTYSFN   = localStorage.SOGI_KoTTYSFN;        //通夜分
      if(localStorage.SOGI_KoTTYEJI)    this.KoTTYEJI   = localStorage.SOGI_KoTTYEJI;        //通夜終了時
      if(localStorage.SOGI_KoTTYEFN)    this.KoTTYEFN   = localStorage.SOGI_KoTTYEFN;        //通夜終了分
      if(localStorage.SOGI_KoTTYSBS)    this.KoTTYSBS   = localStorage.SOGI_KoTTYSBS;        //通夜場所
      if(localStorage.SOGI_KoTTYSJK)    this.KoTTYSJK   = localStorage.SOGI_KoTTYSJK;        //通夜状況
      if(localStorage.SOGI_KoTTYSSK)    this.KoTTYSSK   = localStorage.SOGI_KoTTYSSK;        //通夜司会
      if(localStorage.SOGI_KoTSSOGDT)   this.KoTSSOGDT  = localStorage.SOGI_KoTSSOGDT;       //葬儀日
      if(localStorage.SOGI_KoTSSOGSJI)  this.KoTSSOGSJI = localStorage.SOGI_KoTSSOGSJI;      //葬儀時
      if(localStorage.SOGI_KoTSSOGSFN)  this.KoTSSOGSFN = localStorage.SOGI_KoTSSOGSFN;      //葬儀分
      if(localStorage.SOGI_KoTSSOGEJI)  this.KoTSSOGEJI = localStorage.SOGI_KoTSSOGEJI;      //出棺時
      if(localStorage.SOGI_KoTSSOGEFN)  this.KoTSSOGEFN = localStorage.SOGI_KoTSSOGEFN;      //出棺分
      if(localStorage.SOGI_KoTSSOSBS)   this.KoTSSOSBS  = localStorage.SOGI_KoTSSOSBS;       //葬儀場所
      if(localStorage.SOGI_KoTSSOSSK)   this.KoTSSOSSK  = localStorage.SOGI_KoTSSOSSK;       //葬儀司会
      if(localStorage.SOGI_KoTSSOGAS)   this.KoTSSOGAS  = localStorage.SOGI_KoTSSOGAS;       //葬儀アシスタント
      if(localStorage.SOGI_KoTSSOGJK)   this.KoTSSOGJK  = localStorage.SOGI_KoTSSOGJK;       //葬儀状況
      if(localStorage.SOGI_KoTSJDT)     this.KoTSJDT    = localStorage.SOGI_KoTSJDT;         //火葬日
      if(localStorage.SOGI_KoTSJCD)     this.KoTSJCD    = localStorage.SOGI_KoTSJCD;         //斎場コード
      if(localStorage.SOGI_KoTSJNM)     this.KoTSJNM    = localStorage.SOGI_KoTSJNM;         //斎場名称
      if(localStorage.SOGI_KoTSJNMS)    this.KoTSJNMS   = localStorage.SOGI_KoTSJNMS;        //斎場略称
      if(localStorage.SOGI_KoTSJJI)     this.KoTSJJI    = localStorage.SOGI_KoTSJJI;         //火葬時
      if(localStorage.SOGI_KoTSJFN)     this.KoTSJFN    = localStorage.SOGI_KoTSJFN;         //火葬分
      if(localStorage.SOGI_KoTSJEJI)    this.KoTSJEJI   = localStorage.SOGI_KoTSJEJI;        //火葬終了時
      if(localStorage.SOGI_KoTSJEFN)    this.KoTSJEFN   = localStorage.SOGI_KoTSJEFN;        //火葬終了分
      if(localStorage.SOGI_KoTSJDS)     this.KoTSJDS    = localStorage.SOGI_KoTSJDS;         //同行・導師
      if(localStorage.SOGI_KoTSJD7)     this.KoTSJD7    = localStorage.SOGI_KoTSJD7;         //初七日
      if(localStorage.SOGI_KoTSJHS)     this.KoTSJHS    = localStorage.SOGI_KoTSJHS;         //控室
      if(localStorage.SOGI_KoTHZJK)     this.KoTHZJK    = localStorage.SOGI_KoTHZJK;         //本膳状況
      if(localStorage.SOGI_KoTYOYK)     this.KoTYOYK    = localStorage.SOGI_KoTYOYK;         //斎場予約
      if(localStorage.SOGI_KoTURSMST)   this.KoTURSMST  = localStorage.SOGI_KoTURSMST;       //祭事標準税売上(税抜
      if(localStorage.SOGI_KoTURSMHK)   this.KoTURSMHK  = localStorage.SOGI_KoTURSMHK;       //祭事非課税売上(税抜
      if(localStorage.SOGI_KoTSNEBST)   this.KoTSNEBST  = localStorage.SOGI_KoTSNEBST;       //祭事標準税値引
      if(localStorage.SOGI_KoTSNEBKG)   this.KoTSNEBKG  = localStorage.SOGI_KoTSNEBKG;       //祭事軽減税値引
      if(localStorage.SOGI_KoTSNEBHK)   this.KoTSNEBHK  = localStorage.SOGI_KoTSNEBHK;       //祭事非課税値引
      if(localStorage.SOGI_KoTSKAZST)   this.KoTSKAZST  = localStorage.SOGI_KoTSKAZST;       //祭事標準税対象額計
      if(localStorage.SOGI_KoTSKAZKG)   this.KoTSKAZKG  = localStorage.SOGI_KoTSKAZKG;       //祭事軽減税対象額計
      if(localStorage.SOGI_KoTSKAZHK)   this.KoTSKAZHK  = localStorage.SOGI_KoTSKAZHK;       //祭事非課税対象額計
      if(localStorage.SOGI_KoTSSYOST)   this.KoTSSYOST  = localStorage.SOGI_KoTSSYOST;       //祭事標準税消費税
      if(localStorage.SOGI_KoTSSYOKG)   this.KoTSSYOKG  = localStorage.SOGI_KoTSSYOKG;       //祭事軽減税消費税
      if(localStorage.SOGI_KoTSURKGK)   this.KoTSURKGK  = localStorage.SOGI_KoTSURKGK;       //祭事売上(税込み
      if(localStorage.SOGI_KoTURTMST)   this.KoTURTMST  = localStorage.SOGI_KoTURTMST;       //立替標準税売上(税抜
      if(localStorage.SOGI_KoTURTMKG)   this.KoTURTMKG  = localStorage.SOGI_KoTURTMKG;       //立替軽減税売上(税抜
      if(localStorage.SOGI_KoTURTMHK)   this.KoTURTMHK  = localStorage.SOGI_KoTURTMHK;       //立替非課税売上(税抜
      if(localStorage.SOGI_KoTTNEBST)   this.KoTTNEBST  = localStorage.SOGI_KoTTNEBST;       //立替標準税値引
      if(localStorage.SOGI_KoTTNEBKG)   this.KoTTNEBKG  = localStorage.SOGI_KoTTNEBKG;       //立替軽減税値引
      if(localStorage.SOGI_KoTTNEBHK)   this.KoTTNEBHK  = localStorage.SOGI_KoTTNEBHK;       //立替非課税値引
      if(localStorage.SOGI_KoTTKAZST)   this.KoTTKAZST  = localStorage.SOGI_KoTTKAZST;       //立替標準税対象額計
      if(localStorage.SOGI_KoTTKAZKG)   this.KoTTKAZKG  = localStorage.SOGI_KoTTKAZKG;       //立替軽減税対象額計
      if(localStorage.SOGI_KoTTKAZHK)   this.KoTTKAZHK  = localStorage.SOGI_KoTTKAZHK;       //立替非課税対象額計
      if(localStorage.SOGI_KoTURSMKG)   this.KoTURSMKG  = localStorage.SOGI_KoTURSMKG;       //祭事軽減税売上(税抜
      if(localStorage.SOGI_KoTTSYOST)   this.KoTTSYOST  = localStorage.SOGI_KoTTSYOST;       //立替標準税消費税
      if(localStorage.SOGI_KoTTSYOKG)   this.KoTTSYOKG  = localStorage.SOGI_KoTTSYOKG;       //立替軽減税消費税
      if(localStorage.SOGI_KoTTURKGK)   this.KoTTURKGK  = localStorage.SOGI_KoTTURKGK;       //立替売上(税込み
      if(localStorage.SOGI_KoTSGK)      this.KoTSGK     = localStorage.SOGI_KoTSGK;          //売掛金総額
      if(localStorage.SOGI_KoTSHZNG)    this.KoTSHZNG   = localStorage.SOGI_KoTSHZNG;        //売掛残高
      if(localStorage.SOGI_KoTSHNKG)    this.KoTSHNKG   = localStorage.SOGI_KoTSHNKG;        //売掛金入金金額
      if(localStorage.SOGI_KoSGICNM)    this.KoSGICNM   = localStorage.SOGI_KoSGICNM;        //葬儀委員長名
      if(localStorage.SOGI_KoSGICKN)    this.KoSGICKN   = localStorage.SOGI_KoSGICKN;        //葬儀委員長かな名
      if(localStorage.SOGI_KoSGICZK)    this.KoSGICZK   = localStorage.SOGI_KoSGICZK;        //葬儀委員長属性
      if(localStorage.SOGI_KoDMKB)      this.KoDMKB     = localStorage.SOGI_KoDMKB;          //DM区分
      if(localStorage.SOGI_KoHJGK)      this.KoHJGK     = localStorage.SOGI_KoHJGK;          //標準原価計
      if(localStorage.SOGI_KoARRI)      this.KoARRI     = localStorage.SOGI_KoARRI;          //粗利益計
      if(localStorage.SOGI_KoTJJK)      this.KoTJJK     = localStorage.SOGI_KoTJJK;          //受注進捗状況
      if(localStorage.SOGI_KoTIRNM)     this.KoTIRNM    = localStorage.SOGI_KoTIRNM;         //依頼人
      if(localStorage.SOGI_KoTIRZK)     this.KoTIRZK    = localStorage.SOGI_KoTIRZK;         //依頼人続柄
      if(localStorage.SOGI_KoTIRTL)     this.KoTIRTL    = localStorage.SOGI_KoTIRTL;         //依頼人電話番号
      if(localStorage.SOGI_KoTIRTO)     this.KoTIRTO    = localStorage.SOGI_KoTIRTO;         //搬送先(
      if(localStorage.SOGI_KoTIRD)      this.KoTIRDT    = localStorage.SOGI_KoTIRDT;         //打合日
      if(localStorage.SOGI_KoTIRJI)     this.KoTIRJI    = localStorage.SOGI_KoTIRJI;         //打合時
      if(localStorage.SOGI_KoTIRFN)     this.KoTIRFN    = localStorage.SOGI_KoTIRFN;         //打合分
      if(localStorage.SOGI_KoTIRBK)     this.KoTIRBK    = localStorage.SOGI_KoTIRBK;         //その他･備考
      if(localStorage.SOGI_KoTIRKM)     this.KoTIRKM    = localStorage.SOGI_KoTIRKM;         //搬送・打合せ・その他（）
      if(localStorage.SOGI_KoTIRSH)     this.KoTIRSH    = localStorage.SOGI_KoTIRSH;         //使用品目
      if(localStorage.SOGI_KoTRDCD)     this.KoTRDCD    = localStorage.SOGI_KoTRDCD;         //リードコード
      if(localStorage.SOGI_KoTRDNM)     this.KoTRDNM    = localStorage.SOGI_KoTRDNM;         //リード名
      if(localStorage.SOGI_KoTRDNMS)    this.KoTRDNMS   = localStorage.SOGI_KoTRDNMS;        //リード略称
      if(localStorage.SOGI_KoTRDTN)     this.KoTRDTN    = localStorage.SOGI_KoTRDTN;         //リード担当名
      if(localStorage.SOGI_KoTRDRV)     this.KoTRDRV    = localStorage.SOGI_KoTRDRV;         //リードレベル
      if(localStorage.SOGI_KoSKICD)     this.KoSKICD    = localStorage.SOGI_KoSKICD;         //司会者コード
      if(localStorage.SOGI_KoSKINM)     this.KoSKINM    = localStorage.SOGI_KoSKINM;         //司会者名
      if(localStorage.SOGI_KoSKINMS)    this.KoSKINMS   = localStorage.SOGI_KoSKINMS;        //司会者略称
      if(localStorage.SOGI_KoSVTNCD)    this.KoSVTNCD   = localStorage.SOGI_KoSVTNCD;        //サブ担当コード
      if(localStorage.SOGI_KoSVNTNM)    this.KoSVNTNM   = localStorage.SOGI_KoSVNTNM;        //サブ担当名
      if(localStorage.SOGI_KoSVNTNMS)   this.KoSVNTNMS  = localStorage.SOGI_KoSVNTNMS;       //サブ担当略称
      if(localStorage.SOGI_KoKRTNCD)    this.KoKRTNCD   = localStorage.SOGI_KoKRTNCD;        //経理担当コード
      if(localStorage.SOGI_KoKRNTNM)    this.KoKRNTNM   = localStorage.SOGI_KoKRNTNM;        //経理担当名
      if(localStorage.SOGI_KoTNKDT)     this.KoTNKDT    = localStorage.SOGI_KoTNKDT;         //納棺日
      if(localStorage.SOGI_KoTNKJI)     this.KoTNKJI    = localStorage.SOGI_KoTNKJI;         //納棺時
      if(localStorage.SOGI_KoTNKFN)     this.KoTNKFN    = localStorage.SOGI_KoTNKFN;         //納棺分
      if(localStorage.SOGI_KoTNKMK)     this.KoTNKMK    = localStorage.SOGI_KoTNKMK;         //納棺メーク
      if(localStorage.SOGI_KoTNKJK)     this.KoTNKJK    = localStorage.SOGI_KoTNKJK;         //納棺状況
      if(localStorage.SOGI_KoTNKBS)     this.KoTNKBS    = localStorage.SOGI_KoTNKBS;         //納棺場所
      if(localStorage.SOGI_KoTISU1DT)   this.KoTISU1DT  = localStorage.SOGI_KoTISU1DT;       //移動1日
      if(localStorage.SOGI_KoTISU1JI)   this.KoTISU1JI  = localStorage.SOGI_KoTISU1JI;       //移動1時
      if(localStorage.SOGI_KoTISU1FN)   this.KoTISU1FN  = localStorage.SOGI_KoTISU1FN;       //移動1分
      if(localStorage.SOGI_KoTISU1FR)   this.KoTISU1FR  = localStorage.SOGI_KoTISU1FR;       //F移動1移動元
      if(localStorage.SOGI_KoTISU1BS)   this.KoTISU1BS  = localStorage.SOGI_KoTISU1BS;       //バス台数
      if(localStorage.SOGI_KoTISU1HY)   this.KoTISU1HY  = localStorage.SOGI_KoTISU1HY;       //ハイヤー台数
      if(localStorage.SOGI_KoTISU1JK)   this.KoTISU1JK  = localStorage.SOGI_KoTISU1JK;       //移動1状況
      if(localStorage.SOGI_KoTISU2DT)   this.KoTISU2DT  = localStorage.SOGI_KoTISU2DT;       //移動2日
      if(localStorage.SOGI_KoTISU2JI)   this.KoTISU2JI  = localStorage.SOGI_KoTISU2JI;       //移動2時
      if(localStorage.SOGI_KoTISU2FN)   this.KoTISU2FN  = localStorage.SOGI_KoTISU2FN;       //移動2分
      if(localStorage.SOGI_KoTISU2FR)   this.KoTISU2FR  = localStorage.SOGI_KoTISU2FR;       //F移動2移動元
      if(localStorage.SOGI_KoTISU2BS)   this.KoTISU2BS  = localStorage.SOGI_KoTISU2BS;       //バス台数
      if(localStorage.SOGI_KoTISU2HY)   this.KoTISU2HY  = localStorage.SOGI_KoTISU2HY;       //ハイヤー台数
      if(localStorage.SOGI_KoTISU2JK)   this.KoTISU2JK  = localStorage.SOGI_KoTISU2JK;       //移動2状況
      if(localStorage.SOGI_KoTDRY1DT)   this.KoTDRY1DT  = localStorage.SOGI_KoTDRY1DT;       //ドライ1
      if(localStorage.SOGI_KoTDRY2DT)   this.KoTDRY2DT  = localStorage.SOGI_KoTDRY2DT;       //ドライ2
      if(localStorage.SOGI_KoTDRY3DT)   this.KoTDRY3DT  = localStorage.SOGI_KoTDRY3DT;       //ドライ3
      if(localStorage.SOGI_KoTDRYJK)    this.KoTDRYJK   = localStorage.SOGI_KoTDRYJK;        //ドライ状況
      if(localStorage.SOGI_KoREIJK)     this.KoREIJK    = localStorage.SOGI_KoREIJK;         //礼状状況
      if(localStorage.SOGI_KoREIBK)     this.KoREIBK    = localStorage.SOGI_KoREIBK;         //礼状備考
      if(localStorage.SOGI_KoHENJK)     this.KoHENJK    = localStorage.SOGI_KoHENJK;         //返礼品状況
      if(localStorage.SOGI_KoRIHINM)    this.KoRIHINM   = localStorage.SOGI_KoRIHINM;        //返礼品品目
      if(localStorage.SOGI_KoRICJK)     this.KoRICJK    = localStorage.SOGI_KoRICJK;         //霊柩車状況
      if(localStorage.SOGI_KoRICTK)     this.KoRICTK    = localStorage.SOGI_KoRICTK;         //霊柩車立替
      if(localStorage.SOGI_KoTSSTRJK)   this.KoTSSTRJK  = localStorage.SOGI_KoTSSTRJK;       //寺院状況
      if(localStorage.SOGI_KoTSSTRBS)   this.KoTSSTRBS  = localStorage.SOGI_KoTSSTRBS;       //菩提寺
      if(localStorage.SOGI_KoTSSTRHM)   this.KoTSSTRHM  = localStorage.SOGI_KoTSSTRHM;       //戒(法名）
      if(localStorage.SOGI_KoTSSTROF)   this.KoTSSTROF  = localStorage.SOGI_KoTSSTROF;       //お布施
      if(localStorage.SOGI_KoTKUDAMJ)   this.KoTKUDAMJ  = localStorage.SOGI_KoTKUDAMJ;       //果物状況
      if(localStorage.SOGI_KoTHZBS)     this.KoTHZBS    = localStorage.SOGI_KoTHZBS;         //本膳場所
      if(localStorage.SOGI_KoTHZNY)     this.KoTHZNY    = localStorage.SOGI_KoTHZNY;         //枕飯・枕団子、供養膳
      if(localStorage.SOGI_KoTAZKPHJK)  this.KoTAZKPHJK = localStorage.SOGI_KoTAZKPHJK;      //写真
      if(localStorage.SOGI_KoTAZKPB)    this.KoTAZKPB   = localStorage.SOGI_KoTAZKPB;        //写真備考
      if(localStorage.SOGI_KoTAZKKKJK)  this.KoTAZKKKJK = localStorage.SOGI_KoTAZKKKJK;      //許可書
      if(localStorage.SOGI_KoTAZKKB)    this.KoTAZKKB   = localStorage.SOGI_KoTAZKKB;        //許可備考
      if(localStorage.SOGI_KoTHCDT)     this.KoTHCDT    = localStorage.SOGI_KoTHCDT;         //発注日
      if(localStorage.SOGI_KoTHCJI)     this.KoTHCJI    = localStorage.SOGI_KoTHCJI;         //発注時
      if(localStorage.SOGI_KoTHCFN)     this.KoTHCFN    = localStorage.SOGI_KoTHCFN;         //発注分
      if(localStorage.SOGI_KoTMGKT)     this.KoTMGKT    = localStorage.SOGI_KoTMGKT;         //担当者確定
      if(localStorage.SOGI_KoTBIKO1)    this.KoTBIKO1   = localStorage.SOGI_KoTBIKO1;        //備考1
      if(localStorage.SOGI_KoTBIKO2)    this.KoTBIKO2   = localStorage.SOGI_KoTBIKO2;        //備考2
      if(localStorage.SOGI_KoTBIKO3)    this.KoTBIKO3   = localStorage.SOGI_KoTBIKO3;        //備考3
      if(localStorage.SOGI_KoMUKBDT)    this.KoMUKBDT   = localStorage.SOGI_KoMUKBDT;	      //見積受付簿印刷日
      if(localStorage.SOGI_KoMUKBTM)    this.KoMUKBTM   = localStorage.SOGI_KoMUKBTM;	      //見積受付簿印刷時間
      if(localStorage.SOGI_KoMSKSDT)    this.KoMSKSDT   = localStorage.SOGI_KoMSKSDT;	      //見積祭事印刷日
      if(localStorage.SOGI_KoMSKSTM)    this.KoMSKSTM   = localStorage.SOGI_KoMSKSTM;	      //見積祭事印刷時間
      if(localStorage.SOGI_KoMTKSDT)    this.KoMTKSDT   = localStorage.SOGI_KoMTKSDT;	      //見積立替印刷日
      if(localStorage.SOGI_KoMTKSTM)    this.KoMTKSTM   = localStorage.SOGI_KoMTKSTM;	      //見積立替印刷時間

      if(localStorage.SOGI_InstDt)      this.InstDt     = localStorage.SOGI_InstDt;          //作成日時
      if(localStorage.SOGI_InstId)      this.InstId     = localStorage.SOGI_InstId;          //作成者
      if(localStorage.SOGI_UpdtDt)      this.UpdtDt     = localStorage.SOGI_UpdtDt;          //更新日時
      if(localStorage.SOGI_UpdtId)      this.UpdtId     = localStorage.SOGI_UpdtId;          //更新者

      if(localStorage.SOGI_KgyCd)       this.KgyCd      = localStorage.SOGI_KgyCd;
      if(localStorage.SOGI_KgyNM)       this.KgyNM      = localStorage.SOGI_KgyNM;
      if(localStorage.SOGI_BLCKCD)      this.BLCKCD     = localStorage.SOGI_BLCKCD;
      if(localStorage.SOGI_BLCKNM)      this.BLCKNM     = localStorage.SOGI_BLCKNM;
      if(localStorage.SOGI_SynCd)       this.SynCd      = localStorage.SOGI_SynCd;
      if(localStorage.SOGI_SynNm)       this.SynNm      = localStorage.SOGI_SynNm;
      if(localStorage.SOGI_TANID)       this.TANID      = localStorage.SOGI_TANID;
      if(localStorage.SOGI_DENNO)       this.DENNO      = localStorage.SOGI_SGDENNO;

      if(this.KoTTBSDTJ.length >= 10){
       // this.KoTTNM =this.KoTTBSDTJ;
       // this.KoTTNG   = this.KoTTBSDTJ.slice(0, 2);   //故人生年月日年号
        this.KoTTNG_KNJ = this.KoTTBSDTJ.slice(0, 2);   //故人生年月日年号
       // this.KoTTNM =  this.KoTTBSDTJ.slice(0, 2);
        this.KoTTBSYY	= this.KoTTBSDTJ.slice(3, 5);   //故人生年月日年
       // this.KoTTBSYY	= this.KoTTBSDTJ.slice(2, 3);   //故人生年月日年
        this.KoTTBSMM	= this.KoTTBSDTJ.slice(6, 8);   //故人生年月日月
        this.KoTTBSDD	= this.KoTTBSDTJ.slice(9, 11);   //故人生年月日日
      }
  
  if(this.KoTSHYODT == "Invalid date"){this.KoTSHYODT = ""};
  if(this.KoTNO	    == null || this.KoTNO	    == "null"){this.KoTNO	    = "";}    //受付番号
  if(this.KoTURDT	  == null || this.KoTURDT   == "null"){this.KoTURDT	  = "";}    //売上日
	if(this.KoTUKDT	  == null || this.KoTUKDT   == "null"){this.KoTUKDT	  = "";}    //受付日
	if(this.KoTBLOKNM	== null || this.KoTBLOKNM == "null"){this.KoTBLOKNM	= "";}    //部署名称
	if(this.KoTBLOKCD	== null || this.KoTBLOKCD == "null"){this.KoTBLOKCD	= "";}    //部署コード
	if(this.KoTNTNCD	== null || this.KoTNTNCD  == "null"){this.KoTNTNCD	= "";}    //担当者コード
	if(this.KoTNTNNM	== null || this.KoTNTNNM  == "null"){this.KoTNTNNM	= "";}    //担当者名称
	if(this.KoETNTNCD	== null || this.KoETNTNCD == "null"){this.KoETNTNCD	= "";}    //営業担当コード
	if(this.KoETNTNNM	== null || this.KoETNTNNM == "null"){this.KoETNTNNM	= "";}    //営業担当名称
	if(this.KoTKANO	  == null || this.KoTKANO   == "null"){this.KoTKANO	  = "";}    //会員番号
	if(this.KoTKANM	  == null || this.KoTKANM   == "null"){this.KoTKANM	  = "";}    //会員名称
	if(this.KoTMOKE	  == null || this.KoTMOKE   == "null"){this.KoTMOKE	  = "";}    //喪家
	if(this.KoThCD	  == null || this.KoThCD    == "null"){this.KoThCD	  = "";}    //顧客取引先コード
	if(this.KoThNM	  == null || this.KoThNM    == "null"){this.KoThNM	  = "";}    //顧客取引先名称
	if(this.KoTCD	    == null || this.KoTCD	    == "null"){this.KoTCD	    = "";}    //顧客提携区分コード
	if(this.KoTNM	    == null || this.KoTNM	    == "null"){this.KoTNM	    = "";}    //顧客提携区分名称
	if(this.KoTTKNM	  == null || this.KoTTKNM   == "null"){this.KoTTKNM	  = "";}    //故人(ひかがな）
	if(this.KoTTNM	  == null || this.KoTTNM    == "null"){this.KoTTNM	  = "";}    //故人名前
	if(this.KoTTNG	  == null || this.KoTTNG    == "null"){this.KoTTNG	  = "";}    //故人生年月日年号
	if(this.KoTTBSYY	== null || this.KoTTBSYY  == "null"){this.KoTTBSYY	= "";}    //故人生年月日年
	if(this.KoTTBSMM	== null || this.KoTTBSMM  == "null"){this.KoTTBSMM	= "";}    //故人生年月日月
	if(this.KoTTBSDD	== null || this.KoTTBSDD  == "null"){this.KoTTBSDD	= "";}    //故人生年月日日
	if(this.KoTTEG	  == null || this.KoTTEG    == "null"){this.KoTTEG	  = "";}    //故人年齢
	if(this.KoTTSEX	  == null || this.KoTTSEX   == "null"){this.KoTTSEX	  = "";}    //故人性別
	if(this.KoTTNEX	  == null || this.KoTTNEX   == "null"){this.KoTTNEX	  = "";}    //故人続柄
	if(this.KoTTYU	  == null || this.KoTTYU    == "null"){this.KoTTYU	  = "";}    //故人郵便番号
	if(this.KoTTJYU1	== null || this.KoTTJYU1  == "null"){this.KoTTJYU1	= "";}    //故人住所1
	if(this.KoTTJYU2	== null || this.KoTTJYU2  == "null"){this.KoTTJYU2	= "";}    //故人住所2
	if(this.KoTTJYU3	== null || this.KoTTJYU3  == "null"){this.KoTTJYU3	= "";}    //故人住所3
	if(this.KoTTTEL	  == null || this.KoTTTEL   == "null"){this.KoTTTEL	  = "";}    //故人電話番号
	if(this.KoTMKNM	  == null || this.KoTMKNM   == "null"){this.KoTMKNM	  = "";}    //喪主(ひらがな）
	if(this.KoTMNM	  == null || this.KoTMNM    == "null"){this.KoTMNM	  = "";}    //喪主名前
	if(this.KoTMNEX	  == null || this.KoTMNEX   == "null"){this.KoTMNEX	  = "";}    //喪主続柄
	if(this.KoTMSEX	  == null || this.KoTMSEX   == "null"){this.KoTMSEX	  = "";}    //喪主性別
	if(this.KoTMYU	  == null || this.KoTMYU    == "null"){this.KoTMYU	  = "";}    //喪主郵便番号
	if(this.KoTMJYU1	== null || this.KoTMJYU1  == "null"){this.KoTMJYU1	= "";}    //喪主住所1
	if(this.KoTMJYU2	== null || this.KoTMJYU2  == "null"){this.KoTMJYU2	= "";}    //喪主住所2
	if(this.KoTMJYU3	== null || this.KoTMJYU3  == "null"){this.KoTMJYU3	= "";}    //喪主住所3
	if(this.KoTMTEL	  == null || this.KoTMTEL   == "null"){this.KoTMTEL	  = "";}    //喪主電話番号
	if(this.KoTMTELK	== null || this.KoTMTELK  == "null"){this.KoTMTELK	= "";}    //喪主携帯
	if(this.KoTMTMail	== null || this.KoTMTMail == "null"){this.KoTMTMail	= "";}    //喪主Mail
	if(this.KoTMBIKO	== null || this.KoTMBIKO  == "null"){this.KoTMBIKO	= "";}    //喪主備考
	if(this.KoTSNM	  == null || this.KoTSNM    == "null"){this.KoTSNM	  = "";}    //お支払責任者名前
	if(this.KoTSHYODT	== null || this.KoTSHYODT == "null"){this.KoTSHYODT	= "";}    //お支払予定日
	if(this.KoTSPNM	  == null || this.KoTSPNM   == "null"){this.KoTSPNM	  = "";}    //入金方法名
	if(this.KoTSYU	  == null || this.KoTSYU    == "null"){this.KoTSYU	  = "";}    //支払責任者郵便番号
	if(this.KoTSJYU1	== null || this.KoTSJYU1  == "null"){this.KoTSJYU1	= "";}    //お支払責任者住所1
	if(this.KoTSJYU2	== null || this.KoTSJYU2  == "null"){this.KoTSJYU2	= "";}    //お支払責任者住所2
	if(this.KoTSJYU3	== null || this.KoTSJYU3  == "null"){this.KoTSJYU3	= "";}    //お支払責任者住所3
	if(this.KoTSTEL	  == null || this.KoTSTEL   == "null"){this.KoTSTEL	  = "";}    //お支払責任者電話番号
	if(this.KoTSTELK	== null || this.KoTSTELK  == "null"){this.KoTSTELK	= "";}    //お支払責任者携帯
	if(this.KoTSMail	== null || this.KoTSMail  == "null"){this.KoTSMail	= "";}    //お支払責任者Mail
	if(this.KoTSBIKO	== null || this.KoTSBIKO  == "null"){this.KoTSBIKO	= "";}    //お支払責任者備考
	if(this.KoTSSTRNM	== null || this.KoTSSTRNM == "null"){this.KoTSSTRNM	= "";}    //寺院名称
	if(this.KoTSSSONM	== null || this.KoTSSSONM == "null"){this.KoTSSSONM	= "";}    //宗旨名称
	if(this.KoTSSKSA1	== null || this.KoTSSKSA1 == "null"){this.KoTSSKSA1	= "";}    //会葬挨拶1
	if(this.KoTSSKMNM	== null || this.KoTSSKMNM == "null"){this.KoTSSKMNM	= "";}    //家紋・紋章名称
	if(this.KoTSSBIKO	== null || this.KoTSSBIKO == "null"){this.KoTSSBIKO	= "";}    //引継備考
	if(this.KoTUUKBDT	== null || this.KoTUUKBDT == "null"){this.KoTUUKBDT	= "";}    //受付簿印刷日
	if(this.KoTUUKBTM	== null || this.KoTUUKBTM == "null"){this.KoTUUKBTM	= "";}    //受付簿印時間
	if(this.KoTUMSKSDT== null || this.KoTUMSKSDT== "null"){this.KoTUMSKSDT= "";}    //請求書印刷日
	if(this.KoTUMSKSTM== null || this.KoTUMSKSTM== "null"){this.KoTUMSKSTM= "";}    //請求書印刷時間
	if(this.KoTUTKSSDT== null || this.KoTUTKSSDT== "null"){this.KoTUTKSSDT= "";}    //立替請求書印刷日
	if(this.KoTUTKSSTM== null || this.KoTUTKSSTM== "null"){this.KoTUTKSSTM= "";}    //立替請求書印刷時間
	if(this.KoTPLCD	  == null || this.KoTPLCD   == "null"){this.KoTPLCD	  = "";}    //プランコード
	if(this.KoTPLNM	  == null || this.KoTPLNM   == "null"){this.KoTPLNM	  = "";}    //プラン名称
	if(this.KoTPLGK	  == null || this.KoTPLGK   == "null"){this.KoTPLGK	  = "";}    //プラン金額
	if(this.KoTSTCD	  == null || this.KoTSTCD   == "null"){this.KoTSTCD	  = "";}    //プランセットコード
	if(this.KoTKKCD	  == null || this.KoTKKCD   == "null"){this.KoTKKCD	  = "";}    //会館CD
	if(this.KoTKKNM	  == null || this.KoTKKNM   == "null"){this.KoTKKNM	  = "";}    //会館名
	if(this.KoTHRCD	  == null || this.KoTHRCD   == "null"){this.KoTHRCD	  = "";}    //ホールCD
	if(this.KoTHRNM	  == null || this.KoTHRNM   == "null"){this.KoTHRNM	  = "";}    //ホール名
	if(this.KoTSEYNM	== null || this.KoTSEYNM  == "null"){this.KoTSEYNM	= "";}    //施行予定名
	if(this.KoTDESDT	== null || this.KoTDESDT  == "null"){this.KoTDESDT	= "";}    //死亡日
	if(this.KoTTYSDT	== null || this.KoTTYSDT  == "null"){this.KoTTYSDT	= "";}    //通夜日
	if(this.KoTSSOGDT	== null || this.KoTSSOGDT == "null"){this.KoTSSOGDT	= "";}    //葬儀日
	if(this.KoTSJDT	  == null || this.KoTSJDT   == "null"){this.KoTSJDT	  = "";}    //火葬日
	if(this.KoTSJCD	  == null || this.KoTSJCD   == "null"){this.KoTSJCD	  = "";}    //斎場コード
	if(this.KoTSJNM	  == null || this.KoTSJNM   == "null"){this.KoTSJNM	  = "";}    //斎場名称
	if(this.KoTURSMST	== null || this.KoTURSMST == "null"){this.KoTURSMST	= "";}    //祭事標準税売上(税抜)
	if(this.KoTURSMKG	== null || this.KoTURSMKG == "null"){this.KoTURSMKG	= "";}    //祭事軽減税売上(税抜)
	if(this.KoTURSMHK	== null || this.KoTURSMHK == "null"){this.KoTURSMHK	= "";}    //祭事非課税売上(税抜)
	if(this.KoTSNEBST	== null || this.KoTSNEBST == "null"){this.KoTSNEBST	= "";}    //祭事標準税値引
	if(this.KoTSNEBKG	== null || this.KoTSNEBKG == "null"){this.KoTSNEBKG	= "";}    //祭事軽減税値引
	if(this.KoTSNEBHK	== null || this.KoTSNEBHK == "null"){this.KoTSNEBHK	= "";}    //祭事非課税値引
	if(this.KoTSKAZST	== null || this.KoTSKAZST == "null"){this.KoTSKAZST	= "";}    //祭事標準税対象額計
	if(this.KoTSKAZKG	== null || this.KoTSKAZKG == "null"){this.KoTSKAZKG	= "";}    //祭事軽減税対象額計
	if(this.KoTSKAZHK	== null || this.KoTSKAZHK == "null"){this.KoTSKAZHK	= "";}    //祭事非課税対象額計
	if(this.KoTSSYOST	== null || this.KoTSSYOST == "null"){this.KoTSSYOST	= "";}    //祭事標準税消費税
	if(this.KoTSSYOKG	== null || this.KoTSSYOKG == "null"){this.KoTSSYOKG	= "";}    //祭事軽減税消費税
	if(this.KoTSURKGK	== null || this.KoTSURKGK == "null"){this.KoTSURKGK	= "";}    //祭事売上(税込み)
	if(this.KoTURTMST	== null || this.KoTURTMST == "null"){this.KoTURTMST	= "";}    //立替標準税売上(税抜)
	if(this.KoTURTMKG	== null || this.KoTURTMKG == "null"){this.KoTURTMKG	= "";}    //立替軽減税売上(税抜)
	if(this.KoTURTMHK	== null || this.KoTURTMHK == "null"){this.KoTURTMHK	= "";}    //立替非課税売上(税抜)
	if(this.KoTTNEBST	== null || this.KoTTNEBST == "null"){this.KoTTNEBST	= "";}    //立替標準税値引
	if(this.KoTTNEBKG	== null || this.KoTTNEBKG == "null"){this.KoTTNEBKG	= "";}    //立替軽減税値引
	if(this.KoTTNEBHK	== null || this.KoTTNEBHK == "null"){this.KoTTNEBHK	= "";}    //立替非課税値引
	if(this.KoTTKAZST	== null || this.KoTTKAZST == "null"){this.KoTTKAZST	= "";}    //立替標準税対象額計
	if(this.KoTTKAZKG	== null || this.KoTTKAZKG == "null"){this.KoTTKAZKG	= "";}    //立替軽減税対象額計
	if(this.KoTTKAZHK	== null || this.KoTTKAZHK == "null"){this.KoTTKAZHK	= "";}    //立替非課税対象額計
	if(this.KoTTSYOST	== null || this.KoTTSYOST == "null"){this.KoTTSYOST	= "";}    //立替標準税消費税
	if(this.KoTTSYOKG	== null || this.KoTTSYOKG == "null"){this.KoTTSYOKG	= "";}    //立替軽減税消費税
	if(this.KoTTURKGK	== null || this.KoTTURKGK == "null"){this.KoTTURKGK	= "";}    //立替売上(税込み)
	if(this.KoTSGK	  == null || this.KoTSGK	  == "null"){this.KoTSGK	  = "";}    //売掛金総額
	if(this.KoTSHNKG	== null || this.KoTSHNKG  == "null"){this.KoTSHNKG	= "";}    //祭事入金金額
	if(this.KoTSHZNG	== null || this.KoTSHZNG  == "null"){this.KoTSHZNG	= "";}    //売掛残高
	if(this.KoSGICNM	== null || this.KoSGICNM  == "null"){this.KoSGICNM	= "";}    //葬儀委員長名
	if(this.KoSGICKN	== null || this.KoSGICKN  == "null"){this.KoSGICKN	= "";}    //葬儀委員長かな名
	if(this.KoSGICZK	== null || this.KoSGICZK  == "null"){this.KoSGICZK	= "";}    //葬儀委員長属性
	if(this.KoDMKB	  == null || this.KoDMKB    == "null"){this.KoDMKB	  = "";}    //DM区分
	if(this.KoHJGK	  == null || this.KoHJGK    == "null"){this.KoHJGK	  = "";}    //標準原価計
	if(this.KoARRI	  == null || this.KoARRI    == "null"){this.KoARRI	  = "";}    //粗利益計
	if(this.KoTJJK	  == null || this.KoTJJK    == "null"){this.KoTJJK	  = "";}    //受注進捗状況
  if(this.KoMUKBDT	== null || this.KoMUKBDT  == "null"){this.KoMUKBDT	= "";}	  //見積受付簿印刷日
  if(this.KoMUKBTM	== null || this.KoMUKBTM  == "null"){this.KoMUKBTM	= "";}	  //見積受付簿印刷時間
  if(this.KoMSKSDT	== null || this.KoMSKSDT  == "null"){this.KoMSKSDT	= "";}	  //見積祭事印刷日
  if(this.KoMSKSTM	== null || this.KoMSKSTM  == "null"){this.KoMSKSTM	= "";}	  //見積祭事印刷時間
  if(this.KoMTKSDT	== null || this.KoMTKSDT  == "null"){this.KoMTKSDT	= "";}	  //見積立替印刷日
  if(this.KoMTKSTM	== null || this.KoMTKSTM  == "null"){this.KoMTKSTM	= "";}	  //見積立替印刷時間
	if(this.InstDt	  == null || this.InstDt    == "null"){this.InstDt	  = "";}    //作成日時
	if(this.InstId	  == null || this.InstId    == "null"){this.InstId	  = "";}    //作成者
	if(this.UpdtDt	  == null || this.UpdtDt    == "null"){this.UpdtDt	  = "";}    //更新日時
	if(this.UpdtId	  == null || this.UpdtId    == "null"){this.UpdtId	  = "";}    //更新者

  },
    close() {
      this.$emit('result', {'res': false, 'KoMCD':'--','KoMNM':'--'})
      this.dialogUP = false;
    },
    agreeok() {
      this.$emit('result', {'res': true, 'KoMCD':this.GETKoMCD,'KoMNM':this.GETKoMNM})
      this.dialogUP = false;
     },
    SEXMAN()    {this.KoTTSEX = "男";},   //故人性別
    SEXWMAN()   {this.KoTTSEX = "女";},   //故人性別
    MSSEXMAN()  {this.KoTMSEX = "男";},   //喪主性別
    MSSEXWMAN() {this.KoTMSEX = "女";},   //喪主性別
   
   //-----------------------------------------------------------------
    //削除処理
    //-----------------------------------------------------------------
    async DELETEKoMstdata() {
      const fomeDLgo = {KoTNO: this.KoTNO};
      axios.post('/api/KoTrnDEL',fomeDLgo)
      .then(response => {   //更新後の処理
         })
        .catch(error => {
        console.log(error)
        })
    },
    //-----------------------------------------------------------------
    //郵便番号
    //-----------------------------------------------------------------
    // 郵便番号のバリデーションチェック
    //故人住所
     KOJINAddress() {
     const zipcd = {zipCD: this.KoTTYU};
        axios.post('/api/zipNO',zipcd)
           .then(res => {
            this.ziptb = res.data;
            this.KoTTJYU1 = this.ziptb[0].address1;
            this.KoTTJYU2 = this.ziptb[0].address2;
            this.KoTTJYU3 = this.ziptb[0].address3;
            })
            .catch(error => {
              this.KoTTJYU1 = "";
              this.KoTTJYU2 = "";
              this.KoTTJYU3 = "";
              this.error_msg =  "郵便番号エラー";
              console.log(error)
            })
    },
     //喪主住所
     MOSYUAddress() {
        const zipcd = {zipCD: this.KoTMYU};
        axios.post('/api/zipNO',zipcd)
           .then(res => {
            this.ziptb = res.data;
            this.KoTMJYU1   = this.ziptb[0].address1;
            this.KoTMJYU2   = this.ziptb[0].address2;
            this.KoTMJYU3   = this.ziptb[0].address3;
            })
            .catch(error => {
              this.KoTMJYU1   = "";
              this.KoTMJYU2   = "";
              this.KoTMJYU3   = "";
              this.error_msg  =  "郵便番号エラー";
              console.log(error)
            })
    },
 
     //お支払責任者住所
     SIHARAddress() {
        const zipcd = {zipCD: this.KoTSYU};
        axios.post('/api/zipNO',zipcd)
           .then(res => {
            this.ziptb = res.data;
            this.KoTSJYU1 = this.ziptb[0].address1;
            this.KoTSJYU2 = this.ziptb[0].address2;
            this.KoTSJYU3 = this.ziptb[0].address3;
            })
            .catch(error => {
              this.KoTSJYU1   = "";
              this.KoTSJYU2   = "";
              this.KoTSJYU3   = "";
              this.error_msg  =  "郵便番号エラー";
              console.log(error)
            })
    },
 
    //----------------------------------------
    //寺院マスタ表示
    //----------------------------------------
    JNDialogshow() {
      this.$refs.dialogJN.open();
    },
    //寺院マスタ取得
    responseJN(obj) {
      if(obj.JNNCD != "--"){  //キャンセル時は何もしない
        this.KoTSSTRNM  = obj.JNNNM;    //寺院名
        this.KoTSSSONM  = obj.JNNSHNM;  //宗旨名
      }
        this.dialogJN   = false;
    },
    //----------------------------------------
    //リード表示
    //----------------------------------------
    RDDialogshow() {
      this.$refs.dialogRD.open();
    },
    //リードマスタ取得
    responseRD(obj) {
      if(obj.TRHCD != "--"){  //キャンセル時は何もしない
        this.KoTRDCD  = obj.TRHCD;      //リードコード
        this.KoTRDNM  = obj.TRHNM;      //リード名
        this.KoTRDNMS = obj.TRHNMS;     //リード略称
        this.KoTRDTN  = obj.TRHTNNM;    //担当名
        this.KoTRDRV  = obj.TRHRDRV;    //リードレベル
      }
        this.dialogRD = false;
    },
    //----------------------------------------
    //受付担当（社員全員）
    //----------------------------------------
    SALLUKTDialogshow() {
      this.$refs.dialogSYALL.open();
    },
    //受付担当取得
    responseSYALL(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KoTUKBCD   = obj.SynBLCKCD;  //受付部署コード
        this.KoTUKBNM   = obj.SynBLCKNM;  //受付部署名
        this.KoTUKTCD   = obj.SynCd;      //受付社員コード
        this.KoTUKTNM   = obj.SynNm;      //受付担当者名
        this.KoTUKTNMS  = obj.SynNmS;     //受付担当者略称
      }
        this.dialogSYALL = false;
    },
    //----------------------------------------
    //主担当（主担当）
    //----------------------------------------
    SEIGUKTDialogshow() {
      this.$refs.dialogSYEIG.open();
    },
    //主担当取得
    responseSEIG(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KoTBLOKCD   = obj.SynBLCKCD;  //主担当部署コード
        this.KoTBLOKNM   = obj.SynBLCKNM;  //主担当部署名
        this.KoETNTNCD   = obj.SynCd;      //主担当社員コード
        this.KoETNTNNM   = obj.SynNm;      //主担当担当者名
        this.KoETNTNNMS  = obj.SynNmS;     //主担当担当者略称
      }
        this.dialogSYEIG = false;
    },
    //----------------------------------------
    //司会者
    //----------------------------------------
    SSKIDialogshow() {
      this.$refs.dialogSYSKI.open();
    },
    //司会者取得
    responseSSKI(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KoSKICD   = obj.SynCd;      //司会者社員コード
        this.KoSKINM   = obj.SynNm;      //司会者担当者名
        this.KoSKINMS  = obj.SynNmS;     //司会者担当者略称
      }
        this.dialogSYSKI = false;
    },
    //----------------------------------------
    //サブ担当（主担当）
    //----------------------------------------
    SEIGUsKTDialogshow() {
      this.$refs.dialogSYEIGS.open();
    },
    //サブ担当
    responseSEIGs(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KoSVTNCD   = obj.SynCd;      //サブ担当社員コード
        this.KoSVNTNM   = obj.SynNm;      //サブ担当者名
        this.KoSVNTNMS  = obj.SynNmS;     //サブ担当者略称
      }
        this.dialogSYEIGS = false;
    },
    //----------------------------------------
    //経理担当（社員全員）
    //----------------------------------------
    SALLsDialogshow() {
      this.$refs.dialogSYALLs.open();
    },
    //経理担当取得
    responseSYALLs(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
 
        this.KoKRTNCD   = obj.SynCd;      //経理社員コード
        this.KoKRNTNM   = obj.SynNm;      //経理担当者名
 
      }
        this.dialogSYALLs = false;
    },
    //----------------------------------------
    //会員選択表示
    //----------------------------------------
    KIDialogshow() {
      this.$refs.dialogKAGET.open();
    },
    //会員選択表示
    responseKA(obj) {
      if(obj.KoMCD != "--"){  //キャンセル時は何もしない
        this.KoTKANO = obj.KoMCD; //会員ID
        this.KoTKANM = obj.KoMNM; //会員名
      }
        this.dialogKAGET = false;
    },
   //----------------------------------------
    //施行予定入力
    //----------------------------------------
    SYDialogshow() {
      localStorage.SOGI_KoTSSOGDT = this.KoTSSOGDT; //葬儀日
      localStorage.SOGI_KoTNO     = this.KoTNO;     //受付№
      localStorage.SOGI_KoTKKCD   = "";             //会館CD
      localStorage.SOGI_KoTKKNM   = "";             //会館名
      localStorage.SOGI_KoTHRCD   = "";             //ホールCD
      localStorage.SOGI_KoTHRNM   = "";             //ホール名
      localStorage.SOGI_KoTSEYNM  = "";             //施行予定名  
      localStorage.SOGI_KoTDESDT  = "";             //死亡日
      localStorage.SOGI_KoTTYSDT  = "";             //通夜日
      localStorage.SOGI_KoTSJDT   = "";             //火葬日
      localStorage.SOGI_KoTSJCD   = "";             //斎場コード
      localStorage.SOGI_KoTSJNM   = "";             //斎場名称
      this.$refs.dialogYS.open();
    },
    //施行予定
    responseYS(obj) {
      if(obj.KoTKKCD != "--"){  //キャンセル時は何もしない
        this.KoTKANO    = obj.KoMCD;      //会員ID
        this.KoTKANM    = obj.KoMNM;      //会員名
        this.KoTSSOGDT  = obj.KoTSSOGDT;  //葬儀日
        this.KoTKKCD    = obj.KoTKKCD;    //会館CD
        this.KoTKKNM    = obj.KoTKKNM;    //会館名
        this.KoTHRCD    = obj.KoTHRCD;    //ホールCD
        this.KoTHRNM    = obj.KoTHRNM;    //ホール名
        this.KoTSEYNM   = obj.KoTSEYNM;   //施行予定名  
        this.KoTDESDT   = obj.KoTDESDT;   //死亡日
        this.KoTTYSDT   = obj.KoTTYSDT;   //通夜日
        this.KoTSJDT    = obj.KoTSJDT;    //火葬日
        this.KoTSJCD    = obj.KoTSJCD;    //斎場コード
        this.KoTSJNM    = obj.KoTSJNM;    //斎場名称

        if(this.KoTSSOGDT == null){this.KoTSSOGDT = ""};  //葬儀日
        if(this.KoTKKCD   == null){this.KoTKKCD   = ""};  //会館CD
        if(this.KoTKKNM   == null){this.KoTKKNM   = ""};  //会館名
        if(this.KoTHRCD   == null){this.KoTHRCD   = ""};  //ホールCD
        if(this.KoTHRNM   == null){this.KoTHRNM   = ""};  //ホール名
        if(this.KoTSEYNM  == null){this.KoTSEYNM  = ""};  //施行予定名  
        if(this.KoTDESDT  == null){this.KoTDESDT  = ""};  //死亡日
        if(this.KoTTYSDT  == null){this.KoTTYSDT  = ""};  //通夜日
        if(this.KoTSJDT   == null){this.KoTSJDT   = ""};  //火葬日
        if(this.KoTSJCD   == null){this.KoTSJCD   = ""};  //斎場コード
        if(this.KoTSJNM   == null){this.KoTSJNM   = ""};  //斎場名称

      }
      this.dialogYS = false;
    },
    //----------------------------------------
    //施行プラン入力
    //----------------------------------------
    PLDialogshow() {
      localStorage.SOGI_KoTURDT = this.KoTURDT;   //売上日
      localStorage.SOGI_KoTNO   = this.KoTNO;     //受付№
      localStorage.SOGI_KoThCD  = this.KoThCD;    //顧客取引先コード
      localStorage.SOGI_KoThNM  = this.KoThNM;    //顧客取引先名称
      localStorage.SOGI_KoTCD   = this.KoTCD;     //顧客提携区分コード
      localStorage.SOGI_KoTNM   = this.KoTNM;     //顧客提携区分名称
      localStorage.SOGI_KoTPLCD = this.KoTPLCD;   //プランコード
      localStorage.SOGI_KoTPLNM = this.KoTPLNM;   //プラン名称
      localStorage.SOGI_KoTPLGK = this.KoTPLGK;   //プランセットコード
      this.$refs.dialogPL.open();
    },
    //施行プラン
    responsePL(obj) {
    if(obj.KoTPLCD != "--"){  //キャンセル時は何もしない
      if(this.KoTURDT   == null){this.KoTURDT = ""};   //売上日
      if(this.KoTNO     == null){this.KoTNO   = ""};   //受付№
      if(this.KoThCD    == null){this.KoThCD  = ""};   //顧客取引先コード
      if(this.KoThNM    == null){this.KoThNM  = ""};   //顧客取引先名称
      if(this.KoTCD     == null){this.KoTCD   = ""};   //顧客提携区分コード
      if(this.KoTNM     == null){this.KoTNM   = ""};   //顧客提携区分名称
      if(this.KoTPLCD   == null){this.KoTPLCD = ""};   //プランコード
      if(this.KoTPLNM   == null){this.KoTPLNM = ""};   //プラン名称
      if(this.KoTPLGK   == null){this.KoTPLGK = ""};   //プランセットコード
      }
      this.dialogPL = false;
    },

    //----------------------------------------
    //施行印刷
    //----------------------------------------
    INDialogshow() {
            //受付時
          if(this.KoTUKJI != ""){
            if(this.KoTUKJI.length == 1 ){this.KoTUKJI = "0"+this.KoTUKJI;}
            if(this.KoTUKFN.length == 1 ){this.KoTUKFN = "0"+this.KoTUKFN;}
            this.WKoTUKJI = this.KoTUKJI + ":" +this.KoTUKFN; 
          }
          //死亡時
          if(this.KoTDESJI != ""){
            if(this.KoTDESJI.length == 1 ){this.KoTDESJI = "0"+this.KoTDESJI;}
            if(this.KoTDESFN.length == 1 ){this.KoTDESFN = "0"+this.KoTDESFN;}
            this.WKoTDESJI = this.KoTDESJI + ":" +this.KoTDESFN; 
          }
          //通夜時
          if(this.KoTTYSJI != ""){
            if(this.KoTTYSJI.length == 1 ){this.KoTTYSJI = "0"+this.KoTTYSJI;}
            if(this.KoTTYSFN.length == 1 ){this.KoTTYSFN = "0"+this.KoTTYSFN;}
            this.WKoTDESJI = this.KoTDESJI + ":" +this.KoTDESFN; 
            this.WKoTTYSJI = this.KoTTYSJI + ":" +this.KoTTYSFN; 
          }
          //通夜終了時
          if(this.KoTTYEJI != ""){
            if(this.KoTTYEJI.length == 1 ){this.KoTTYEJI = "0"+this.KoTTYEJI;}
            if(this.KoTTYEFN.length == 1 ){this.KoTTYEFN = "0"+this.KoTTYEFN;}
            this.WKoTTYEJI = this.KoTTYEJI + ":" +this.KoTTYEFN; 
          }
          //葬儀時
          if(this.KoTSSOGSJI != ""){
            if(this.KoTSSOGSJI.length == 1 ){this.KoTSSOGSJI = "0"+this.KoTSSOGSJI;}
            if(this.KoTSSOGSFN.length == 1 ){this.KoTSSOGSFN = "0"+this.KoTSSOGSFN;}
            this.WKoTSSOGSJI = this.KoTSSOGSJI + ":" +this.KoTSSOGSFN; 
          }
          //出棺時
          if(this.KoTSSOGEJI != ""){
            if(this.KoTSSOGEJI.length == 1 ){this.KoTSSOGEJI = "0"+this.KoTSSOGEJI;}
            if(this.KoTSSOGEFN.length == 1 ){this.KoTSSOGEFN = "0"+this.KoTSSOGEFN;}
            this.WKoTSSOGEJI = this.KoTSSOGEJI + ":" +this.KoTSSOGEFN; 
          }
          //火葬時
          if(this.KoTSJJI != ""){
            if(this.KoTSJJI.length == 1 ){this.KoTSJJI = "0"+this.KoTSJJI;}
            if(this.KoTSJFN.length == 1 ){this.KoTSJFN = "0"+this.KoTSJFN;}
            this.WKoTSJJI = this.KoTSJJI + ":" +this.KoTSJFN; 
          }
          //火葬終了時
          if(this.KoTSJEJI != ""){
            if(this.KoTSJEJI.length == 1 ){this.KoTSJEJI = "0"+this.KoTSJEJI;}
            if(this.KoTSJEFN.length == 1 ){this.KoTSJEFN = "0"+this.KoTSJEFN;}
            this.WKoTSJEJI = this.KoTSJEJI + ":" +this.KoTSJEFN; 
          }
          //打合時
          if(this.KoTIRJI != ""){
            if(this.KoTIRJI.length == 1 ){this.KoTIRJI = "0"+this.KoTIRJI;}
            if(this.KoTIRFN.length == 1 ){this.KoTIRFN = "0"+this.KoTIRFN;}
            this.WKoTIRJI = this.KoTIRJI + ":" +this.KoTIRFN; 
          }
          //納棺時
          if(this.KoTNKJI != ""){
            if(this.KoTNKJI.length == 1 ){this.KoTNKJI = "0"+this.KoTNKJI;}
            if(this.KoTNKFN.length == 1 ){this.KoTNKFN = "0"+this.KoTNKFN;}
            this.WKoTNKJI = this.KoTNKJI + ":" +this.KoTNKFN; 
          }
          //移動1時
          if(this.KoTISU1JI != ""){
            if(this.KoTISU1JI.length == 1 ){this.KoTISU1JI = "0"+this.KoTISU1JI;}
            if(this.KoTISU1FN.length == 1 ){this.KoTISU1FN = "0"+this.KoTISU1FN;}
            this.WKoTISU1JI = this.KoTISU1JI + ":" +this.KoTISU1FN; 
          }
          //移動2時
          if(this.KoTISU2JI != ""){
            if(this.KoTISU2JI.length == 1 ){this.KoTISU2JI = "0"+this.KoTISU2JI;}
            if(this.KoTISU2FN.length == 1 ){this.KoTISU2FN = "0"+this.KoTISU2FN;}
            this.WKoTISU2JI = this.KoTISU2JI + ":" +this.KoTISU2FN; 
          }
          this.WKoTSSOGDT  = moment(this.KoTSSOGDT).format('YYYY-MM-DD');   //葬儀日
          this.WKoTURDT    = moment(this.KoTURDT).format('YYYY-MM-DD');     //売上日
          this.WKoTUKDT    = moment(this.KoTUKDT).format('YYYY-MM-DD');     //受付日
          this.WKoTSHYODT  = moment(this.KoTSHYODT).format('YYYY-MM-DD');   //支払予定日
          this.WKKoTTYSDT  = moment(this.KoTTYSDT).format('YYYY-MM-DD');    //通夜日
          this.WKoTSJDT    = moment(this.KoTSJDT).format('YYYY-MM-DD');     //火葬日
          this.WKoTIRDT    = moment(this.KoTIRDT).format('YYYY-MM-DD');     //打合日時
          this.WKoTNKDT    = moment(this.KoTNKDT).format('YYYY-MM-DD');     //納棺日
          this.WKoTISU1DT  = moment(this.KoTISU1DT).format('YYYY-MM-DD');   //移動1日
          this.WKoTISU2DT  = moment(this.KoTISU2DT).format('YYYY-MM-DD');   //移動2日
          this.WKoTDRY1DT  = moment(this.KoTDRY1DT).format('YYYY-MM-DD');   //ドライ1
          this.WKoTDRY2DT  = moment(this.KoTDRY2DT).format('YYYY-MM-DD');   //ドライ2
          this.WKoTDRY3DT  = moment(this.KoTDRY3DT).format('YYYY-MM-DD');   //ドライ3
          //console.log(this.WKoTSHYODT);
          localStorage.SOGI_KoTNO      = this.KoTNO;           //受付番号
          localStorage.SOGI_H_KoTNKTM  = this.WH_KoTNKTM;      //表示用納棺時分
          localStorage.SOGI_KoTURDT    = this.WKoTURDT;        //売上日
          localStorage.SOGI_KoTUKDT    = this.WKoTUKDT;        //受付日
          localStorage.SOGI_KoTUKJI    = this.WKoTUKJI;        //受付時
          localStorage.SOGI_KoTUKFN    = "";                   //受付分
          localStorage.SOGI_KoTBLOKCD  = this.KoTBLOKCD;       //部署コード
          localStorage.SOGI_KoTBLOKNM  = this.KoTBLOKNM;       //部署名称
          localStorage.SOGI_KoETNTNCD  = this.KoETNTNCD;       //営業担当コード
          localStorage.SOGI_KoETNTNNM  = this.KoETNTNNM;       //営業担当名称
          localStorage.SOGI_KoETNTNNMS = this.KoETNTNNMS;      //主担当略称
          localStorage.SOGI_KoTKANO    = this.KoTKANO;         //会員番号
          localStorage.SOGI_KoTKANM    = this.KoTKANM;         //会員名称
          localStorage.SOGI_KoTMOKE    = this.KoTMOKE;         //喪家
          localStorage.SOGI_KoThCD     = this.KoThCD;          //顧客取引先コード
          localStorage.SOGI_KoThNM     = this.KoThNM;          //顧客取引先名称
          localStorage.SOGI_KoTCD      = this.KoTCD;           //顧客提携区分コード
          localStorage.SOGI_KoTNM      = this.KoTNM;           //顧客提携区分名称
          localStorage.SOGI_KoTTKNM    = this.KoTTKNM;         //故人(カナ）
          localStorage.SOGI_KoTTNM     = this.KoTTNM;          //故人名前
          localStorage.SOGI_KoTTBSDTJ  = this.KoTTBSDTJ;       //故人生年月日(和暦）
          localStorage.SOGI_KoTTEG     = this.KoTTEG;          //故人年齢
          localStorage.SOGI_KoTTSEX    = this.KoTTSEX;         //故人性別
          localStorage.SOGI_KoTTNEX    = this.KoTTNEX;         //故人続柄
          localStorage.SOGI_KoTTYU     = this.KoTTYU;          //故人郵便番号
          localStorage.SOGI_KoTTJYU1   = this.KoTTJYU1;        //故人住所1
          localStorage.SOGI_KoTTJYU2   = this.KoTTJYU2;        //故人住所2
          localStorage.SOGI_KoTTJYU3   = this.KoTTJYU3;        //故人住所3
          localStorage.SOGI_KoTTTEL    = this.KoTTTEL;         //故人電話番号
          localStorage.SOGI_KoTMKNM    = this.KoTMKNM;         //喪主(カナ）
          localStorage.SOGI_KoTMNM     = this.KoTMNM;          //喪主名前
          localStorage.SOGI_KoTMNEX    = this.KoTMNEX;         //喪主続柄
          localStorage.SOGI_KoTMSEX    = this.KoTMSEX;         //喪主性別
          localStorage.SOGI_KoTMYU     = this.KoTMYU;          //喪主郵便番号
          localStorage.SOGI_KoTMJYU1   = this.KoTMJYU1;        //喪主住所1
          localStorage.SOGI_KoTMJYU2   = this.KoTMJYU2;        //喪主住所2
          localStorage.SOGI_KoTMJYU3   = this.KoTMJYU3;        //喪主住所3
          localStorage.SOGI_KoTMTEL    = this.KoTMTEL;         //喪主電話番号
          localStorage.SOGI_KoTMTELK   = this.KoTMTELK;        //喪主携帯
          localStorage.SOGI_KoTMTMail  = this.KoTMTMail;       //喪主Mail
          localStorage.SOGI_KoTMBIKO   = this.KoTMBIKO;        //喪主備考
          localStorage.SOGI_KoTSNM     = this.KoTSNM;          //お支払責任者名前
          localStorage.SOGI_KoTSKNM    = this.KoTSKNM;         //お支払カナ
          localStorage.SOGI_KoTSHYODT  = this.WKoTSHYODT;       //お支払予定日
          localStorage.SOGI_KoTSPNM    = this.KoTSPNM;         //入金方法名
          localStorage.SOGI_KoTSYU     = this.KoTSYU;          //支払責任者郵便番号
          localStorage.SOGI_KoTSJYU1   = this.KoTSJYU1;        //お支払責任者住所1
          localStorage.SOGI_KoTSJYU2   = this.KoTSJYU2;        //お支払責任者住所2
          localStorage.SOGI_KoTSJYU3   = this.KoTSJYU3;        //お支払責任者住所3
          localStorage.SOGI_KoTSTEL    = this.KoTSTEL;         //お支払責任者電話番号
          localStorage.SOGI_KoTSTELK   = this.KoTSTELK;        //お支払責任者携帯
          localStorage.SOGI_KoTSMail   = this.KoTSMail;        //お支払責任者Mail
          localStorage.SOGI_KoTSBIKO   = this.KoTSBIKO;        //お支払責任者備考
          localStorage.SOGI_KoTSSTRNM  = this.KoTSSTRNM;       //寺院名称
          localStorage.SOGI_KoTSSSONM  = this.KoTSSSONM;       //宗旨名称
          localStorage.SOGI_KoTSSKSA1  = this.KoTSSKSA1;       //会葬挨拶1
          localStorage.SOGI_KoTSSKMNM  = this.KoTSSKMNM;       //家紋・紋章名称
          localStorage.SOGI_KoTSSBIKO  = this.KoTSSBIKO;       //引継ぎ備考
          localStorage.SOGI_KoTUUKBDT  = this.KoTUUKBDT;       //受付簿印刷日
          localStorage.SOGI_KoTUUKBTM  = this.KoTUUKBTM;       //受付簿印時間
          localStorage.SOGI_KoTUMSKSDT = this.KoTUMSKSDT;      //請求書印刷日
          localStorage.SOGI_KoTUMSKSTM = this.KoTUMSKSTM;      //請求書印刷時間
          localStorage.SOGI_KoTUTKSSDT = this.KoTUTKSSDT;      //立替請求書印刷日
          localStorage.SOGI_KoTUTKSSTM = this.KoTUTKSSTM;      //立替請求書印刷時間
          localStorage.SOGI_KoTPLCD    = this.KoTPLCD;         //プランコード
          localStorage.SOGI_KoTPLNM    = this.KoTPLNM;         //プラン名称
          localStorage.SOGI_KoTPLGK    = this.KoTPLGK;         //プラン金額
          localStorage.SOGI_KoTKKCD    = this.KoTKKCD;         //会館CD
          localStorage.SOGI_KoTKKNM    = this.KoTKKNM;         //会館名
          localStorage.SOGI_KoTHRCD    = this.KoTHRCD;         //ホールCD
          localStorage.SOGI_KoTHRNM    = this.KoTHRNM;         //ホール名
          localStorage.SOGI_KoTHRJK    = this.KoTHRJK;         //会館ホール略称
          localStorage.SOGI_KoTHRNMS   = this.KoTHRNMS;        //会館ホール略称
          localStorage.SOGI_KoTDESDTJ  = this.KoTDESDTJ;       //死亡日(和暦
          localStorage.SOGI_KoTDESBS   = this.KoTDESBS;        //死亡場所
          localStorage.SOGI_KoTDESJI   = this.WKoTDESJI;       //死亡時
          localStorage.SOGI_KoTDESFN   = "";                   //死亡分 
          localStorage.SOGI_KoTTYSDT   = this.WKKoTTYSDT;      //通夜日
          localStorage.SOGI_KoTTYSJI   = this.WKoTTYSJI;       //通夜時
          localStorage.SOGI_KoTTYSFN   = "";                   //通夜分
          localStorage.SOGI_KoTTYEJI   = this.WKoTTYEJI;       //通夜終了時
          localStorage.SOGI_KoTTYEFN   = "";                   //通夜終了分
          localStorage.SOGI_KoTTYSBS   = this.KoTTYSBS;        //通夜場所
          localStorage.SOGI_KoTTYSJK   = this.KoTTYSJK;        //通夜状況
          localStorage.SOGI_KoTTYSSK   = this.KoTTYSSK;        //通夜司会
          localStorage.SOGI_KoTSSOGDT  = this.WKoTSSOGDT;      //葬儀日
          localStorage.SOGI_KoTSSOGSJI = this.WKoTSSOGSJI;     //葬儀時
          localStorage.SOGI_KoTSSOGSFN = "";                   //葬儀分
          localStorage.SOGI_KoTSSOGEJI = this.WKoTSSOGEJI;     //出棺時
          localStorage.SOGI_KoTSSOGEFN = "";                   //出棺分
          localStorage.SOGI_KoTSSOSBS  = this.KoTSSOSBS;       //葬儀場所
          localStorage.SOGI_KoTSSOSSK  = this.KoTSSOSSK;       //葬儀司会
          localStorage.SOGI_KoTSSOGAS  = this.KoTSSOGAS;       //葬儀アシスタント
          localStorage.SOGI_KoTSSOGJK  = this.KoTSSOGJK;       //葬儀状況
          localStorage.SOGI_KoTSJDT    = this.WKoTSJDT;         //火葬日
          localStorage.SOGI_KoTSJCD    = this.KoTSJCD;         //斎場コード
          localStorage.SOGI_KoTSJNM    = this.KoTSJNM;         //斎場名称
          localStorage.SOGI_KoTSJNMS   = this.KoTSJNMS;        //斎場略称
          localStorage.SOGI_KoTSJJI    = this.WKoTSJJI;        //火葬時
          localStorage.SOGI_KoTSJFN    = "";                   //火葬分
          localStorage.SOGI_KoTSJEJI   = this.WKoTSJEJI;       //火葬終了時
          localStorage.SOGI_KoTSJEFN   = "";                   //火葬終了分
          localStorage.SOGI_KoTSJDS    = this.KoTSJDS;         //同行・導師
          localStorage.SOGI_KoTSJD7    = this.KoTSJD7;         //初七日
          localStorage.SOGI_KoTSJHS    = this.KoTSJHS;         //控室
          localStorage.SOGI_KoTHZJK    = this.KoTHZJK;         //本膳状況
          localStorage.SOGI_KoTYOYK    = this.KoTYOYK;         //斎場予約
          localStorage.SOGI_KoTURSMST  = this.KoTURSMST;       //祭事標準税売上(税抜
          localStorage.SOGI_KoTURSMHK  = this.KoTURSMHK;       //祭事非課税売上(税抜
          localStorage.SOGI_KoTSNEBST  = this.KoTSNEBST;       //祭事標準税値引
          localStorage.SOGI_KoTSNEBKG  = this.KoTSNEBKG;       //祭事軽減税値引
          localStorage.SOGI_KoTSNEBHK  = this.KoTSNEBHK;       //祭事非課税値引
          localStorage.SOGI_KoTSKAZST  = this.KoTSKAZST;       //祭事標準税対象額計
          localStorage.SOGI_KoTSKAZKG  = this.KoTSKAZKG;       //祭事軽減税対象額計
          localStorage.SOGI_KoTSKAZHK  = this.KoTSKAZHK;       //祭事非課税対象額計
          localStorage.SOGI_KoTSSYOST  = this.KoTSSYOST;       //祭事標準税消費税
          localStorage.SOGI_KoTSSYOKG  = this.KoTSSYOKG;       //祭事軽減税消費税
          localStorage.SOGI_KoTSURKGK  = this.KoTSURKGK;       //祭事売上(税込み
          localStorage.SOGI_KoTURTMST  = this.KoTURTMST;       //立替標準税売上(税抜
          localStorage.SOGI_KoTURTMKG  = this.KoTURTMKG;       //立替軽減税売上(税抜
          localStorage.SOGI_KoTURTMHK  = this.KoTURTMHK;       //立替非課税売上(税抜
          localStorage.SOGI_KoTTNEBST  = this.KoTTNEBST;       //立替標準税値引
          localStorage.SOGI_KoTTNEBKG  = this.KoTTNEBKG;       //立替軽減税値引
          localStorage.SOGI_KoTTNEBHK  = this.KoTTNEBHK;       //立替非課税値引
          localStorage.SOGI_KoTTKAZST  = this.KoTTKAZST;       //立替標準税対象額計
          localStorage.SOGI_KoTTKAZKG  = this.KoTTKAZKG;       //立替軽減税対象額計
          localStorage.SOGI_KoTTKAZHK  = this.KoTTKAZHK;       //立替非課税対象額計
          localStorage.SOGI_KoTURSMKG  = this.KoTURSMKG;       //祭事軽減税売上(税抜
          localStorage.SOGI_KoTTSYOST  = this.KoTTSYOST;       //立替標準税消費税
          localStorage.SOGI_KoTTSYOKG  = this.KoTTSYOKG;       //立替軽減税消費税
          localStorage.SOGI_KoTTURKGK  = this.KoTTURKGK;       //立替売上(税込み
          localStorage.SOGI_KoTSGK     = this.KoTSGK;          //売掛金総額
          localStorage.SOGI_KoTSHZNG   = this.KoTSHZNG;        //売掛残高
          localStorage.SOGI_KoTSHNKG   = this.KoTSHNKG;        //売掛金入金金額
          localStorage.SOGI_KoSGICNM   = this.KoSGICNM;        //葬儀委員長名
          localStorage.SOGI_KoSGICKN   = this.KoSGICKN;        //葬儀委員長かな名
          localStorage.SOGI_KoSGICZK   = this.KoSGICZK;        //葬儀委員長属性
          localStorage.SOGI_KoDMKB     = this.KoDMKB;          //DM区分
          localStorage.SOGI_KoHJGK     = this.KoHJGK;          //標準原価計
          localStorage.SOGI_KoARRI     = this.KoARRI;          //粗利益計
          localStorage.SOGI_KoTJJK     = this.KoTJJK;          //受注進捗状況
          localStorage.SOGI_KoTIRNM    = this.KoTIRNM;         //依頼人
          localStorage.SOGI_KoTIRZK    = this.KoTIRZK;         //依頼人続柄
          localStorage.SOGI_KoTIRTL    = this.KoTIRTL;         //依頼人電話番号
          localStorage.SOGI_KoTIRTO    = this.KoTIRTO;         //搬送先(
          localStorage.SOGI_KoTIRD     = this.WKoTIRDT;        //打合日
          localStorage.SOGI_KoTIRJI    = this.WKoTIRJI;        //打合時
          localStorage.SOGI_KoTIRFN    = "";                   //打合分
          localStorage.SOGI_KoTIRBK    = this.KoTIRBK;         //その他･備考
          localStorage.SOGI_KoTIRKM    = this.KoTIRKM;         //搬送・打合せ・その他（）
          localStorage.SOGI_KoTIRSH    = this.KoTIRSH;         //使用品目
          localStorage.SOGI_KoTRDCD    = this.KoTRDCD;         //リードコード
          localStorage.SOGI_KoTRDNM    = this.KoTRDNM;         //リード名
          localStorage.SOGI_KoTRDNMS   = this.KoTRDNMS;        //リード略称
          localStorage.SOGI_KoTRDTN    = this.KoTRDTN;         //リード担当名
          localStorage.SOGI_KoTRDRV    = this.KoTRDRV;         //リードレベル
          localStorage.SOGI_KoSKICD    = this.KoSKICD;         //司会者コード
          localStorage.SOGI_KoSKINM    = this.KoSKINM;         //司会者名
          localStorage.SOGI_KoSKINMS   = this.KoSKINMS;        //司会者略称
          localStorage.SOGI_KoSVTNCD   = this.KoSVTNCD;        //サブ担当コード
          localStorage.SOGI_KoSVNTNM   = this.KoSVNTNM;        //サブ担当名
          localStorage.SOGI_KoSVNTNMS  = this.KoSVNTNMS;       //サブ担当略称
          localStorage.SOGI_KoKRTNCD   = this.KoKRTNCD;        //経理担当コード
          localStorage.SOGI_KoKRNTNM   = this.KoKRNTNM;        //経理担当名
          localStorage.SOGI_KoTNKDT    = this.WKoTNKDT;        //納棺日
          localStorage.SOGI_KoTNKJI    = this.WKoTNKJI;        //納棺時
          localStorage.SOGI_KoTNKFN    = "";                   //納棺分
          localStorage.SOGI_KoTNKMK    = this.KoTNKMK;         //納棺メーク
          localStorage.SOGI_KoTNKJK    = this.KoTNKJK;         //納棺状況
          localStorage.SOGI_KoTNKBS    = this.KoTNKBS;         //納棺場所
          localStorage.SOGI_KoTISU1DT  = this.WKoTISU1DT;      //移動1日
          localStorage.SOGI_KoTISU1JI  = this.WKoTISU1JI;      //移動1時
          localStorage.SOGI_KoTISU1FN  = "";                   //移動1分
          localStorage.SOGI_KoTISU1FR  = this.KoTISU1FR;       //F移動1移動元
          localStorage.SOGI_KoTISU1BS  = this.KoTISU1BS;       //バス台数
          localStorage.SOGI_KoTISU1HY  = this.KoTISU1HY;       //ハイヤー台数
          localStorage.SOGI_KoTISU1JK  = this.KoTISU1JK;       //移動1状況
          localStorage.SOGI_KoTISU2DT  = this.WKoTISU2DT;      //移動2日
          localStorage.SOGI_KoTISU2JI  = this.WKoTISU2JI;      //移動2時
          localStorage.SOGI_KoTISU2FN  = "";                   //移動2分
          localStorage.SOGI_KoTISU2FR  = this.KoTISU2FR;       //F移動2移動元
          localStorage.SOGI_KoTISU2BS  = this.KoTISU2BS;       //バス台数
          localStorage.SOGI_KoTISU2HY  = this.KoTISU2HY;       //ハイヤー台数
          localStorage.SOGI_KoTISU2JK  = this.KoTISU2JK;       //移動2状況
          localStorage.SOGI_KoTDRY1DT  = this.WKoTDRY1DT;       //ドライ1
          localStorage.SOGI_KoTDRY2DT  = this.WKoTDRY2DT;       //ドライ2
          localStorage.SOGI_KoTDRY3DT  = this.WKoTDRY3DT;       //ドライ3
          localStorage.SOGI_KoTDRYJK   = this.KoTDRYJK;        //ドライ状況
          localStorage.SOGI_KoREIJK    = this.KoREIJK;         //礼状状況
          localStorage.SOGI_KoREIBK    = this.KoREIBK;         //礼状備考
          localStorage.SOGI_KoHENJK    = this.KoHENJK;         //返礼品状況
          localStorage.SOGI_KoRIHINM   = this.KoRIHINM;        //返礼品品目
          localStorage.SOGI_KoRICJK    = this.KoRICJK;         //霊柩車状況
          localStorage.SOGI_KoRICTK    = this.KoRICTK;         //霊柩車立替
          localStorage.SOGI_KoTSSTRJK  = this.KoTSSTRJK;       //寺院状況
          localStorage.SOGI_KoTSSTRBS  = this.KoTSSTRBS;       //菩提寺
          localStorage.SOGI_KoTSSTRHM  = this.KoTSSTRHM;       //戒(法名）
          localStorage.SOGI_KoTSSTROF  = this.KoTSSTROF;       //お布施
          localStorage.SOGI_KoTKUDAMJ  = this.KoTKUDAMJ;       //果物状況
          localStorage.SOGI_KoTHZBS    = this.KoTHZBS;         //本膳場所
          localStorage.SOGI_KoTHZNY    = this.KoTHZNY;         //枕飯・枕団子、供養膳
          localStorage.SOGI_KoTAZKPHJK = this.KoTAZKPHJK;      //写真
          localStorage.SOGI_KoTAZKPB   = this.KoTAZKPB;        //写真備考
          localStorage.SOGI_KoTAZKKKJK = this.KoTAZKKKJK;      //許可書
          localStorage.SOGI_KoTAZKKB   = this.KoTAZKKB;        //許可備考
          localStorage.SOGI_KoTHCDT    = this.KoTHCDT;         //発注日
          localStorage.SOGI_KoTHCJI    = this.KoTHCJI;         //発注時
          localStorage.SOGI_KoTHCFN    = this.KoTHCFN;         //発注分
          localStorage.SOGI_KoTMGKT    = this.KoTMGKT;         //担当者確定
          localStorage.SOGI_KoTBIKO1   = this.KoTBIKO1;        //備考1
          localStorage.SOGI_KoTBIKO2   = this.KoTBIKO2;        //備考2
          localStorage.SOGI_KoTBIKO3   = this.KoTBIKO3;        //備考3
          localStorage.SOGI_KoMUKBDT   = this.KoMUKBDT;	       //見積受付簿印刷日
          localStorage.SOGI_KoMUKBTM   = this.KoMUKBTM;	       //見積受付簿印刷時間
          localStorage.SOGI_KoMSKSDT   = this.KoMSKSDT;	       //見積祭事印刷日
          localStorage.SOGI_KoMSKSTM   = this.KoMSKSTM;	       //見積祭事印刷時間
          localStorage.SOGI_KoMTKSDT   = this.KoMTKSDT;	       //見積立替印刷日
          localStorage.SOGI_KoMTKSTM   = this.KoMTKSTM;	       //見積立替印刷時間
          localStorage.SOGI_InstDt     = this.InstDt;          //作成日時
          localStorage.SOGI_InstId     = this.InstId;          //作成者
          localStorage.SOGI_UpdtDt     = this.UpdtDt;          //更新日時
          localStorage.SOGI_UpdtId     = this.UpdtId;          //更新者
          this.$refs.dialogIN.open();
    },
    //施行予定
    responseIN(obj) {
          this.dialogIN = false;
    },
    //----------------------------------------
    //供物入力
    //----------------------------------------
    KMDialogshow() {
          localStorage.SOGI_KoTNO      = this.KoTNO;           //受付番号
          localStorage.SOGI_KoTURDT    = this.WKoTURDT;        //売上日
          localStorage.SOGI_KoTUKDT    = this.WKoTUKDT;        //受付日
          localStorage.SOGI_KoTUKJI    = this.WKoTUKJI;        //受付時
          localStorage.SOGI_KoTUKFN    = "";                   //受付分
          localStorage.SOGI_KoTBLOKCD  = this.KoTBLOKCD;       //部署コード
          localStorage.SOGI_KoTBLOKNM  = this.KoTBLOKNM;       //部署名称
          localStorage.SOGI_KoETNTNCD  = this.KoETNTNCD;       //営業担当コード
          localStorage.SOGI_KoETNTNNM  = this.KoETNTNNM;       //営業担当名称
          localStorage.SOGI_KoETNTNNMS = this.KoETNTNNMS;      //主担当略称
          localStorage.SOGI_KoTKANO    = this.KoTKANO;         //会員番号
          localStorage.SOGI_KoTKANM    = this.KoTKANM;         //会員名称
          localStorage.SOGI_KoTMOKE    = this.KoTMOKE;         //喪家
          localStorage.SOGI_KoThCD     = this.KoThCD;          //顧客取引先コード
          localStorage.SOGI_KoThNM     = this.KoThNM;          //顧客取引先名称
          localStorage.SOGI_KoTCD      = this.KoTCD;           //顧客提携区分コード
          localStorage.SOGI_KoTNM      = this.KoTNM;           //顧客提携区分名称
          localStorage.SOGI_KoTTKNM    = this.KoTTKNM;         //故人(カナ）
          localStorage.SOGI_KoTTNM     = this.KoTTNM;          //故人名前
          localStorage.SOGI_KoTTBSDTJ  = this.KoTTBSDTJ;       //故人生年月日(和暦）
          localStorage.SOGI_KoTTEG     = this.KoTTEG;          //故人年齢
          localStorage.SOGI_KoTTSEX    = this.KoTTSEX;         //故人性別
          localStorage.SOGI_KoTTNEX    = this.KoTTNEX;         //故人続柄
          localStorage.SOGI_KoTTYU     = this.KoTTYU;          //故人郵便番号
          localStorage.SOGI_KoTTJYU1   = this.KoTTJYU1;        //故人住所1
          localStorage.SOGI_KoTTJYU2   = this.KoTTJYU2;        //故人住所2
          localStorage.SOGI_KoTTJYU3   = this.KoTTJYU3;        //故人住所3
          localStorage.SOGI_KoTTTEL    = this.KoTTTEL;         //故人電話番号
          localStorage.SOGI_KoTMKNM    = this.KoTMKNM;         //喪主(カナ）
          localStorage.SOGI_KoTMNM     = this.KoTMNM;          //喪主名前
          localStorage.SOGI_KoTMNEX    = this.KoTMNEX;         //喪主続柄
          localStorage.SOGI_KoTMSEX    = this.KoTMSEX;         //喪主性別
          localStorage.SOGI_KoTMYU     = this.KoTMYU;          //喪主郵便番号
          localStorage.SOGI_KoTMJYU1   = this.KoTMJYU1;        //喪主住所1
          localStorage.SOGI_KoTMJYU2   = this.KoTMJYU2;        //喪主住所2
          localStorage.SOGI_KoTMJYU3   = this.KoTMJYU3;        //喪主住所3
          localStorage.SOGI_KoTMTEL    = this.KoTMTEL;         //喪主電話番号
          localStorage.SOGI_KoTMTELK   = this.KoTMTELK;        //喪主携帯
          localStorage.SOGI_KoTMTMail  = this.KoTMTMail;       //喪主Mail
          localStorage.SOGI_KoTMBIKO   = this.KoTMBIKO;        //喪主備考
          localStorage.SOGI_KoTSNM     = this.KoTSNM;          //お支払責任者名前
          localStorage.SOGI_KoTSKNM    = this.KoTSKNM;         //お支払カナ
      this.$refs.dialogKM.open();
    },
    //施行予定
    responseKM(obj) {
        this.dialogKM = false;
    },
     //----------------------------------------
    //社員マスタ表示
    //----------------------------------------
    SYADialogshow() {
      this.$refs.dialogSY.open();
    },
    //社員マスタ取得
    responseSY(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KoETNTNCD = obj.SynCd; //社員コード
        this.KoETNTNNM = obj.SynNm; //社員名
        this.KoTBLOKCD = obj.SynBLCKCD; //部署コード
        this.KoTBLOKNM = obj.SynBLCKNM; //部署名称
      }
        this.dialogSY = false;
    },
    //----------------------------------------
    //会館ホール表示
    //----------------------------------------
    KKHRshow() {
      this.$refs.dialogKKHR.open();
    },
    //会館ホール取得
    responseKKHR(obj) {
      if(obj.HrKKCD != "--"){  //キャンセル時は何もしない
        this.KoTKKCD = obj.HrKKCD; //会館コード
        this.KoTKKNM = obj.HrKKNM; //会館名
        this.KoTHRCD = obj.HrHRCD; //ホールコード
        this.KoTHRNM = obj.HrHRNM; //ホール名称
      //  this.KoTHRNMS= obj.SynBLCKNM; //ホール略称
      }
        this.dialogKKHR = false;
    },
    //----------------------------------------
    //斎場表示
    //----------------------------------------
    KKHRSJshow() {
      this.$refs.dialogKKHRSJ.open();
    },
    ///斎場表示取得
    responseKKHRSJ(obj) {
      if(obj.HrKKCD != "--"){  //キャンセル時は何もしない
        this.KoTSJCD = obj.HrKKCD; //斎場コード
        this.KoTSJNM = obj.HrKKNM; //斎場名
      //  this.KoTSJNMS = obj.HrHRCD; //斎場略称
      }
        this.dialogKKHRSJ = false;
    },
    //----------------------------------------
    //003:宗派設定 分類マスタ表示
    //----------------------------------------
    SHDialogshow() {
      this.$store.commit("increment_BRID","003") 
      this.$refs.dialogBR.open();
    },
    //宗派属性取得
    responseSH(obj) {
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTSSSONM = obj.BRMSNM;
      }
        this.dialogBR = false;
    },
    //----------------------------------------
    //014:入金設定 分類マスタ表示
    //----------------------------------------
    NKNDialogshow() {
      //localStorage.setItem('PLBR','014');
      this.$store.commit("increment_BRID","014") 
      this.$refs.dialogBRNK.open();
    },
    //入金属性取得
    responseNK(obj) {
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTSPNM = obj.BRMSNM;
      }
        this.dialogBRNK = false;
    },
    //----------------------------------------
    //005:属性 故人続柄分類マスタ表示
    //----------------------------------------
    KZDialogshow() {
      this.$refs.dialogBKZ.open();
    },
    //----------------------------------------
    //故人属性取得
    //----------------------------------------
    KZresponseBR(obj) {
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTTNEX = obj.BRMSNM;
      }
        this.dialogBKZ = false;
    },
    //----------------------------------------
    //005:属性 喪主続柄分類マスタ表示
    //----------------------------------------
    MZDialogshow() {
      this.$refs.dialogMSZ.open();
    },
    //----------------------------------------
    //故人属性取得
    //----------------------------------------
    MSresponseBR(obj) {
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTMNEX = obj.BRMSNM;
      }
        this.dialogKZ = false;
    },
    //----------------------------------------
    //005:属性 分類マスタ表示
    //----------------------------------------
    ZKDialogshow() {
      //localStorage.setItem('PLBR','005');
      this.$store.commit("increment_BRID","005") 
      
      this.$refs.dialogBR.open();
    },
    //----------------------------------------
    //依頼人属性取得
    //----------------------------------------
    IRresponseBR(obj) {
     console.log('依頼人')
     console.log(obj.BRMSDCD)
     console.log(obj.BRMSNM)
     
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTIRZK = obj.BRMSNM;
      }
        this.dialogBR = false;
    },
    //----------------------------------------
    //喪主属性取得
    //----------------------------------------
    MSresponseBR(obj) {
     console.log('喪主')
     console.log(obj.BRMSDCD)
     console.log(obj.BRMSNM)
    if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTMNEX = obj.BRMSNM;
      }
      this.dialogBR = false;
    },
    //----------------------------------------
    //斎場HP表示
    //----------------------------------------
    //SAOJOshow() {
    //  this.$refs.dialogSAOJO.open();
    //},
    //宗派属性取得
    //responseSAOJO(obj) {
    //  this.dialogSAOJO = false;
    //},
    moveLink (url) {
      window.open(url, '_blank')
    },
    //----------------------------------------
    //DAMO
    //----------------------------------------
    KJresponseBR(obj) {
 
     if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTTNEX = obj.BRMSNM;
      }
        this.dialogKZ = false;
    },
    //----------------------------------------
    //888:受注状況設定 分類マスタ表示
    //----------------------------------------
    JCJDialogshow() {
      //this.$store.commit("increment_BRID","888") 
      //localStorage.setItem('PLBR','888');
      this.$refs.dialogJC.open();
    },
    //受注状況設定
    responseJC(obj) {
    console.log(obj.BRMSDCD)
     console.log(obj.BRMSNM)
      if(obj.BRMSDCD != "--"){  //キャンセル時は何もしない
        this.KoTJJK = obj.BRMSNM;
      }
        this.dialogJC = false;
    },
 
    //----------------------------------------
    //エラー確認
    //----------------------------------------
    ERRORConfirm () {
        this.dialogERROR = false;
    },
    //----------------------------------------
    //故人住所から喪主住所にコピー
    //----------------------------------------
    COPYKMAddress(){
        this.KoTMYU   =  this.KoTTYU;      //喪主住所 〒
        this.KoTMJYU1 =  this.KoTTJYU1;    //都道府県
        this.KoTMJYU2 =  this.KoTTJYU2;    //市町村区
        this.KoTMJYU3 =  this.KoTTJYU3;    //地域番地
        this.KoTMTEL  =  this.KoTTTEL;     //電話№
    },
    //----------------------------------------
    //喪主から請求者にコピー
    //----------------------------------------
    COPYMSAddress(){
        this.KoTSNM   =  this.KoTMNM;      //お支払責任者名前
        this.KoTSKNM  =  this.KoTMKNM;      //お支払責任者名前 ふりがな
        this.KoTSYU   =  this.KoTMYU;      //お支払責任者住所 〒
        this.KoTSJYU1 =  this.KoTMJYU1;    //都道府県
        this.KoTSJYU2 =  this.KoTMJYU2;    //市町村区
        this.KoTSJYU3 =  this.KoTMJYU3;    //地域番地
        this.KoTSTEL  =  this.KoTMTEL;     //電話№
        this.KoTSTELK =  this.KoTMTELK;    //携帯№
        this.KoTSMail =  this.KoTMTMail;   //mail
    },
    //////////////////////////////////////////////
    //状況
    //////////////////////////////////////////////
    //----------------------------------------
    //式場状況ボタン
    //----------------------------------------
    KoTHRJKBOT(){
      if(this.KoTHRJK == "〇"){
       this.KoTHRJK  = "✖";
       this.KoTKKCD   = "";	  //式場コード
       this.KoTHRNM   = "";		//式場名称
       this.KoTHRNMS  = "";		//式場略称
 
      }else{
        this.KoTHRJK = "〇";
      }
     },
    //----------------------------------------
    //通夜状況ボタン
    //----------------------------------------
    KoTTYSJKBOT(){
      if(this.KoTTYSJK == "〇"){
       this.KoTTYSJK = "✖";
       this.KoTTYSDT = "";	  //通夜日
       this.KoTTYSJI = "";		//開始時
  //     this.KoTTYSFN = "";		//開始時
       this.KoTTYEJI = "";		//終了時
  //     this.KoTTYEFN = "";		//終了時
       this.KoTTYSBS = "";		//通夜場所
       this.KoTTYSSK = "";		//司
       this.KoTTYSAS = "";		//ア
      }else{
        this.KoTTYSJK = "〇";
      }
     },
   //----------------------------------------
    //告別状況ボタン
    //----------------------------------------
    KoTSSOGJKBOT(){
      if(this.KoTSSOGJK == "〇"){
       this.KoTSSOGJK   = "✖";
       this.KoTSSOGDT   = "";	//告別日
       this.KoTSSOGSJI  = "";	//告別開始時
 //      this.KoTSSOGSFN  = "";	//告別開始分
       this.KoTSSOGEJI  = "";	//告別終了時
 //      this.KoTSSOGEFN  = "";	//告別終了分
       this.KoTSSOSBS   = "";	//告別場所
   
       this.KoTSSOSSK   = "";		//司
       this.KoTSSOGAS   = "";		//ア
      }else{
        this.KoTSSOGJK = "〇";
      }
     },
   //----------------------------------------
   //本膳状況ボタン
   //----------------------------------------
    KoTHZJKBOT(){
      if(this.KoTHZJK == "〇"){
       this.KoTHZJK   = "✖";
       this.KoTSJD7   = "";	  //初七日
       this.KoTHZBS   = "";		//初七日場所
 
      }else{
        this.KoTHZJK  = "〇";
      }
     },
 //----------------------------------------
 //本膳状況ボタン
 //----------------------------------------
    KoTNKJKBOT(){
      if(this.KoTNKJK == "〇"){
       this.KoTNKJK   = "✖";
       this.KoTNKDT   = "";	  //納棺日
       this.KoTNKJI   = "";		//時
    //   this.KoTNKFN   = "";		//分
       this.KoTNKMK   = "";		//メーク
       this.KoTNKBS   = "";		//納棺場所
 
      }else{
        this.KoTNKJK  = "〇";
      }
     },
  //----------------------------------------
  //通夜移動状況ボタン
  //----------------------------------------
    KoTISU1JKBOT(){
      if(this.KoTISU1JK == "〇"){
       this.KoTISU1JK   = "✖";
       this.KoTISU1DT   = "";	  //通夜移動日
       this.KoTISU1JI   = "";		//時
   //    this.KoTISU1FN   = "";		//分
       this.KoTISU1FR   = "";		//通夜移動場所
       this.KoTISU1BS   = "";		//バス
       this.KoTISU1HY   = "";		//タクシー
      }else{
        this.KoTISU1JK  = "〇";
      }
     },
  //----------------------------------------
  //出棺移動状況ボタン
  //----------------------------------------
    KoTISU2JKBOT(){
      if(this.KoTISU2JK == "〇"){
       this.KoTISU2JK   = "✖";
       this.KoTISU2DT   = "";	  //出棺移動日
       this.KoTISU2JI   = "";		//時
    //   this.KoTISU2FN   = "";		//分
       this.KoTISU2FR   = "";		//出棺移動場所
       this.KoTISU2BS   = "";		//バス
       this.KoTISU2HY   = "";		//タクシー
      }else{
        this.KoTISU2JK  = "〇";
      }
     },
  //----------------------------------------
  //霊柩車状況ボタン
  //----------------------------------------
    KoRICJKBOT(){
      if(this.KoRICJK == "〇"){
       this.KoRICJK   = "✖";
       this.KoRICTK   = "";	  //出霊柩車
       }else{
        this.KoRICJK  = "〇";
      }
     },
  //----------------------------------------
  //写真状況ボタン
  //----------------------------------------
    KoTAZKPHJKBOT(){
      if(this.KoTAZKPHJK == "〇"){
       this.KoTAZKPHJK   = "✖";
       this.KoTAZKPB      = "";	  //写真
       }else{
        this.KoTAZKPHJK   = "〇";
      }
     },
  //----------------------------------------
  //許可証状況ボタン
  //----------------------------------------
    KoTAZKKKJKBOT(){
      if(this.KoTAZKKKJK == "〇"){
       this.KoTAZKKKJK    = "✖";
       this.KoTAZKKB      = "";	  //許可証備考
       }else{
        this.KoTAZKKKJK   = "〇";
      }
     },
  //----------------------------------------
  //ドライ施行状況ボタン
  //----------------------------------------
    KoTDRYJKBOT(){
      if(this.KoTDRYJK == "〇"){
       this.KoTDRYJK       = "✖";
       this.KoTDRY1DT      = "";	  //ドライ施行日
       this.KoTDRY1DT      = "";	  //ドライ施行日
       this.KoTDRY1DT      = "";	  //ドライ施行日
       }else{
        this.KoTDRYJK      = "〇";
      }
     },
  //----------------------------------------
  //礼状状況ボタン
  //----------------------------------------
    KoREIJKBOT(){
      if(this.KoREIJK == "〇"){
       this.KoREIJK     = "✖";
       this.KoREIBK     = "";	  //礼状備考
       }else{
        this.KoREIJK    = "〇";
      }
     },
  //----------------------------------------
  //返礼品状況ボタン
  //----------------------------------------
    KoHENJKBOT(){
      if(this.KoHENJK == "〇"){
       this.KoHENJK     = "✖";
       this.KoRIHINM    = "";	  //返礼品備考
       }else{
        this.KoHENJK    = "〇";
      }
     },
  //----------------------------------------
  //果物状況ボタン
  //----------------------------------------
    KoTKUDAMJBOT(){
      if(this.KoTKUDAMJ == "〇"){
       this.KoTKUDAMJ     = "✖";
       }else{
        this.KoTKUDAMJ    = "〇";
      }
     },
  //////////////////////////////////////////////
  //状況
  //////////////////////////////////////////////
  //----------------------------------------
  //搬送先(TO)
  //----------------------------------------
    KoTIRTO_JITK(){
      this.KoTIRTO = "自宅";
     },
    KoTIRTO_KAIA(){
      this.KoTIRTO = "会館";
     },
    KoTIRTO_SAIJ(){
      this.KoTIRTO = "斎場";
     },
  //----------------------------------------
  //通夜場所
  //----------------------------------------
    KoTTYSBS_JITK(){
      this.KoTTYSBS = "自宅";
     },
    KoTTYSBS_KAIA(){
      this.KoTTYSBS = "会館";
     },
    KoTTYSBS_SAIJ(){
      this.KoTTYSBS = "斎場";
     },
  //----------------------------------------
  //告別場所
  //----------------------------------------
    KoTSSOSBS_JITK(){
      this.KoTSSOSBS = "自宅";
     },
    KoTSSOSBS_KAIA(){
      this.KoTSSOSBS = "会館";
     },
    KoTSSOSBS_SAIJ(){
      this.KoTSSOSBS = "斎場";
     },
 //----------------------------------------
  //初七日場所
  //----------------------------------------
    KoTHZBS_JITK(){
      this.KoTHZBS = "自宅";
     },
    KoTHZBS_KAIA(){
      this.KoTHZBS = "会館";
     },
    KoTHZBS_SAIJ(){
      this.KoTHZBS = "斎場";
     },
  //----------------------------------------
  //納棺場所
  //----------------------------------------
    KoTNKBS_JITK(){
      this.KoTNKBS = "自宅";
     },
    KoTNKBS_KAIA(){
      this.KoTNKBS = "会館";
     },
    KoTNKBS_SAIJ(){
      this.KoTNKBS = "斎場";
     },
  //----------------------------------------
  //通夜移動場所
  //----------------------------------------
    KoTISU1FR_JITK(){
      this.KoTISU1FR = "自宅";
     },
    KoTISU1FR_KAIA(){
      this.KoTISU1FR = "会館";
     },
    KoTISU1FR_SAIJ(){
      this.KoTISU1FR = "斎場";
     },
  //----------------------------------------
  //施行予定表示
  //----------------------------------------
    SEKOUYOTEI_SHOW(){
            this.SKYOTrn.length = 0;

            if (this.KoTNKJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"納棺",           //施工名称
                    SKYTDT:this.KoTNKDT,     //施行順
                    SKYTHH:this.KoTNKJI,     //開始時  
                    SKYTMM:this.KoTNKFN,     //開始分 
                    SKYSHH: "",               //終了時  
                    SKYSMM:"",               //終了分
                    SKYTFBNM:this.KoTNKBS,     //納棺場所
                    SKYTP1NM:"メーク",         //名称1
                    SKYTP1DT:this.KoTNKMK,     //値1
                    SKYTP1TN:"式",             //単位1
                    SKYTP2NM:"",               //名称2
                    SKYTP2DT:"",               //値2
                    SKYTP2TN:"",               //単位2
                    SKYTP3NM:"",               //名称3
                    SKYTP3DT:"",               //値3
                    SKYTP3TN:"",               //単位3
                    SKYTNY:""                //備考
                });
            }
            if (this.KoTTYSJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"通夜",           //施工名称
                    SKYTDT:this.KoTTYSDT,    //施行日
                    SKYTHH:this.KoTTYSJI,    //施行時  
                    SKYTMM:this.KoTTYSFN,    //施行分 
                    SKYSHH:this.KoTTYEJI,    //終了時  
                    SKYSMM:this.KoTTYEFN,    //終了分
                    SKYTFBNM:this.KoTTYSBS,    //通夜場所
                    SKYTP1NM:"司会",           //名称1
                    SKYTP1DT:this.KoTTYSSK,    //値1
                    SKYTP1TN:"",               //単位1
                    SKYTP2NM:"アシ",           //名称2
                    SKYTP2DT:this.KoTTYSAS,    //値2
                    SKYTP2TN:"",               //単位2
                    SKYTP3NM:"",               //名称3
                    SKYTP3DT:"",               //値3
                    SKYTP3TN:"",               //単位3
                    SKYTNY:""                //備考
                });
            }
            if (this.KoTTYSJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"通夜移動",           //施工名称
                    SKYTDT:this.KoTISU1DT,       //施行日
                    SKYTHH:this.KoTISU1JI,       //施行時  
                 //   SKYTMM:this.KoTISU1FN,       //施行分 
                    SKYSHH:"",                   //終了時  
                 //   SKYSMM:"",                   //終了分
                    SKYTFBNM:"",                   //通夜場所
                    SKYTP1NM:"バス",               //名称1
                    SKYTP1DT:this.KoTISU1BS,       //値1
                    SKYTP1TN:"台",                 //単位1
                    SKYTP2NM:"ＴＸ",               //名称2
                    SKYTP2DT:this.KoTISU1HY,       //値2
                    SKYTP2TN:"台",                 //単位2
                    SKYTP3NM:"",                   //名称3
                    SKYTP3DT:"",                   //値3
                    SKYTP3TN:"",                   //単位3
                    SKYTNY:""                      //備考
                });
            }
            if (this.KoTHRJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"会館",                       //施工名称
                    SKYTDT:"",                           //施行日
                    SKYTFBNM:this.KoTKKNM,    //会館場所
                    SKYTNY:this.KoTHRNM       //会館備考
               });
            }
            if (this.KoTSSOGJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"告別",                   //施工名称
                    SKYTDT:this.KoTSSOGDT,           //施行日
                    SKYTHH:this.KoTSSOGSJI,          //施行時  
                    SKYTMM:this.KoTSSOGSFN,          //施行分
                    SKYSHH:this.KoTSSOGEJI,          //終了時  
                    SKYSMM:this.KoTSSOGEFN,          //終了分
                    SKYTFBNM:this.KoTSSOSBS,          //納棺場所
                    SKYTP1NM:"司会",                  //名称1
                    SKYTP1DT:this.KoTSSOSSK,          //値1
                    SKYTP1TN:"",                      //単位1
                    SKYTP2NM:"アシ",                  //名称2
                    SKYTP2DT:this.KoTSSOGAS,          //値2
                    SKYTP2TN:"",                      //単位2
                    SKYTP3NM:"",                      //名称3
                    SKYTP3DT:"",                      //値3
                    SKYTP3TN:"",                      //単位3
                    SKYTNY:""                         //備考
               });
            }
            this.SKYOTrn.push({  // 追加する項目の内容を設定する
                SKYTNM:"火葬",                       //施工名称
                SKYTDT:this.KoTSJDT,                 //施行日
                SKYTHH:this.KoTSJJI,                 //施行時  
                SKYTMM:this.KoTSJFN,                 //施行分
                SKYSHH:this.KoTSJEJI,                //終了時  
                SKYSMM:this.KoTSJEFN,                //終了分
                SKYTFBNM:this.KoTSJNM,               //火葬場所
                SKYTP1NM:"同行",                     //名称1
                SKYTP1DT:this.KoTSJDS,               //値1
                SKYTP1TN:"",                         //単位1
                SKYTP2NM:"控室",                     //名称2
                SKYTP2DT:this.KoTSJHS,               //値2
                SKYTP2TN:"",                         //単位2
                SKYTP3NM:"",                         //名称3
                SKYTP3DT:"",                         //値3
                SKYTP3TN:"",                         //単位3
                SKYTNY:""                            //備考
            });
            if (this.KoTISU2JK == "〇")    //火葬移動
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"移動(出棺)",              //施工名称
                    SKYTDT:this.KoTISU2DT,           //施行日
                    SKYTHH:this.KoTISU2JI,           //施行時  
                    SKYTMM:this.KoTISU2JI,           //施行分
                    SKYSHH:"",                       //終了時  
                    SKYSMM:"",                       //終了分
                    SKYTFBNM:"",                     //火葬場所
                    SKYTP1NM:"霊柩",                 //名称1
                    SKYTP1DT:this.KoRICJK,           //値1
                    SKYTP1TN:"",                     //単位1
                    SKYTP2NM:"バス",                 //名称2
                    SKYTP2DT:this.KoTISU2BS,         //値2
                    SKYTP2TN:"台",                   //単位2
                    SKYTP3NM:"ＴＸ",                 //名称3
                    SKYTP3DT:this.KoTISU2HY,         //値3
                    SKYTP3TN:"台",                   //単位3
                    SKYTNY:""                        //備考
               });
            }
        },
  
  
  //----------------------------------------
  //出棺移動場
  //----------------------------------------
    KoTISU2FR_JITK(){
      this.KoTISU2FR = "自宅";
     },
    KoTISU2FR_KAIA(){
      this.KoTISU2FR = "会館";
     },
    KoTISU2FR_SAIJ(){
      this.KoTISU2FR = "斎場";
     },
  //----------------------------------------
  //漢字ふりがな変換
  //----------------------------------------
    handleNameInput() {
        this.furigana = autokana.getFurigana();
    },
   //----------------------------------------
  //更新処理
  //----------------------------------------
   async addKoMstdata() {
      let ERRCHK = "ERROR";
      if(this.KoTUKDT == ""){
        this.message  = "受付日が設定されていません設定してください",
        this.dialogERROR = true;
      }else{
        if(this.KoTMOKE == ""){
          this.message  = "喪家が入力されていません入力してください",
          this.dialogERROR = true;
        }else{
          if(this.KoTTNM == ""){
            this.message  = "故人名が入力されていません入力してください",
            this.dialogERROR = true;
          }else{
            if(this.KoTJJK == ""){
              this.message  = "受注進捗状況が設定されていません設定してください",
              this.dialogERROR = true;
            }else{
              ERRCHK = "OK";
            }
          }
        }
      }
      
      if(ERRCHK == "OK"){
       let WKoTSSOGDT  = "";
      if(this.KoTSSOGDT  !=""){
        WKoTSSOGDT  = moment(this.KoTSSOGDT).format('YYYY/MM/DD');   //葬儀日
      }
      let WKoTURDT    = "";
      if(this.KoTURDT  !=""){
        WKoTURDT    = moment(this.KoTURDT).format('YYYY/MM/DD');     //売上日
      }
      let WKoTUKDT    = "";
      if(this.KoTUKDT  !=""){
        WKoTUKDT    = moment(this.KoTUKDT).format('YYYY/MM/DD');     //受付日
      }
      let WKoTSHYODT  = "";
      if(this.KoTSHYODT  !=""){
        WKoTSHYODT  = moment(this.KoTSHYODT).format('YYYY/MM/DD');   //支払予定日
       }
      let WKoTSJDT = ""; 
      if(this.KoTSJDT  !=""){
          WKoTSJDT = moment(this.KoTSJDT).format('YYYY/MM/DD');   //火葬日
       }
      
      let WKoTIRDT = "";
      if(this.KoTIRDT  !=""){
        WKoTIRDT = moment(this.KoTIRDT).format('YYYY/MM/DD');   //打合日
      }
      let WKoTNKDT = "";
      if(this.KoTNKDT  !=""){
        WKoTNKDT = moment(this.KoTNKDT).format('YYYY/MM/DD');   //納棺日
      }
      let WKoTISU1DT = "";
      let WKoTISU2DT = "";
      if(this.KoTISU1DT  !=""){
        WKoTISU1DT = moment(this.KoTISU1DT).format('YYYY/MM/DD');   //移動1日
      }
      if(this.KoTISU2DT  !=""){
        WKoTISU2DT = moment(this.KoTISU2DT).format('YYYY/MM/DD');   //移動2日
      }
      let WKoTDRY1DT = "";
      let WKoTDRY2DT = "";
      let WKoTDRY3DT = "";
      if(this.KoTDRY1DT  !=""){
        WKoTDRY1DT = moment(this.KoTDRY1DT).format('YYYY/MM/DD');   //ドライ1
      }
      if(this.KoTDRY2DT  !=""){
        WKoTDRY2DT = moment(this.KoTDRY2DT).format('YYYY/MM/DD');   //ドライ2
      }
       if(this.KoTDRY3DT  !=""){
        WKoTDRY3DT = moment(this.KoTDRY3DT).format('YYYY/MM/DD');   //ドライ3
      }

      let dateKYOU = moment(new Date).format('YYYY/MM/DD h:mm:ss');
      var KoTUKJIw = "";      //受付時
      var KoTUKFNw = "";      //受付分
      //受付時
      if(this.KoTUKJI != ""){
        var KoTUKJI_r = this.KoTUKJI.split(':');
        KoTUKJIw  = KoTUKJI_r[0];
        KoTUKFNw  = KoTUKJI_r[1];
      }

      var KoTDESJIw = "";     //死亡時
      var KoTDESFNw = "";     //死亡分
      //死亡時
      if(this.KoTDESJI != ""){
        var KoTDESJI_r = this.KoTDESJI.split(':');
        KoTDESJIw  = KoTDESJI_r[0];
        KoTDESFNw  = KoTDESJI_r[1];
      }

      var KoTTYSJIw = "";           //通夜時
      var KoTTYSFNw = "";           //通夜分
      var KoTTYEJIw = "";           //通夜終了時
      var KoTTYEFNw = "";           //通夜終了分
      //通夜時
      if(this.KoTTYSJI != ""){
        var KoTTYSJI_r = this.KoTTYSJI.split(':');
        KoTTYSJIw  = KoTTYSJI_r[0];
        KoTTYSFNw  = KoTTYSJI_r[1];
      }
      //通夜終了時
      if(this.KoTTYEJI != ""){
        var KoTTYEJI_r = this.KoTTYEJI.split(':');
        KoTTYEJIw  = KoTTYEJI_r[0];
        KoTTYEFNw  = KoTTYEJI_r[1];
      }

      var KoTSSOGSJIw = "";     //葬儀時
      var KoTSSOGSFNw = "";     //葬儀分
      //葬儀時
      if(this.KoTSSOGSJI != ""){
        var KoTSSOGSJI_r = this.KoTSSOGSJI.split(':');
        KoTSSOGSJIw  = KoTSSOGSJI_r[0];
        KoTSSOGSFNw  = KoTSSOGSJI_r[1];
      }

      var KoTSSOGEJIw = "";     //出棺時
      var KoTSSOGEFNw = "";     //出棺分
      //出棺時
      if(this.KoTSSOGEJI != ""){
        var KoTSSOGEJI_r = this.KoTSSOGEJI.split(':');
        KoTSSOGEJIw  = KoTSSOGEJI_r[0];
        KoTSSOGEFNw  = KoTSSOGEJI_r[1];
      }

      var KoTSJJIw = "";     //火葬時
      var KoTSJFNw = "";     //火葬分
      //火葬時
      if(this.KoTSJJI != ""){
        var KoTSJJI_r = this.KoTSJJI.split(':');
        KoTSJJIw  = KoTSJJI_r[0];
        KoTSJFNw  = KoTSJJI_r[1];
      }

      var KoTSJEJIw = "";     //火葬終了時
      var KoTSJEFNw = "";     //火葬終了分
      //火葬終了時
      if(this.KoTSJEJI != ""){
        var KoTSJEJI_r = this.KoTSJEJI.split(':');
        KoTSJEJIw  = KoTSJEJI_r[0];
        KoTSJEFNw  = KoTSJEJI_r[1];
      }

      var KoTIRJIw = "";     //打合時
      var KoTIRFNw = "";     //打合分
      //打合時
      if(this.KoTIRJI != ""){
        var KoTIRJI_r = this.KoTIRJI.split(':');
        KoTIRJIw  = KoTIRJI_r[0];
        KoTIRFNw  = KoTIRJI_r[1];
      }

      var KoTNKJIw = "";     //納棺時
      var KoTNKFNw = "";     //納棺分
      //納棺時
      if(this.KoTNKJI != ""){
        var KoTNKJI_r = this.KoTNKJI.split(':');
        KoTNKJIw  = KoTNKJI_r[0];
        KoTNKFNw  = KoTNKJI_r[1];
      }

      var KoTISU1JIw = "";     //移動1時
      var KoTISU1FNw = "";     //移動1分
      //移動1時
      if(this.KoTISU1JI != ""){
        var KoTISU1JI_r = this.KoTISU1JI.split(':');
        KoTISU1JIw  = KoTISU1JI_r[0];
        KoTISU1FNw  = KoTISU1JI_r[1];
      }

      var KoTISU2JIw = "";     //移動2時
      var KoTISU2FNw = "";     //移動2分
      //移動2時
      if(this.KoTISU2JI != ""){
        var KoTISU2JI_r = this.KoTISU2JI.split(':');
        KoTISU2JIw  = KoTISU2JI_r[0];
        KoTISU2FNw  = KoTISU2JI_r[1];
      }

      this.KoTTTELN   = this.KoTTTEL.replace('(', '');            //故人電話番号CTI
      this.KoTTTELN   = this.KoTTTEL.replace(')', '');            //故人電話番号CTI
      this.KoTTTELN   = this.KoTTTELN.replace('_', '');           //故人電話番号CTI
      this.KoTMTELN   = this.KoTMTEL.replace('(', '');            //祭事喪主電話番号CTI
      this.KoTMTELN   = this.KoTMTEL.replace(')', '');            //祭事喪主電話番号CTI
      this.KoTMTELN   = this.KoTMTELN.replace('_', '');           //祭事喪主電話番号CTI
      this.KoTMTELKN  = this.KoTMTELK.replace('(', '');           //祭事喪主携帯CTI
      this.KoTMTELKN  = this.KoTMTELK.replace(')', '');           //祭事喪主携帯CTI
      this.KoTMTELKN  = this.KoTMTELKN.replace('_', '');          //祭事喪主携帯CTI
      this.KoTSTELN   = this.KoTSTEL.replace('(', '');            //祭事お支払責任者電話番号CTI
      this.KoTSTELN   = this.KoTSTEL.replace(')', '');            //祭事お支払責任者電話番号CTI
      this.KoTSTELN   = this.KoTSTELN.replace('_', '');           //祭事お支払責任者電話番号CTI
      this.KoTSTELKN  = this.KoTSTELK.replace('(', '');           //祭事お支払責任者携帯CTI
      this.KoTSTELKN  = this.KoTSTELK.replace(')', '');           //祭事お支払責任者携帯CTI
      this.KoTSTELKN  = this.KoTSTELKN.replace('_', '');          //祭事お支払責任者携帯CTI
      this.KoTIRTLN   = this.KoTIRTL.replace('(', '');            //祭事依頼人CTI
      this.KoTIRTLN   = this.KoTIRTL.replace(')', '');            //祭事依頼人CTI
      this.KoTIRTLN   = this.KoTIRTLN.replace('_', '');           //祭事依頼人CTI

      var YY2 = "";
      var MM2 = "";
      var DD2 = "";
      YY2 = this.KoTTBSYY;  //西暦年
      MM2 = this.KoTTBSMM;  //西暦月
      DD2 = this.KoTTBSDD;  //西暦日

      if(YY2.length == 1){YY2 = "0"+ YY2;}
      if(MM2.length == 1){MM2 = "0"+ MM2;}
      if(DD2.length == 1){DD2 = "0"+ DD2;}
      
      this.KoTTBSDTJ  = this.KoTTNG_KNJ + YY2+ "年" + MM2 + "月" + DD2 + "日";  //和暦誕生日

      var NENYYYY  = 0;

      if(this.KoTTNG_KNJ == "明治"){NENYYYY = 1867;} //和暦西暦開始年
      if(this.KoTTNG_KNJ == "大正"){NENYYYY = 1911;} //和暦西暦開始年
      if(this.KoTTNG_KNJ == "昭和"){NENYYYY = 1925;} //和暦西暦開始年
      if(this.KoTTNG_KNJ == "平成"){NENYYYY = 1988;} //和暦西暦開始年
      if(this.KoTTNG_KNJ == "令和"){NENYYYY = 2018;} //和暦西暦開始年
     
      if(this.KoTTBSYY != ""){NENYYYY = NENYYYY + this.KoTTBSYY;}   //西暦年変換
      this.KoTTBSDT   = NENYYYY + "/" + MM2 + "/" + DD2;            //西暦誕生日


      const fomego = { 
        KoTNO:      this.KoTNO,               //受付番号
        KoTURDT:    WKoTURDT,                 //売上日
        KoTUKDT:    WKoTUKDT,                 //受付日
        KoTUKJI:    KoTUKJIw,                 //受付時
        KoTUKFN:    KoTUKFNw,                 //受付分
        KoTUKBCD:   this.KoTUKBCD,            //受付部署コード
        KoTUKBNM:   this.KoTUKBNM,            //受付部署名
        KoTUKTCD:   this.KoTUKTCD,            //受付担当者コード
        KoTUKTNM:   this.KoTUKTNM,            //受付担当者名
        KoTUKTNMS:  this.KoTUKTNMS,           //受付担当者名略称
        KoTBLOKCD:  this.KoTBLOKCD,           //部署名称
        KoTBLOKNM:  this.KoTBLOKNM,           //部署コード
        KoETNTNCD:   this.KoETNTNCD,            //営業担当コード
        KoETNTNNM:  this.KoETNTNNM,           //営業担当名称
        KoETNTNNMS: this.KoETNTNNMS,          //主担当略称
        KoTKANO:    this.KoTKANO,             //会員番号
        KoTKANM:    this.KoTKANM,             //会員名称
        KoTMOKE:    this.KoTMOKE,             //喪家
        KoThCD:     this.KoThCD,              //顧客取引先コード
        KoThNM:     this.KoThNM,              //顧客取引先名称
        KoTCD:      this.KoTCD,               //顧客提携区分コード
        KoTNM:      this.KoTNM,               //顧客提携区分名称
        KoTTKNM:    this.KoTTKNM,             //故人(カナ）
        KoTTNM:     this.KoTTNM,              //故人名前
        KoTTBSDT:   this.KoTTBSDT,            //西暦誕生日
        KoTTBSDTJ:  this.KoTTBSDTJ,           //和暦誕生日
        KoTTEG:     this.KoTTEG,              //故人年齢
        KoTTSEX:    this.KoTTSEX,             //故人性別
        KoTTNEX:    this.KoTTNEX,             //故人続柄
        KoTTYU:     this.KoTTYU,              //故人郵便番号
        KoTTJYU1:   this.KoTTJYU1,            //故人住所1
        KoTTJYU2:   this.KoTTJYU2,            //故人住所2
        KoTTJYU3:   this.KoTTJYU3,            //故人住所3
        KoTTTEL:    this.KoTTTEL,             //故人電話番号
        KoTMKNM:    this.KoTMKNM,             //喪主(カナ）
        KoTMNM:     this.KoTMNM,              //喪主名前
        KoTMNEX:    this.KoTMNEX,             //喪主続柄
        KoTMSEX:    this.KoTMSEX,             //喪主性別
        KoTMYU:     this.KoTMYU,              //喪主郵便番号
        KoTMJYU1:   this.KoTMJYU1,            //喪主住所1
        KoTMJYU2:   this.KoTMJYU2,            //喪主住所2
        KoTMJYU3:   this.KoTMJYU3,            //喪主住所3
        KoTMTEL:    this.KoTMTEL,             //喪主電話番号
        KoTMTELK:   this.KoTMTELK,            //喪主携帯
        KoTMTMail:  this.KoTMTMail,           //喪主l
        KoTMBIKO:   this.KoTMBIKO,            //喪主備考
        KoTSNM:     this.KoTSNM,              //お支払責任者名前
        KoTSKNM:    this.KoTSKNM,             //お支払カナ
        KoTSHYODT:  WKoTSHYODT,               //お支払予定日
        KoTSPNM:    this.KoTSPNM,             //入金方法名
        KoTSYU:     this.KoTSYU,              //支払責任者郵便番号
        KoTSJYU1:   this.KoTSJYU1,            //お支払責任者住所1
        KoTSJYU2:   this.KoTSJYU2,            //お支払責任者住所2
        KoTSJYU3:   this.KoTSJYU3,            //お支払責任者住所3
        KoTSTEL:    this.KoTSTEL,             //お支払責任者電話番号
        KoTSTELK:   this.KoTSTELK,            //お支払責任者携帯
        KoTSMail:   this.KoTSMail,            //お支払責任者l
        KoTSBIKO:   this.KoTSBIKO,            //お支払責任者備考
        KoTSSTRNM:  this.KoTSSTRNM,           //寺院名称
        KoTSSSONM:  this.KoTSSSONM,           //宗旨名称
        KoTSSKSA1:  this.KoTSSKSA1,           //会葬挨拶1
        KoTSSKMNM:  this.KoTSSKMNM,           //家紋・紋章名称
        KoTSSBIKO:  this.KoTSSBIKO,           //引継備考
        KoTUUKBDT:  this.KoTUUKBDT,           //受付簿印刷日
        KoTUUKBTM:  this.KoTUUKBTM,           //受付簿印時間
        KoTUMSKSDT: this.KoTUMSKSDT,          //請求書印刷日
        KoTUMSKSTM: this.KoTUMSKSTM,          //請求書印刷時間
        KoTUTKSSDT: this.KoTUTKSSDT,          //立替請求書印刷日
        KoTUTKSSTM: this.KoTUTKSSTM,          //立替請求書印刷時間
        KoTPLCD:    this.KoTPLCD,             //プランコード
        KoTPLNM:    this.KoTPLNM,             //プラン名称
        KoTPLGK:    this.KoTPLGK,             //プラン金額
        KoTKKCD:    this.KoTKKCD,             //会館
        KoTKKNM:    this.KoTKKNM,             //会館名
        KoTHRNM:    this.KoTHRNM,             //ホール
        KoTHRCD:    this.KoTHRCD,             //ホール名
        KoTHRJK:    this.KoTHRJK,             //ホール名
        KoTHRNMS:   this.KoTHRNMS,            //会館ホール状況
        KoTSEYNM:   this.KoTSEYNM,            //施行予定名
        KoTDESDT:   this.KoTDESDT,            //死亡日
        KoTDESDTJ:  this.KoTDESDTJ,           //死亡日和暦
        KoTDESBS:   this.KoTDESBS,            //死亡場所
        KoTDESJI:   KoTDESJIw,                //死亡時
        KoTDESFN:   KoTDESFNw,                //死亡分 
        KoTTYSDT:   this.KoTTYSDT,            //通夜日
        KoTTYSJI:   KoTTYSJIw,                //通夜時
        KoTTYSFN:   KoTTYSFNw,                //通夜分
        KoTTYEJI:   KoTTYEJIw,                //通夜終了時
        KoTTYEFN:   KoTTYEFNw,                //通夜終了分
        KoTTYSBS:   this.KoTTYSBS,            //通夜場所
        KoTTYSJK:   this.KoTTYSJK,            //通夜状況
        KoTTYSSK:   this.KoTTYSSK,            //通夜司会
        KoTTYSAS:   this.KoTTYSAS,            //通夜アシスタント
        KoTSSOGDT:  WKoTSSOGDT,               //葬儀日
        KoTSSOGSJI: KoTSSOGSJIw,              //葬儀時 
        KoTSSOGSFN: KoTSSOGSFNw,              //葬儀分
        KoTSSOGEJI: KoTSSOGEJIw,              //出棺時
        KoTSSOGEFN: KoTSSOGEFNw,              //出棺分
        KoTSSOSBS:  this.KoTSSOSBS,           //葬儀場所
        KoTSSOSSK:  this.KoTSSOSSK,           //葬儀司会
        KoTSSOGJK:  this.KoTSSOGJK,           //葬儀状況
        KoTSSOGAS:  this.KoTSSOGAS,           //葬儀アシスタント
        KoTSJDT:    WKoTSJDT,            //火葬日
        KoTSJCD:    this.KoTSJCD,             //斎場コード
        KoTSJNM:    this.KoTSJNM,             //斎場名称
        KoTSJNMS:   this.KoTSJNMS,            //斎場略称
        KoTSJJI:    KoTSJJIw,                 //火葬時
        KoTSJFN:    KoTSJFNw,                 //火葬分
        KoTSJEJI:   KoTSJEJIw,                //火葬終了時
        KoTSJEFN:   KoTSJEFNw,                //火葬終了分
        KoTSJDS:    this.KoTSJDS,             //導師
        KoTSJD7:    this.KoTSJD7,             //初七日
        KoTHZJK:    this.KoTHZJK,             //本膳状況
        KoTSJHS:    this.KoTSJHS,             //控室
        KoTYOYK:    this.KoTYOYK,             //斎場予約
        KoTURSMST:  this.KoTURSMST,           //祭事標準税売上(税抜)
        KoTURSMHK:  this.KoTURSMHK,           //祭事軽減税売上(税抜)
        KoTSNEBST:  this.KoTSNEBST,           //祭事非課税売上(税抜)
        KoTSNEBKG:  this.KoTSNEBKG,           //祭事標準税値引
        KoTSNEBHK:  this.KoTSNEBHK,           //祭事軽減税値引
        KoTSKAZST:  this.KoTSKAZST,           //祭事非課税値引
        KoTSKAZKG:  this.KoTSKAZKG,           //祭事標準税対象額計
        KoTSKAZHK:  this.KoTSKAZHK,           //祭事軽減税対象額計
        KoTSSYOST:  this.KoTSSYOST,           //祭事非課税対象額計
        KoTSSYOKG:  this.KoTSSYOKG,           //祭事標準税消費税
        KoTSURKGK:  this.KoTSURKGK,           //祭事軽減税消費税
        KoTURTMST:  this.KoTURTMST,           //祭事売上(税込み)
        KoTURTMKG:  this.KoTURTMKG,           //立替標準税売上(税抜)
        KoTURTMHK:  this.KoTURTMHK,           //立替軽減税売上(税抜)
        KoTTNEBST:  this.KoTTNEBST,           //立替非課税売上(税抜)
        KoTTNEBKG:  this.KoTTNEBKG,           //立替標準税値引
        KoTTNEBHK:  this.KoTTNEBHK,           //立替軽減税値引
        KoTTKAZST:  this.KoTTKAZST,           //立替非課税値引
        KoTTKAZKG:  this.KoTTKAZKG,           //立替標準税対象額計
        KoTTKAZHK:  this.KoTTKAZHK,           //立替軽減税対象額計
        KoTURSMKG:  this.KoTURSMKG,           //立替非課税対象額計
        KoTTSYOST:  this.KoTTSYOST,           //立替標準税消費税
        KoTTSYOKG:  this.KoTTSYOKG,           //立替軽減税消費税
        KoTTURKGK:  this.KoTTURKGK,           //立替売上(税込み)
        KoTSGK:     this.KoTSGK,              //売掛金総額
        KoTSHNKG:   this.KoTSHNKG,            //祭事入金金額
        KoTSHZNG:   this.KoTSHZNG,            //売掛残高
        KoSGICNM:   this.KoSGICNM,            //葬儀委員長名
        KoSGICKN:   this.KoSGICKN,            //葬儀委員長かな名
        KoSGICZK:   this.KoSGICZK,            //葬儀委員長属性
        KoDMKB:     this.KoDMKB,              //区分
        KoHJGK:     this.KoHJGK,              //標準原価計
        KoARRI:     this.KoARRI,              //粗利益計
        KoTJJK:     this.KoTJJK,              //受注進捗状況
        KoTIRNM:    this.KoTIRNM,             //依頼人
        KoTIRZK:    this.KoTIRZK,             //依頼人続柄
        KoTIRTL:    this.KoTIRTL,             //依頼人電話番号
        KoTIRTO:    this.KoTIRTO,             //搬送先()
        KoTIRDT:    WKoTIRDT,                 //打合日時
        KoTIRJI:    KoTIRJIw,                 //打合時
        KoTIRFN:    KoTIRFNw,                 //打合分
        KoTIRBK:    this.KoTIRBK,             //その他･備考
        KoTIRKM:    this.KoTIRKM,             //搬送・打合せ・その他（）
        KoTIRSH:    this.KoTIRSH,             //使用品目
        KoTRDCD:    this.KoTRDCD,             //リードコード
        KoTRDNM:    this.KoTRDNM,             //リード名
        KoTRDNMS:   this.KoTRDNMS,            //リード名
        KoTRDTN:    this.KoTRDTN,             //リード担当名
        KoTRDRV:    this.KoTRDRV,             //リードレベル
        KoSKICD:    this.KoSKICD,             //司会者コード
        KoSKINM:    this.KoSKINM,             //司会者名
        KoSKINMS:   this.KoSKINMS,            //司会者略称
        KoSVTNCD:   this.KoSVTNCD,            //サブ担当コード
        KoSVNTNM:   this.KoSVNTNM,            //サブ担当名
        KoSVNTNMS:  this.KoSVNTNMS,           //サブ担当略称
        KoKRTNCD:   this.KoKRTNCD,            //経理担当コード
        KoKRNTNM:   this.KoKRNTNM,            //経理担当名
        KoTNKDT:    WKoTNKDT,                 //納棺日
        KoTNKJI:    KoTNKJIw,                 //納棺時
        KoTNKFN:    KoTNKFNw,                 //納棺分
        KoTNKMK:    this.KoTNKMK,             //納棺メーク
        KoTNKJK:    this.KoTNKJK,             //納棺状況
        KoTNKBS:    this.KoTNKBS,             //納棺場所
        KoTISU1DT:  WKoTISU1DT,               //移動1日
        KoTISU1JI:  KoTISU1JIw,               //移動1時
        KoTISU1FN:  KoTISU1FNw,               //移動1分
        KoTISU1FR:  this.KoTISU1FR,           //移動1移動元
        KoTISU1BS:  this.KoTISU1BS,           //バス台数
        KoTISU1HY:  this.KoTISU1HY,           //ハイヤー台数
        KoTISU1JK:  this.KoTISU1JK,           //移動1状況
        KoTISU2DT:  WKoTISU2DT,               //移動2日
        KoTISU2JI:  KoTISU2JIw,               //移動2時
        KoTISU2FN:  KoTISU2FNw,               //移動2分
        KoTISU2FR:  this.KoTISU2FR,           //移動2移動元
        KoTISU2BS:  this.KoTISU2BS,           //バス台数
        KoTISU2HY:  this.KoTISU2HY,           //ハイヤー台数
        KoTISU2JK:  this.KoTISU2JK,           //移動2状況
        KoTDRY1DT:  WKoTDRY1DT,               //ドライ1
        KoTDRY2DT:  WKoTDRY2DT,               //ドライ2
        KoTDRY3DT:  WKoTDRY3DT,               //ドライ3
        KoTDRYJK:   this.KoTDRYJK,            //ドライ状況
        KoREIJK:    this.KoREIJK,             //礼状状況
        KoREIBK:    this.KoREIBK,             //礼状備考
        KoHENJK:    this.KoHENJK,             //返礼品状況
        KoRIHINM:   this.KoRIHINM,            //返礼品品目
        KoRICJK:    this.KoRICJK,             //霊柩車状況
        KoRICTK:    this.KoRICTK,             //霊柩車立替
        KoTSSTRJK:  this.KoTSSTRJK,           //寺院状況
        KoTSSTRBS:  this.KoTSSTRBS,           //菩提寺
        KoTSSTRHM:  this.KoTSSTRHM,           //戒(法)名
        KoTSSTROF:  this.KoTSSTROF,           //Fお布施
        KoTKUDAMJ:  this.KoTKUDAMJ,           //果物状況
        KoTHZBS:    this.KoTHZBS,             //本膳場所
        KoTHZNY:    this.KoTHZNY,             //枕飯・枕団子、供養膳
        KoTAZKPHJK: this.KoTAZKPHJK,          //写真
        KoTAZKPB:   this.KoTAZKPB,            //写真備考
        KoTAZKKKJK: this.KoTAZKKKJK,          //許可書
        KoTAZKKB:   this.KoTAZKKB,            //許可備考
        KoTHCDT:    this.KoTHCDT,             //発注日
        KoTHCJI:    this.KoTHCJI,             //発注時
        KoTHCFN:    this.KoTHCFN,             //発注分
        KoTMGKT:    this.KoTMGKT,             //担当者確定
        KoTBIKO1:   this.KoTBIKO1,            //備考1
        KoTBIKO2:   this.KoTBIKO2,            //備考2
        KoTBIKO3:   this.KoTBIKO3,            //備考3
        KoTTTELN:   this.KoTTTELN,            //故人電話番号CTI
        KoTMTELN:   this.KoTMTELN,            //祭事喪主電話番号CTI
        KoTMTELKN:  this.KoTMTELKN,           //祭事喪主携帯CTI
        KoTSTELN:   this.KoTSTELN,            //祭事お支払責任者電話番号CTI
        KoTSTELKN:  this.KoTSTELKN,           //祭事お支払責任者携帯CTI
        KoTIRTLN:   this.KoTIRTLN,            //祭事依頼人CTI
        KoTHCENDF:  this.KoTHCENDF,           //発注完了
        InstDt:     dateKYOU,                 //作成日時
        InstId:     this.InstId,              //作成者
        UpdtDt:     dateKYOU,                 //更新日時
        UpdtId:     this.UpdtId               //更新者
        };

        axios.post('/api/KoTrnUAD',fomego)
         .then(res => {
          })
          .catch(error => {
            console.log(error)
          })

    }
    },



   },
  components: {
    appDialog:      BRMSDialog,       //分類マスタ
    appDialogJK:    BRJKDialog,       //分類マスタ(状況)
    appDialogKZ:    BRKJZDialog,      //分類マスタ(故人続柄)
    appDialogMZ:    BRMSZDialog,      //分類マスタ(喪主続柄)
    appDialogNK:    BRNKDialog,       //分類マスタ(入金)
    appDialogSALL:  SYAINALLDialog,   //社員マスタ(全社員)
    appDialogSALLs: SYAINALLsDialog,  //社員マスタ（経理）
    appDialogSEIG:  SYAINEIGDialog,   //社員マスタ(営業)
    appDialogSEIGs: SYAINEIGsDialog,  //社員マスタ(営業 2)
    appDialogSSKI:  SYAINSKIDialog,   //社員マスタ(司会)
    appDialogJN:    SMJNNDialog,      //取引先マスタ(寺院)
    appDialogLD:    LeadDialog,       //取引先マスタ(リード)
    appDialogKKHR:  KKHRDialog,       //会館ホール
    appDialogKA:    KaiinSHDialog,    //会員マスタ
    appDialogKKHRSJ:KKHRSJDialog,     //斎場検索
    appDialogPLAND: PLANDialog,       //プラン設定
    //appDialogSAIJYO:SAIJYODialog,     //斎場ＨＰ
  //  appDialogYS:  SYSDialog,        //施行予定マスタ
      appDialogIN:  INSTDialog,       //施行印刷マスタ
      appDialogKM:  KMDialog,         //供物入力
  //    appDialogTS:  TESdialog,          //TEST帳票
  }
}
</script>
<style scoped>
.form-contents .form-row {

  padding-top: 2px;
  padding-bottom: 2px;
}
.form-contents .form-row .col-form-label {
  line-height: 2;
}
.striped-form-row .form-row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.form-control {
    border: 1px solid;  /* 枠線 */
    border-radius: 0.3em;   /* 角丸 */
    padding: 0.1em;          /* 内側の余白量 */
    background-color: rgb(255, 255, 255);  /* 背景色 */
    font-size: 1.1em;          /* 文字サイズ */
    line-height: 1;        /* 行の高さ */
}
.input-name {
    background-color: #ffffff;
    margin-top: 5px;
    width: 0px;
    
}

</style>