<template>
 <v-dialog v-model="dialogKKHRSJ">
   <v-app id="inspire">
  <v-card width="100%">
    <v-toolbar flat color="grey lighten-2" height="30">
      <v-toolbar-title>斎場選択(popKaikanSJ)</v-toolbar-title>
    </v-toolbar> 
        <v-row>
          <v-subheader></v-subheader>
          <v-col cols="6"  md="6" sm="6" xsmd="6">
            <div >
              <v-btn x-large color="success" dark v-on:click="agreeok" block><v-icon left>mdi-gavel</v-icon>斎場選択確定</v-btn>
            </div>
          </v-col>
          <v-col cols="4"  md="4" sm="4" xsmd="4">
            <v-btn  fab dark small color="pink" v-on:click="close">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-alert></v-alert>
          <v-alert dense><strong>斎場</strong></v-alert>
          <v-alert dense>{{ HrKKCD }}</v-alert>
          <v-alert dense>{{HrKKNM }}</v-alert>
          <v-alert dense><strong>ホール</strong></v-alert>
          <v-alert dense>{{ HrHRCD }}</v-alert>
          <v-alert dense>{{ HrHRNM }}</v-alert>
          <v-alert></v-alert>
        </v-row>
    
     <v-simple-table :fixed-header="true" class="KAIIN_DSP"  :dense="true" height="300" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">会館コード</th>
            <th class="text-left">会館名</th>
            <th class="text-left">ホールコード</th>
            <th class="text-left">ホール名称</th>
            <th class="text-left">部署コード</th>
            <th class="text-left">部署名称</th>
           </tr>
        </thead>
        <tbody>
          <tr v-for="(KkHr,index) in KkHr" :key="index" @click="showAlert2(KkHr)">
            <td>{{ KkHr.HrKKCD }}</td>	
            <td>{{ KkHr.HrKKNM }}</td>
            <td>{{ KkHr.HrHRCD }}</td>
            <td>{{ KkHr.HrHRNM }}</td>
            <td>{{ KkHr.HrBLCKCD }}</td>	
            <td>{{ KkHr.HrBLCKNM }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
  </v-app>
</v-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: "KoMst_DSP",
  data() {
    return {
      dialogKKHRSJ:false,
      KkHr: [],
      HrKKCD:    "",  //会館コード
      HrKKNM:    "",  //会館名
      HrHRCD:    "",  //ホールコード
      HrHRNM:    "",  //ホール名称
      HrBLCKCD:  "",  //部署コード
      HrBLCKNM:  "",  //部署名称
      HrBLIMG1:  "",  //会館ホールイメージ1
      HrBLIMG2:  "",  //会館ホールイメージ2
      HrBLIMG3:  "",  //会館ホールイメージ3
      HrBLIMG4:  "",  //会館ホールイメージ4
      HrBLIMG5:  "",  //会館ホールイメージ5
      HrHTML:    "",  //会館ホールＨＴＭＬ
     }
  },
  methods: {
      async KKHRquery() {
        axios.post('/api/KkHrSJGET')
        .then(response => {
          this.KkHr = response.data;
          console.log(this.KkHr);
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogKKHRSJ = true;
    },
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------  
    open() {
     this.KKHRquery();
    },
    close() {
      this.$emit('result', {'res': false, 'HrKKCD':'--','HrKKNM':'--'})
      this.dialogKKHRSJ = false;
    },
    agreeok() {
      this.$emit('result', {'res': true,'HrKKCD':this.HrKKCD,'HrKKNM':this.HrKKNM,'HrHRCD':this.HrHRCD,'HrHRNM':this.HrHRNM})
      this.dialogKKHRSJ = false;
     },
     showAlert2: function(item) {
      this.HrKKCD     = item.HrKKCD;     //会館コード
      this.HrKKNM     = item.HrKKNM;     //会館名
      this.HrHRCD     = item.HrHRCD;     //ホールコード
      this.HrHRNM     = item.HrHRNM;     //ホール名称
      this.HrBLCKCD   = item.HrBLCKCD;   //部署コード
      this.HrBLCKNM   = item.HrBLCKNM;   //部署名称
      this.HrBLIMG1   = item.HrBLIMG1;   //会館ホールイメージ1
      this.HrBLIMG2   = item.HrBLIMG2;   //会館ホールイメージ2
      this.HrBLIMG3   = item.HrBLIMG3;   //会館ホールイメージ3
      this.HrBLIMG4   = item.HrBLIMG4;   //会館ホールイメージ4
      this.HrBLIMG5   = item.HrBLIMG5;   //会館ホールイメージ5
      this.HrHTML     = item.HrHTML;     //会館ホールＨＴＭＬ
     },
  },
 }
</script>