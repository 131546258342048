<template>
 <v-dialog v-model="dialogHNM">
   <v-app id="inspire">
    <v-card width="100%">
        <v-row>
            <v-col cols="1" md="1"><v-alert>popHin</v-alert></v-col>
            <v-col cols="6" md="6"><div class="my-2"><v-btn x-large color="success"  v-on:click="this.dialogHNMGET" dark block><v-icon left>mdi-gavel</v-icon>確定処理</v-btn></div></v-col>
            <v-col cols="3" md="3"><div class="my-2">
                <v-btn x-large color="primary"  v-on:click="SMHINMDialogshow" dark block><v-icon left>mdi-plus-box-outline</v-icon>選択処理 </v-btn></div>
                <app-dialogSMHINM :text="text" ref="dialogSMHINM" @result="responseSMHINM"></app-dialogSMHINM>
             </v-col>        
            <v-col cols="2" md="2"><v-btn  fab dark small color="pink" v-on:click="dialogHNMclose"><v-icon dark>mdi-minus</v-icon></v-btn></v-col>
        </v-row>
        <v-row>
            <v-alert><strong>品目</strong>{{ MsHICD }}:{{ MsHINM }}</v-alert>
           <v-alert><strong>単位</strong>{{ MsTAN }}</v-alert>
           <v-alert><strong>提携先</strong>{{ WRBRITU }}</v-alert>
        </v-row>
        <v-row>
            <v-col cols="9" md="9"><v-img  max-height="700" max-width="700" v-bind:src="IMGSRC"></v-img></v-col>
            <v-col cols="3" md="3">
              <v-btn   v-on:click="IMGshow1" v-if="HINMIMG1show">IMG1</v-btn>
              <v-btn   v-on:click="IMGshow2" v-if="HINMIMG2show">IMG2</v-btn>
              <v-btn   v-on:click="IMGshow3" v-if="HINMIMG3show">IMG3</v-btn>
              <v-btn   v-on:click="IMGshow4" v-if="HINMIMG4show">IMG4</v-btn>
              <v-btn   v-on:click="IMGshow5" v-if="HINMIMG5show">IMG5</v-btn>
            </v-col>
        </v-row>    
        <v-row>
          <v-col cols="1" lg="1" md="1" sm="1" xsmd="1"/>
          <v-col cols="1" lg="1" md="1" sm="1" xsmd="12"><v-text-field type="number" v-mask="'#'" v-model="MsSETFG" clearable label="S" @change="MsSETFGChanges"/></v-col>
          <v-col cols="3" lg="3" md="3" sm="3" xsmd="12"><v-text-field type="number" v-mask="'########'" v-model="MsUNT" clearable label="単価(税抜)"  @change="KEISAN"/></v-col>
          <v-col cols="3" lg="3" md="3" sm="3" xsmd="12"><v-text-field type="number" v-mask="'###'"  v-model="MsSuu" clearable label="数"  @change="KEISAN"/></v-col>
          <v-col cols="3" lg="3" md="3" sm="3" xsmd="12"><v-alert><strong>税抜</strong>{{ MsKin }}</v-alert></v-col>
        </v-row>
        <v-row>
          <v-col  cols="1" lg="1" md="1" sm="1" xsmd="1"/>
          <v-col  cols="4" lg="4" md="4" sm="4" xsmd="12"><v-text-field type="number" v-mask="'#######'" v-model="MsNebiki" clearable label="値引"  @change="KEISAN"/></v-col>
          <v-col  cols="4" lg="4" md="4" sm="4" xsmd="6"><v-alert><strong>値引後:</strong>{{ MsGKing }}</v-alert></v-col>
          <v-col  cols="3" lg="3" md="3" sm="3" xsmd="6"><v-alert><strong>消費税区分:</strong>{{ MsSYOKB }}</v-alert></v-col>
        </v-row>
        <v-row>
          <v-alert></v-alert>
          <v-alert><strong>消費税</strong></v-alert>
          <v-alert>{{ MsUCSYOG }}</v-alert>
          <v-alert></v-alert>
          <v-alert><strong>税込</strong></v-alert>
          <v-alert>{{ MsZKGK }}</v-alert>
          <v-alert></v-alert>
          <v-btn color="primary" v-on:click="SHZBHJ">標</v-btn>
          <v-alert></v-alert>
          <v-btn color="primary" v-on:click="SHZBKG">軽</v-btn>
          <v-alert></v-alert>
          <v-btn color="primary" v-on:click="SHZBHK">非</v-btn>
          <v-alert></v-alert>
        </v-row>
        <v-row>
          <v-alert></v-alert>
          <v-text-field v-model="MsBIKO" outlined clearable label="備考"/>
          <v-alert></v-alert>
        </v-row>
   </v-card>
  </v-app>
  </v-dialog>
</template>
<script>
//    Vue.use(VueMask.VueMaskPlugin);
</script>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
import axiosJsonpAdapter from 'axios-jsonp'
//import { listSkyos }    from "../graphql/queries";
//import { getSmwrb }     from "../graphql/queries";      //割引マスタ取得
//import { getSmhinm }    from "../graphql/queries";     //品目マスタ取得
import moment           from "moment";
import SMHINMDialog     from "../views/popSMHINM.vue";      //品目マスタ選択
export default { 
  data () {
     // テンプレート内で保有するデータ  
      return {
        SKYOS: [],
        SKYOTrn: [],
        //祭事明細
        MsHIBJYUN:'',          //品目大分類並び順
        MsHIBCD:'',            //品目大分類コード
        MsHIBNM:'',            //品目大分類名称
        MsSYBRJN:'',           //品目分類順
        MsSYBRCD:'',           //品目分類コード
        MsSYBRNM:'',           //品目分類名称
        MsHIJYUN:'',           //品目並び順
        MsTAKE:'',             //立替商品
        MsJSYA:'',             //発注商品
        MsHND:'',              //変動商品
        MsHICD:'',             //品目コード
        MsHINM:'',             //品目名称
        MsTAN:'',              //単位名称
        MsSETFG:'',            //セットフラグ
        MsUNT:'',              //販売単価
        MsSYOKB:'',            //売上消費税区分
        MsSIT:'',              //仕入単価
        MsSuu:'',              //売上数量
        MsKin:'',              //売上金額(税抜)
        MsNebiki:'',           //売上値引(税抜)
        MsGKing:'',            //売上額値引後(税抜)
        MsUCSYOG:'',           //売上消費税額
        MsZKGK:'',             //売上税込額(税込)
        MsBIKO:'',             //備考
        MsIMG1:'',             //イメージ1
        MsIMG2:'',             //イメージ2
        MsIMG3:'',             //イメージ3
        MsIMG4:'',             //イメージ4
        MsIMG5:'',             //イメージ5

        HINMIMG1:'',           //品目イメージ1
        HINMIMG2:'',           //品目イメージ2
        HINMIMG3:'',           //品目イメージ3
        HINMIMG4:'',           //品目イメージ4
        HINMIMG5:'',           //品目イメージ5

        dialogHNM:false,
        DELBOshow: false,
        date: new Date().toISOString().substr(0, 10),
        menuSKYTDT:false,
        modal11:false,
        modal12:false,
        modal13:false,
        modal14:false,
        text: "",
        //初期設定
        KgyCd:"",     //初期設定企業コード
        KgyNM:"",     //初期設定企業名称
        BLCKCD:"",    //初期設定部署コード
        BLCKNM:"",    //初期設定部署名称
        SynCd:"",     //初期設定社員名称
        SynNm:"",     //初期設定社員名称
        TANID:"",     //初期設定端末ID
        DENNO:"0",    //初期設定伝票番号
        GNZHJ:"",     //初期設定現在標準税
        GNZKG:"",     //初期設定現在軽減税
        KGZDT:"",     //初期設定消費税切替日
        KGZHJ:"",     //初期設定次期標準税
        KGZKG:"",     //初期設定次期軽減税
        KoTURDT:"",   //売上日
        KoThCD:"",    //提携コード
        KoThNM:"",    //提携名称
        KoTCD:"",     //提携取引先コード
        KoTNM:"",     //提携取引先名称
        ksGNZHJ:"",   //計算標準税
        ksGNZKG:"",   //計算軽減税

        WRBRITU:"",   //割引率
        //ボタン表示
        IMGSRC:"",
        HINMIMG1show:false,
        HINMIMG2show:false,
        HINMIMG3show:false,
        HINMIMG4show:false,
        HINMIMG5show:false,
     }
    },
   methods: {
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------
    open() {     
    this.dialogHNM = true;
    
    if(localStorage.SOGI_KgyCd)     this.KgyCd      = localStorage.SOGI_KgyCd;      //初期設定企業コード
    if(localStorage.SOGI_KgyNM)     this.KgyNM      = localStorage.SOGI_KgyNM;      //初期設定企業名称
    if(localStorage.SOGI_BLCKCD)    this.BLCKCD     = localStorage.SOGI_BLCKCD;     //初期設定部署コード
    if(localStorage.SOGI_BLCKNM)    this.BLCKNM     = localStorage.SOGI_BLCKNM;     //初期設定部署名称
    if(localStorage.SOGI_SynCd)     this.SynCd      = localStorage.SOGI_SynCd;      //初期設定社員名称
    if(localStorage.SOGI_SynNm)     this.SynNm      = localStorage.SOGI_SynNm;      //初期設定社員名称
    if(localStorage.SOGI_TANID)     this.TANID      = localStorage.SOGI_TANID;      //初期設定端末ID
    if(localStorage.SOGI_DENNO)     this.DENNO      = localStorage.SOGI_SGDENNO;    //初期設定伝票番号
    if(localStorage.SOGI_GNZHJ)     this.GNZHJ      = localStorage.SOGI_GNZHJ;      //初期設定現在標準税
    if(localStorage.SOGI_GNZKG)     this.GNZKG      = localStorage.SOGI_GNZKG;      //初期設定現在軽減税
    if(localStorage.SOGI_KGZDT)     this.KGZDT      = localStorage.SOGI_KGZDT;      //初期設定消費税切替日
    if(localStorage.SOGI_KGZHJ)     this.KGZHJ      = localStorage.SOGI_KGZHJ;      //初期設定次期標準税
    if(localStorage.SOGI_KGZKG)     this.KGZKG      = localStorage.SOGI_KGZKG;      //初期設定次期軽減税
    if(localStorage.SOGI_KoTSSOGDT) this.KoTSSOGDT  = localStorage.SOGI_KoTSSOGDT;  //葬儀日
    if(localStorage.SOGI_SGDENNO)   this.PS_KoTNO   = localStorage.SOGI_SGDENNO;    //伝票番号
    if(localStorage.SOGI_KoTURDT)   this.KoTURDT    = localStorage.SOGI_KoTURDT;    //売上日
    if(localStorage.SOGI_KoThCD)    this.KoThCD     = localStorage.SOGI_KoThCD;     //提携コード
    if(localStorage.SOGI_KoThNM)    this.KoThNM     = localStorage.SOGI_KoThNM;     //提携名称
    if(localStorage.SOGI_KoTCD)     this.KoTCD      = localStorage.SOGI_KoTCD;      //提携取引先コード
    if(localStorage.SOGI_KoTNM)     this.KoTNM      = localStorage.SOGI_KoTNM;      //提携取引先名称
     
    if( localStorage.SOGI_MsHICD == ""){
      this.MsHIBJYUN  = "";         //品目大分類並び順
      this.MsHIBCD    = "";         //品目大分類コード
      this.MsHIBNM    = "";         //品目大分類名称
      this.MsSYBRJN   = "";         //品目分類順
      this.MsSYBRCD   = "";         //品目分類コード
      this.MsSYBRNM   = "";         //品目分類名称
      this.MsHIJYUN   = "";         //品目並び順
      this.MsTAKE     = "";         //立替商品
      this.MsJSYA     = "";         //発注商品
      this.MsHND      = "";         //変動商品
      this.MsHICD     = "";         //品目コード
      this.MsHINM     = "";         //品目名称
      this.MsTAN      = "";         //単位名称
      this.MsSETFG    = "";         //セットフラグ
      this.MsUNT      = "";         //販売単価
      this.MsSYOKB    = "";         //売上消費税区分
      this.MsSIT      = "";         //仕入単価
      this.MsSuu      = "";         //売上数量
      this.MsKin      = "";         //売上金額(税抜)
      this.MsNebiki   = "";         //売上値引(税抜)
      this.MsGKing    = "";         //売上額値引後(税抜)
      this.MsUCSYOG   = "";         //売上消費税額
      this.MsZKGK     = "";         //売上税込額(税込)
      this.MsBIKO     = "";         //備考
      this.MsIMG1     = "";         //イメージ1
      this.MsIMG2     = "";         //イメージ2
      this.MsIMG3     = "";         //イメージ3
      this.MsIMG4     = "";         //イメージ4
      this.MsIMG5     = "";         //イメージ5
      this.IMGSRC     = "";         //表示イメージ
    }else{
        if(localStorage.SOGI_MsHIBJYUN)  this.MsHIBJYUN  = localStorage.SOGI_MsHIBJYUN;         //品目大分類並び順
        if(localStorage.SOGI_MsHIBCD)    this.MsHIBCD    = localStorage.SOGI_MsHIBCD;           //品目大分類コード
        if(localStorage.SOGI_MsHIBNM)    this.MsHIBNM    = localStorage.SOGI_MsHIBNM;           //品目大分類名称
        if(localStorage.SOGI_MsSYBRJN)   this.MsSYBRJN   = localStorage.SOGI_MsSYBRJN;          //品目分類順
        if(localStorage.SOGI_MsSYBRCD)   this.MsSYBRCD   = localStorage.SOGI_MsSYBRCD;          //品目分類コード
        if(localStorage.SOGI_MsSYBRNM)   this.MsSYBRNM   = localStorage.SOGI_MsSYBRNM;          //品目分類名称
        if(localStorage.SOGI_MsHIJYUN)   this.MsHIJYUN   = localStorage.SOGI_MsHIJYUN;          //品目並び順
        if(localStorage.SOGI_MsTAKE)     this.MsTAKE     = localStorage.SOGI_MsTAKE;            //立替商品
        if(localStorage.SOGI_MsJSYA)     this.MsJSYA     = localStorage.SOGI_MsJSYA;            //発注商品
        if(localStorage.SOGI_MsHND)      this.MsHND      = localStorage.SOGI_MsHND;             //変動商品
        if(localStorage.SOGI_MsHICD)     this.MsHICD     = localStorage.SOGI_MsHICD;            //品目コード
        if(localStorage.SOGI_MsHINM)     this.MsHINM     = localStorage.SOGI_MsHINM;            //品目名称
        if(localStorage.SOGI_MsTAN)      this.MsTAN      = localStorage.SOGI_MsTAN;             //単位名称
        if(localStorage.SOGI_MsSETFG)    this.MsSETFG    = localStorage.SOGI_MsSETFG;           //セットフラグ
        if(localStorage.SOGI_MsUNT)      this.MsUNT      = localStorage.SOGI_MsUNT;             //販売単価
        if(localStorage.SOGI_MsSYOKB)    this.MsSYOKB    = localStorage.SOGI_MsSYOKB;           //売上消費税区分
        if(localStorage.SOGI_MsSIT)      this.MsSIT      = localStorage.SOGI_MsSIT;             //仕入単価
        if(localStorage.SOGI_MsSuu)      this.MsSuu      = localStorage.SOGI_MsSuu;             //売上数量
        if(localStorage.SOGI_MsKin)      this.MsKin      = localStorage.SOGI_MsKin;             //売上金額(税抜)
        if(localStorage.SOGI_MsNebiki)   this.MsNebiki   = localStorage.SOGI_MsNebiki;          //売上値引(税抜)
        if(localStorage.SOGI_MsGKing)    this.MsGKing    = localStorage.SOGI_MsGKing;           //売上額値引後(税抜)
        if(localStorage.SOGI_MsUCSYOG)   this.MsUCSYOG   = localStorage.SOGI_MsUCSYOG;          //売上消費税額
        if(localStorage.SOGI_MsZKGK)     this.MsZKGK     = localStorage.SOGI_MsZKGK;            //売上税込額(税込)
        if(localStorage.SOGI_MsBIKO)     this.MsBIKO     = localStorage.SOGI_MsBIKO;            //備考
        if(localStorage.SOGI_MsIMG1)     this.MsIMG1     = localStorage.SOGI_MsIMG1;            //イメージ1
        if(localStorage.SOGI_MsIMG2)     this.MsIMG2     = localStorage.SOGI_MsIMG2;            //イメージ2
        if(localStorage.SOGI_MsIMG3)     this.MsIMG3     = localStorage.SOGI_MsIMG3;            //イメージ3
        if(localStorage.SOGI_MsIMG4)     this.MsIMG4     = localStorage.SOGI_MsIMG4;            //イメージ4
        if(localStorage.SOGI_MsIMG5)     this.MsIMG5     = localStorage.SOGI_MsIMG5;            //イメージ5

        this.IMGSRC = this.MsIMG1;        //表示イメージ
        //品目マスタイメージ取得
        this.HinimgGET();
        this.KEISAN();
      }
      //売上日＞＝初期設定消費税切替日
      if(this.KoTURDT >= this.KGZDT){
        this.ksGNZHJ    =   this.KGZHJ;   //計算標準税
        this.ksGNZKG    =   this.KGZKG;   //計算軽減税
      }else{
        this.ksGNZHJ    =   this.GNZHJ;   //計算標準税
        this.ksGNZKG    =   this.GNZKG;   //計算軽減税
       }
    },
   //----------------------------------------
   //品目イメージ取得
   //----------------------------------------
   async HinimgGET(){
        //品目マスタ イメージ取得
        let SMHINMQuery    = `query GetSmhinm {getSMHINM(HINMOCD:"`+this.MsHICD+`", KgyCd: "`+this.KgyCd+`") {HINDIBRJN HINDIBRCD HINDIBRNM HINSYBRJN HINMIMG1 HINMIMG2 HINMIMG3 HINMIMG4 HINMIMG5}}`;
        let SMHINM_GT      =   await API.graphql(graphqlOperation(SMHINMQuery));
        this.MsIMG1        =   SMHINM_GT.data.getSMHINM.HINMIMG1; //品目イメージ1
        this.HINMIMG1      =   SMHINM_GT.data.getSMHINM.HINMIMG1; //品目イメージ1
        this.MsIMG2        =   SMHINM_GT.data.getSMHINM.HINMIMG2; //品目イメージ2
        this.HINMIMG2      =   SMHINM_GT.data.getSMHINM.HINMIMG2; //品目イメージ2
        this.MsIMG3        =   SMHINM_GT.data.getSMHINM.HINMIMG3; //品目イメージ3
        this.HINMIMG3      =   SMHINM_GT.data.getSMHINM.HINMIMG3; //品目イメージ3
        this.MsIMG4        =   SMHINM_GT.data.getSMHINM.HINMIMG4; //品目イメージ4
        this.HINMIMG4      =   SMHINM_GT.data.getSMHINM.HINMIMG4; //品目イメージ4
        this.MsIMG5        =   SMHINM_GT.data.getSMHINM.HINMIMG5; //品目イメージ5
        this.HINMIMG5      =   SMHINM_GT.data.getSMHINM.HINMIMG5; //品目イメージ5

        this.IMGSRC = this.MsIMG1;

    },
    //----------------------------------------
    //品目取得キャンセル
    //----------------------------------------
    dialogHNMclose() {
      this.$emit('result', {'res': false, 'MsHICD':'--','MsHINM':'--'})
      this.dialogHNM = false;
    },
    //----------------------------------------
    //品目取得確定
    //----------------------------------------
    dialogHNMGET() {
       this.$emit('result', {'res': true,
        'MsNO':this.MsNO,           //受付番号
        'KoTURDT':this.KoTURDT,     //売上日
        'MsHIBJYUN':this.MsHIBJYUN, //品目大分類並び順
        'MsHIBCD':this.MsHIBCD,     //品目大分類コード
        'MsHIBNM':this.MsHIBNM,     //品目大分類名称
        'MsSYBRJN':this.MsSYBRJN,   //品目分類順
        'MsSYBRCD':this.MsSYBRCD,   //品目分類コード
        'MsSYBRNM':this.MsSYBRNM,   //品目分類名称
        'MsHIJYUN':this.MsHIJYUN,   //品目並び順
        'MsTAKE':this.MsTAKE,       //立替商品
        'MsJSYA':this.MsJSYA,       //発注商品
        'MsHND':this.MsHND,         //変動商品
        'MsHICD':this.MsHICD,       //品目コード
        'MsHINM':this.MsHINM,       //品目名称
        'MsTAN':this.MsTAN,         //単位名称
        'MsSETFG':this.MsSETFG,     //セットフラグ
        'MsUNT':this.MsUNT,         //販売単価
        'MsSYOKB':this.MsSYOKB,     //売上消費税区分
        'MsSIT':this.MsSIT,         //仕入単価
        'MsSuu':this.MsSuu,         //売上数量
        'MsKin':this.MsKin,         //売上金額(税抜)
        'MsNebiki':this.MsNebiki,   //売上値引(税抜)
        'MsGKing':this.MsGKing,     //売上額値引後(税抜)
        'MsUCSYOG':this.MsUCSYOG,   //売上消費税額
        'MsZKGK':this.MsZKGK,       //売上税込額(税込)
        'MsBIKO':this.MsBIKO,       //備考
        'MsIMG1':this.HINMIMG1,     //イメージ1
        'MsIMG2':this.HINMIMG2,     //イメージ2
        'MsIMG3':this.HINMIMG3,     //イメージ3
        'MsIMG4':this.HINMIMG4,     //イメージ4
        'MsIMG5':this.HINMIMG5      //イメージ5
       })
      this.dialogHNM = false;
    },
    //----------------------------------------
    //品目マスタ表示 popSMHINM
    //----------------------------------------
    SMHINMDialogshow() {
      this.$refs.dialogSMHINM.open();
    },
    //品目取得
    responseSMHINM(obj) {
      if(obj.HINMOCD != "--"){  //キャンセル時は何もしない
        this.MsHIBJYUN = obj.HINDIBRJN;     //品目大分類並び順
        this.MsHIBCD   = obj.HINDIBRCD;     //品目大分類コード
        this.MsHIBNM   = obj.HINDIBRNM;     //品目大分類名称
        this.MsSYBRJN  = obj.HINSYBRJN;     //品目分類順
        this.MsSYBRCD  = obj.HINSYBRCD;     //品目分類コード
        this.MsSYBRNM  = obj.HINSYBRNM;     //品目分類名称
        this.MsHIJYUN  = "0";               //品目並び順
        this.MsTAKE    = obj.HINMOUK3;      //立替商品
        this.MsJSYA    = "";                //発注商品
        this.MsHND     = obj.HINMHEND;      //変動商品
        this.MsHICD    = obj.HINMOCD;       //品目コード
        this.MsHINM    = obj.HINMONM;       //品目名称
        this.MsTAN     = obj.HINMOTNNM;     //単位名称
        this.MsSETFG   = "";                //セットフラグ
        this.MsUNT     = obj.HINMOTK;       //販売単価
        this.MsSYOKB   = obj.HINMOSHKG;     //売上消費税区分
        this.MsSIT     = obj.HINSITK;       //仕入単価
        this.MsSuu     = "1";               //売上数量
        this.MsKin     = obj.HINMOTK;       //売上金額(税抜)
        this.MsNebiki  = "0";               //売上値引(税抜)
        this.MsGKing   = obj.HINMOTK;       //売上額値引後(税抜)
        this.MsUCSYOG  = "0";               //売上消費税額
        this.MsZKGK    = "0";               //売上税込額(税込)
        this.MsBIKO    = "";                //備考
        this.MsIMG1    = obj.HINMIMG1;      //イメージ1
        this.MsIMG2    = obj.HINMIMG2;      //イメージ2
        this.MsIMG3    = obj.HINMIMG3;      //イメージ3
        this.MsIMG4    = obj.HINMIMG4;      //イメージ4
        this.MsIMG5    = obj.HINMIMG5;      //イメージ5
        this.HINMIMG1  = obj.HINMIMG1;      //品目イメージ1
        this.HINMIMG2  = obj.HINMIMG2;      //品目イメージ2
        this.HINMIMG3  = obj.HINMIMG3;      //品目イメージ3
        this.HINMIMG4  = obj.HINMIMG4;      //品目イメージ4
        this.HINMIMG5  = obj.HINMIMG5;      //品目イメージ5
        
         if(this.HINMIMG1 == "" || this.HINMIMG1 == null){
         }else{
            this.IMGSRC    = this.HINMIMG1;
            this.HINMIMG1show = true;
        }
        if(this.HINMIMG2 == "" || this.HINMIMG2 == null){
        }else{
            this.HINMIMG2show = true;
        }
        if(this.HINMIMG3 == "" || this.HINMIMG3 == null){
        }else{
            this.HINMIMG3show = true;
        }
        if(this.HINMIMG4 == "" || this.HINMIMG4 == null){
        }else{
            this.HINMIMG4show = true;
        }
        if(this.HINMIMG5 == "" || this.HINMIMG5 == null){
        }else{
            this.HINMIMG5show = true;
        }
      }
      this.dialogSMHINM = false;
      if(this.KoThCD == "" || this.KoThCD == null || this.MsSYBRCD == "" || this.MsSYBRCD == null){    //提携コード
      }else{
        this.WARIBGET();
      }
      this.KEISAN();
    },
    //----------------------------------------
    //標準税ボタン
    //----------------------------------------
    SHZBHJ() {
        this.MsSYOKB    = "標準税";
         this.KEISAN();
    },
    //----------------------------------------
    //軽減税ボタン
    //----------------------------------------
    SHZBKG() {
        this.MsSYOKB    = "軽減税";
        this.KEISAN();
    },
    //----------------------------------------
    //非課税ボタン
    //----------------------------------------
    SHZBHK() {
        this.MsSYOKB    = "非課税";
        this.KEISAN();
    },
    //----------------------------------------
    //イメージ1表示ボタン
    //----------------------------------------
    IMGshow1() {
        this.IMGSRC    = this.HINMIMG1;
    },
    //----------------------------------------
    //イメージ2表示ボタン
    //----------------------------------------
    IMGshow2() {
        this.IMGSRC    = this.HINMIMG2;
    },
    //----------------------------------------
    //イメージ3表示ボタン
    //----------------------------------------
    IMGshow3() {
        this.IMGSRC    = this.HINMIMG2;
    },
    //----------------------------------------
    //イメージ4表示ボタン
    //----------------------------------------
    IMGshow4() {
        this.IMGSRC    = this.HINMIMG3;
    },
    //----------------------------------------
    //イメージ5表示ボタン
    //----------------------------------------
    IMGshow5() {
        this.IMGSRC    = this.HINMIMG4;
    },
    //----------------------------------------
    //セットフラグ設定
    //----------------------------------------
    MsSETFGChanges() {
        if(this.MsSETFG == "1"){
            this.MsBIKO = "【プラン構成品】";
        }else{
            this.MsBIKO = "";
        }
    },
   //----------------------------------------
   //売上額計算
   //----------------------------------------
   KEISAN() {
       if(this.MsUNT    == "" || this.MsUNT     == null){this.MsUNT     = 0}    //単価(税抜)
       if(this.MsSuu    == "" || this.MsSuu     == null){this.MsSuu     = 0}    //数量
       if(this.MsNebiki == "" || this.MsNebiki  == null){this.MsNebiki  = 0}    //値引額
       if(this.WRBRITU  == "" || this.WRBRITU   == null){this.WRBRITU   = 0}    //割引率
       this.MsKin       =   this.MsUNT  *   this.MsSuu;                         //税抜金額
       this.MsNebiki    =   parseInt(this.MsKin * this.WRBRITU    /100);        //値引金額計算
       this.MsGKing     =   this.MsKin  -   this.MsNebiki;                      //値引後額

       if(this.MsSYOKB  ==  "非課税"){
            this.MsUCSYOG   =   "0";
       }else{
            if(this.MsSYOKB ==  "標準税"){
               this.MsUCSYOG   =    parseInt(this.MsGKing * this.ksGNZHJ    /100);  //計算標準税
            }else{
               this.MsUCSYOG   =    parseInt(this.MsGKing * this.ksGNZKG    /100);  //計算軽減税
            }
        }
        this.MsZKGK =   this.MsGKing +    this.MsUCSYOG;                            //税込金額
   },
   //----------------------------------------
   //割引率取得
   //----------------------------------------
   async WARIBGET(){
     //割引率取得
      let SMWRBQuery    =  `query GetSmwrb{getSMWRB(KgyCd:"`+this.KgyCd+`",DSPKEYA:"`+this.KoTCD+`/`+this.MsSYBRCD+`"){DSPKEYA KgyCd WRBRITU}}`;
      let SMWRB_GT      =  await API.graphql(graphqlOperation(SMWRBQuery));
      this.WRBRITU      =  SMWRB_GT.data.getSMWRB.WRBRITU; //割引率
      if(this.WRBRITU == null || this.WRBRITU == ""){
        this.WRBRITU = 0;
      }
      this.KEISAN();
    },
  },
  components: {
        appDialogSMHINM: SMHINMDialog, //品目選択
  }
}
</script>