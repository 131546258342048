<template>
 <v-row justify="center">
 <v-dialog v-model="dialogJN" width="500">
  <v-card>
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>寺院選択(SMJNN)</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjJNNCD" :counter="10" label="寺院コード"  required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjJNNNM" :counter="10" label="寺院名称" required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjJNNSHCD" :counter="10" label="宗派コード" required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
       <v-text-field v-model="hjJNNSHNM" :counter="10" label="宗派名称" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" md="10">
        <div >
          <v-btn x-large color="success" dark v-on:click="agreeok"><v-icon left>mdi-pencil</v-icon>寺院選択確定</v-btn>
        </div>
      </v-col>
       <v-col cols="2" md="2">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table :fixed-header="true" class="SMJNN_DSP"  :dense="true" height="500">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">寺院コード</th>
            <th class="text-left">寺院名称</th>
            <th class="text-left">宗派コード</th>
            <th class="text-left">宗派名称</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(SMJNN,index) in SMJNN" :key="index" @click="showAlert2(SMJNN)">
            <td>{{ SMJNN.TRHCD }}</td>	
            <td>{{ SMJNN.TRHNM}}</td>
            <td>{{ SMJNN.TRHSHCD }}</td>
            <td>{{ SMJNN.TRHSHNM }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</v-dialog>
</v-row>
</template>
<script>
import axios from 'axios';
export default {
  name: "SMJNN_DSP",
  data() {
    return {
      dialogJN:false,
      SMJNN: [],
      hjJNNCD:      "",  //寺院コード  選択
      hjJNNNM:      "",  //寺院名称    選択
      hjJNNSHCD:    "",  //宗派コード  選択
      hjJNNSHNM:    "",  //宗派名称    選択
      PL_KIGYOUID:  ""   //グローバル企業コード
    }
  },
  //寺院マスタ取得
  methods: {
    async SMJNNquery() {
       const fomego = { BRMSDCD: this.PL_BRMSID};
        axios.post('/api/JININGET',fomego)
        .then(response => {
          this.SMJNN = response.data
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogJN = true;
    },
    //オープン時
    open() {
      this.SMJNNquery();     //寺院マスタ取得
    },
    close() {
      this.$emit('result', {'res': false, 'JNNCD':'--','JNNNM':'--','JNNSHCD':'--','JNNSHNM':'--'})
      this.dialogJN = false;
    },
    agreeok() {
      this.$emit('result', {'res': true, 'JNNCD':this.hjJNNCD,'JNNNM':this.hjJNNNM,'JNNSHCD':this.hjJNNSHCD,'JNNSHNM':this.hjJNNSHNM})
      this.dialogJN = false;
     },
    showAlert2: function(item) {
      this.hjJNNCD      = item.TRHCD;     //寺院コード
      this.hjJNNNM      = item.TRHNM;     //寺院名
      this.hjJNNSHCD    = item.TRHSHCD;   //宗派コード
      this.hjJNNSHNM    = item.TRHSHNM;   //宗派名
    },
  } 
 }
</script>