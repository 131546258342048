<template >
<div>
  <v-app>
     <div justify="center">
       <v-row>
           <v-spacer> </v-spacer>
       </v-row>
       <v-row>
        <v-spacer> </v-spacer>
        <v-menu ref="menu" v-model="STARTmenu"  :return-value.sync="date">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense v-model="STKoTSSOGDT" label="開始日"  v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="STKoTSSOGDT"  locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
        </v-menu>
        <v-spacer> </v-spacer>
        <v-menu ref="menu" v-model="ENDmenu"  :return-value.sync="date"  persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense  v-model="ETKoTSSOGDT" label="終了日" v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="ETKoTSSOGDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
        </v-menu>
        <v-spacer> </v-spacer>
        <v-btn @click="KoTrnGetsubmit">検索</v-btn>
        <v-spacer> </v-spacer>
        <v-btn @click="KIDialogshow" >祭事新規作成</v-btn>
        <appDialogSS  ref="dialogSS" @result="responseSS"></appDialogSS>
        <v-spacer> </v-spacer>
         </v-row>
       <v-row>
        <appDialogUP  ref="dialogUP" @result="responseUP"></appDialogUP>
       </v-row>
    </div>
    <v-simple-table> 
      <table>
      <thead>
        <tr>
          <th rowspan="4"><b>行</b></th>
          <th>伝票№</th>
          <th>流入元</th>
          <th>葬家</th>
          <th>故人</th>
          <th>式場</th>
          <th>通夜</th>
          <th>告別</th>
          <th>火葬</th>
          <th>納棺</th>
          <th>移動</th>
          <th>移動</th>
          <th>写真</th>
          <th>ドライ</th>
          <th>礼状</th>
          <th>霊柩</th>
          <th>寺院</th>
          <th>備考</th>
        </tr>
        <tr>
          <th>売上日</th>
          <th>担当者</th>
          <th>主担当</th>
          <th>喪主</th>
           <th>ＨＲ</th>
           <th>開始</th>
           <th>開始</th>
           <th>開始</th>
           <th>開始</th>
           <th>FROM</th>
           <th>FROM</th>
           <th>許可</th>
           <th></th>
           <th>返礼</th>
           <th>バス</th>
           <th>果物</th>
           <th></th>
        </tr>
        <tr>
          <th>受注日</th>
          <th> </th>
          <th>司会者</th>
          <th>支払</th>
          <th>プラン</th>
          <th>終了</th>
          <th>終了</th>
          <th>火葬場</th>
          <th>施術</th>
          <th>TO</th>
           <th>TO</th>
          <th>備考</th>
          <th></th>
          <th></th>
          <th>TX</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="item in desserts" :key="item.name">
        <tr>
           <td  bgcolor="#ebf6f7" rowspan="4">
              <v-btn  class="ma-2" fab @click="onButtonClick(item)"><v-icon>mdi-pencil</v-icon></v-btn>
           </td>
        </tr>
        <tr >
          <td  v-bind:style="{ backgroundColor:'#ebf6f7'}">{{ item.KoTNO }}</td>                                <!--1.1 伝票№ -->
          <td  bgcolor="#ebf6f7">{{ item.KoTRDNMS}} </td>                                                       <!--1.2 流入元 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTMOKE }}</td>                                                        <!--1.3 葬家 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTTNM }}</td>                                                         <!--1.4 故人 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTKKNM }}</td>                                                        <!--1.5 式場 -->
          <td  v-bind:style="{backgroundColor: item.KoTTYSDTBK}">{{ item.KoTTYSJK }}{{ item.KoTTYSDT }}</td>    <!--1.6 通夜 -->
          <td  v-bind:style="{backgroundColor: item.KoTSSOGDTBK}">{{ item.KoTSSOGDT }}</td>                     <!--1.7 告別 -->
          <td  bgcolor="#ebf6f7">{{item.KoTSJDT}}</td>                                                          <!--1.8 火葬 -->
          <td  v-bind:style="{backgroundColor: item.KoTNOKDTBK}">{{ item.KoTNKDT }}</td>                        <!--1.9 納棺 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTISU1DT }}</td>                                                      <!--1.10 移動 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTISU2DT }}</td>                                                      <!--1.11 移動 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTAZKPHJK }}</td>                                                     <!--1.12 写真 -->
          <td  v-bind:style="{backgroundColor: item.KoTDR1BK}" bgcolor="#ebf6f7">{{ item.KoTDRY1DT }}</td>      <!--1.13 ドライ -->
          <td  bgcolor="#ebf6f7">{{ item.KoREIJK }}</td>                                                        <!--1.14 礼状 -->
          <td  bgcolor="#ebf6f7">{{ item.KoRICJK }}</td>                                                        <!--1.15 霊柩 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTSSTRNM }}</td>                                                      <!--1.16 寺院 -->
          <td  bgcolor="#ebf6f7">{{ item.KoTBIKO1 }}</td>                                                       <!--1.17 備考 -->
       </tr>
       <tr >
          <td>{{ item.KoTURDT }}</td>                                                                       <!--2.1 売上日 -->
          <td rowspan="2">{{ item.KoTRDTN }}</td>                                                           <!--2.2 担当者 -->
          <td>{{ item.KoETNTNNM }}</td>                                                                     <!--2.3 担当者 -->
          <td>{{ item.KoTMNM }}</td>                                                                        <!--2.4 ホール -->
          <td>{{ item.KoTHRNM }}</td>                                                                       <!--2.5 喪主 -->
          <td>{{ item.KoTTYSJI }}:{{ item.KoTTYSFN }}</td>                                                  <!--2.6 通夜開始 -->
          <td>{{ item.KoTSSOGSJI }}:{{ item.KoTSSOGSFN }}</td>                                              <!--2.7 告別開始 -->
          <td>{{ item.KoTSJJI }}:{{ item.KoTSJFN }}</td>                                                    <!--2.8 火葬時 -->
          <td>{{ item.H_KoTNKTM }}</td>                                                    <!--2.9 納棺開始 -->
          <td>{{ item.KoTISU1FR }}</td>                                                                     <!--2.10 移動FROM -->
          <td>{{ item.KoTISU2FR }}</td>                                                                     <!--2.11 移動FROM -->
          <td>{{ item.KoTAZKKKJK }}</td>                                                                    <!--2.12 許可証 -->
          <td v-bind:style="{backgroundColor: item.KoTDR2BK}">{{ item.KoTDRY2DT }}</td>                     <!--2.13 ドライ -->
          <td rowspan="2">{{ item.KoHENJK }}</td>                                                           <!--2.14 返礼品 -->
          <td rowspan="2">{{ item.KoBSCJK }}</td>                                                           <!--2.15 バス -->
          <td rowspan="2">{{ item.KoTKUDAMJ }}</td>                                                         <!--2.16 果物 -->
          <td>{{ item.KoTBIKO2 }}</td>                                                                      <!--2.17 備考 -->
        </tr>
        <tr >
          <td>{{ item.KoTUKDT }}</td>                                                                     <!--3.1 受付日 -->
                                                                                                          <!--3.2  -->
          <td>{{ item.KoSIKAINM }}</td>                                                                   <!--3.3 司会者 -->
          <td>{{ item.KoTSNM }}</td>                                                                      <!--3.4 支払 -->
          <td>{{ item.KoTPLNM }}</td>                                                                     <!--3.5 プラン -->
          <td>{{ item.KoTTYEJI }}:{{ item.KoTTYEFN }}</td>                                                <!--3.6 通夜終了 -->
          <td>{{ item.KoTSSOGEJI }}:{{ item.KoTSSOGEFN }}</td>                                            <!--3.7 告別出棺 -->
          <td>{{ item.KoTSJNM }}</td>                                                                     <!--3.8 火葬場 -->
          <td>{{ item.KoTNKMK }}</td>                                                                     <!--3.9 納棺施術-->
          <td>{{ item.KoTISU1TO }}</td>                                                                   <!--3.10 移動TO -->
          <td>{{ item.KoTISU2TO }}</td>                                                                   <!--3.11 移動TO -->
          <td>{{ item.KoTAZKKKJKAA }}</td>                                                                <!--3.12 備考1 -->
          <td v-bind:style="{backgroundColor: item.KoTDR3BK}">{{ item.KoTDRY3DT}}</td>                    <!--3.13 ドライ -->
                                                                                                          <!--3.14  -->
                                                                                                          <!--3.15  -->
                                                                                                          <!--3.16  -->
          <td>{{ item.KoTBIKO3 }}</td>                                                                    <!--3.17 備考  -->
        </tr>       
      </tbody>
      </table>
     </v-simple-table>
     </v-app>
</div>
</template>

<script>
import axios from 'axios';
import moment from "moment";
import SKSSDialog from './Skoinput.vue';        //葬儀施行新規
import SKUPDialog from './Skoupdate.vue';       //葬儀施行更新
//import moment from 'moment';
export default {
    data: () => ({
      desserts: [],
      STKoTSSOGDT: '',
      ETKoTSSOGDT: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      STARTmenu: '',
      ENDmenu: '',
      KoTNO:      '',   //受付番号
      H_KoTNKTM:  '',   //表示用納棺時分
      KoTURDT:    '',   //売上日
      KoTUKDT:    '',   //受付日
      KoTUKJI:    '',   //受付時
      KoTUKFN:    '',   //受付分
      KoTBLOKCD:  '',   //部署コード
      KoTBLOKNM:  '',   //部署名称
      KoETNTNCD:  '',   //営業担当コード
      KoETNTNNM:  '',   //営業担当名称
      KoETNTNNMS: '',   //主担当略称
      KoTKANO:    '',   //会員番号
      KoTKANM:    '',   //会員名称
      KoTMOKE:    '',   //喪家
      KoThCD:     '',   //顧客取引先コード
      KoThNM:     '',   //顧客取引先名称
      KoTCD:      '',   //顧客提携区分コード
      KoTNM:      '',   //顧客提携区分名称
      KoTTKNM:    '',   //故人(カナ）
      KoTTNM:     '',   //故人名前
      KoTTBSDTJ:  '',   //故人生年月日(和暦）
      KoTTEG:     '',   //故人年齢
      KoTTSEX:    '',   //故人性別
      KoTTNEX:    '',   //故人続柄
      KoTTYU:     '',   //故人郵便番号
      KoTTJYU1:   '',   //故人住所1
      KoTTJYU2:   '',   //故人住所2
      KoTTJYU3:   '',   //故人住所3
      KoTTTEL:    '',   //故人電話番号
      KoTMKNM:    '',   //喪主(カナ）
      KoTMNM:     '',   //喪主名前
      KoTMNEX:    '',   //喪主続柄
      KoTMSEX:    '',   //喪主性別
      KoTMYU:     '',   //喪主郵便番号
      KoTMJYU1:   '',   //喪主住所1
      KoTMJYU2:   '',   //喪主住所2
      KoTMJYU3:   '',   //喪主住所3
      KoTMTEL:    '',   //喪主電話番号
      KoTMTELK:   '',   //喪主携帯
      KoTMTMail:  '',   //喪主Mail
      KoTMBIKO:   '',   //喪主備考
      KoTSNM:     '',   //お支払責任者名前
      KoTSKNM:    '',   //お支払カナ
      KoTSHYODT:  '',   //お支払予定日
      KoTSPNM:    '',   //入金方法名
      KoTSYU:     '',   //支払責任者郵便番号
      KoTSJYU1:   '',   //お支払責任者住所1
      KoTSJYU2:   '',   //お支払責任者住所2
      KoTSJYU3:   '',   //お支払責任者住所3
      KoTSTEL:    '',   //お支払責任者電話番号
      KoTSTELK:   '',   //お支払責任者携帯
      KoTSMail:   '',   //お支払責任者Mail
      KoTSBIKO:   '',   //お支払責任者備考
      KoTSSTRNM:  '',   //寺院名称
      KoTSSSONM:  '',   //宗旨名称
      KoTSSKSA1:  '',   //会葬挨拶1
      KoTSSKMNM:  '',   //家紋・紋章名称
      KoTSSBIKO:  '',   //引継ぎ備考
      KoTUUKBDT:  '',   //受付簿印刷日
      KoTUUKBTM:  '',   //受付簿印時間
      KoTUMSKSDT: '',   //請求書印刷日
      KoTUMSKSTM: '',   //請求書印刷時間
      KoTUTKSSDT: '',   //立替請求書印刷日
      KoTUTKSSTM: '',   //立替請求書印刷時間
      KoTPLCD:    '',   //プランコード
      KoTPLNM:    '',   //プラン名称
      KoTPLGK:    '',   //プラン金額
      KoTKKCD:    '',   //会館CD
      KoTKKNM:    '',   //会館名
      KoTHRCD:    '',   //ホールCD
      KoTHRNM:    '',   //ホール名
      KoTHRJK:    '',   //会館ホール略称
      KoTHRNMS:   '',   //会館ホール略称
      KoTDESDTJ:  '',   //死亡日(和暦
      KoTDESBS:   '',   //死亡場所
      KoTDESJI:   '',   //死亡時
      KoTDESFN:   '',   //死亡分 
      KoTTYSDT:   '',   //通夜日
      KoTTYSJI:   '',   //通夜時
      KoTTYSFN:   '',   //通夜分
      KoTTYEJI:   '',   //通夜終了時
      KoTTYEFN:   '',   //通夜終了分
      KoTTYSBS:   '',   //通夜場所
      KoTTYSJK:   '',   //通夜状況
      KoTTYSSK:   '',   //通夜司会
      KoTSSOGDT:  '',   //葬儀日
      KoTSSOGSJI: '',   //葬儀時
      KoTSSOGSFN: '',   //葬儀分
      KoTSSOGEJI: '',   //出棺時
      KoTSSOGEFN: '',   //出棺分
      KoTSSOSBS:  '',   //葬儀場所
      KoTSSOSSK:  '',   //葬儀司会
      KoTSSOGAS:  '',   //葬儀アシスタント
      KoTSSOGJK:  '',   //葬儀状況
      KoTSJDT:    '',   //火葬日
      KoTSJCD:    '',   //斎場コード
      KoTSJNM:    '',   //斎場名称
      KoTSJNMS:   '',   //斎場略称
      KoTSJJI:    '',   //火葬時
      KoTSJFN:    '',   //火葬分
      KoTSJEJI:   '',   //火葬終了時
      KoTSJEFN:   '',   //火葬終了分
      KoTSJDS:    '',   //同行・導師
      KoTSJD7:    '',   //初七日
      KoTSJHS:    '',   //控室
      KoTHZJK:    '',   //本膳状況
      KoTYOYK:    '',   //斎場予約
      KoTURSMST:  '',   //祭事標準税売上(税抜
      KoTURSMHK:  '',   //祭事非課税売上(税抜
      KoTSNEBST:  '',   //祭事標準税値引
      KoTSNEBKG:  '',   //祭事軽減税値引
      KoTSNEBHK:  '',   //祭事非課税値引
      KoTSKAZST:  '',   //祭事標準税対象額計
      KoTSKAZKG:  '',   //祭事軽減税対象額計
      KoTSKAZHK:  '',   //祭事非課税対象額計
      KoTSSYOST:  '',   //祭事標準税消費税
      KoTSSYOKG:  '',   //祭事軽減税消費税
      KoTSURKGK:  '',   //祭事売上(税込み
      KoTURTMST:  '',   //立替標準税売上(税抜
      KoTURTMKG:  '',   //立替軽減税売上(税抜
      KoTURTMHK:  '',   //立替非課税売上(税抜
      KoTTNEBST:  '',   //立替標準税値引
      KoTTNEBKG:  '',   //立替軽減税値引
      KoTTNEBHK:  '',   //立替非課税値引
      KoTTKAZST:  '',   //立替標準税対象額計
      KoTTKAZKG:  '',   //立替軽減税対象額計
      KoTTKAZHK:  '',   //立替非課税対象額計
      KoTURSMKG:  '',   //祭事軽減税売上(税抜
      KoTTSYOST:  '',   //立替標準税消費税
      KoTTSYOKG:  '',   //立替軽減税消費税
      KoTTURKGK:  '',   //立替売上(税込み
      KoTSGK:     '',   //売掛金総額
      KoTSHZNG:   '',   //売掛残高
      KoTSHNKG:   '',   //売掛金入金金額
      KoSGICNM:   '',   //葬儀委員長名
      KoSGICKN:   '',   //葬儀委員長かな名
      KoSGICZK:   '',   //葬儀委員長属性
      KoDMKB:     '',   //DM区分
      KoHJGK:     '',   //標準原価計
      KoARRI:     '',   //粗利益計
      KoTJJK:     '',   //受注進捗状況
      KoTIRNM:    '',   //依頼人
      KoTIRZK:    '',   //依頼人続柄
      KoTIRTL:    '',   //依頼人電話番号
      KoTIRTO:    '',   //搬送先(
      KoTIRDT:    '',   //打合日
      KoTIRJI:    '',   //打合時
      KoTIRFN:    '',   //打合分
      KoTIRBK:    '',   //その他･備考
      KoTIRKM:    '',   //搬送・打合せ・その他（）
      KoTIRSH:    '',   //使用品目
      KoTRDCD:    '',   //リードコード
      KoTRDNM:    '',   //リード名
      KoTRDNMS:   '',   //リード略称
      KoTRDTN:    '',   //リード担当名
      KoTRDRV:    '',   //リードレベル
      KoSKICD:    '',   //司会者コード
      KoSKINM:    '',   //司会者名
      KoSKINMS:   '',   //司会者略称
      KoSVTNCD:   '',   //サブ担当コード
      KoSVNTNM:   '',   //サブ担当名
      KoSVNTNMS:  '',   //サブ担当略称
      KoKRTNCD:   '',   //経理担当コード
      KoKRNTNM:   '',   //経理担当名
      KoTNKDT:    '',   //納棺日
      KoTNKJI:    '',   //納棺時
      KoTNKFN:    '',   //納棺分
      KoTNKMK:    '',   //納棺メーク
      KoTNKJK:    '',   //納棺状況
      KoTNKBS:    '',   //納棺場所
      KoTISU1DT:  '',   //移動1日
      KoTISU1JI:  '',   //移動1時
      KoTISU1FN:  '',   //移動1分
      KoTISU1FR:  '',   //F移動1移動元
      KoTISU1BS:  '',   //バス台数
      KoTISU1HY:  '',   //ハイヤー台数
      KoTISU1JK:  '',   //移動1状況
      KoTISU2DT:  '',   //移動2日
      KoTISU2JI:  '',   //移動2時
      KoTISU2FN:  '',   //移動2分
      KoTISU2FR:  '',   //F移動2移動元
      KoTISU2BS:  '',   //バス台数
      KoTISU2HY:  '',   //ハイヤー台数
      KoTISU2JK:  '',   //移動2状況
      KoTDRY1DT:  '',   //ドライ1
      KoTDRY2DT:  '',   //ドライ2
      KoTDRY3DT:  '',   //ドライ3
      KoTDRYJK:   '',   //ドライ状況
      KoREIJK:    '',   //礼状状況
      KoREIBK:    '',   //礼状備考
      KoHENJK:    '',   //返礼品状況
      KoRIHINM:   '',   //返礼品品目
      KoRICJK:    '',   //霊柩車状況
      KoRICTK:    '',   //霊柩車立替
      KoTSSTRJK:  '',   //寺院状況
      KoTSSTRBS:  '',   //菩提寺
      KoTSSTRHM:  '',   //戒(法名）
      KoTSSTROF:  '',   //お布施
      KoTKUDAMJ:  '',   //果物状況
      KoTHZBS:    '',   //本膳場所
      KoTHZNY:    '',   //枕飯・枕団子、供養膳
      KoTAZKPHJK: '',   //写真
      KoTAZKPB:   '',   //写真備考
      KoTAZKKKJK: '',   //許可書
      KoTAZKKB:   '',   //許可備考
      KoTHCDT:    '',   //発注日
      KoTHCJI:    '',   //発注時
      KoTHCFN:    '',   //発注分
      KoTMGKT:    '',   //担当者確定
      KoTBIKO1:   '',   //備考1
      KoTBIKO2:   '',   //備考2
      KoTBIKO3:   '',   //備考3
      KoMUKBDT:   '',   //受付簿印刷日
      KoMUKBTM:   '',   //受付簿印刷時間
      KoMSKSDT:   '',   //祭事見積印刷日
      KoMSKSTM:   '',   //祭事見積印刷時間
      KoMTKSDT:   '',   //立替見積印刷日
      KoMTKSTM:   '',   //立替見積印刷時間
      InstDt:     '',   //作成日時
      InstId:     '',   //作成者
      UpdtDt:     '',   //更新日時
      UpdtId:     '',   //更新者

      WKoTUKJI:   '',   //受付時
      WKoTDESJI:  '',   //死亡時
      WKoTTYSJI:  '',   //通夜時
      WKoTTYEJI:  '',   //通夜終了時
      WKoTSSOGSJI:'',   //葬儀時
      WKoTSSOGEJI:'',   //出棺時
      WKoTSJJI:   '',   //火葬時
      WKoTSJEJI:  '',   //火葬終了時
      WKoTIRJI:   '',   //打合時
      WKoTNKJI:   '',   //納棺時
      WKoTISU1JI: '',   //移動1時
      WKoTISU2JI: '',   //移動2時
      WKKoTTYSDT: '',   //通夜日
      WKoTSSOGDT: '',   //葬儀日
      WKoTURDT:   '',   //売上日
      WKoTUKDT:   '',   //受付日
      WKoTSHYODT: '',   //支払予定日
      WKoTSJDT:   '',   //火葬日
      WKoTIRDT:   '',   //打合日
      WKoTNKDT:   '',   //納棺日
      WKoTISU1DT: '',   //移動1日
      WKoTISU2DT: '',   //移動2日
      WKoTDRY1DT: '',   //ドライ1
      WKoTDRY2DT: '',   //ドライ2
      WKoTDRY3DT: ''   //ドライ3
     }),

   //初期取得
   mounted: function() {
      var dt = new Date();  //現在日時のDateオブジェクトを生成
      var ldt = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);  //今月末日を取得
      //フォーマット整形
      var y = ldt.getFullYear();
      var m = ("00" + (ldt.getMonth()+1)).slice(-2);
      var d = ("00" + ldt.getDate()).slice(-2);
      this.STKoTSSOGDT = y + "-" + m + "-" + "01";  //月初日
      this.ETKoTSSOGDT = y + "-" + m + "-" + d;     //月末日
      //this.STKoTSSOGDT = moment(xSTKoTSSOGDT).format('YYYY/MM/DD');
      //this.STKoTSSOGDT = "";
      //this.ETKoTSSOGDT = moment().endOf('month').format("YYYY-MM-DD");
      this.KoTrnGetsubmit();
   },
    methods: {
    //--------------------------------------------------------------------
    //葬祭データ取得 post
    //--------------------------------------------------------------------
    KoTrnGetsubmit() {
      this.desserts =[];
      this.items = [];
      var SGDT = this.STKoTSSOGDT.split('-').join('/'); 
      var EGDT = this.ETKoTSSOGDT.split('-').join('/'); 
  
      const fomego = {SDT: SGDT, EDT: EGDT,PLM:"01",KoTNO:""};
      axios.post('/api/KoTrnGet',fomego)
           .then(response => {
                this.desserts = response.data;
            })
            .catch(error => {
            console.log(error)
      })
    },
    //----------------------------------------
    //葬儀施行新規
    //----------------------------------------
    KIDialogshow() {
      localStorage.SOGICA_KoTNO = "";
      this.$refs.dialogSS.open();
    },
    //葬儀施行新規表示
    responseSS(obj) {
      if(obj.KoMCD != "--"){  //キャンセル時は何もしない
        this.KoTrnsquery()
      }
        this.dialogSS = false;
    },
    //----------------------------------------
    //葬儀施行更新
    //----------------------------------------
    UPDialogshow() {
       
          //受付時
          if(this.KoTUKJI != ""){
            if(this.KoTUKJI.length == 1 ){this.KoTUKJI = "0"+this.KoTUKJI;}
            if(this.KoTUKFN.length == 1 ){this.KoTUKFN = "0"+this.KoTUKFN;}
            this.WKoTUKJI = this.KoTUKJI + ":" +this.KoTUKFN; 
          }
          //死亡時
          if(this.KoTDESJI != ""){
            if(this.KoTDESJI.length == 1 ){this.KoTDESJI = "0"+this.KoTDESJI;}
            if(this.KoTDESFN.length == 1 ){this.KoTDESFN = "0"+this.KoTDESFN;}
            this.WKoTDESJI = this.KoTDESJI + ":" +this.KoTDESFN; 
          }
          //通夜時
          if(this.KoTTYSJI != ""){
            if(this.KoTTYSJI.length == 1 ){this.KoTTYSJI = "0"+this.KoTTYSJI;}
            if(this.KoTTYSFN.length == 1 ){this.KoTTYSFN = "0"+this.KoTTYSFN;}
            this.WKoTDESJI = this.KoTDESJI + ":" +this.KoTDESFN; 
            this.WKoTTYSJI = this.KoTTYSJI + ":" +this.KoTTYSFN; 
          }
          //通夜終了時
          if(this.KoTTYEJI != ""){
            if(this.KoTTYEJI.length == 1 ){this.KoTTYEJI = "0"+this.KoTTYEJI;}
            if(this.KoTTYEFN.length == 1 ){this.KoTTYEFN = "0"+this.KoTTYEFN;}
            this.WKoTTYEJI = this.KoTTYEJI + ":" +this.KoTTYEFN; 
          }
          //葬儀時
          if(this.KoTSSOGSJI != ""){
            if(this.KoTSSOGSJI.length == 1 ){this.KoTSSOGSJI = "0"+this.KoTSSOGSJI;}
            if(this.KoTSSOGSFN.length == 1 ){this.KoTSSOGSFN = "0"+this.KoTSSOGSFN;}
            this.WKoTSSOGSJI = this.KoTSSOGSJI + ":" +this.KoTSSOGSFN; 
          }
          //出棺時
          if(this.KoTSSOGEJI != ""){
            if(this.KoTSSOGEJI.length == 1 ){this.KoTSSOGEJI = "0"+this.KoTSSOGEJI;}
            if(this.KoTSSOGEFN.length == 1 ){this.KoTSSOGEFN = "0"+this.KoTSSOGEFN;}
            this.WKoTSSOGEJI = this.KoTSSOGEJI + ":" +this.KoTSSOGEFN; 
          }
          //火葬時
          if(this.KoTSJJI != ""){
            if(this.KoTSJJI.length == 1 ){this.KoTSJJI = "0"+this.KoTSJJI;}
            if(this.KoTSJFN.length == 1 ){this.KoTSJFN = "0"+this.KoTSJFN;}
            this.WKoTSJJI = this.KoTSJJI + ":" +this.KoTSJFN; 
          }
          //火葬終了時
          if(this.KoTSJEJI != ""){
            if(this.KoTSJEJI.length == 1 ){this.KoTSJEJI = "0"+this.KoTSJEJI;}
            if(this.KoTSJEFN.length == 1 ){this.KoTSJEFN = "0"+this.KoTSJEFN;}
            this.WKoTSJEJI = this.KoTSJEJI + ":" +this.KoTSJEFN; 
          }
          //打合時
          if(this.KoTIRJI != ""){
            if(this.KoTIRJI.length == 1 ){this.KoTIRJI = "0"+this.KoTIRJI;}
            if(this.KoTIRFN.length == 1 ){this.KoTIRFN = "0"+this.KoTIRFN;}
            this.WKoTIRJI = this.KoTIRJI + ":" +this.KoTIRFN; 
          }
          //納棺時
          if(this.KoTNKJI != ""){
            if(this.KoTNKJI.length == 1 ){this.KoTNKJI = "0"+this.KoTNKJI;}
            if(this.KoTNKFN.length == 1 ){this.KoTNKFN = "0"+this.KoTNKFN;}
            this.WKoTNKJI = this.KoTNKJI + ":" +this.KoTNKFN; 
          }
          //移動1時
          if(this.KoTISU1JI != ""){
            if(this.KoTISU1JI.length == 1 ){this.KoTISU1JI = "0"+this.KoTISU1JI;}
            if(this.KoTISU1FN.length == 1 ){this.KoTISU1FN = "0"+this.KoTISU1FN;}
            this.WKoTISU1JI = this.KoTISU1JI + ":" +this.KoTISU1FN; 
          }
          //移動2時
          if(this.KoTISU2JI != ""){
            if(this.KoTISU2JI.length == 1 ){this.KoTISU2JI = "0"+this.KoTISU2JI;}
            if(this.KoTISU2FN.length == 1 ){this.KoTISU2FN = "0"+this.KoTISU2FN;}
            this.WKoTISU2JI = this.KoTISU2JI + ":" +this.KoTISU2FN; 
          }
      
         if(this.KoTSSOGDT  != ""){ this.WKoTSSOGDT   = moment(this.KoTSSOGDT).format('YYYY-MM-DD');}   //葬儀日
         if(this.KoTURDT    != ""){ this.WKoTURDT     = moment(this.KoTURDT).format('YYYY-MM-DD');}     //売上日
         if(this.KoTUKDT    != ""){ this.WKoTUKDT     = moment(this.KoTUKDT).format('YYYY-MM-DD');}     //受付日
         if(this.KoTSHYODT  != ""){ this.WKoTSHYODT   = moment(this.KoTSHYODT).format('YYYY-MM-DD');}   //支払予定日
         if(this.KoTTYSDT   != ""){ this.WKKoTTYSDT   = moment(this.KoTTYSDT).format('YYYY-MM-DD');}    //通夜日
         if(this.KoTSJDT    != ""){ this.WKoTSJDT     = moment(this.KoTSJDT).format('YYYY-MM-DD');}     //火葬日
         if(this.KoTIRDT    != ""){ this.WKoTIRDT     = moment(this.KoTIRDT).format('YYYY-MM-DD');}     //打合日時
         if(this.KoTNKDT    != ""){ this.WKoTNKDT     = moment(this.KoTNKDT).format('YYYY-MM-DD');}     //納棺日
         if(this.KoTISU1DT  != ""){ this.WKoTISU1DT   = moment(this.KoTISU1DT).format('YYYY-MM-DD');}   //移動1日
         if(this.KoTISU2DT  != ""){ this.WKoTISU2DT   = moment(this.KoTISU2DT).format('YYYY-MM-DD');}   //移動2日
         if(this.KoTDRY1DT  != ""){ this.WKoTDRY1DT   = moment(this.KoTDRY1DT).format('YYYY-MM-DD');}   //ドライ1
         if(this.KoTDRY2DT  != ""){ this.WKoTDRY2DT   = moment(this.KoTDRY2DT).format('YYYY-MM-DD');}   //ドライ2
         if(this.KoTDRY3DT  != ""){ this.WKoTDRY3DT   = moment(this.KoTDRY3DT).format('YYYY-MM-DD');}   //ドライ3
     
         localStorage.SOGI_KoTNO      = this.KoTNO;           //受付番号
         localStorage.SOGI_H_KoTNKTM  = this.WH_KoTNKTM;      //表示用納棺時分
         localStorage.SOGI_KoTURDT    = this.WKoTURDT;        //売上日
         localStorage.SOGI_KoTUKDT    = this.WKoTUKDT;        //受付日
         localStorage.SOGI_KoTUKJI    = this.WKoTUKJI;        //受付時
         localStorage.SOGI_KoTUKFN    = "";                   //受付分
         localStorage.SOGI_KoTBLOKCD  = this.KoTBLOKCD;       //部署コード
         localStorage.SOGI_KoTBLOKNM  = this.KoTBLOKNM;       //部署名称
         localStorage.SOGI_KoETNTNCD  = this.KoETNTNCD;       //営業担当コード
         localStorage.SOGI_KoETNTNNM  = this.KoETNTNNM;       //営業担当名称
         localStorage.SOGI_KoETNTNNMS = this.KoETNTNNMS;      //主担当略称
         localStorage.SOGI_KoTKANO    = this.KoTKANO;         //会員番号
         localStorage.SOGI_KoTKANM    = this.KoTKANM;         //会員名称
         localStorage.SOGI_KoTMOKE    = this.KoTMOKE;         //喪家
         localStorage.SOGI_KoThCD     = this.KoThCD;          //顧客取引先コード
         localStorage.SOGI_KoThNM     = this.KoThNM;          //顧客取引先名称
         localStorage.SOGI_KoTCD      = this.KoTCD;           //顧客提携区分コード
         localStorage.SOGI_KoTNM      = this.KoTNM;           //顧客提携区分名称
         localStorage.SOGI_KoTTKNM    = this.KoTTKNM;         //故人(カナ）
         localStorage.SOGI_KoTTNM     = this.KoTTNM;          //故人名前
         localStorage.SOGI_KoTTBSDTJ  = this.KoTTBSDTJ;       //故人生年月日(和暦）
         localStorage.SOGI_KoTTEG     = this.KoTTEG;          //故人年齢
         localStorage.SOGI_KoTTSEX    = this.KoTTSEX;         //故人性別
         localStorage.SOGI_KoTTNEX    = this.KoTTNEX;         //故人続柄
         localStorage.SOGI_KoTTYU     = this.KoTTYU;          //故人郵便番号
         localStorage.SOGI_KoTTJYU1   = this.KoTTJYU1;        //故人住所1
         localStorage.SOGI_KoTTJYU2   = this.KoTTJYU2;        //故人住所2
         localStorage.SOGI_KoTTJYU3   = this.KoTTJYU3;        //故人住所3
         localStorage.SOGI_KoTTTEL    = this.KoTTTEL;         //故人電話番号
         localStorage.SOGI_KoTMKNM    = this.KoTMKNM;         //喪主(カナ）
         localStorage.SOGI_KoTMNM     = this.KoTMNM;          //喪主名前
         localStorage.SOGI_KoTMNEX    = this.KoTMNEX;         //喪主続柄
         localStorage.SOGI_KoTMSEX    = this.KoTMSEX;         //喪主性別
         localStorage.SOGI_KoTMYU     = this.KoTMYU;          //喪主郵便番号
         localStorage.SOGI_KoTMJYU1   = this.KoTMJYU1;        //喪主住所1
         localStorage.SOGI_KoTMJYU2   = this.KoTMJYU2;        //喪主住所2
         localStorage.SOGI_KoTMJYU3   = this.KoTMJYU3;        //喪主住所3
         localStorage.SOGI_KoTMTEL    = this.KoTMTEL;         //喪主電話番号
         localStorage.SOGI_KoTMTELK   = this.KoTMTELK;        //喪主携帯
         localStorage.SOGI_KoTMTMail  = this.KoTMTMail;       //喪主Mail
         localStorage.SOGI_KoTMBIKO   = this.KoTMBIKO;        //喪主備考
         localStorage.SOGI_KoTSNM     = this.KoTSNM;          //お支払責任者名前
         localStorage.SOGI_KoTSKNM    = this.KoTSKNM;         //お支払カナ
         localStorage.SOGI_KoTSHYODT  = this.WKoTSHYODT;       //お支払予定日
         localStorage.SOGI_KoTSPNM    = this.KoTSPNM;         //入金方法名
         localStorage.SOGI_KoTSYU     = this.KoTSYU;          //支払責任者郵便番号
         localStorage.SOGI_KoTSJYU1   = this.KoTSJYU1;        //お支払責任者住所1
         localStorage.SOGI_KoTSJYU2   = this.KoTSJYU2;        //お支払責任者住所2
         localStorage.SOGI_KoTSJYU3   = this.KoTSJYU3;        //お支払責任者住所3
         localStorage.SOGI_KoTSTEL    = this.KoTSTEL;         //お支払責任者電話番号
         localStorage.SOGI_KoTSTELK   = this.KoTSTELK;        //お支払責任者携帯
         localStorage.SOGI_KoTSMail   = this.KoTSMail;        //お支払責任者Mail
         localStorage.SOGI_KoTSBIKO   = this.KoTSBIKO;        //お支払責任者備考
         localStorage.SOGI_KoTSSTRNM  = this.KoTSSTRNM;       //寺院名称
         localStorage.SOGI_KoTSSSONM  = this.KoTSSSONM;       //宗旨名称
         localStorage.SOGI_KoTSSKSA1  = this.KoTSSKSA1;       //会葬挨拶1
         localStorage.SOGI_KoTSSKMNM  = this.KoTSSKMNM;       //家紋・紋章名称
         localStorage.SOGI_KoTSSBIKO  = this.KoTSSBIKO;       //引継ぎ備考
         localStorage.SOGI_KoTUUKBDT  = this.KoTUUKBDT;       //受付簿印刷日
         localStorage.SOGI_KoTUUKBTM  = this.KoTUUKBTM;       //受付簿印時間
         localStorage.SOGI_KoTUMSKSDT = this.KoTUMSKSDT;      //請求書印刷日
         localStorage.SOGI_KoTUMSKSTM = this.KoTUMSKSTM;      //請求書印刷時間
         localStorage.SOGI_KoTUTKSSDT = this.KoTUTKSSDT;      //立替請求書印刷日
         localStorage.SOGI_KoTUTKSSTM = this.KoTUTKSSTM;      //立替請求書印刷時間
         localStorage.SOGI_KoTPLCD    = this.KoTPLCD;         //プランコード
         localStorage.SOGI_KoTPLNM    = this.KoTPLNM;         //プラン名称
         localStorage.SOGI_KoTPLGK    = this.KoTPLGK;         //プラン金額
         localStorage.SOGI_KoTKKCD    = this.KoTKKCD;         //会館CD
         localStorage.SOGI_KoTKKNM    = this.KoTKKNM;         //会館名
         localStorage.SOGI_KoTHRCD    = this.KoTHRCD;         //ホールCD
         localStorage.SOGI_KoTHRNM    = this.KoTHRNM;         //ホール名
         localStorage.SOGI_KoTHRJK    = this.KoTHRJK;         //会館ホール略称
         localStorage.SOGI_KoTHRNMS   = this.KoTHRNMS;        //会館ホール略称
         localStorage.SOGI_KoTDESDTJ  = this.KoTDESDTJ;       //死亡日(和暦
         localStorage.SOGI_KoTDESBS   = this.KoTDESBS;        //死亡場所
         localStorage.SOGI_KoTDESJI   = this.WKoTDESJI;       //死亡時
         localStorage.SOGI_KoTDESFN   = "";                   //死亡分 
         localStorage.SOGI_KoTTYSDT   = this.WKKoTTYSDT;      //通夜日
         localStorage.SOGI_KoTTYSJI   = this.WKoTTYSJI;       //通夜時
         localStorage.SOGI_KoTTYSFN   = "";                   //通夜分
         localStorage.SOGI_KoTTYEJI   = this.WKoTTYEJI;       //通夜終了時
         localStorage.SOGI_KoTTYEFN   = "";                   //通夜終了分
         localStorage.SOGI_KoTTYSBS   = this.KoTTYSBS;        //通夜場所
         localStorage.SOGI_KoTTYSJK   = this.KoTTYSJK;        //通夜状況
         localStorage.SOGI_KoTTYSSK   = this.KoTTYSSK;        //通夜司会
         localStorage.SOGI_KoTSSOGDT  = this.WKoTSSOGDT;      //葬儀日
         localStorage.SOGI_KoTSSOGSJI = this.WKoTSSOGSJI;     //葬儀時
         localStorage.SOGI_KoTSSOGSFN = "";                   //葬儀分
         localStorage.SOGI_KoTSSOGEJI = this.WKoTSSOGEJI;     //出棺時
         localStorage.SOGI_KoTSSOGEFN = "";                   //出棺分
         localStorage.SOGI_KoTSSOSBS  = this.KoTSSOSBS;       //葬儀場所
         localStorage.SOGI_KoTSSOSSK  = this.KoTSSOSSK;       //葬儀司会
         localStorage.SOGI_KoTSSOGAS  = this.KoTSSOGAS;       //葬儀アシスタント
         localStorage.SOGI_KoTSSOGJK  = this.KoTSSOGJK;       //葬儀状況
         localStorage.SOGI_KoTSJDT    = this.WKoTSJDT;         //火葬日
         localStorage.SOGI_KoTSJCD    = this.KoTSJCD;         //斎場コード
         localStorage.SOGI_KoTSJNM    = this.KoTSJNM;         //斎場名称
         localStorage.SOGI_KoTSJNMS   = this.KoTSJNMS;        //斎場略称
         localStorage.SOGI_KoTSJJI    = this.WKoTSJJI;        //火葬時
         localStorage.SOGI_KoTSJFN    = "";                   //火葬分
         localStorage.SOGI_KoTSJEJI   = this.WKoTSJEJI;       //火葬終了時
         localStorage.SOGI_KoTSJEFN   = "";                   //火葬終了分
         localStorage.SOGI_KoTSJDS    = this.KoTSJDS;         //同行・導師
         localStorage.SOGI_KoTSJD7    = this.KoTSJD7;         //初七日
         localStorage.SOGI_KoTSJHS    = this.KoTSJHS;         //控室
         localStorage.SOGI_KoTHZJK    = this.KoTHZJK;         //本膳状況
         localStorage.SOGI_KoTYOYK    = this.KoTYOYK;         //斎場予約
         localStorage.SOGI_KoTURSMST  = this.KoTURSMST;       //祭事標準税売上(税抜
         localStorage.SOGI_KoTURSMHK  = this.KoTURSMHK;       //祭事非課税売上(税抜
         localStorage.SOGI_KoTSNEBST  = this.KoTSNEBST;       //祭事標準税値引
         localStorage.SOGI_KoTSNEBKG  = this.KoTSNEBKG;       //祭事軽減税値引
         localStorage.SOGI_KoTSNEBHK  = this.KoTSNEBHK;       //祭事非課税値引
         localStorage.SOGI_KoTSKAZST  = this.KoTSKAZST;       //祭事標準税対象額計
         localStorage.SOGI_KoTSKAZKG  = this.KoTSKAZKG;       //祭事軽減税対象額計
         localStorage.SOGI_KoTSKAZHK  = this.KoTSKAZHK;       //祭事非課税対象額計
         localStorage.SOGI_KoTSSYOST  = this.KoTSSYOST;       //祭事標準税消費税
         localStorage.SOGI_KoTSSYOKG  = this.KoTSSYOKG;       //祭事軽減税消費税
         localStorage.SOGI_KoTSURKGK  = this.KoTSURKGK;       //祭事売上(税込み
         localStorage.SOGI_KoTURTMST  = this.KoTURTMST;       //立替標準税売上(税抜
         localStorage.SOGI_KoTURTMKG  = this.KoTURTMKG;       //立替軽減税売上(税抜
         localStorage.SOGI_KoTURTMHK  = this.KoTURTMHK;       //立替非課税売上(税抜
         localStorage.SOGI_KoTTNEBST  = this.KoTTNEBST;       //立替標準税値引
         localStorage.SOGI_KoTTNEBKG  = this.KoTTNEBKG;       //立替軽減税値引
         localStorage.SOGI_KoTTNEBHK  = this.KoTTNEBHK;       //立替非課税値引
         localStorage.SOGI_KoTTKAZST  = this.KoTTKAZST;       //立替標準税対象額計
         localStorage.SOGI_KoTTKAZKG  = this.KoTTKAZKG;       //立替軽減税対象額計
         localStorage.SOGI_KoTTKAZHK  = this.KoTTKAZHK;       //立替非課税対象額計
         localStorage.SOGI_KoTURSMKG  = this.KoTURSMKG;       //祭事軽減税売上(税抜
         localStorage.SOGI_KoTTSYOST  = this.KoTTSYOST;       //立替標準税消費税
         localStorage.SOGI_KoTTSYOKG  = this.KoTTSYOKG;       //立替軽減税消費税
         localStorage.SOGI_KoTTURKGK  = this.KoTTURKGK;       //立替売上(税込み
         localStorage.SOGI_KoTSGK     = this.KoTSGK;          //売掛金総額
         localStorage.SOGI_KoTSHZNG   = this.KoTSHZNG;        //売掛残高
         localStorage.SOGI_KoTSHNKG   = this.KoTSHNKG;        //売掛金入金金額
         localStorage.SOGI_KoSGICNM   = this.KoSGICNM;        //葬儀委員長名
         localStorage.SOGI_KoSGICKN   = this.KoSGICKN;        //葬儀委員長かな名
         localStorage.SOGI_KoSGICZK   = this.KoSGICZK;        //葬儀委員長属性
         localStorage.SOGI_KoDMKB     = this.KoDMKB;          //DM区分
         localStorage.SOGI_KoHJGK     = this.KoHJGK;          //標準原価計
         localStorage.SOGI_KoARRI     = this.KoARRI;          //粗利益計
         localStorage.SOGI_KoTJJK     = this.KoTJJK;          //受注進捗状況
         localStorage.SOGI_KoTIRNM    = this.KoTIRNM;         //依頼人
         localStorage.SOGI_KoTIRZK    = this.KoTIRZK;         //依頼人続柄
         localStorage.SOGI_KoTIRTL    = this.KoTIRTL;         //依頼人電話番号
         localStorage.SOGI_KoTIRTO    = this.KoTIRTO;         //搬送先(
         localStorage.SOGI_KoTIRDT    = this.WKoTIRDT;        //打合日
         localStorage.SOGI_KoTIRJI    = this.WKoTIRJI;        //打合時
         localStorage.SOGI_KoTIRFN    = "";                   //打合分
         localStorage.SOGI_KoTIRBK    = this.KoTIRBK;         //その他･備考
         localStorage.SOGI_KoTIRKM    = this.KoTIRKM;         //搬送・打合せ・その他（）
         localStorage.SOGI_KoTIRSH    = this.KoTIRSH;         //使用品目
         localStorage.SOGI_KoTRDCD    = this.KoTRDCD;         //リードコード
         localStorage.SOGI_KoTRDNM    = this.KoTRDNM;         //リード名
         localStorage.SOGI_KoTRDNMS   = this.KoTRDNMS;        //リード略称
         localStorage.SOGI_KoTRDTN    = this.KoTRDTN;         //リード担当名
         localStorage.SOGI_KoTRDRV    = this.KoTRDRV;         //リードレベル
         localStorage.SOGI_KoSKICD    = this.KoSKICD;         //司会者コード
         localStorage.SOGI_KoSKINM    = this.KoSKINM;         //司会者名
         localStorage.SOGI_KoSKINMS   = this.KoSKINMS;        //司会者略称
         localStorage.SOGI_KoSVTNCD   = this.KoSVTNCD;        //サブ担当コード
         localStorage.SOGI_KoSVNTNM   = this.KoSVNTNM;        //サブ担当名
         localStorage.SOGI_KoSVNTNMS  = this.KoSVNTNMS;       //サブ担当略称
         localStorage.SOGI_KoKRTNCD   = this.KoKRTNCD;        //経理担当コード
         localStorage.SOGI_KoKRNTNM   = this.KoKRNTNM;        //経理担当名
         localStorage.SOGI_KoTNKDT    = this.WKoTNKDT;        //納棺日
         localStorage.SOGI_KoTNKJI    = this.WKoTNKJI;        //納棺時
         localStorage.SOGI_KoTNKFN    = "";                   //納棺分
         localStorage.SOGI_KoTNKMK    = this.KoTNKMK;         //納棺メーク
         localStorage.SOGI_KoTNKJK    = this.KoTNKJK;         //納棺状況
         localStorage.SOGI_KoTNKBS    = this.KoTNKBS;         //納棺場所
         localStorage.SOGI_KoTISU1DT  = this.WKoTISU1DT;      //移動1日
         localStorage.SOGI_KoTISU1JI  = this.WKoTISU1JI;      //移動1時
         localStorage.SOGI_KoTISU1FN  = "";                   //移動1分
         localStorage.SOGI_KoTISU1FR  = this.KoTISU1FR;       //F移動1移動元
         localStorage.SOGI_KoTISU1BS  = this.KoTISU1BS;       //バス台数
         localStorage.SOGI_KoTISU1HY  = this.KoTISU1HY;       //ハイヤー台数
         localStorage.SOGI_KoTISU1JK  = this.KoTISU1JK;       //移動1状況
         localStorage.SOGI_KoTISU2DT  = this.WKoTISU2DT;      //移動2日
         localStorage.SOGI_KoTISU2JI  = this.WKoTISU2JI;      //移動2時
         localStorage.SOGI_KoTISU2FN  = "";                   //移動2分
         localStorage.SOGI_KoTISU2FR  = this.KoTISU2FR;       //F移動2移動元
         localStorage.SOGI_KoTISU2BS  = this.KoTISU2BS;       //バス台数
         localStorage.SOGI_KoTISU2HY  = this.KoTISU2HY;       //ハイヤー台数
         localStorage.SOGI_KoTISU2JK  = this.KoTISU2JK;       //移動2状況
         localStorage.SOGI_KoTDRY1DT  = this.WKoTDRY1DT;       //ドライ1
         localStorage.SOGI_KoTDRY2DT  = this.WKoTDRY2DT;       //ドライ2
         localStorage.SOGI_KoTDRY3DT  = this.WKoTDRY3DT;       //ドライ3
         localStorage.SOGI_KoTDRYJK   = this.KoTDRYJK;        //ドライ状況
         localStorage.SOGI_KoREIJK    = this.KoREIJK;         //礼状状況
         localStorage.SOGI_KoREIBK    = this.KoREIBK;         //礼状備考
         localStorage.SOGI_KoHENJK    = this.KoHENJK;         //返礼品状況
         localStorage.SOGI_KoRIHINM   = this.KoRIHINM;        //返礼品品目
         localStorage.SOGI_KoRICJK    = this.KoRICJK;         //霊柩車状況
         localStorage.SOGI_KoRICTK    = this.KoRICTK;         //霊柩車立替
         localStorage.SOGI_KoTSSTRJK  = this.KoTSSTRJK;       //寺院状況
         localStorage.SOGI_KoTSSTRBS  = this.KoTSSTRBS;       //菩提寺
         localStorage.SOGI_KoTSSTRHM  = this.KoTSSTRHM;       //戒(法名）
         localStorage.SOGI_KoTSSTROF  = this.KoTSSTROF;       //お布施
         localStorage.SOGI_KoTKUDAMJ  = this.KoTKUDAMJ;       //果物状況
         localStorage.SOGI_KoTHZBS    = this.KoTHZBS;         //本膳場所
         localStorage.SOGI_KoTHZNY    = this.KoTHZNY;         //枕飯・枕団子、供養膳
         localStorage.SOGI_KoTAZKPHJK = this.KoTAZKPHJK;      //写真
         localStorage.SOGI_KoTAZKPB   = this.KoTAZKPB;        //写真備考
         localStorage.SOGI_KoTAZKKKJK = this.KoTAZKKKJK;      //許可書
         localStorage.SOGI_KoTAZKKB   = this.KoTAZKKB;        //許可備考
         localStorage.SOGI_KoTHCDT    = this.KoTHCDT;         //発注日
         localStorage.SOGI_KoTHCJI    = this.KoTHCJI;         //発注時
         localStorage.SOGI_KoTHCFN    = this.KoTHCFN;         //発注分
         localStorage.SOGI_KoTMGKT    = this.KoTMGKT;         //担当者確定
         localStorage.SOGI_KoTBIKO1   = this.KoTBIKO1;        //備考1
         localStorage.SOGI_KoTBIKO2   = this.KoTBIKO2;        //備考2
         localStorage.SOGI_KoTBIKO3   = this.KoTBIKO3;        //備考3
         localStorage.SOGI_KoMUKBDT   = this.KoMUKBDT;	      //見積受付簿印刷日
         localStorage.SOGI_KoMUKBTM   = this.KoMUKBTM;	      //見積受付簿印刷時間
         localStorage.SOGI_KoMSKSDT   = this.KoMSKSDT;	      //見積祭事印刷日
         localStorage.SOGI_KoMSKSTM   = this.KoMSKSTM;	      //見積祭事印刷時間
         localStorage.SOGI_KoMTKSDT   = this.KoMTKSDT;	      //見積立替印刷日
         localStorage.SOGI_KoMTKSTM   = this.KoMTKSTM;	      //見積立替印刷時間
         localStorage.SOGI_InstDt     = this.InstDt;          //作成日時
         localStorage.SOGI_InstId     = this.InstId;          //作成者
         localStorage.SOGI_UpdtDt     = this.UpdtDt;          //更新日時
         localStorage.SOGI_UpdtId     = this.UpdtId;          //更新者
        //console.log(this.KoTNO);
        this.$refs.dialogUP.open();
    },
    //葬儀施行新規表示
    responseUP(obj) {
      if(obj.KoMCD != "--"){  //キャンセル時は何もしない
        this.KoTrnsquery()
      }
        this.dialoUP = false;
    },
 
    //画面のリセット
    reset () {
        this.MTpBsTnpCd = '';
        this.MTpBsTnpNm = '';
    },
   onButtonClick: function(item) {      

          this.KoTNO      = item.KoTNO;           //受付番号
          this.H_KoTNKTM  = item.WH_KoTNKTM;      //表示用納棺時分
          this.KoTURDT    = item.KoTURDT;         //売上日
          this.KoTUKDT    = item.KoTUKDT;         //受付日
          this.KoTUKJI    = item.KoTUKJI;         //受付時
          this.KoTUKFN    = item.KoTUKFN;         //受付分
          this.KoTBLOKCD  = item.KoTBLOKCD;       //部署コード
          this.KoTBLOKNM  = item.KoTBLOKNM;       //部署名称
          this.KoETNTNCD  = item.KoETNTNCD;       //営業担当コード
          this.KoETNTNNM  = item.KoETNTNNM;       //営業担当名称
          this.KoETNTNNMS = item.KoETNTNNMS;      //主担当略称
          this.KoTKANO    = item.KoTKANO;         //会員番号
          this.KoTKANM    = item.KoTKANM;         //会員名称
          this.KoTMOKE    = item.KoTMOKE;         //喪家
          this.KoThCD     = item.KoThCD;          //顧客取引先コード
          this.KoThNM     = item.KoThNM;          //顧客取引先名称
          this.KoTCD      = item.KoTCD;           //顧客提携区分コード
          this.KoTNM      = item.KoTNM;           //顧客提携区分名称
          this.KoTTKNM    = item.KoTTKNM;         //故人(カナ）
          this.KoTTNM     = item.KoTTNM;          //故人名前
          this.KoTTBSDTJ  = item.KoTTBSDTJ;       //故人生年月日(和暦）
          this.KoTTEG     = item.KoTTEG;          //故人年齢
          this.KoTTSEX    = item.KoTTSEX;         //故人性別
          this.KoTTNEX    = item.KoTTNEX;         //故人続柄
          this.KoTTYU     = item.KoTTYU;          //故人郵便番号
          this.KoTTJYU1   = item.KoTTJYU1;        //故人住所1
          this.KoTTJYU2   = item.KoTTJYU2;        //故人住所2
          this.KoTTJYU3   = item.KoTTJYU3;        //故人住所3
          this.KoTTTEL    = item.KoTTTEL;         //故人電話番号
          this.KoTMKNM    = item.KoTMKNM;         //喪主(カナ）
          this.KoTMNM     = item.KoTMNM;          //喪主名前
          this.KoTMNEX    = item.KoTMNEX;         //喪主続柄
          this.KoTMSEX    = item.KoTMSEX;         //喪主性別
          this.KoTMYU     = item.KoTMYU;          //喪主郵便番号
          this.KoTMJYU1   = item.KoTMJYU1;        //喪主住所1
          this.KoTMJYU2   = item.KoTMJYU2;        //喪主住所2
          this.KoTMJYU3   = item.KoTMJYU3;        //喪主住所3
          this.KoTMTEL    = item.KoTMTEL;         //喪主電話番号
          this.KoTMTELK   = item.KoTMTELK;        //喪主携帯
          this.KoTMTMail  = item.KoTMTMail;       //喪主Mail
          this.KoTMBIKO   = item.KoTMBIKO;        //喪主備考
          this.KoTSNM     = item.KoTSNM;          //お支払責任者名前
          this.KoTSKNM    = item.KoTSKNM;         //お支払カナ
          this.KoTSHYODT  = item.KoTSHYODT;       //お支払予定日
          this.KoTSPNM    = item.KoTSPNM;         //入金方法名
          this.KoTSYU     = item.KoTSYU;          //支払責任者郵便番号
          this.KoTSJYU1   = item.KoTSJYU1;        //お支払責任者住所1
          this.KoTSJYU2   = item.KoTSJYU2;        //お支払責任者住所2
          this.KoTSJYU3   = item.KoTSJYU3;        //お支払責任者住所3
          this.KoTSTEL    = item.KoTSTEL;         //お支払責任者電話番号
          this.KoTSTELK   = item.KoTSTELK;        //お支払責任者携帯
          this.KoTSMail   = item.KoTSMail;        //お支払責任者Mail
          this.KoTSBIKO   = item.KoTSBIKO;        //お支払責任者備考
          this.KoTSSTRNM  = item.KoTSSTRNM;       //寺院名称
          this.KoTSSSONM  = item.KoTSSSONM;       //宗旨名称
          this.KoTSSKSA1  = item.KoTSSKSA1;       //会葬挨拶1
          this.KoTSSKMNM  = item.KoTSSKMNM;       //家紋・紋章名称
          this.KoTSSBIKO  = item.KoTSSBIKO;       //引継ぎ備考
          this.KoTUUKBDT  = item.KoTUUKBDT;       //受付簿印刷日
          this.KoTUUKBTM  = item.KoTUUKBTM;       //受付簿印時間
          this.KoTUMSKSDT = item.KoTUMSKSDT;      //請求書印刷日
          this.KoTUMSKSTM = item.KoTUMSKSTM;      //請求書印刷時間
          this.KoTUTKSSDT = item.KoTUTKSSDT;      //立替請求書印刷日
          this.KoTUTKSSTM = item.KoTUTKSSTM;      //立替請求書印刷時間
          this.KoTPLCD    = item.KoTPLCD;         //プランコード
          this.KoTPLNM    = item.KoTPLNM;         //プラン名称
          this.KoTPLGK    = item.KoTPLGK;         //プラン金額
          this.KoTKKCD    = item.KoTKKCD;         //会館CD
          this.KoTKKNM    = item.KoTKKNM;         //会館名
          this.KoTHRCD    = item.KoTHRCD;         //ホールCD
          this.KoTHRNM    = item.KoTHRNM;         //ホール名
          this.KoTHRJK    = item.KoTHRJK;         //会館ホール略称
          this.KoTHRNMS   = item.KoTHRNMS;        //会館ホール略称
          this.KoTDESDTJ  = item.KoTDESDTJ;       //死亡日(和暦
          this.KoTDESBS   = item.KoTDESBS;        //死亡場所
          this.KoTDESJI   = item.KoTDESJI;        //死亡時
          this.KoTDESFN   = item.KoTDESFN;        //死亡分 
          this.KoTTYSDT   = item.KoTTYSDT;        //通夜日
          this.KoTTYSJI   = item.KoTTYSJI;        //通夜時
          this.KoTTYSFN   = item.KoTTYSFN;        //通夜分
          this.KoTTYEJI   = item.KoTTYEJI;        //通夜終了時
          this.KoTTYEFN   = item.KoTTYEFN;        //通夜終了分
          this.KoTTYSBS   = item.KoTTYSBS;        //通夜場所
          this.KoTTYSJK   = item.KoTTYSJK;        //通夜状況
          this.KoTTYSSK   = item.KoTTYSSK;        //通夜司会
          this.KoTSSOGDT  = item.KoTSSOGDT;       //葬儀日
          this.KoTSSOGSJI = item.KoTSSOGSJI;      //葬儀時
          this.KoTSSOGSFN = item.KoTSSOGSFN;      //葬儀分
          this.KoTSSOGEJI = item.KoTSSOGEJI;      //出棺時
          this.KoTSSOGEFN = item.KoTSSOGEFN;      //出棺分
          this.KoTSSOSBS  = item.KoTSSOSBS;       //葬儀場所
          this.KoTSSOSSK  = item.KoTSSOSSK;       //葬儀司会
          this.KoTSSOGAS  = item.KoTSSOGAS;       //葬儀アシスタント
          this.KoTSSOGJK  = item.KoTSSOGJK;       //葬儀状況
          this.KoTSJDT    = item.KoTSJDT;         //火葬日
          this.KoTSJCD    = item.KoTSJCD;         //斎場コード
          this.KoTSJNM    = item.KoTSJNM;         //斎場名称
          this.KoTSJNMS   = item.KoTSJNMS;        //斎場略称
          this.KoTSJJI    = item.KoTSJJI;         //火葬時
          this.KoTSJFN    = item.KoTSJFN;         //火葬分
          this.KoTSJEJI   = item.KoTSJEJI;        //火葬終了時
          this.KoTSJEFN   = item.KoTSJEFN;        //火葬終了分
          this.KoTSJDS    = item.KoTSJDS;         //同行・導師
          this.KoTSJD7    = item.KoTSJD7;         //初七日
          this.KoTSJHS    = item.KoTSJHS;         //控室
          this.KoTHZJK    = item.KoTHZJK;         //本膳状況
          this.KoTYOYK    = item.KoTYOYK;         //斎場予約
          this.KoTURSMST  = item.KoTURSMST;       //祭事標準税売上(税抜
          this.KoTURSMHK  = item.KoTURSMHK;       //祭事非課税売上(税抜
          this.KoTSNEBST  = item.KoTSNEBST;       //祭事標準税値引
          this.KoTSNEBKG  = item.KoTSNEBKG;       //祭事軽減税値引
          this.KoTSNEBHK  = item.KoTSNEBHK;       //祭事非課税値引
          this.KoTSKAZST  = item.KoTSKAZST;       //祭事標準税対象額計
          this.KoTSKAZKG  = item.KoTSKAZKG;       //祭事軽減税対象額計
          this.KoTSKAZHK  = item.KoTSKAZHK;       //祭事非課税対象額計
          this.KoTSSYOST  = item.KoTSSYOST;       //祭事標準税消費税
          this.KoTSSYOKG  = item.KoTSSYOKG;       //祭事軽減税消費税
          this.KoTSURKGK  = item.KoTSURKGK;       //祭事売上(税込み
          this.KoTURTMST  = item.KoTURTMST;       //立替標準税売上(税抜
          this.KoTURTMKG  = item.KoTURTMKG;       //立替軽減税売上(税抜
          this.KoTURTMHK  = item.KoTURTMHK;       //立替非課税売上(税抜
          this.KoTTNEBST  = item.KoTTNEBST;       //立替標準税値引
          this.KoTTNEBKG  = item.KoTTNEBKG;       //立替軽減税値引
          this.KoTTNEBHK  = item.KoTTNEBHK;       //立替非課税値引
          this.KoTTKAZST  = item.KoTTKAZST;       //立替標準税対象額計
          this.KoTTKAZKG  = item.KoTTKAZKG;       //立替軽減税対象額計
          this.KoTTKAZHK  = item.KoTTKAZHK;       //立替非課税対象額計
          this.KoTURSMKG  = item.KoTURSMKG;       //祭事軽減税売上(税抜
          this.KoTTSYOST  = item.KoTTSYOST;       //立替標準税消費税
          this.KoTTSYOKG  = item.KoTTSYOKG;       //立替軽減税消費税
          this.KoTTURKGK  = item.KoTTURKGK;       //立替売上(税込み
          this.KoTSGK     = item.KoTSGK;          //売掛金総額
          this.KoTSHZNG   = item.KoTSHZNG;        //売掛残高
          this.KoTSHNKG   = item.KoTSHNKG;        //売掛金入金金額
          this.KoSGICNM   = item.KoSGICNM;        //葬儀委員長名
          this.KoSGICKN   = item.KoSGICKN;        //葬儀委員長かな名
          this.KoSGICZK   = item.KoSGICZK;        //葬儀委員長属性
          this.KoDMKB     = item.KoDMKB;          //DM区分
          this.KoHJGK     = item.KoHJGK;          //標準原価計
          this.KoARRI     = item.KoARRI;          //粗利益計
          this.KoTJJK     = item.KoTJJK;          //受注進捗状況
          this.KoTIRNM    = item.KoTIRNM;         //依頼人
          this.KoTIRZK    = item.KoTIRZK;         //依頼人続柄
          this.KoTIRTL    = item.KoTIRTL;         //依頼人電話番号
          this.KoTIRTO    = item.KoTIRTO;         //搬送先(
          this.KoTIRDT    = item.KoTIRDT;         //打合日
          this.KoTIRJI    = item.KoTIRJI;         //打合時
          this.KoTIRFN    = item.KoTIRFN;         //打合分
          this.KoTIRBK    = item.KoTIRBK;         //その他･備考
          this.KoTIRKM    = item.KoTIRKM;         //搬送・打合せ・その他（）
          this.KoTIRSH    = item.KoTIRSH;         //使用品目
          this.KoTRDCD    = item.KoTRDCD;         //リードコード
          this.KoTRDNM    = item.KoTRDNM;         //リード名
          this.KoTRDNMS   = item.KoTRDNMS;        //リード略称
          this.KoTRDTN    = item.KoTRDTN;         //リード担当名
          this.KoTRDRV    = item.KoTRDRV;         //リードレベル
          this.KoSKICD    = item.KoSKICD;         //司会者コード
          this.KoSKINM    = item.KoSKINM;         //司会者名
          this.KoSKINMS   = item.KoSKINMS;        //司会者略称
          this.KoSVTNCD   = item.KoSVTNCD;        //サブ担当コード
          this.KoSVNTNM   = item.KoSVNTNM;        //サブ担当名
          this.KoSVNTNMS  = item.KoSVNTNMS;       //サブ担当略称
          this.KoKRTNCD   = item.KoKRTNCD;        //経理担当コード
          this.KoKRNTNM   = item.KoKRNTNM;        //経理担当名
          this.KoTNKDT    = item.KoTNKDT;         //納棺日
          this.KoTNKJI    = item.KoTNKJI;         //納棺時
          this.KoTNKFN    = item.KoTNKFN;         //納棺分
          this.KoTNKMK    = item.KoTNKMK;         //納棺メーク
          this.KoTNKJK    = item.KoTNKJK;         //納棺状況
          this.KoTNKBS    = item.KoTNKBS;         //納棺場所
          this.KoTISU1DT  = item.KoTISU1DT;       //移動1日
          this.KoTISU1JI  = item.KoTISU1JI;       //移動1時
          this.KoTISU1FN  = item.KoTISU1FN;       //移動1分
          this.KoTISU1FR  = item.KoTISU1FR;       //F移動1移動元
          this.KoTISU1BS  = item.KoTISU1BS;       //バス台数
          this.KoTISU1HY  = item.KoTISU1HY;       //ハイヤー台数
          this.KoTISU1JK  = item.KoTISU1JK;       //移動1状況
          this.KoTISU2DT  = item.KoTISU2DT;       //移動2日
          this.KoTISU2JI  = item.KoTISU2JI;       //移動2時
          this.KoTISU2FN  = item.KoTISU2FN;       //移動2分
          this.KoTISU2FR  = item.KoTISU2FR;       //F移動2移動元
          this.KoTISU2BS  = item.KoTISU2BS;       //バス台数
          this.KoTISU2HY  = item.KoTISU2HY;       //ハイヤー台数
          this.KoTISU2JK  = item.KoTISU2JK;       //移動2状況
          this.KoTDRY1DT  = item.KoTDRY1DT;       //ドライ1
          this.KoTDRY2DT  = item.KoTDRY2DT;       //ドライ2
          this.KoTDRY3DT  = item.KoTDRY3DT;       //ドライ3
          this.KoTDRYJK   = item.KoTDRYJK;        //ドライ状況
          this.KoREIJK    = item.KoREIJK;         //礼状状況
          this.KoREIBK    = item.KoREIBK;         //礼状備考
          this.KoHENJK    = item.KoHENJK;         //返礼品状況
          this.KoRIHINM   = item.KoRIHINM;        //返礼品品目
          this.KoRICJK    = item.KoRICJK;         //霊柩車状況
          this.KoRICTK    = item.KoRICTK;         //霊柩車立替
          this.KoTSSTRJK  = item.KoTSSTRJK;       //寺院状況
          this.KoTSSTRBS  = item.KoTSSTRBS;       //菩提寺
          this.KoTSSTRHM  = item.KoTSSTRHM;       //戒(法名）
          this.KoTSSTROF  = item.KoTSSTROF;       //お布施
          this.KoTKUDAMJ  = item.KoTKUDAMJ;       //果物状況
          this.KoTHZBS    = item.KoTHZBS;         //本膳場所
          this.KoTHZNY    = item.KoTHZNY;         //枕飯・枕団子、供養膳
          this.KoTAZKPHJK = item.KoTAZKPHJK;      //写真
          this.KoTAZKPB   = item.KoTAZKPB;        //写真備考
          this.KoTAZKKKJK = item.KoTAZKKKJK;      //許可書
          this.KoTAZKKB   = item.KoTAZKKB;        //許可備考
          this.KoTHCDT    = item.KoTHCDT;         //発注日
          this.KoTHCJI    = item.KoTHCJI;         //発注時
          this.KoTHCFN    = item.KoTHCFN;         //発注分
          this.KoTMGKT    = item.KoTMGKT;         //担当者確定
          this.KoTBIKO1   = item.KoTBIKO1;        //備考1
          this.KoTBIKO2   = item.KoTBIKO2;        //備考2
          this.KoTBIKO3   = item.KoTBIKO3;        //備考3
          this.KoMUKBDT   = item.KoMUKBDT;	      //見積受付簿印刷日
          this.KoMUKBTM   = item.KoMUKBTM;	      //見積受付簿印刷時間
          this.KoMSKSDT   = item.KoMSKSDT;	      //見積祭事印刷日
          this.KoMSKSTM   = item.KoMSKSTM;	      //見積祭事印刷時間
          this.KoMTKSDT   = item.KoMTKSDT;	      //見積立替印刷日
          this.KoMTKSTM   = item.KoMTKSTM;	      //見積立替印刷時間
          this.InstDt     = item.InstDt;          //作成日時
          this.InstId     = item.InstId;          //作成者
          this.UpdtDt     = item.UpdtDt;          //更新日時
          this.UpdtId     = item.UpdtId;          //更新者
          
         this.UPDialogshow();
      },
    },
    components: {
      appDialogSS: SKSSDialog,     //葬儀施行新規
      appDialogUP: SKUPDialog,     //葬儀施行更新
  }
}
</script>
<style>
table {
  width: 100%;
  border-spacing: 0;
  font-size:12px;
}
table th {
  color: #fff;
  padding: 1px 1px;
  background: #258;
  font-weight: bold;
  border-left:1px solid #258;
  border-top:1px solid #258;
  border-bottom:1px solid #258;
  line-height: 120%;
  
  text-shadow:0 -1px 0 rgba(34,85,136,0.9);
  box-shadow: 0px 1px 1px rgba(255,255,255,0.3) inset;
}
thead th {
  /* 縦スクロール時に固定する */
 
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}
table tr td {
  padding: 3px 1px;
  border-bottom: 1px solid #84b2e0;
  border-left: 1px solid #84b2e0;
 
 white-space: nowrap;
}
table tr td:last-child {
  border-right: 1px solid #ebf6f7;
  box-shadow: 2px 2px 1px rgba(0,0,0,0.1);
}
table tr {
  background: #fff;
}
table tr:nth-child(2n+1) {
  background: #fff;
}
table tr:last-child td {
  box-shadow: 2px 2px 1px rgba(0,0,0,0.1);
}
table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}
table tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}
table tr:hover {
  background: #bbd4ee;
  cursor:pointer;
}

</style>
