<template>
 <v-dialog v-model="dialogSMTKK">
   <v-app id="inspire">
  <v-card width="100%">
    <v-toolbar flat color="grey lighten-2" height="30">
      <v-toolbar-title>提携企業マスタ検索(popTEIKEI)</v-toolbar-title>
    </v-toolbar> 
    <v-row>
      <v-subheader></v-subheader>
      <v-col cols="6"  md="6" sm="6" xsmd="6">
        <div >
          <v-btn x-large color="success" dark v-on:click="agreeok" block><v-icon left>mdi-gavel</v-icon>提携企業マスタ確定</v-btn>
        </div>
      </v-col>
       <v-col cols="4"  md="4" sm="4" xsmd="4">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
        <v-row>
         <v-subheader></v-subheader>
          <v-subheader><strong>提携</strong></v-subheader>
          <v-subheader><v-alert dense>{{ TRHCD }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ TRHNM }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ TRHTKKWA }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ TRHTKCD }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ TRHTKNM }}</v-alert></v-subheader>
        </v-row>
    </v-row>
     <v-simple-table :fixed-header="true" class="SMTKK_DSP"  :dense="true" height="300" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">取引先コード</th>
            <th class="text-left">取引先名称</th>
            <th class="text-left">供物割引率</th>
            <th class="text-left">提携区分コード</th>
            <th class="text-left">提携区分名称</th>
           </tr>
        </thead>
        <tbody>
          <tr v-for="(SMTKK,index) in SMTKK" :key="index" @click="showAlert2(SMTKK)">
            <td>{{ SMTKK.TRHCD }}</td>	
            <td>{{ SMTKK.TRHNM }}</td>
            <td>{{ SMTKK.TRHTKKWA }}</td>	
            <td>{{ SMTKK.TRHTKCD }}</td>
            <td>{{ SMTKK.TRHTKNM }}</td>
            
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
  </v-app>
</v-dialog>
</template>
<script>
import axios from 'axios';

export default {
  name: "SMTKK_DSP",
  data() {
    return {
      dialogSMTKK:false,
      SMTKK: [],
      TRHCD:     "",    //取引先コード
      TRHNM:     "",    //取引先名称
      TRHTKCD:   "",    //提携先コード
      TRHTKNM:   "",    //提携先名称
      TRHTKKWA:  "",    //供物割引率
      text:      "",
     }
  },
  methods: {
    async SMTKKShow() {
        axios.post('/api/TKKSGET')
        .then(response => {
          this.SMTKK = response.data
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogSMTKK = true;
    },
     //-----------------------------------------------------------------
     //オープン処理
     //----------------------------------------------------------------- 
    open() {
     if(localStorage.SOGI_KgyCd) this.KgyCd = localStorage.SOGI_KgyCd;
     this.SMTKKShow();
     this.dialogSMTKK = true;
     this.$emit('result', {'res': false, 'TRHCD':'--','TRHNM':'--'})
    },
    close() {
      this.$emit('result', {'res': false, 'TRHCD':'--','TKKTNM':'--'})
      this.dialogSMTKK = false;
    },
    agreeok() {
      this.$emit('result', {'res': true,'TRHCD':this.TKKTCD,'TKKTNM':this.TKKTNM,'TRHTKKWA':this.TRHTKKWA,'TRHTKCD':this.TRHTKCD,'TRHTKNM':this.TRHTKNM})
      this.dialogSMTKK = false;
     },
      showAlert2: function(item) {
      this.TRHCD      = item.TRHCD;     //取引先コード
      this.TRHNM      = item.TRHNM;     //取引先名称
      this.TRHTKKWA   = item.TRHTKKWA;  //供物割引率
      this.TRHTKCD    = item.TRHTKCD;   //提携区分コード
      this.TRHTKNM    = item.TRHTKNM;   //提携区分名称
      },
  },
} 
</script>