<template>
 <v-dialog v-model="dialogSMHINM">
   <v-app id="inspire">
  <v-card width="100%">
    <v-toolbar flat color="grey lighten-2" height="30">
      <v-toolbar-title>品目検索(popSMHINM)</v-toolbar-title>
    </v-toolbar> 
    <v-row>
      <v-subheader></v-subheader>
      <v-col cols="6"  md="6" sm="6" xsmd="6">
        <div ><v-btn x-large color="success" dark v-on:click="agreeok" block><v-icon left>mdi-gavel</v-icon>品目選択確定</v-btn></div>
      </v-col>
      <v-col cols="4"  md="4" sm="4" xsmd="4">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    <v-row>
      <v-subheader></v-subheader>
      <v-subheader><strong>品目</strong></v-subheader>
      <v-subheader><v-alert dense>{{ HINMOCD }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ HINMONM }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ HINMOTK }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ HINMOTNNM }}</v-alert></v-subheader>
      <v-subheader><v-alert dense>{{ HINMOSHKG }}</v-alert></v-subheader>
    </v-row>
  </v-row>
  <v-card-title>プラン詳細
    <v-spacer></v-spacer>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="品目検索" single-line hide-details></v-text-field>
  </v-card-title>
  <v-data-table :headers="headers" :items="SMHINM" :search="search" @click:row="clickRow">
    <template v-slot:[`item.actions`]="{ item }">
      <v-img :src="item.HINMIMG1" style="max-width: 150px; max-height: 100px" />
    </template>
   </v-data-table>
  </v-card>
  </v-app>
</v-dialog>
</template>

<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import { listSmhinms } from "../graphql/queries";
export default {
  name: "SMHINM_DSP",
  data() {
    return {
        search: '',
        dialogSMHINM:false,
        SMHINM: [],
        HINDIBRJN:  "",  //品目大分類順
        HINDIBRCD:  "",  //品目大分類コード
        HINDIBRNM:  "",  //品目大分類名
        HINSYBRJN:  "",  //品目分類順
        HINSYBRCD:  "",  //品目分類コード
        HINSYBRNM:  "",  //品目分類名
        HINMOCD:    "",  //品目コード
        HINMONM:    "",  //品目名
        HINMOUK5:   "",  //自社フラグ
        HINMOTNNM:  "",  //単位名
        HINMOTK:    "",  //単価
        HINSITK:    "",  //仕入単価
        HINMOSHKG:  "",  //消費税区分
        HINMOUK3:   "",  //区分立替
        HINMHEND:   "",  //区分変動
        HINMIMG1:   "",  //品目イメージ1
        HINMIMG2:   "",  //品目イメージ2
        HINMIMG3:   "",  //品目イメージ3
        HINMIMG4:   "",  //品目イメージ4
        HINMIMG5:   "",  //品目イメージ5
        KgyCd:    "",  //企業コード
        text: "",
        headers: [
            { text: 'コード', align: 'start',value: 'HINMOCD',width: '80'},
            { text: '品目名称', value: 'HINMONM',width: '100'},
            { text: '単価',  value: 'HINMOTK',width: '80' },
            { text: '単位',  value: 'HINMOTNNM',width: '80' },
            { text: '税区',  value: 'HINMOSHKG',width: '80' },
            { text: 'Image', value: 'HINMIMG1', sortable: false,width: '150' },
      ],
     }
  },
  methods: {
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------
    open() {
     if(localStorage.SOGI_KgyCd) this.KgyCd = localStorage.SOGI_KgyCd;
     this.SMHINMShow();
     this.dialogSMHINM = true;
     
    },
    close() {
      this.$emit('result', {'res': false, 'HINMOCD':'--','HINMONM':'--'})
      this.dialogSMHINM = false;
    },
    agreeok() {
      this.$emit('result', {'res': true,
        'HINDIBRJN':this.HINDIBRJN,   //品目大分類順
        'HINDIBRCD':this.HINDIBRCD,   //品目大分類コード
        'HINDIBRNM':this.HINDIBRNM,   //品目大分類名
        'HINSYBRJN':this.HINSYBRJN,   //品目分類順
        'HINSYBRCD':this.HINSYBRCD,   //品目分類コード
        'HINSYBRNM':this.HINSYBRNM,   //品目分類名
        'HINMOCD':this.HINMOCD,       //品目コード
        'HINMONM':this.HINMONM,       //品目名
        'HINMOUK5':this.HINMOUK5,     //自社フラグ
        'HINMOTNNM':this.HINMOTNNM,   //単位名
        'HINMOTK':this.HINMOTK,       //単価
        'HINSITK':this.HINSITK,       //仕入単価
        'HINMOSHKG':this.HINMOSHKG,   //消費税区分
        'HINMOUK3':this.HINMOUK3,     //区分立替
        'HINMHEND':this.HINMHEND,     //区分変動
        'HINMIMG1':this.HINMIMG1,     //品目イメージ1
        'HINMIMG2':this.HINMIMG2,     //品目イメージ2
        'HINMIMG3':this.HINMIMG3,     //品目イメージ3
        'HINMIMG4':this.HINMIMG4,     //品目イメージ4
        'HINMIMG5':this.HINMIMG5      //品目イメージ5
      })
      this.dialogSMHINM = false;
     },
     async SMHINMShow() {
        axios.post('/api/SMHINMALL')
        .then(response => {
          this.SMHINM = response.data
        })
        .catch(error => {
        console.log(error)
      })
      },

    clickRow(row) {
        this.HINDIBRJN  = row.HINDIBRJN;	//品目大分類順
        this.HINDIBRCD	= row.HINDIBRCD;	//品目大分類コード
        this.HINDIBRNM	= row.HINDIBRNM;	//品目大分類名
        this.HINSYBRJN	= row.HINSYBRJN;	//品目分類順
        this.HINSYBRCD	= row.HINSYBRCD;	//品目分類コード
        this.HINSYBRNM	= row.HINSYBRNM;	//品目分類名
        this.HINMOCD	= row.HINMOCD;	  //品目コード
        this.HINMONM	= row.HINMONM;	  //品目名
        this.HINMOUK5	= row.HINMOUK5;	  //自社フラグ
        this.HINMOTNNM	= row.HINMOTNNM;	//単位名
        this.HINMOTK	= row.HINMOTK;	  //単価
        this.HINSITK	= row.HINSITK;	  //仕入単価
        this.HINMOSHKG	= row.HINMOSHKG;	//消費税区分
        this.HINMOUK3	= row.HINMOUK3;	  //区分立替
        this.HINMHEND	= row.HINMHEND;	  //区分変動
        this.HINMIMG1	= row.HINMIMG1;	  //品目イメージ1
        this.HINMIMG2	= row.HINMIMG2;	  //品目イメージ2
        this.HINMIMG3	= row.HINMIMG3;	  //品目イメージ3
        this.HINMIMG4	= row.HINMIMG4;	  //品目イメージ4
        this.HINMIMG5	= row.HINMIMG5;	  //品目イメージ5
      },
     //----------------------------------------
    //テーブル検索マスタ表示
    //----------------------------------------
    /* eslint-disable */
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  },
} 
</script>