<template>
 <v-dialog v-model="dialogKAGET">
   <v-app id="inspire">
    <v-card width="100%">
      <v-toolbar flat color="grey lighten-2" height="30"><v-toolbar-title>会員選択(popKaiin)</v-toolbar-title></v-toolbar>
      <v-row>
       <v-subheader></v-subheader>
       <v-col cols="3" md="3"><v-text-field v-model="INKoMCD"  label="会員コード（半角）" dense></v-text-field></v-col>
       <v-col sm="1" md="1"><v-btn color="error" v-on:click="CLINKoMCD" >CL</v-btn></v-col>
       <v-col sm="7" md="7"><v-btn color="primary" v-on:click="KAGETShow1" >会員コード検索(バーコード)</v-btn></v-col>
       </v-row>
      <v-row>
       <v-subheader></v-subheader>
       <v-col cols="3" md="3"><v-text-field v-model="INKoMNM"  label="会員漢字名称（全角）"></v-text-field></v-col>
       <v-col sm="1" md="1"><v-btn color="error" v-on:click="CLINKoMNM" >CL</v-btn></v-col>
       <v-col sm="7" md="7"><v-btn color="primary" v-on:click="KAGETShow2" >会  員  漢字  検  索</v-btn></v-col>
      </v-row>
      <v-row>
       <v-subheader></v-subheader>
        <v-col cols="3" md="3"><v-text-field v-model="INKoMKNM"  label="会員カナ名称（全角）"></v-text-field></v-col>
        <v-col sm="1" md="1"><v-btn color="error" v-on:click="CLINKoMKNM" >CL</v-btn></v-col>
        <v-col sm="7" md="7"><v-btn color="primary" v-on:click="KAGETShow3" >会  員  カナ  検  索</v-btn></v-col>
      </v-row>
      <v-row>
       <v-subheader></v-subheader>
       <v-subheader><strong>会員コード</strong></v-subheader>
       <v-alert dense>{{ KoMCD }}</v-alert>
       <v-subheader><strong>会員名</strong></v-subheader>
       <v-alert dense>{{ KoMNM }}</v-alert>
      </v-row>
      <v-row>
        <v-subheader></v-subheader>
        <v-col cols="6"  md="6" sm="6" xsmd="6"><div ><v-btn x-large color="success" dark v-on:click="agreeok" block>会員選択確定</v-btn></div></v-col>
        <v-col cols="4"  md="4" sm="4" xsmd="4"><v-btn  fab dark small color="pink" v-on:click="close"><v-icon dark>mdi-minus</v-icon></v-btn></v-col>
      </v-row>
      <v-simple-table :fixed-header="true" class="KAIIN_DSP"  :dense="true" height="300" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">会員コード</th>
              <th class="text-left">会員名称</th>
              <th class="text-left">会員名称(カナ）</th>
              <th class="text-left">会員区分名</th>
              <th class="text-left">状況名</th>
              <th class="text-left">住所県</th>
              <th class="text-left">市町村区</th>
              <th class="text-left">丁番</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(KAITB,index) in KAITB" :key="index" @click="showAlert2(KAITB)">
              <td>{{ KAITB.KoMCD }}</td>	
              <td>{{ KAITB.KoMNM }}</td>
              <td>{{ KAITB.KoMKNM }}</td>
              <td>{{ KAITB.KoMKKBNM }}</td>
              <td>{{ KAITB.KoMJYKYNM }}</td>	
              <td>{{ KAITB.KoMJYU1 }}</td>
              <td>{{ KAITB.KoMJYU2 }}</td>
              <td>{{ KAITB.KoMJYU3 }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    </v-app>
  </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: "KoMst_DSP",
  data() {
    return {
      dialogKAGET:false,
      KAITB: [],
      INKoMCD:      "",  //会員コード  選択
      INKoMNM:      "",  //会員漢字名称 選択
      INKoMKNM:     "",  //会員カナ名称
      GETKoMCD:     "",  //会員コード  選択
      GETKoMNM:     "",  //会員漢字名称 選択
      KoMCD:        "",   //会員コード
      KoMNM:        "",   //会員名称
      KoMKNM:       "",   //会員名称(カナ）
      KoMKKBCD:     "",   //会員区分
      KoMKKBNM:     "",   //会員区分名
      KoMIKOMCD:    "",   //会員進捗状況コード
      KoMIKOMNM:    "",   //会員進捗状況名
      KoMJYKYCD:    "",   //状況コード
      KoMJYKYNM:    "",   //状況名
      KoMBSDTJ:     "",   //会員生年月日(和暦）
      KoMBSDT:      "",   //会員生年月日(西暦）
      KoMNR:        "",   //会員年齢
      KoMSX:        "",   //会員性別
      KoMBK:        "",   //備考
      KoMARAECD:    "",   //エリアコード
      KoMARAENM:    "",   //エリア名称
      KoMYU:        "",   //会員郵便番号
      KoMJYU1:      "",   //会員住所1
      KoMJYU2:      "",   //会員住所2
      KoMJYU3:      "",   //会員住所3
      KoMTEL:       "",   //会員電話番号
      KoMTELK:      "",   //会員携帯番号
      KoMMail:      "",   //会員Mail
      KoMNKDT:      "",   //入会日
      KoMKKDT:      "",   //休会日
      KoMTKDT:      "",   //退会日
      KoMKSDT:      "",   //更新日
      KoMKIDT:      "",   //会員期限
      KoMRRSS:      "",   //会員連絡先氏名
      KoMRRST:      "",   //会員連絡先電話
      KoMRTLK:      "",   //会員連絡先携帯番号
      KoMRBK:       "",   //会員連絡先備考
      KoMTRNM:      "",   //寺院
      KoMSSNM:      "",   //宗旨
      KoMSSBK:      "",   //引継備考
      KoAZHSK:      "",   //預り保証金
      KoAZHNK:      "",   //年会費
      KoAZHTK:      "",   //月会費
      KoAZBK:       "",   //会費備考
      KoKBLOKCD:    "",   //部署コード
      KoMBLOKNM:    "",   //部署名称
      KoMNCD:       "",   //営業担当コード
      KoMNNM:       "",   //営業担当名称
      KoThCD:       "",   //顧客取引先コード
      KoThNM:       "",   //顧客取引先名称
      KoTCD:        "",   //顧客提携区分コード
      KoTNM:        "",   //顧客提携区分名称
      KoTPLCD:      "",   //プランコード
      KoTPLNM:      "",   //プラン名称
      KoTPLGK:      "",   //プラン金額
      KoTURSMST:    "",   //祭事標準税売上(税抜)
      KoTURSMKG:    "",   //祭事軽減税売上(税抜)
      KoTURSMHK:    "",   //祭事非課税売上(税抜)
      KoTSNEBST:    "",   //祭事標準税値引
      KoTSNEBKG:    "",   //祭事軽減税値引
      KoTSNEBHK:    "",   //祭事非課税値引
      KoTSKAZST:    "",   //祭事標準税対象額計
      KoTSKAZKG:    "",   //祭事軽減税対象額計
      KoTSKAZHK:    "",   //祭事非課税対象額計
      KoTSSYOST:    "",   //祭事標準税消費税
      KoTSSYOKG:    "",   //祭事軽減税消費税
      KoTSURKGK:    "",   //祭事売上(税込み)
      KoTURTMST:    "",   //立替標準税売上(税抜)
      KoTURTMKG:    "",   //立替軽減税売上(税抜)
      KoTURTMHK:    "",   //立替非課税売上(税抜)
      KoTTNEBST:    "",   //立替標準税値引
      KoTTNEBKG:    "",   //立替軽減税値引
      KoTTNEBHK:    "",   //立替非課税値引
      KoTTKAZST:    "",   //立替標準税対象額計
      KoTTKAZKG:    "",   //立替軽減税対象額計
      KoTTKAZHK:    "",   //立替非課税対象額計
      KoTTSYOST:    "",   //立替標準税消費税
      KoTTSYOKG:    "",   //立替軽減税消費税
      KoTTURKGK:    "",   //立替売上(税込み)
      KoDMKB:       "",   //DM区分
      KoTMOKE:      "",   //喪家
      KoTTNM:       "",   //ご心配な方名
      KoTTKNM:      "",   //ご心配な方カ
      KoTTSEX:      "",   //性別
      KoTTBSDTJ:    "",   //性別
      KoTTEG:       "",   //年齢
      KoTTNEX:      "",   //続柄
      KoTTYU:       "",   //住所〒
      KoTTJYU1:     "",   //住所1
      KoTTJYU2:     "",   //住所2
      KoTTJYU3:     "",   //住所3
      KoTTTE:       "",   //電話
      KoTMNM:       "",   //喪主名
      KoTMKNM:      "",   //喪主カ
      KoTMSEX:      "",   //性別
      KoTMNEX:      "",   //続柄
      KoTMYU:       "",   //住所〒
      KoTMJYU1:     "",   //住所1
      KoTMJYU2:     "",   //住所2
      KoTMJYU3:     "",   //住所3
      KoTMTEL:      "",   //電話
      KoTMTELK:     "",   //携帯
      KoTMTMail:    "",   //mail
      KoTMBIKO:     "",   //備考
      KoTSNM:       "",   //支払者名
      KoTSKNM:      "",   //支払者名カ
      KoTSPNM:      "",   //入金方法
      KoTSYU:       "",   //住所〒
      KoTSJYU1:     "",   //住所1
      KoTSJYU2:     "",   //住所2
      KoTSJYU3:     "",   //住所3
      KoTSTEL:      "",   //電話
      KoTSTELK:     "",   //携帯
      KoTSMail:     "",   //mail
      KoTSBIKO:     "",   //備考
      KoTSSKSA1:    "",   //会葬挨拶
      KoSGICNM:     "",   //葬儀委員長名
      KoSGICKN:     "",   //葬儀委員長名カ
      KoSGICZK:     "",   //属性
      KoTDESBS:     "",   //ご入院先
      KoTIRTO:      "",   //移送先
      KoTHRJK:      "",   //式場状況
      KoTKKCD:      "",   //会館コード
      KoTKKNM:      "",   //会館名
      KoTHRCD:      "",   //ホールコード
      KoTHRNM:      "",   //ホール名
      KoTHRNMS:     "",   //会館ホール略称
      KoTTYSJK:     "",   //通夜状況
      KoTTYSJKJN:   "",   //通夜順
      KoTTYSBS:     "",   //通夜場所
      KoTTYSSK:     "",   //通夜司会
      KoTTYSAS:     "",   //通夜アシ
      KoTSSOGJK:    "",   //告別状況
      KoTSSOGJKJN:  "",   //告別順
      KoTSSOSBS:    "",   //告別場所
      KoTSSOSSK:    "",   //告別司会
      KoTSSOGAS:    "",   //告別アシ
      KoTHZJK:      "",   //本膳
      KoTHZJKJN:    "",   //本膳順
      KoTHZBS:      "",   //本膳場所
      KoTHZNY:      "",   //枕飯・枕団子、供養膳
      KoTSJKJN:     "",   //火葬順
      KoTSJCD:      "",   //火葬コード
      KoTSJNM:      "",   //火葬名
      KoTSJNMS:     "",   //火葬名略称
      KoTSJDS:      "",   //同行
      KoTSJHS:      "",   //控室
      KoTYOYK:      "",   //斎場予約
      KoTNKJK:      "",   //納棺状況
      KoTNKJKJN:    "",   //納棺順
      KoTNKMK:      "",   //納棺メーク
      KoTNKBS:      "",   //納棺場所
      KoTISU1JK:    "",   //移動(通夜)
      KoTISU1JKJN:  "",   //移動(通夜)順
      KoTISU1FR:    "",   //移動(通夜)場所
      KoTISU1BS:    "",   //移動(通夜)バス
      KoTISU1HY:    "",   //移動(通夜)ＴＸ
      KoTISU2JK:    "",   //移動(出棺)状況
      KoTISU2JKJN:  "",   //移動(出棺)順
      KoTISU2FR:    "",   //移動(出棺)場所
      KoRICJK:      "",   //霊柩状況
      KoTISU2BS:    "",   //移動(出棺)バス
      KoTISU2HY:    "",   //移動(出棺ＴＸ
      KoTDRYJK:     "",   //ドライ状況
      KoREIJK:      "",   //礼状状況
      KoREIBK:      "",   //礼状備考
      KoRIHINM:     "",   //返礼品
      KoTKUDAMJ:    "",   //果物状況
      KoTSSTRJK:    "",   //寺院状況
      KoTSSTRNM:    "",   //寺院名
      KoTSSTRBS:    "",   //菩提寺
      KoTSSSONM:    "",   //宗旨
      KoTSSKMNM:    "",   //家紋・紋章
      KoTSSTRHM:    "",   //戒(法)名
      KoTSSTROF:    "",   //お布施
      KoTBIKO1:     "",   //備考1
      KoTBIKO2:     "",   //備考2
      KoTBIKO3:     "",   //備考3
      InstDt:       "",   //作成日時
      InstId:       "",   //作成者
      UpdtDt:       "",   //更新日時
      UpdtId:       "",   //更新者
     }
  },
  methods: {
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------  
    open() {     
     this.dialogKAGET = true;
    },
    close() {
      this.$emit('result', {'res': false, 'KoMCD':'--','KoMNM':'--'})
      this.dialogKAGET = false;
    },
    agreeok() {
      this.$emit('result', {
        'res': true,
          'KoMCD':      this.KoMCD,       //会員コード
          'KoMNM':      this.KoMNM,       //会員名称
          'KoMKNM':     this.KoMKNM,      //会員名称(カナ）
          'KoMKKBCD':   this.KoMKKBCD,    //会員区分
          'KoMKKBNM':   this.KoMKKBNM,    //会員区分名
          'KoMIKOMCD':  this.KoMIKOMCD,   //会員進捗状況コード
          'KoMIKOMNM':  this.KoMIKOMNM,   //会員進捗状況名
          'KoMJYKYCD':  this.KoMJYKYCD,   //状況コード
          'KoMJYKYNM':  this.KoMJYKYNM,   //状況名
          'KoMBSDTJ':   this.KoMBSDTJ,    //会員生年月日(和暦）
          'KoMBSDT':    this.KoMBSDT,     //会員生年月日(西暦）
          'KoMNR':      this.KoMNR,       //会員年齢
          'KoMSX':      this.KoMSX,       //会員性別
          'KoMBK':      this.KoMBK,       //備考
          'KoMARAECD':  this.KoMARAECD,   //エリアコード
          'KoMARAENM':  this.KoMARAENM,   //エリア名称
          'KoMYU':      this.KoMYU,       //会員郵便番号
          'KoMJYU1':    this.KoMJYU1,     //会員住所1
          'KoMJYU2':    this.KoMJYU2,     //会員住所2
          'KoMJYU3':    this.KoMJYU3,     //会員住所3
          'KoMTEL':     this.KoMTEL,      //会員電話番号
          'KoMTELK':    this.KoMTELK,     //会員携帯番号
          'KoMMail':    this.KoMMail,     //会員Mail
          'KoMNKDT':    this.KoMNKDT,     //入会日
          'KoMKKDT':    this.KoMKKDT,     //休会日
          'KoMTKDT':    this.KoMTKDT,     //退会日
          'KoMKSDT':    this.KoMKSDT,     //更新日
          'KoMKIDT':    this.KoMKIDT,     //会員期限
          'KoMRRSS':    this.KoMRRSS,     //会員連絡先氏名
          'KoMRRST':    this.KoMRRST,     //会員連絡先電話
          'KoMRTLK':    this.KoMRTLK,     //会員連絡先携帯番号
          'KoMRBK':     this.KoMRBK,      //会員連絡先備考
          'KoMTRNM':    this.KoMTRNM,     //寺院
          'KoMSSNM':    this.KoMSSNM,     //宗旨
          'KoMSSBK':    this.KoMSSBK,     //引継備考
          'KoAZHSK':    this.KoAZHSK,     //預り保証金
          'KoAZHNK':    this.KoAZHNK,     //年会費
          'KoAZHTK':    this.KoAZHTK,     //月会費
          'KoAZBK':     this.KoAZBK,      //会費備考
          'KoKBLOKCD':  this.KoKBLOKCD,   //部署コード
          'KoMBLOKNM':  this.KoMBLOKNM,   //部署名称
          'KoMNCD':     this.KoMNCD,      //営業担当コード
          'KoMNNM':     this.KoMNNM,      //営業担当名称
          'KoThCD':     this.KoThCD,      //顧客取引先コード
          'KoThNM':     this.KoThNM,      //顧客取引先名称
          'KoTCD':      this.KoTCD,       //顧客提携区分コード
          'KoTNM':      this.KoTNM,       //顧客提携区分名称
          'KoTPLCD':    this.KoTPLCD,     //プランコード
          'KoTPLNM':    this.KoTPLNM,     //プラン名称
          'KoTPLGK':    this.KoTPLGK,     //プラン金額
          'KoTURSMST':  this.KoTURSMST,   //祭事標準税売上(税抜)
          'KoTURSMKG':  this.KoTURSMKG,   //祭事軽減税売上(税抜)
          'KoTURSMHK':  this.KoTURSMHK,   //祭事非課税売上(税抜)
          'KoTSNEBST':  this.KoTSNEBST,   //祭事標準税値引
          'KoTSNEBKG':  this.KoTSNEBKG,   //祭事軽減税値引
          'KoTSNEBHK':  this.KoTSNEBHK,   //祭事非課税値引
          'KoTSKAZST':  this.KoTSKAZST,   //祭事標準税対象額計
          'KoTSKAZKG':  this.KoTSKAZKG,   //祭事軽減税対象額計
          'KoTSKAZHK':  this.KoTSKAZHK,   //祭事非課税対象額計
          'KoTSSYOST':  this.KoTSSYOST,   //祭事標準税消費税
          'KoTSSYOKG':  this.KoTSSYOKG,   //祭事軽減税消費税
          'KoTSURKGK':  this.KoTSURKGK,   //祭事売上(税込み)
          'KoTURTMST':  this.KoTURTMST,   //立替標準税売上(税抜)
          'KoTURTMKG':  this.KoTURTMKG,   //立替軽減税売上(税抜)
          'KoTURTMHK':  this.KoTURTMHK,   //立替非課税売上(税抜)
          'KoTTNEBST':  this.KoTTNEBST,   //立替標準税値引
          'KoTTNEBKG':  this.KoTTNEBKG,   //立替軽減税値引
          'KoTTNEBHK':  this.KoTTNEBHK,   //立替非課税値引
          'KoTTKAZST':  this.KoTTKAZST,   //立替標準税対象額計
          'KoTTKAZKG':  this.KoTTKAZKG,   //立替軽減税対象額計
          'KoTTKAZHK':  this.KoTTKAZHK,   //立替非課税対象額計
          'KoTTSYOST':  this.KoTTSYOST,   //立替標準税消費税
          'KoTTSYOKG':  this.KoTTSYOKG,   //立替軽減税消費税
          'KoTTURKGK':  this.KoTTURKGK,   //立替売上(税込み)
          'KoDMKB':     this.KoDMKB,      //DM区分
          'KoTMOKE':    this.KoTMOKE,     //喪家
          'KoTTNM':     this.KoTTNM,      //ご心配な方名
          'KoTTKNM':    this.KoTTKNM,     //ご心配な方カ
          'KoTTSEX':    this.KoTTSEX,     //性別
          'KoTTBSDTJ':  this.KoTTBSDTJ,   //性別
          'KoTTEG':     this.KoTTEG,      //年齢
          'KoTTNEX':    this.KoTTNEX,     //続柄
          'KoTTYU':     this.KoTTYU,      //住所〒
          'KoTTJYU1':   this.KoTTJYU1,    //住所1
          'KoTTJYU2':   this.KoTTJYU2,    //住所2
          'KoTTJYU3':   this.KoTTJYU3,    //住所3
          'KoTTTE':     this.KoTTTE,      //電話
          'KoTMNM':     this.KoTMNM,      //喪主名
          'KoTMKNM':    this.KoTMKNM,     //喪主カ
          'KoTMSEX':    this.KoTMSEX,     //性別
          'KoTMNEX':    this.KoTMNEX,     //続柄
          'KoTMYU':     this.KoTMYU,      //住所〒
          'KoTMJYU1':   this.KoTMJYU1,    //住所1
          'KoTMJYU2':   this.KoTMJYU2,    //住所2
          'KoTMJYU3':   this.KoTMJYU3,    //住所3
          'KoTMTEL':    this.KoTMTEL,     //電話
          'KoTMTELK':   this.KoTMTELK,    //携帯
          'KoTMTMail':  this.KoTMTMail,   //mail
          'KoTMBIKO':   this.KoTMBIKO,    //備考
          'KoTSNM':     this.KoTSNM,      //支払者名
          'KoTSKNM':    this.KoTSKNM,     //支払者名カ
          'KoTSPNM':    this.KoTSPNM,     //入金方法
          'KoTSYU':     this.KoTSYU,      //住所〒
          'KoTSJYU1':   this.KoTSJYU1,    //住所1
          'KoTSJYU2':   this.KoTSJYU2,    //住所2
          'KoTSJYU3':   this.KoTSJYU3,    //住所3
          'KoTSTEL':    this.KoTSTEL,     //電話
          'KoTSTELK':   this.KoTSTELK,    //携帯
          'KoTSMail':   this.KoTSMail,    //mail
          'KoTSBIKO':   this.KoTSBIKO,    //備考
          'KoTSSKSA1':  this.KoTSSKSA1,   //会葬挨拶
          'KoSGICNM':   this.KoSGICNM,    //葬儀委員長名
          'KoSGICKN':   this.KoSGICKN,    //葬儀委員長名カ
          'KoSGICZK':   this.KoSGICZK,    //属性
          'KoTDESBS':   this.KoTDESBS,    //ご入院先
          'KoTIRTO':    this.KoTIRTO,     //移送先
          'KoTHRJK':    this.KoTHRJK,     //式場状況
          'KoTKKCD':    this.KoTKKCD,     //会館コード
          'KoTKKNM':    this.KoTKKNM,     //会館名
          'KoTHRCD':    this.KoTHRCD,     //ホールコード
          'KoTHRNM':    this.KoTHRNM,     //ホール名
          'KoTHRNMS':   this.KoTHRNMS,    //会館ホール略称
          'KoTTYSJK':   this.KoTTYSJK,    //通夜状況
          'KoTTYSJKJN': this.KoTTYSJKJN,  //通夜順
          'KoTTYSBS':   this.KoTTYSBS,    //通夜場所
          'KoTTYSSK':   this.KoTTYSSK,    //通夜司会
          'KoTTYSAS':   this.KoTTYSAS,    //通夜アシ
          'KoTSSOGJK':  this.KoTSSOGJK,   //告別状況
          'KoTSSOGJKJN':this.KoTSSOGJKJN, //告別順
          'KoTSSOSBS':  this.KoTSSOSBS,   //告別場所
          'KoTSSOSSK':  this.KoTSSOSSK,   //告別司会
          'KoTSSOGAS':  this.KoTSSOGAS,   //告別アシ
          'KoTHZJK':    this.KoTHZJK,     //本膳
          'KoTHZJKJN':  this.KoTHZJKJN,   //本膳順
          'KoTHZBS':    this.KoTHZBS,     //本膳場所
          'KoTHZNY':    this.KoTHZNY,     //枕飯・枕団子、供養膳
          'KoTSJKJN':   this.KoTSJKJN,    //火葬順
          'KoTSJCD':    this.KoTSJCD,     //火葬コード
          'KoTSJNM':    this.KoTSJNM,     //火葬名
          'KoTSJNMS':   this.KoTSJNMS,    //火葬名略称
          'KoTSJDS':    this.KoTSJDS,     //同行
          'KoTSJHS':    this.KoTSJHS,     //控室
          'KoTYOYK':    this.KoTYOYK,     //斎場予約
          'KoTNKJK':    this.KoTNKJK,     //納棺状況
          'KoTNKJKJN':  this.KoTNKJKJN,   //納棺順
          'KoTNKMK':    this.KoTNKMK,     //納棺メーク
          'KoTNKBS':    this.KoTNKBS,     //納棺場所
          'KoTISU1JK':  this.KoTISU1JK,   //移動(通夜)
          'KoTISU1JKJN':this.KoTISU1JKJN, //移動(通夜)順
          'KoTISU1FR':  this.KoTISU1FR,   //移動(通夜)場所
          'KoTISU1BS':  this.KoTISU1BS,   //移動(通夜)バス
          'KoTISU1HY':  this.KoTISU1HY,   //移動(通夜)ＴＸ
          'KoTISU2JK':  this.KoTISU2JK,   //移動(出棺)状況
          'KoTISU2JKJN':this.KoTISU2JKJN, //移動(出棺)順
          'KoTISU2FR':  this.KoTISU2FR,   //移動(出棺)場所
          'KoRICJK':    this.KoRICJK,     //霊柩状況
          'KoTISU2BS':  this.KoTISU2BS,   //移動(出棺)バス
          'KoTISU2HY':  this.KoTISU2HY,   //移動(出棺ＴＸ
          'KoTDRYJK':   this.KoTDRYJK,    //ドライ状況
          'KoREIJK':    this.KoREIJK,     //礼状状況
          'KoREIBK':    this.KoREIBK,     //礼状備考
          'KoRIHINM':   this.KoRIHINM,    //返礼品
          'KoTKUDAMJ':  this.KoTKUDAMJ,   //果物状況
          'KoTSSTRJK':  this.KoTSSTRJK,   //寺院状況
          'KoTSSTRNM':  this.KoTSSTRNM,   //寺院名
          'KoTSSTRBS':  this.KoTSSTRBS,   //菩提寺
          'KoTSSSONM':  this.KoTSSSONM,   //宗旨
          'KoTSSKMNM':  this.KoTSSKMNM,   //家紋・紋章
          'KoTSSTRHM':  this.KoTSSTRHM,   //戒(法)名
          'KoTSSTROF':  this.KoTSSTROF,   //お布施
          'KoTBIKO1':   this.KoTBIKO1,    //備考1
          'KoTBIKO2':   this.KoTBIKO2,    //備考2
          'KoTBIKO3':   this.KoTBIKO3,    //備考3
          'InstDt':     this.InstDt,      //作成日時
          'InstId':     this.InstId,      //作成者
          'UpdtDt':     this.UpdtDt,      //更新日時
          'UpdtId':     this.UpdtId       //更新者
 
        })
      this.dialogKAGET = false;
     },
    //会員コード検索
    async KAGETShow1() {
       const fomego = { SCVAL: this.INKoMCD,SRFLF:"01"};
        axios.post('/api/KainGET',fomego)
        .then(response => {
          this.KAITB = response.data;
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogLD = true;
    },
     //会員名称検索
     async KAGETShow2() {
        const fomego = { SCVAL: this.INKoMNM,SRFLF:"02"};
        axios.post('/api/KainGET',fomego)
        .then(response => {
          this.KAITB = response.data;
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogLD = true;
      },
      //会員カナ名称検索
     async KAGETShow3() {
        const fomego = { SCVAL: this.INKoMKNM,SRFLF:"03"};
        axios.post('/api/KainGET',fomego)
        .then(response => {
          this.KAITB = response.data;
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogLD = true;
      },
     CLINKoMCD(){
        this.INKoMCD = "";
     },
     CLINKoMNM(){
        this.INKoMNM = "";
     },
     CLINKoMKNM(){
        this.INKoMKNM = "";
     }, 
    showAlert2: function(item) {
      this.KoMCD          = item.KoMCD;       //会員コード
      this.KoMNM          = item.KoMNM;       //会員名称
      this.KoMKNM         = item.KoMKNM;      //会員名称(カナ）
      this.KoMKKBCD       = item.KoMKKBCD;    //会員区分
      this.KoMKKBNM       = item.KoMKKBNM;    //会員区分名
      this.KoMIKOMCD      = item.KoMIKOMCD;   //会員進捗状況コード
      this.KoMIKOMNM      = item.KoMIKOMNM;   //会員進捗状況名
      this.KoMJYKYCD      = item.KoMJYKYCD;   //状況コード
      this.KoMJYKYNM      = item.KoMJYKYNM;   //状況名
      this.KoMBSDTJ       = item.KoMBSDTJ;    //会員生年月日(和暦）
      this.KoMBSDT        = item.KoMBSDT;     //会員生年月日(西暦）
      this.KoMNR          = item.KoMNR;       //会員年齢
      this.KoMSX          = item.KoMSX;       //会員性別
      this.KoMBK          = item.KoMBK;       //備考
      this.KoMARAECD      = item.KoMARAECD;   //エリアコード
      this.KoMARAENM      = item.KoMARAENM;   //エリア名称
      this.KoMYU          = item.KoMYU;       //会員郵便番号
      this.KoMJYU1        = item.KoMJYU1;     //会員住所1
      this.KoMJYU2        = item.KoMJYU2;     //会員住所2
      this.KoMJYU3        = item.KoMJYU3;     //会員住所3
      this.KoMTEL         = item.KoMTEL;      //会員電話番号
      this.KoMTELK        = item.KoMTELK;     //会員携帯番号
      this.KoMMail        = item.KoMMail;     //会員Mail
      this.KoMNKDT        = item.KoMNKDT;     //入会日
      this.KoMKKDT        = item.KoMKKDT;     //休会日
      this.KoMTKDT        = item.KoMTKDT;     //退会日
      this.KoMKSDT        = item.KoMKSDT;     //更新日
      this.KoMKIDT        = item.KoMKIDT;     //会員期限
      this.KoMRRSS        = item.KoMRRSS;     //会員連絡先氏名
      this.KoMRRST        = item.KoMRRST;     //会員連絡先電話
      this.KoMRTLK        = item.KoMRTLK;     //会員連絡先携帯番号
      this.KoMRBK         = item.KoMRBK;      //会員連絡先備考
      this.KoMTRNM        = item.KoMTRNM;     //寺院
      this.KoMSSNM        = item.KoMSSNM;     //宗旨
      this.KoMSSBK        = item.KoMSSBK;     //引継備考
      this.KoAZHSK        = item.KoAZHSK;     //預り保証金
      this.KoAZHNK        = item.KoAZHNK;     //年会費
      this.KoAZHTK        = item.KoAZHTK;     //月会費
      this.KoAZBK         = item.KoAZBK;      //会費備考
      this.KoKBLOKCD      = item.KoKBLOKCD;   //部署コード
      this.KoMBLOKNM      = item.KoMBLOKNM;   //部署名称
      this.KoMNCD         = item.KoMNCD;      //営業担当コード
      this.KoMNNM         = item.KoMNNM;      //営業担当名称
      this.KoThCD         = item.KoThCD;      //顧客取引先コード
      this.KoThNM         = item.KoThNM;      //顧客取引先名称
      this.KoTCD          = item.KoTCD;       //顧客提携区分コード
      this.KoTNM          = item.KoTNM;       //顧客提携区分名称
      this.KoTPLCD        = item.KoTPLCD;     //プランコード
      this.KoTPLNM        = item.KoTPLNM;     //プラン名称
      this.KoTPLGK        = item.KoTPLGK;     //プラン金額
      this.KoTURSMST      = item.KoTURSMST;   //祭事標準税売上(税抜)
      this.KoTURSMKG      = item.KoTURSMKG;   //祭事軽減税売上(税抜)
      this.KoTURSMHK      = item.KoTURSMHK;   //祭事非課税売上(税抜)
      this.KoTSNEBST      = item.KoTSNEBST;   //祭事標準税値引
      this.KoTSNEBKG      = item.KoTSNEBKG;   //祭事軽減税値引
      this.KoTSNEBHK      = item.KoTSNEBHK;   //祭事非課税値引
      this.KoTSKAZST      = item.KoTSKAZST;   //祭事標準税対象額計
      this.KoTSKAZKG      = item.KoTSKAZKG;   //祭事軽減税対象額計
      this.KoTSKAZHK      = item.KoTSKAZHK;   //祭事非課税対象額計
      this.KoTSSYOST      = item.KoTSSYOST;   //祭事標準税消費税
      this.KoTSSYOKG      = item.KoTSSYOKG;   //祭事軽減税消費税
      this.KoTSURKGK      = item.KoTSURKGK;   //祭事売上(税込み)
      this.KoTURTMST      = item.KoTURTMST;   //立替標準税売上(税抜)
      this.KoTURTMKG      = item.KoTURTMKG;   //立替軽減税売上(税抜)
      this.KoTURTMHK      = item.KoTURTMHK;   //立替非課税売上(税抜)
      this.KoTTNEBST      = item.KoTTNEBST;   //立替標準税値引
      this.KoTTNEBKG      = item.KoTTNEBKG;   //立替軽減税値引
      this.KoTTNEBHK      = item.KoTTNEBHK;   //立替非課税値引
      this.KoTTKAZST      = item.KoTTKAZST;   //立替標準税対象額計
      this.KoTTKAZKG      = item.KoTTKAZKG;   //立替軽減税対象額計
      this.KoTTKAZHK      = item.KoTTKAZHK;   //立替非課税対象額計
      this.KoTTSYOST      = item.KoTTSYOST;   //立替標準税消費税
      this.KoTTSYOKG      = item.KoTTSYOKG;   //立替軽減税消費税
      this.KoTTURKGK      = item.KoTTURKGK;   //立替売上(税込み)
      this.KoDMKB         = item.KoDMKB;      //DM区分
      this.KoTMOKE        = item.KoTMOKE;     //喪家
      this.KoTTNM         = item.KoTTNM;      //ご心配な方名
      this.KoTTKNM        = item.KoTTKNM;     //ご心配な方カ
      this.KoTTSEX        = item.KoTTSEX;     //性別
      this.KoTTBSDTJ      = item.KoTTBSDTJ;   //性別
      this.KoTTEG         = item.KoTTEG;      //年齢
      this.KoTTNEX        = item.KoTTNEX;     //続柄
      this.KoTTYU         = item.KoTTYU;      //住所〒
      this.KoTTJYU1       = item.KoTTJYU1;    //住所1
      this.KoTTJYU2       = item.KoTTJYU2;    //住所2
      this.KoTTJYU3       = item.KoTTJYU3;    //住所3
      this.KoTTTE         = item.KoTTTE;      //電話
      this.KoTMNM         = item.KoTMNM;      //喪主名
      this.KoTMKNM        = item.KoTMKNM;     //喪主カ
      this.KoTMSEX        = item.KoTMSEX;     //性別
      this.KoTMNEX        = item.KoTMNEX;     //続柄
      this.KoTMYU         = item.KoTMYU;      //住所〒
      this.KoTMJYU1       = item.KoTMJYU1;    //住所1
      this.KoTMJYU2       = item.KoTMJYU2;    //住所2
      this.KoTMJYU3       = item.KoTMJYU3;    //住所3
      this.KoTMTEL        = item.KoTMTEL;     //電話
      this.KoTMTELK       = item.KoTMTELK;    //携帯
      this.KoTMTMail      = item.KoTMTMail;   //mail
      this.KoTMBIKO       = item.KoTMBIKO;    //備考
      this.KoTSNM         = item.KoTSNM;      //支払者名
      this.KoTSKNM        = item.KoTSKNM;     //支払者名カ
      this.KoTSPNM        = item.KoTSPNM;     //入金方法
      this.KoTSYU         = item.KoTSYU;      //住所〒
      this.KoTSJYU1       = item.KoTSJYU1;    //住所1
      this.KoTSJYU2       = item.KoTSJYU2;    //住所2
      this.KoTSJYU3       = item.KoTSJYU3;    //住所3
      this.KoTSTEL        = item.KoTSTEL;     //電話
      this.KoTSTELK       = item.KoTSTELK;    //携帯
      this.KoTSMail       = item.KoTSMail;    //mail
      this.KoTSBIKO       = item.KoTSBIKO;    //備考
      this.KoTSSKSA1      = item.KoTSSKSA1;   //会葬挨拶
      this.KoSGICNM       = item.KoSGICNM;    //葬儀委員長名
      this.KoSGICKN       = item.KoSGICKN;    //葬儀委員長名カ
      this.KoSGICZK       = item.KoSGICZK;    //属性
      this.KoTDESBS       = item.KoTDESBS;    //ご入院先
      this.KoTIRTO        = item.KoTIRTO;     //移送先
      this.KoTHRJK        = item.KoTHRJK;     //式場状況
      this.KoTKKCD        = item.KoTKKCD;     //会館コード
      this.KoTKKNM        = item.KoTKKNM;     //会館名
      this.KoTHRCD        = item.KoTHRCD;     //ホールコード
      this.KoTHRNM        = item.KoTHRNM;     //ホール名
      this.KoTHRNMS       = item.KoTHRNMS;    //会館ホール略称
      this.KoTTYSJK       = item.KoTTYSJK;    //通夜状況
      this.KoTTYSJKJN     = item.KoTTYSJKJN;  //通夜順
      this.KoTTYSBS       = item.KoTTYSBS;    //通夜場所
      this.KoTTYSSK       = item.KoTTYSSK;    //通夜司会
      this.KoTTYSAS       = item.KoTTYSAS;    //通夜アシ
      this.KoTSSOGJK      = item.KoTSSOGJK;   //告別状況
      this.KoTSSOGJKJN    = item.KoTSSOGJKJN; //告別順
      this.KoTSSOSBS      = item.KoTSSOSBS;   //告別場所
      this.KoTSSOSSK      = item.KoTSSOSSK;   //告別司会
      this.KoTSSOGAS      = item.KoTSSOGAS;   //告別アシ
      this.KoTHZJK        = item.KoTHZJK;     //本膳
      this.KoTHZJKJN      = item.KoTHZJKJN;   //本膳順
      this.KoTHZBS        = item.KoTHZBS;     //本膳場所
      this.KoTHZNY        = item.KoTHZNY;     //枕飯・枕団子、供養膳
      this.KoTSJKJN       = item.KoTSJKJN;    //火葬順
      this.KoTSJCD        = item.KoTSJCD;     //火葬コード
      this.KoTSJNM        = item.KoTSJNM;     //火葬名
      this.KoTSJNMS       = item.KoTSJNMS;    //火葬名略称
      this.KoTSJDS        = item.KoTSJDS;     //同行
      this.KoTSJHS        = item.KoTSJHS;     //控室
      this.KoTYOYK        = item.KoTYOYK;     //斎場予約
      this.KoTNKJK        = item.KoTNKJK;     //納棺状況
      this.KoTNKJKJN      = item.KoTNKJKJN;   //納棺順
      this.KoTNKMK        = item.KoTNKMK;     //納棺メーク
      this.KoTNKBS        = item.KoTNKBS;     //納棺場所
      this.KoTISU1JK      = item.KoTISU1JK;   //移動(通夜)
      this.KoTISU1JKJN    = item.KoTISU1JKJN; //移動(通夜)順
      this.KoTISU1FR      = item.KoTISU1FR;   //移動(通夜)場所
      this.KoTISU1BS      = item.KoTISU1BS;   //移動(通夜)バス
      this.KoTISU1HY      = item.KoTISU1HY;   //移動(通夜)ＴＸ
      this.KoTISU2JK      = item.KoTISU2JK;   //移動(出棺)状況
      this.KoTISU2JKJN    = item.KoTISU2JKJN; //移動(出棺)順
      this.KoTISU2FR      = item.KoTISU2FR;   //移動(出棺)場所
      this.KoRICJK        = item.KoRICJK;     //霊柩状況
      this.KoTISU2BS      = item.KoTISU2BS;   //移動(出棺)バス
      this.KoTISU2HY      = item.KoTISU2HY;   //移動(出棺ＴＸ
      this.KoTDRYJK       = item.KoTDRYJK;    //ドライ状況
      this.KoREIJK        = item.KoREIJK;     //礼状状況
      this.KoREIBK        = item.KoREIBK;     //礼状備考
      this.KoRIHINM       = item.KoRIHINM;    //返礼品
      this.KoTKUDAMJ      = item.KoTKUDAMJ;   //果物状況
      this.KoTSSTRJK      = item.KoTSSTRJK;   //寺院状況
      this.KoTSSTRNM      = item.KoTSSTRNM;   //寺院名
      this.KoTSSTRBS      = item.KoTSSTRBS;   //菩提寺
      this.KoTSSSONM      = item.KoTSSSONM;   //宗旨
      this.KoTSSKMNM      = item.KoTSSKMNM;   //家紋・紋章
      this.KoTSSTRHM      = item.KoTSSTRHM;   //戒(法)名
      this.KoTSSTROF      = item.KoTSSTROF;   //お布施
      this.KoTBIKO1       = item.KoTBIKO1;    //備考1
      this.KoTBIKO2       = item.KoTBIKO2;    //備考2
      this.KoTBIKO3       = item.KoTBIKO3;    //備考3
      this.InstDt         = item.InstDt;      //作成日時
      this.InstId         = item.InstId;      //作成者
      this.UpdtDt         = item.UpdtDt;      //更新日時
      this.UpdtId         = item.UpdtId;      //更新者
     },
  },
 }
</script>