<template>
 <v-dialog v-model="dialogIN">
    <div class="row">
    <div class="col-xs-12">
       <v-progress-circular :size="250" :width="10" color="purple" indeterminate/>
    </div>
    </div>
  </v-dialog>
</template>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import XlsxPopulate from 'xlsx-populate'; //XlsxPopulate
//import { getExtkumt } from "../graphql/queries";            //供物Excelマスタ
//import { getSmkblck } from "../graphql/queries";            //部署マスタ
//const Promise = XlsxPopulate.Promise;
//受入ファイルの種類
export default {
	data() {
	return {
      dialogIN:false,
      EXKMTB: [],
      PARAMT: [],
        //初期設定
        KgyCd   :"",    //初期設定企業コード
        KgyNM   :"",    //初期設定企業名称
        BLCKCD  :"",    //初期設定部署コード
        BLCKNM  :"",    //初期設定部署名称
        SynCd   :"",    //初期設定社員名称
        SynNm   :"",    //初期設定社員名称
        TANID   :"",    //初期設定端末ID
        DENNO   :"0",   //初期設定伝票番号
        GNZHJ   :"",    //初期設定現在標準税
        GNZKG   :"",    //初期設定現在軽減税
        KGZDT   :"",    //初期設定消費税切替日
        KGZHJ   :"",    //初期設定次期標準税
        KGZKG   :"",    //初期設定次期軽減税
        KoTURDT :"",    //売上日
        KoThCD  :"",    //提携コード
        KoThNM  :"",    //提携名称
        KoTCD   :"",    //提携取引先コード
        KoTNM   :"",    //提携取引先名称
        ksGNZHJ :"",    //計算標準税
        ksGNZKG :"",    //計算軽減税
        //供物
        KUMDNNO :'',	//伝票番号
        KUMUKNO :'',	//受付番号
        KUMNODT :'',	//売上日
        KUUTDT  :'',	//受付日
        KUMBLCD :'',	//部署コード
        KUMBLNM :'',	//部署名称
        KUMTNCD :'',	//営業担当者コード
        KUMTNNM :'',	//営業担当名称
        KUMSOKE :'',	//葬家
        KUMKNM  :'',	//故人
        KUMMNM  :'',	//喪主
        KUTOUKE :'',	//ご当家請求
        KUMSKCD :'',	//請求先コード
        KUMSNM1 :'',	//請求先漢字1
        KUMSNM2 :'',	//請求先漢字2
        KUMTEL  :'',	//電話番号
        KUMTELK :'',	//携帯番号
        KUMFAX	:'',	//ファックス
        KUMSYU	:'',	//請求先郵便番号
        KUMSJY1	:'',	//請求先住所1
        KUMSJY2	:'',	//請求先住所2
        KUMSJY3	:'',	//請求先住所3
        KUMYSYU	:'',	//送付先郵便番号
        KUMYSJY1:'',	//送付先住所1
        KUMYSJY2:'',	//送付先住所2
        KUMYSJY3:'',	//送付先住所3
        KUMNBI	:'',	//納品日
        KUMNTM	:'',	//納品時間
        KUMNBS	:'',	//納品場所
        KUMSYCD	:'',	//商品コード
        KUMSYNM	:'',	//商品名称
        KUMSYUG	:'',	//売上単価
        KUMTNI	:'',	//単位
        KUMSYKB	:'',	//売上消費税区分
        KUMSYSG	:'',	//仕入単価
        KUMSuu	:'',	//売上数量
        KUMZRGK	:'',	//売上金額(税抜)
        KUMNbk	:'',	//売上値引(税抜)
        KUMURI	:'',	//売上額値引後(税抜)
        KUMSZGK	:'',	//売上消費税計
        KUMUAGK	:'',	//売上合計(税込)
        KUMNKG	:'',	//売掛入金金額
        KUMZNG	:'',	//売掛残高
        KUMCHDT	:'',	//発注完了日
        KUMCHTM	:'',	//発注完了時間
        KUMSCSDT:'',	//請求完了日
        KUMCSTM	:'',	//請求完了時間
        KUMTKCD	:'',	//提携先コード
        KUMTKNM	:'',	//提携先名称
        KUMTKWR	:'',	//割引率
        KUMNA11	:'',	//名札11
        KUMNA12	:'',	//名札12
        KUMNA21	:'',	//名札21
        KUMNA22	:'',	//名札22
        URZHJ      :'',	//標準税売上(税抜)
        URZKG      :'',	//軽減税売上(税抜)
        URZHK      :'',	//非課税売上(税抜)
        NEBHJ      :'',	//標準税値引
        NEBKG      :'',	//軽減税値引
        NEBHK      :'',	//非課税値引
        TSGHJ      :'',	//標準税対象額計
        TSGKG      :'',	//軽減税対象額計
        TSGHK      :'',	//非課税対象額計
        SHZHJ      :'',	//標準税消費税
        SHZKG      :'',	//軽減税消費税
        InstDt		:'',	//作成日時
        InstId		:'',	//作成者
        //部署マスタ
        BL_BLCKCD	:'', //	部署コード
        BL_BLCKNM	:'', //	部署名称
        BL_BLCKTEL	:'', //	TEL
        BL_BLCKFAX	:'', //	FAX
        BL_BLCKMAIL:'', //	MAIL
        BL_BLCKSK1	:'', //	請求先代表者
        BL_BLCKSK5	:'', //	請求口座名義
        BL_BLCKSK4	:'', //	請求取引銀行名
        BL_BLCKSK6	:'', //	口座
        BL_BLCKSK2	:'', //	請求住所
        BL_BLCKSK3	:'', //	請求電話
        BL_BLCKYB1	:'', //	郵便番号1
        BL_BLCKYB2	:'', //	郵便番号2
        BL_BLCKJY1	:'', //	住所1
        BL_BLCKJY2	:'', //	住所2
        BL_BLCKJY3	:'', //	住所3
        //EXCEL 供物
        PR_KUMDNNO  :'',	//伝票番号
        PR_KUMUKNO  :'',	//受付番号
        PR_KUMNODT  :'',	//売上日
        PR_KUUTDT   :'',	//受付日
        PR_KUMSKSDT :'',	//印刷日
        PR_KUMSKSTM :'',	//印刷時間
        PR_BLCKCD   :'',	//営業所コード
        PR_BLCKNM   :'',	//営業所名
        PR_BLCKYB1  :'',	//郵便番号1
        PR_BLCKYB2  :'',	//郵便番号2
        PR_BLCKJY1  :'',	//住所1
        PR_BLCKJY2  :'',	//住所2
        PR_BLCKJY3  :'',	//住所3
        PR_BLCKTEL  :'',	//TEL
        PR_BLCKFAX  :'',	//FAX
        PR_BLCKMAIL :'',	//MAIL
        PR_BLCKSK1  :'',	//請求先代表者
        PR_BLCKSK2  :'',	//請求住所
        PR_BLCKSK3  :'',	//請求電話
        PR_BLCKSK4  :'',	//請求取引銀行名
        PR_BLCKSK5  :'',	//請求口座名義
        PR_BLCKSK6  :'',	//口座
        PR_KUMSOKE  :'',	//葬家
        PR_KUMKNM   :'',	//故人
        PR_KUMMNM   :'',	//喪主
        PR_KUMBLCD  :'',	//部署コード
        PR_KUMBLNM  :'',	//部署名称
        PR_KUMTNCD  :'',	//営業担当コード
        PR_KUMTNNM  :'',	//営業担当名称
        PR_KUMSKCD  :'',	//請求先コード
        PR_KUMSNM1  :'',	//請求先漢字1
        PR_KUMSNM2  :'',	//請求先漢字2
        PR_KUMSYU   :'',	//請求先郵便番号
        PR_KUMSJY1  :'',	//住所1
        PR_KUMSJY2  :'',	//住所2
        PR_KUMSJY3  :'',	//住所3
        PR_KUMTEL   :'',	//℡
        PR_KUMTELK  :'',	//携帯
        PR_KUMFAX   :'',	//ファックス
        PR_KUMNBI   :'',	//納品日
        PR_KUMNTM   :'',	//納品時間
        PR_KUMNBS   :'',	//納品場所
        PR_KUMSYCD  :'',	//商品コード
        PR_KUMSYNM  :'',	//商品名称
        PR_KUMSYUG  :'',	//売上単価
        PR_KUMTNI   :'',	//単位
        PR_KUMSYKB  :'',	//売上消費税区分
        PR_KUMZRGK  :'',	//売上金額(税抜)
        PR_KUMSuu   :'',	//売上数量
        PR_KUMNbk   :'',	//売上値引(税抜)
        PR_KUMURI   :'',	//売上額値引後(税抜)
        PR_KUMSZGK  :'',	//売上消費税計
        PR_KUMUAGK  :'',	//売上合計(税込)
        PR_KUMNKG   :'',	//売掛入金金額
        PR_KUMZNG   :'',	//売掛残高
        PR_KUMTKCD  :'',	//提携先コード
        PR_KUMTKNM  :'',	//提携先名称
        PR_KUMTKWR  :'',	//割引率
        PR_KUMNA11  :'',	//名札11
        PR_KUMNA12  :'',	//名札12
        PR_KUMNA21  :'',	//名札21
        PR_KUMNA22  :'',	//名札22
        PR_URZHJ    :'',	//標準税売上(税抜)
        PR_URZKG    :'',	//軽減税売上(税抜)
        PR_URZHK    :'',	//非課税売上(税抜)
        PR_NEBHJ    :'',	//標準税値引
        PR_NEBHK    :'',	//非課税値引
        PR_NEBKG    :'',	//軽減税値引
        PR_TSGHJ    :'',	//標準税対象額計
        PR_TSGKG    :'',	//軽減税対象額計
        PR_TSGHK	:'',	//非課税対象額計
        PR_SHZHJ	:'',	//標準税消費税
        PR_SHZKG	:'',	//軽減税消費税
        outexcel:"立替請求書.xlsx",
		Koteifile:"https://cors-anywhere.herokuapp.com/https://bslg.xsrv.jp/excel/供物請求書.xlsx",
		}; 
	},
	methods: {
        open() {
        if(localStorage.SOGI_KgyCd) this.KgyCd = localStorage.SOGI_KgyCd;              //初期設定企業コード
        if(localStorage.SOGI_KgyNM) this.KgyNM = localStorage.SOGI_KgyNM;              //初期設定企業名称
        if(localStorage.SOGI_BLCKCD) this.BLCKCD = localStorage.SOGI_BLCKCD;           //初期設定部署コード
        if(localStorage.SOGI_BLCKNM) this.BLCKNM = localStorage.SOGI_BLCKNM;           //初期設定部署名称
        if(localStorage.SOGI_SynCd) this.SynCd = localStorage.SOGI_SynCd;              //初期設定社員名称
        if(localStorage.SOGI_SynNm) this.SynNm = localStorage.SOGI_SynNm;              //初期設定社員名称
        if(localStorage.SOGI_TANID) this.TANID = localStorage.SOGI_TANID;              //初期設定端末ID
        if(localStorage.SOGI_DENNO) this.DENNO = localStorage.SOGI_SGDENNO;            //初期設定伝票番号
        if(localStorage.SOGI_GNZHJ) this.GNZHJ = localStorage.SOGI_GNZHJ;              //初期設定現在標準税
        if(localStorage.SOGI_GNZKG) this.GNZKG = localStorage.SOGI_GNZKG;              //初期設定現在軽減税
        if(localStorage.SOGI_KGZDT) this.KGZDT = localStorage.SOGI_KGZDT;              //初期設定消費税切替日
        if(localStorage.SOGI_KGZHJ) this.KGZHJ = localStorage.SOGI_KGZHJ;              //初期設定次期標準税
        if(localStorage.SOGI_KGZKG) this.KGZKG = localStorage.SOGI_KGZKG;              //初期設定次期軽減税
        if(localStorage.SOGI_KoTSSOGDT)this.KoTSSOGDT   = localStorage.SOGI_KoTSSOGDT; //葬儀日
        if(localStorage.SOGI_SGDENNO) this.PS_KoTNO     = localStorage.SOGI_SGDENNO;   //伝票番号

        if(localStorage.SOGI_KUMDNNO) this.KUMDNNO  = localStorage.SOGI_KUMDNNO;       //伝票番号
        if(localStorage.SOGI_KUMUKNO) this.KUMUKNO  = localStorage.SOGI_KUMUKNO;       //受付番号
        if(localStorage.SOGI_KUMNODT) this.KUMNODT  = localStorage.SOGI_KUMNODT;       //売上日
        if(localStorage.SOGI_KUUTDT)  this.KUUTDT   = localStorage.SOGI_KUUTDT;        //受付日
        if(localStorage.SOGI_KUMBLCD) this.KUMBLCD  = localStorage.SOGI_KUMBLCD;       //部署コード
        if(localStorage.SOGI_KUMBLNM) this.KUMBLNM  = localStorage.SOGI_KUMBLNM;       //部署名称
        if(localStorage.SOGI_KUMTNCD) this.KUMTNCD  = localStorage.SOGI_KUMTNCD;       //営業担当者コード
        if(localStorage.SOGI_KUMTNNM) this.KUMTNNM  = localStorage.SOGI_KUMTNNM;       //営業担当名称
        if(localStorage.SOGI_KUMSOKE) this.KUMSOKE  = localStorage.SOGI_KUMSOKE;       //葬家
        if(localStorage.SOGI_KUMKNM)  this.KUMKNM   = localStorage.SOGI_KUMKNM;        //故人
        if(localStorage.SOGI_KUMMNM)  this.KUMMNM   = localStorage.SOGI_KUMMNM;        //喪主
        if(localStorage.SOGI_KUMMNM)  this.KUMMNM   = localStorage.SOGI_KUMMNM;        //喪主
        if(localStorage.SOGI_KUTOUKE) this.KUTOUKE  = localStorage.SOGI_KUTOUKE;       //ご当家請求
        if(localStorage.SOGI_KUMSKCD) this.KUMSKCD  = localStorage.SOGI_KUMSKCD;       //請求先コード
        if(localStorage.SOGI_KUMSNM1) this.KUMSNM1  = localStorage.SOGI_KUMSNM1;       //請求先漢字1
        if(localStorage.SOGI_KUMSNM2) this.KUMSNM2  = localStorage.SOGI_KUMSNM2;       //請求先漢字2
        if(localStorage.SOGI_KUMTEL ) this.KUMTEL   = localStorage.SOGI_KUMTEL;        //電話番号
        if(localStorage.SOGI_KUMTELK) this.KUMTELK  = localStorage.SOGI_KUMTELK;       //携帯番号
        if(localStorage.SOGI_KUMFAX ) this.KUMFAX   = localStorage.SOGI_KUMFAX;        //ファックス
        if(localStorage.SOGI_KUMSYU ) this.KUMSYU   = localStorage.SOGI_KUMSYU;        //請求先郵便番号
        if(localStorage.SOGI_KUMSJY1) this.KUMSJY1  = localStorage.SOGI_KUMSJY1;       //請求先住所1
        if(localStorage.SOGI_KUMSJY2) this.KUMSJY2  = localStorage.SOGI_KUMSJY2;       //請求先住所2
        if(localStorage.SOGI_KUMSJY3) this.KUMSJY3  = localStorage.SOGI_KUMSJY3;       //請求先住所3
        if(localStorage.SOGI_KUMYSYU) this.KUMYSYU  = localStorage.SOGI_KUMYSYU;       //送付先郵便番号
        if(localStorage.SOGI_KUMYSJY1)this.KUMYSJY1 = localStorage.SOGI_KUMYSJY1;      //送付先住所1
        if(localStorage.SOGI_KUMYSJY2)this.KUMYSJY2 = localStorage.SOGI_KUMYSJY2;      //送付先住所2
        if(localStorage.SOGI_KUMYSJY3)this.KUMYSJY3 = localStorage.SOGI_KUMYSJY3;      //送付先住所3
        if(localStorage.SOGI_KUMNBI ) this.KUMNBI   = localStorage.SOGI_KUMNBI;        //納品日
        if(localStorage.SOGI_KUMNTM ) this.KUMNTM   = localStorage.SOGI_KUMNTM;        //納品時間
        if(localStorage.SOGI_KUMNBS ) this.KUMNBS   = localStorage.SOGI_KUMNBS;        //納品場所
        if(localStorage.SOGI_KUMSYCD) this.KUMSYCD  = localStorage.SOGI_KUMSYCD;       //商品コード
        if(localStorage.SOGI_KUMSYNM) this.KUMSYNM  = localStorage.SOGI_KUMSYNM;       //商品名称
        if(localStorage.SOGI_KUMSYUG) this.KUMSYUG  = localStorage.SOGI_KUMSYUG;       //売上単価
        if(localStorage.SOGI_KUMTNI ) this.KUMTNI   = localStorage.SOGI_KUMTNI;        //単位
        if(localStorage.SOGI_KUMSYKB) this.KUMSYKB  = localStorage.SOGI_KUMSYKB;       //売上消費税区分
        if(localStorage.SOGI_KUMSYSG) this.KUMSYSG  = localStorage.SOGI_KUMSYSG;       //仕入単価
        if(localStorage.SOGI_KUMSuu ) this.KUMSuu   = localStorage.SOGI_KUMSuu;       //売上数量
        if(localStorage.SOGI_KUMZRGK) this.KUMZRGK  = localStorage.SOGI_KUMZRGK;       //売上金額(税抜)
        if(localStorage.SOGI_KUMNbk ) this.KUMNbk   = localStorage.SOGI_KUMNbk;       //売上値引(税抜)
        if(localStorage.SOGI_KUMURI ) this.KUMURI   = localStorage.SOGI_KUMURI;       //売上額値引後(税抜)
        if(localStorage.SOGI_KUMSZGK) this.KUMSZGK  = localStorage.SOGI_KUMSZGK;       //売上消費税計
        if(localStorage.SOGI_KUMUAGK) this.KUMUAGK  = localStorage.SOGI_KUMUAGK;       //売上合計(税込)
        if(localStorage.SOGI_KUMNKG ) this.KUMNKG   = localStorage.SOGI_KUMNKG;       //売掛入金金額
        if(localStorage.SOGI_KUMZNG ) this.KUMZNG   = localStorage.SOGI_KUMZNG;       //売掛残高
        if(localStorage.SOGI_KUMCHDT) this.KUMCHDT  = localStorage.SOGI_KUMCHDT;       //発注完了日
        if(localStorage.SOGI_KUMCHTM) this.KUMCHTM  = localStorage.SOGI_KUMCHTM;       //発注完了時間
        if(localStorage.SOGI_KUMSCSDT)this.KUMSCSDT = localStorage.SOGI_KUMSCSDT;       //請求完了日
        if(localStorage.SOGI_KUMCSTM) this.KUMCSTM  = localStorage.SOGI_KUMCSTM;       //請求完了時間
        if(localStorage.SOGI_KUMTKCD) this.KUMTKCD  = localStorage.SOGI_KUMTKCD;       //提携先コード
        if(localStorage.SOGI_KUMTKNM) this.KUMTKNM  = localStorage.SOGI_KUMTKNM;       //提携先名称
        if(localStorage.SOGI_KUMTKWR) this.KUMTKWR  = localStorage.SOGI_KUMTKWR;       //割引率
        if(localStorage.SOGI_KUMNA11) this.KUMNA11  = localStorage.SOGI_KUMNA11;       //名札11
        if(localStorage.SOGI_KUMNA12) this.KUMNA12  = localStorage.SOGI_KUMNA12;       //名札12
        if(localStorage.SOGI_KUMNA21) this.KUMNA21  = localStorage.SOGI_KUMNA21;       //名札21
        if(localStorage.SOGI_KUMNA22) this.KUMNA22  = localStorage.SOGI_KUMNA22;       //名札22
        if(localStorage.SOGI_URZHJ  ) this.URZHJ    = localStorage.SOGI_URZHJ;       //標準税売上(税抜)
        if(localStorage.SOGI_URZKG  ) this.URZKG    = localStorage.SOGI_URZKG;       //軽減税売上(税抜)
        if(localStorage.SOGI_URZHK  ) this.URZHK    = localStorage.SOGI_URZHK;       //非課税売上(税抜)
        if(localStorage.SOGI_NEBHJ  ) this.NEBHJ    = localStorage.SOGI_NEBHJ;       //標準税値引
        if(localStorage.SOGI_NEBKG  ) this.NEBKG    = localStorage.SOGI_NEBKG;       //軽減税値引
        if(localStorage.SOGI_NEBHK  ) this.NEBHK    = localStorage.SOGI_NEBHK;       //非課税値引
        if(localStorage.SOGI_TSGHJ  ) this.TSGHJ    = localStorage.SOGI_TSGHJ;       //標準税対象額計
        if(localStorage.SOGI_TSGKG  ) this.TSGKG    = localStorage.SOGI_TSGKG;       //軽減税対象額計
        if(localStorage.SOGI_TSGHK  ) this.TSGHK    = localStorage.SOGI_TSGHK;       //非課税対象額計
        if(localStorage.SOGI_SHZHJ  ) this.SHZHJ    = localStorage.SOGI_SHZHJ;       //標準税消費税
        if(localStorage.SOGI_SHZKG  ) this.SHZKG    = localStorage.SOGI_SHZKG;       //軽減税消費税
        
        this.EXTKUMTquery();
        this.dialogIN = true;
    },
    //-----------------------------------------------------------------
    //ブロックマスタの取得更新
    //-----------------------------------------------------------------
    async EXTKUMTquery() {
        //ブロックマスタ
        this.PARAMT.length = 0;
        const P_PARAMT = {zipCD: this.BLCKCD};
        axios.post('/api/SMKBLCKGET',P_PARAMT)
           .then(res => {
            this.PARAMT = res.data;
            this.BL_BLCKCD    = this.PARAMT[0].BLCKCD;    //伝部署コード
            this.BL_BLCKNM    = this.PARAMT[0].BLCKNM;    //部署名称
            this.BL_BLCKTEL   = this.PARAMT[0].BLCKTEL;   //TEL
            this.BL_BLCKFAX   = this.PARAMT[0].BLCKFAX;   //FAX
            this.BL_BLCKMAIL  = this.PARAMT[0].BLCKMAIL;  //MAIL
            this.BL_BLCKSK1   = this.PARAMT[0].BLCKSK1;   //請求先代表者
            this.BL_BLCKSK5   = this.PARAMT[0].BLCKSK5;   //請求口座名義
            this.BL_BLCKSK4   = this.PARAMT[0].BLCKSK4;   //請求取引銀行名
            this.BL_BLCKSK6   = this.PARAMT[0].BLCKSK6;   //口座
            this.BL_BLCKSK2   = this.PARAMT[0].BLCKSK2;   //請求住所
            this.BL_BLCKSK3   = this.PARAMT[0].BLCKSK3;   //請求電話
            this.BL_BLCKYB1   = this.PARAMT[0].BLCKYB1;   //郵便番号1
            this.BL_BLCKYB2   = this.PARAMT[0].BLCKYB2;   //郵便番号2
            this.BL_BLCKJY1   = this.PARAMT[0].BLCKJY1;   //住所1
            this.BL_BLCKJY2   = this.PARAMT[0].BLCKJY2;   //住所2
            this.BL_BLCKJY3   = this.PARAMT[0].BLCKJY3;   //住所3
        })
 
        //供物請求書エクセル
        this.PARAMT.length = 0;
        axios.post('/api/EXTKUMTGet',P_PARAMT)
          .then(res => {
          this.PARAMT = res.data;
            this.PR_KUMDNNO = this.PARAMT[0].KUMDNNO;    //伝票番号
            this.PR_KUMUKNO	= this.PARAMT[0].KUMUKNO;    //受付番号
            this.PR_KUMNODT	= this.PARAMT[0].KUMNODT;    //売上日
            this.PR_KUUTDT	= this.PARAMT[0].KUUTDT;     //受付日
            this.PR_KUMSKSDT= this.PARAMT[0].KUMSKSDT;   //印刷日
            this.PR_KUMSKSTM= this.PARAMT[0].KUMSKSTM;   //印刷時間
            this.PR_BLCKCD	= this.PARAMT[0].BLCKCD;     //営業所コード
            this.PR_BLCKNM	= this.PARAMT[0].BLCKNM;     //営業所名
            this.PR_BLCKYB1	= this.PARAMT[0].BLCKYB1;    //郵便番号1
            this.PR_BLCKYB2	= this.PARAMT[0].BLCKYB2;    //郵便番号2
            this.PR_BLCKJY1	= this.PARAMT[0].BLCKJY1;    //住所1
            this.PR_BLCKJY2	= this.PARAMT[0].BLCKJY2;    //住所2
            this.PR_BLCKJY3	= this.PARAMT[0].BLCKJY3;    //住所3
            this.PR_BLCKTEL	= this.PARAMT[0].BLCKTEL;    //TEL
            this.PR_BLCKFAX	= this.PARAMT[0].BLCKFAX;    //FAX
            this.PR_BLCKMAIL= this.PARAMT[0].BLCKMAIL;   //MAIL
            this.PR_BLCKSK1	= this.PARAMT[0].BLCKSK1;    //請求先代表者
            this.PR_BLCKSK2	= this.PARAMT[0].BLCKSK2;    //請求住所
            this.PR_BLCKSK3	= this.PARAMT[0].BLCKSK3;    //請求電話
            this.PR_BLCKSK4	= this.PARAMT[0].BLCKSK4;    //請求取引銀行名
            this.PR_BLCKSK5	= this.PARAMT[0].BLCKSK5;    //請求口座名義
            this.PR_BLCKSK6	= this.PARAMT[0].BLCKSK6;    //口座
            this.PR_KUMSOKE	= this.PARAMT[0].KUMSOKE;    //葬家
            this.PR_KUMKNM	= this.PARAMT[0].KUMKNM;     //故人
            this.PR_KUMMNM	= this.PARAMT[0].KUMMNM;     //喪主
            this.PR_KUMBLCD	= this.PARAMT[0].KUMBLCD;    //部署コード
            this.PR_KUMBLNM	= this.PARAMT[0].KUMBLNM;    //部署名称
            this.PR_KUMTNCD	= this.PARAMT[0].KUMTNCD;    //営業担当コード
            this.PR_KUMTNNM	= this.PARAMT[0].KUMTNNM;    //営業担当名称
            this.PR_KUMSKCD	= this.PARAMT[0].KUMSKCD;    //請求先コード
            this.PR_KUMSNM1	= this.PARAMT[0].KUMSNM1;    //請求先漢字1
            this.PR_KUMSNM2	= this.PARAMT[0].KUMSNM2;    //請求先漢字2
            this.PR_KUMSYU	= this.PARAMT[0].KUMSYU;     //請求先郵便番号
            this.PR_KUMSJY1	= this.PARAMT[0].KUMSJY1;    //住所1
            this.PR_KUMSJY2	= this.PARAMT[0].KUMSJY2;    //住所2
            this.PR_KUMSJY3	= this.PARAMT[0].KUMSJY3;    //住所3
            this.PR_KUMTEL	= this.PARAMT[0].KUMTEL;     //℡
            this.PR_KUMTELK	= this.PARAMT[0].KUMTELK;    //携帯
            this.PR_KUMFAX	= this.PARAMT[0].KUMFAX;     //ファックス
            this.PR_KUMNBI	= this.PARAMT[0].KUMNBI;     //納品日
            this.PR_KUMNTM	= this.PARAMT[0].KUMNTM;     //納品時間
            this.PR_KUMNBS	= this.PARAMT[0].KUMNBS;     //納品場所
            this.PR_KUMSYCD	= this.PARAMT[0].KUMSYCD;    //商品コード
            this.PR_KUMSYNM	= this.PARAMT[0].KUMSYNM;    //商品名称
            this.PR_KUMSYUG	= this.PARAMT[0].KUMSYUG;    //売上単価
            this.PR_KUMTNI	= this.PARAMT[0].KUMTNI;     //単位
            this.PR_KUMSYKB	= this.PARAMT[0].KUMSYKB;    //売上消費税区分
            this.PR_KUMZRGK	= this.PARAMT[0].KUMZRGK;    //売上金額(税抜)
            this.PR_KUMSuu	= this.PARAMT[0].KUMSuu;     //売上数量
            this.PR_KUMNbk	= this.PARAMT[0].KUMNbk;     //売上値引(税抜)
            this.PR_KUMURI	= this.PARAMT[0].KUMURI;     //売上額値引後(税抜)
            this.PR_KUMSZGK	= this.PARAMT[0].KUMSZGK;    //売上消費税計
            this.PR_KUMUAGK	= this.PARAMT[0].KUMUAGK;    //売上合計(税込)
            this.PR_KUMNKG	= this.PARAMT[0].KUMNKG;     //売掛入金金額
            this.PR_KUMZNG	= this.PARAMT[0].KUMZNG;     //売掛残高
            this.PR_KUMTKCD	= this.PARAMT[0].KUMTKCD;    //提携先コード
            this.PR_KUMTKNM	= this.PARAMT[0].KUMTKNM;    //提携先名称
            this.PR_KUMTKWR	= this.PARAMT[0].KUMTKWR;    //割引率
            this.PR_KUMNA11	= this.PARAMT[0].KUMNA11;    //名札11
            this.PR_KUMNA12	= this.PARAMT[0].KUMNA12;    //名札12
            this.PR_KUMNA21	= this.PARAMT[0].KUMNA21;    //名札21
            this.PR_KUMNA22	= this.PARAMT[0].KUMNA22;    //名札22
            this.PR_URZHJ   = this.PARAMT[0].URZHJ;      //標準税売上(税抜)
            this.PR_URZKG   = this.PARAMT[0].URZKG;      //軽減税売上(税抜)
            this.PR_URZHK   = this.PARAMT[0].URZHK;      //非課税売上(税抜)
            this.PR_NEBHJ   = this.PARAMT[0].NEBHJ;      //標準税値引
            this.PR_NEBHK   = this.PARAMT[0].NEBHK;      //非課税値引
            this.PR_NEBKG   = this.PARAMT[0].NEBKG;      //軽減税値引
            this.PR_TSGHJ   = this.PARAMT[0].TSGHJ;      //標準税対象額計
            this.PR_TSGKG   = this.PARAMT[0].TSGKG;      //軽減税対象額計
            this.PR_TSGHK   = this.PARAMT[0].TSGHK;      //非課税対象額計
            this.PR_SHZHJ   = this.PARAMT[0].SHZHJ;      //標準税消費税
            this.PR_SHZKG   = this.PARAMT[0].SHZKG;      //軽減税消費税
       })
      this.makeexcel();
      this.dialogIN = false;
    },
    //-----------------------------------------------------------------
    //供物請求書エクセルの作成
    //-----------------------------------------------------------------
    /* eslint-disable */
    makeexcel(e) {
		//const  Promise = XlsxPopulate.Promise;
		const XlsxPopulate = require( 'xlsx-populate' );
		const req = new XMLHttpRequest();
        const url = this.Koteifile;
        const WPR_KUMDNNO  	= this.PR_KUMDNNO;    //伝票番号
        const WPR_KUMUKNO	= this.PR_KUMUKNO;    //受付番号
		const WPR_KUMNODT	= this.PR_KUMNODT;    //売上日
		const WPR_KUUTDT	= this.PR_KUUTDT;     //受付日
		const WPR_KUMSKSDT 	= this.PR_KUMSKSDT;   //印刷日
		const WPR_KUMSKSTM 	= this.PR_KUMSKSTM;   //印刷時間
		const WPR_BLCKCD	= this.PR_BLCKCD;     //営業所コード
		const WPR_BLCKNM	= this.PR_BLCKNM;     //営業所名
		const WPR_BLCKYB1	= this.PR_BLCKYB1;    //郵便番号1
		const WPR_BLCKYB2	= this.PR_BLCKYB2;    //郵便番号2
		const WPR_BLCKJY1	= this.PR_BLCKJY1;    //住所1
		const WPR_BLCKJY2	= this.PR_BLCKJY2;    //住所2
		const WPR_BLCKJY3	= this.PR_BLCKJY3;    //住所3
		const WPR_BLCKTEL	= this.PR_BLCKTEL;    //TEL
		const WPR_BLCKFAX	= this.PR_BLCKFAX;    //FAX
		const WPR_BLCKMAIL 	= this.PR_BLCKMAIL;   //MAIL
		const WPR_BLCKSK1	= this.PR_BLCKSK1;    //請求先代表者
		const WPR_BLCKSK2	= this.PR_BLCKSK2;    //請求住所
		const WPR_BLCKSK3	= this.PR_BLCKSK3;    //請求電話
		const WPR_BLCKSK4	= this.PR_BLCKSK4;    //請求取引銀行名
		const WPR_BLCKSK5	= this.PR_BLCKSK5;    //請求口座名義
		const WPR_BLCKSK6	= this.PR_BLCKSK6;    //口座
		const WPR_KUMSOKE	= this.PR_KUMSOKE;    //葬家
		const WPR_KUMKNM	= this.PR_KUMKNM;     //故人
		const WPR_KUMMNM	= this.PR_KUMMNM;     //喪主
        const WPR_KUMBLCD	= this.PR_KUMBLCD;    //部署コード
        const WPR_KUMBLNM	= this.PR_KUMBLNM;    //部署名称
        const WPR_KUMTNCD	= this.PR_KUMTNCD;    //営業担当コード
        const WPR_KUMTNNM	= this.PR_KUMTNNM;    //営業担当名称
        const WPR_KUMSKCD	= this.PR_KUMSKCD;    //請求先コード
        const WPR_KUMSNM1	= this.PR_KUMSNM1;    //請求先漢字1
        const WPR_KUMSNM2	= this.PR_KUMSNM2;    //請求先漢字2
        const WPR_KUMSYU	= this.PR_KUMSYU;     //請求先郵便番号
        const WPR_KUMSJY1	= this.PR_KUMSJY1;    //住所1
        const WPR_KUMSJY2	= this.PR_KUMSJY2;    //住所2
        const WPR_KUMSJY3	= this.PR_KUMSJY3;    //住所3
        const WPR_KUMTEL	= this.PR_KUMTEL;     //℡
        const WPR_KUMTELK	= this.PR_KUMTELK;    //携帯
        const WPR_KUMFAX	= this.PR_KUMFAX;     //ファックス
        const WPR_KUMNBI	= this.PR_KUMNBI;     //納品日
        const WPR_KUMNTM	= this.PR_KUMNTM;     //納品時間
        const WPR_KUMNBS	= this.PR_KUMNBS;     //納品場所
        const WPR_KUMSYCD	= this.PR_KUMSYCD;    //商品コード
        const WPR_KUMSYNM	= this.PR_KUMSYNM;    //商品名称
        const WPR_KUMSYUG	= this.PR_KUMSYUG;    //売上単価
        const WPR_KUMTNI	= this.PR_KUMTNI;     //単位
        const WPR_KUMSYKB	= this.PR_KUMSYKB;    //売上消費税区分
        const WPR_KUMZRGK	= this.PR_KUMZRGK;    //売上金額(税抜)
        const WPR_KUMSuu	= this.PR_KUMSuu;     //売上数量
        const WPR_KUMNbk	= this.PR_KUMNbk;     //売上値引(税抜)
        const WPR_KUMURI	= this.PR_KUMURI;     //売上額値引後(税抜)
        const WPR_KUMSZGK	= this.PR_KUMSZGK;    //売上消費税計
        const WPR_KUMUAGK	= this.PR_KUMUAGK;    //売上合計(税込)
        const WPR_KUMNKG	= this.PR_KUMNKG;     //売掛入金金額
        const WPR_KUMZNG	= this.PR_KUMZNG;     //売掛残高
        const WPR_KUMTKCD	= this.PR_KUMTKCD;    //提携先コード
        const WPR_KUMTKNM	= this.PR_KUMTKNM;    //提携先名称
        const WPR_KUMTKWR	= this.PR_KUMTKWR;    //割引率
        const WPR_KUMNA11	= this.PR_KUMNA11;    //名札11
        const WPR_KUMNA12	= this.PR_KUMNA12;    //名札12
        const WPR_KUMNA21	= this.PR_KUMNA21;    //名札21
        const WPR_KUMNA22	= this.PR_KUMNA22;    //名札22
        const WPR_URZHJ     = this.PR_URZHJ;      //標準税売上(税抜)
        const WPR_URZKG     = this.PR_URZKG;      //軽減税売上(税抜)
        const WPR_URZHK     = this.PR_URZHK;      //非課税売上(税抜)
        const WPR_NEBHJ    	= this.PR_NEBHJ;      //標準税値引
        const WPR_NEBHK     = this.PR_NEBHK;      //非課税値引
        const WPR_NEBKG     = this.PR_NEBKG;      //軽減税値引
        const WPR_TSGHJ     = this.PR_TSGHJ;      //標準税対象額計
        const WPR_TSGKG     = this.PR_TSGKG;      //軽減税対象額計
        const WPR_TSGHK     = this.PR_TSGHK;      //非課税対象額計
        const WPR_SHZHJ     = this.PR_SHZHJ;      //標準税消費税
        const WPR_SHZKG     = this.PR_SHZKG;      //軽減税消費税
        const W_KUMDNNO    	= this.KUMDNNO;       //伝票番号
        const W_KUMUKNO     = this.KUMUKNO;       //受付番号
		const W_KUMNODT     = this.KUMNODT;       //売上日
		const W_KUUTDT      = this.KUUTDT;        //受付日
		const W_KUMSKSDT   	= this.KUMSKSDT;      //印刷日
		const W_KUMSKSTM   	= this.KUMSKSTM;      //印刷時間
        const W_BLCKCD  	= this.BLCKCD;        //営業所コード
    	const W_BLCKNM      = this.BLCKNM;        //営業所名
		const W_BLCKYB1     = this.BLCKYB1;       //郵便番号1
		const W_BLCKYB2     = this.BLCKYB2;       //郵便番号2
		const W_BLCKJY1     = this.BLCKJY1;       //住所1
		const W_BLCKJY2     = this.BLCKJY2;    //住所2
		const W_BLCKJY3     = this.BLCKJY3;    //住所3
		const W_BLCKTEL     = this.BLCKTEL;    //TEL
		const W_BLCKFAX     = this.BLCKFAX;    //FAX
		const W_BLCKMAIL   	= this.BLCKMAIL;   //MAIL 
		const W_BLCKSK1       	= this.BLCKSK1;    //請求先代表者
		const W_BLCKSK2       	= this.BLCKSK2;    //請求住所
		const W_BLCKSK3       	= this.BLCKSK3;    //請求電話
		const W_BLCKSK4       	= this.BLCKSK4;    //請求取引銀行名
		const W_BLCKSK5       	= this.BLCKSK5;    //請求口座名義
		const W_BLCKSK6       	= this.BLCKSK6;    //口座
		const W_KUMSOKE       	= this.KUMSOKE;    //葬家
		const W_KUMKNM       	= this.KUMKNM;     //故人
		const W_KUMMNM       	= this.KUMMNM;     //喪主
        const W_KUMBLCD       	= this.KUMBLCD;    //部署コード
        const W_KUMBLNM       	= this.KUMBLNM;    //部署名称
        const W_KUMTNCD       	= this.KUMTNCD;    //営業担当コード
        const W_KUMTNNM       	= this.KUMTNNM;    //営業担当名称
        const W_KUMSKCD       	= this.KUMSKCD;    //請求先コード
        const W_KUMSNM1       	= this.KUMSNM1;    //請求先漢字1
        const W_KUMSNM2       	= this.KUMSNM2;    //請求先漢字2
        const W_KUMSYU       	= this.KUMSYU;     //請求先郵便番号
        const W_KUMSJY1       	= this.KUMSJY1;    //住所1
        const W_KUMSJY2       	= this.KUMSJY2;    //住所2
        const W_KUMSJY3       	= this.KUMSJY3;    //住所3
        const W_KUMTEL       	= this.KUMTEL;     //℡
        const W_KUMTELK       	= this.KUMTELK;    //携帯
        const W_KUMFAX       	= this.KUMFAX;     //ファックス
        const W_KUMNBI       	= this.KUMNBI;     //納品日
        const W_KUMNTM       	= this.KUMNTM;     //納品時間
        const W_KUMNBS       	= this.KUMNBS;     //納品場所
        const W_KUMSYCD       	= this.KUMSYCD;    //商品コード
        const W_KUMSYNM       	= this.KUMSYNM;    //商品名称
        const W_KUMSYUG       	= this.KUMSYUG;    //売上単価
        const W_KUMTNI       	= this.KUMTNI;     //単位
        const W_KUMSYKB       	= this.KUMSYKB;    //売上消費税区分
        const W_KUMZRGK       	= this.KUMZRGK;    //売上金額(税抜)
        const W_KUMSuu       	= this.KUMSuu;     //売上数量
        const W_KUMNbk       	= this.KUMNbk;     //売上値引(税抜)
        const W_KUMURI       	= this.KUMURI;     //売上額値引後(税抜)
        const W_KUMSZGK       	= this.KUMSZGK;    //売上消費税計
        const W_KUMUAGK       	= this.KUMUAGK;    //売上合計(税込)
        const W_KUMNKG       	= this.KUMNKG;     //売掛入金金額
        const W_KUMZNG       	= this.KUMZNG;     //売掛残高
        const W_KUMTKCD       	= this.KUMTKCD;    //提携先コード
        const W_KUMTKNM       	= this.KUMTKNM;    //提携先名称
        const W_KUMTKWR       	= this.KUMTKWR;    //割引率
        const W_KUMNA11       	= this.KUMNA11;    //名札11
        const W_KUMNA12       	= this.KUMNA12;    //名札12
        const W_KUMNA21       	= this.KUMNA21;    //名札21
        const W_KUMNA22       	= this.KUMNA22;    //名札22
        const W_URZHJ           = this.URZHJ;      //標準税売上(税抜)
        const W_URZKG           = this.URZKG;      //軽減税売上(税抜)
        const W_URZHK           = this.URZHK;      //非課税売上(税抜)
        const W_NEBHJ           = this.NEBHJ;      //標準税値引
        const W_NEBHK           = this.NEBHK;      //非課税値引
        const W_NEBKG           = this.NEBKG;      //軽減税値引
        const W_TSGHJ           = this.TSGHJ;      //標準税対象額計
        const W_TSGKG           = this.TSGKG;      //軽減税対象額計
        const W_TSGHK           = this.TSGHK;      //非課税対象額計
        const W_SHZHJ           = this.SHZHJ;      //標準税消費税
        const W_SHZKG           = this.SHZKG;      //軽減税消費税

        const WBL_BLCKCD	    = this.BL_BLCKCD;    //伝部署コード
        const WBL_BLCKNM	    = this.BL_BLCKNM;    //部署名称
		const WBL_BLCKTEL	    = this.BL_BLCKTEL;   //TEL
        const WBL_BLCKFAX	    = this.BL_BLCKFAX;   //FAX
        const WBL_BLCKMAIL	    = this.BL_BLCKMAIL;  //MAIL

        const WBL_BLCKSK1       = this.BL_BLCKSK1;   //請求先代表者
        const WBL_BLCKSK5	    = this.BL_BLCKSK5;   //請求口座名義
        const WBL_BLCKSK4	    = this.BL_BLCKSK4;   //請求取引銀行名
        const WBL_BLCKSK6       = this.BL_BLCKSK6;   //口座
        const WBL_BLCKSK2       = this.BL_BLCKSK2;   //請求住所
        const WBL_BLCKSK3       = this.BL_BLCKSK3;   //請求電話
        const WBL_BLCKYB1       = this.BL_BLCKYB1;   //郵便番号1
        const WBL_BLCKYB2       = this.BL_BLCKYB2;   //郵便番号2
        const WBL_BLCKJY1       = this.BL_BLCKJY1;   //住所1
        const WBL_BLCKJY2       = this.BL_BLCKJY2;   //住所2
        const WBL_BLCKJY3       = this.BL_BLCKJY3;   //住所3

      req.open("GET", url, true);
			req.responseType = "arraybuffer";
			req.onreadystatechange = function () {
                  if (req.readyState === 4 && req.status === 200){
                      XlsxPopulate.fromDataAsync(req.response)
                      .then(function (workbook) {
					workbook.outputAsync().then(data => resolve(data));
                    const sheet2 = workbook.sheet("データ")
                  
                    if(WPR_KUMDNNO  != ""){sheet2.cell(WPR_KUMDNNO).value(W_KUMDNNO)}
                    if(WPR_KUMUKNO	!= ""){sheet2.cell(WPR_KUMUKNO).value(W_KUMUKNO)}
                    if(WPR_KUMNODT	!= ""){sheet2.cell(WPR_KUMNODT).value(W_KUMNODT)}
                    if(WPR_KUUTDT	!= ""){sheet2.cell(WPR_KUUTDT).value(W_KUUTDT)}
                    if(WPR_KUMSKSDT	!= ""){sheet2.cell(WPR_KUMSKSDT).value(W_KUMSKSDT)}
                    if(WPR_KUMSKSTM	!= ""){sheet2.cell(WPR_KUMSKSTM).value(W_KUMSKSTM)}
                    if(WPR_BLCKCD	!= ""){sheet2.cell(WPR_BLCKCD).value(WBL_BLCKCD)}
                    if(WPR_BLCKNM	!= ""){sheet2.cell(WPR_BLCKNM).value(WBL_BLCKNM)}
                    if(WPR_BLCKYB1	!= ""){sheet2.cell(WPR_BLCKYB1).value(WBL_BLCKYB1)}
                    if(WPR_BLCKYB2	!= ""){sheet2.cell(WPR_BLCKYB2).value(WBL_BLCKYB2)}
                    if(WPR_BLCKJY1	!= ""){sheet2.cell(WPR_BLCKJY1).value(WBL_BLCKJY1)}
                    if(WPR_BLCKJY2	!= ""){sheet2.cell(WPR_BLCKJY2).value(WBL_BLCKJY2)}
                    if(WPR_BLCKJY3	!= ""){sheet2.cell(WPR_BLCKJY3).value(WBL_BLCKJY3)}
                    if(WPR_BLCKTEL	!= ""){sheet2.cell(WPR_BLCKTEL).value(WBL_BLCKTEL)}
                    if(WPR_BLCKFAX	!= ""){sheet2.cell(WPR_BLCKFAX).value(WBL_BLCKFAX)}
                    if(WPR_BLCKMAIL	!= ""){sheet2.cell(WPR_BLCKMAIL).value(WBL_BLCKMAIL)}
                    if(WPR_BLCKSK1	!= ""){sheet2.cell(WPR_BLCKSK1).value(W_BLCKSK1)}
                    if(WPR_BLCKSK2	!= ""){sheet2.cell(WPR_BLCKSK2).value(W_BLCKSK2)}
                    if(WPR_BLCKSK3	!= ""){sheet2.cell(WPR_BLCKSK3).value(W_BLCKSK3)}
                    if(WPR_BLCKSK4	!= ""){sheet2.cell(WPR_BLCKSK4).value(W_BLCKSK4)}
                    if(WPR_BLCKSK5	!= ""){sheet2.cell(WPR_BLCKSK5).value(W_BLCKSK5)}
                    if(WPR_BLCKSK6	!= ""){sheet2.cell(WPR_BLCKSK6).value(W_BLCKSK6)}
                    if(WPR_KUMSOKE	!= ""){sheet2.cell(WPR_KUMSOKE).value(W_KUMSOKE)}
                    if(WPR_KUMKNM	!= ""){sheet2.cell(WPR_KUMKNM).value(W_KUMKNM)}
                    if(WPR_KUMMNM	!= ""){sheet2.cell(WPR_KUMMNM).value(W_KUMMNM)}
                    if(WPR_KUMBLCD	!= ""){sheet2.cell(WPR_KUMBLCD).value(W_KUMBLCD)}
                    if(WPR_KUMBLNM	!= ""){sheet2.cell(WPR_KUMBLNM).value(W_KUMBLNM)}
                    if(WPR_KUMTNCD	!= ""){sheet2.cell(WPR_KUMTNCD).value(W_KUMTNCD)}
                    if(WPR_KUMTNNM	!= ""){sheet2.cell(WPR_KUMTNNM).value(W_KUMTNNM)}
                    if(WPR_KUMSKCD	!= ""){sheet2.cell(WPR_KUMSKCD).value(W_KUMSKCD)}
                    if(WPR_KUMSNM1	!= ""){sheet2.cell(WPR_KUMSNM1).value(W_KUMSNM1)}
                    if(WPR_KUMSNM2	!= ""){sheet2.cell(WPR_KUMSNM2).value(W_KUMSNM2)}
                    if(WPR_KUMSYU	!= ""){sheet2.cell(WPR_KUMSYU).value(W_KUMSYU)}
                    if(WPR_KUMSJY1	!= ""){sheet2.cell(WPR_KUMSJY1).value(W_KUMSJY1)}
                    if(WPR_KUMSJY2	!= ""){sheet2.cell(WPR_KUMSJY2).value(W_KUMSJY2)}
                    if(WPR_KUMSJY3	!= ""){sheet2.cell(WPR_KUMSJY3).value(W_KUMSJY3)}
                    if(WPR_KUMTEL	!= ""){sheet2.cell(WPR_KUMTEL).value(W_KUMTEL)}
                    if(WPR_KUMTELK	!= ""){sheet2.cell(WPR_KUMTELK).value(W_KUMTELK)}
                    if(WPR_KUMFAX	!= ""){sheet2.cell(WPR_KUMFAX).value(W_KUMFAX)}
                    if(WPR_KUMNBI	!= ""){sheet2.cell(WPR_KUMNBI).value(W_KUMNBI)}
                    if(WPR_KUMNTM	!= ""){sheet2.cell(WPR_KUMNTM).value(W_KUMNTM)}
                    if(WPR_KUMNBS	!= ""){sheet2.cell(WPR_KUMNBS).value(W_KUMNBS)}
                    if(WPR_KUMSYCD	!= ""){sheet2.cell(WPR_KUMSYCD).value(W_KUMSYCD)}
                    if(WPR_KUMSYNM	!= ""){sheet2.cell(WPR_KUMSYNM).value(W_KUMSYNM)}
                    if(WPR_KUMSYUG	!= ""){sheet2.cell(WPR_KUMSYUG).value(W_KUMSYUG)}
                    if(WPR_KUMTNI	!= ""){sheet2.cell(WPR_KUMTNI).value(W_KUMTNI)}
                    if(WPR_KUMSYKB	!= ""){sheet2.cell(WPR_KUMSYKB).value(W_KUMSYKB)}
                    if(WPR_KUMZRGK	!= ""){sheet2.cell(WPR_KUMZRGK).value(W_KUMZRGK)}
                    if(WPR_KUMSuu	!= ""){sheet2.cell(WPR_KUMSuu).value(W_KUMSuu)}
                    if(WPR_KUMNbk	!= ""){sheet2.cell(WPR_KUMNbk).value(W_KUMNbk)}
                    if(WPR_KUMURI	!= ""){sheet2.cell(WPR_KUMURI).value(W_KUMURI)}
                    if(WPR_KUMSZGK	!= ""){sheet2.cell(WPR_KUMSZGK).value(W_KUMSZGK)}
                    if(WPR_KUMUAGK	!= ""){sheet2.cell(WPR_KUMUAGK).value(W_KUMUAGK)}
                    if(WPR_KUMNKG	!= ""){sheet2.cell(WPR_KUMNKG).value(W_KUMNKG)}
                    if(WPR_KUMZNG	!= ""){sheet2.cell(WPR_KUMZNG).value(W_KUMZNG)}
                    if(WPR_KUMTKCD	!= ""){sheet2.cell(WPR_KUMTKCD).value(W_KUMTKCD)}
                    if(WPR_KUMTKNM	!= ""){sheet2.cell(WPR_KUMTKNM).value(W_KUMTKNM)}
                    if(WPR_KUMTKWR	!= ""){sheet2.cell(WPR_KUMTKWR).value(W_KUMTKWR)}
                    if(WPR_KUMNA11	!= ""){sheet2.cell(WPR_KUMNA11).value(W_KUMNA11)}
                    if(WPR_KUMNA12	!= ""){sheet2.cell(WPR_KUMNA12).value(W_KUMNA12)}
                    if(WPR_KUMNA21	!= ""){sheet2.cell(WPR_KUMNA21).value(W_KUMNA21)}
                    if(WPR_KUMNA22	!= ""){sheet2.cell(WPR_KUMNA22).value(W_KUMNA22)}
                    if(WPR_URZHJ	!= ""){sheet2.cell(WPR_URZHJ).value(W_URZHJ)}
                    if(WPR_URZKG	!= ""){sheet2.cell(WPR_URZKG).value(W_URZKG)}
                    if(WPR_URZHK    != ""){sheet2.cell(WPR_URZHK).value(W_URZHK)}
                    if(WPR_NEBHJ	!= ""){sheet2.cell(WPR_NEBHJ).value(W_NEBHJ)}
                    if(WPR_NEBHK	!= ""){sheet2.cell(WPR_NEBHK).value(W_NEBHK)}
                    if(WPR_NEBKG	!= ""){sheet2.cell(WPR_NEBKG).value(W_NEBKG)}
                    if(WPR_TSGHJ	!= ""){sheet2.cell(WPR_TSGHJ).value(W_TSGHJ)}
                    if(WPR_TSGKG	!= ""){sheet2.cell(WPR_TSGKG).value(W_TSGKG)}
                    if(WPR_TSGHK	!= ""){sheet2.cell(WPR_TSGHK).value(W_TSGHK)}
                    if(WPR_SHZHJ	!= ""){sheet2.cell(WPR_SHZHJ).value(W_SHZHJ)}
                    if(WPR_SHZKG	!= ""){sheet2.cell(WPR_SHZKG).value(W_SHZKG)}

					workbook.outputAsync()
                    .then(function (blob) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob, "供物見積書.xlsx");
                            } else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = "供物見積書.xlsx";
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
					})		
          }
			};
			req.send();
		},
	}
}
</script>