<template>
 <v-row justify="center">
 <v-dialog v-model="dialogLD" width="500">
  <v-card>
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>リード</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjTRHCD" :counter="10" label="リード先コード"  required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjTRHNM" :counter="10" label="リード名称" required></v-text-field>
      </v-col>
      <v-col cols="2" md="2">
        <v-text-field v-model="hjTRHNMS" :counter="10" label="略称" required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjTRHTNNM" :counter="10" label="担当者" required></v-text-field>
      </v-col>
      <v-col cols="1" md="1">
       <v-text-field v-model="hjTRHRDRV" :counter="10" label="RV" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" md="10">
        <div >
          <v-btn x-large color="success" dark v-on:click="agreeok"><v-icon left>mdi-pencil</v-icon>リード選択確定</v-btn>
        </div>
      </v-col>
       <v-col cols="2" md="2">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table :fixed-header="true" class="SMJNN_DSP"  :dense="true" height="500">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">リード先コード</th>
            <th class="text-left">リード名称</th>
            <th class="text-left">略称</th>
            <th class="text-left">担当者</th>
            <th class="text-left">RV</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(LeadTB,index) in LeadTB" :key="index" @click="showAlert2(LeadTB)">
            <td>{{ LeadTB.TRHCD }}</td>	
            <td>{{ LeadTB.TRHNM}}</td>
            <td>{{ LeadTB.TRHSHCD }}</td>
            <td>{{ LeadTB.TRHSHNM }}</td>
            <td>{{ LeadTB.TRHRDRV }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</v-dialog>
</v-row>
</template>
<script>
import axios from 'axios';
export default {
  name: "SMJNN_DSP",
  data() {
    return {
      dialogLD:false,
      LeadTB: [],
      hjTRHCD:       "",  //取引先コード
      hjTRHNM:       "",  //取引先名称
      hjTRHNMS:      "",  //取引先略称
      hjTRHTNNM:     "",  //担当者名称
      hjTRHRDRV:     ""   //リードレベル
    }
  },
  //取引先マスタ取得(リード)
  methods: {
    async Leadquery() {
       const fomego = { BRMSDCD: this.PL_BRMSID};
        axios.post('/api/LeadGET',fomego)
        .then(response => {
          this.LeadTB = response.data
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogLD = true;
    },
    //オープン時
    open() {
      this.Leadquery();     //寺院マスタ取得
    },
    close() {
      this.$emit('result', {'res': false, 'TRHCD':'--','TRHNM':'--','TRHNMS':'--','TRHTNNM':'--','TRHRDRV':'--'})
      this.dialogLD = false;
    },
    agreeok() {
      this.$emit('result', {'res': true, 'TRHCD':this.hjTRHCD,'TRHNM':this.hjTRHNM,'TRHNMS':this.hjTRHNMS,'TRHTNNM':this.hjTRHTNNM,'TRHRDRV':this.hjTRHRDRV})
      this.dialogLD = false;
     },
    showAlert2: function(item) {
      this.hjTRHCD      = item.TRHCD;     //リード先コード
      this.hjTRHNM      = item.TRHNM;     //リード名称
      this.hjTRHNMS     = item.TRHNMS;    //略称
      this.hjTRHTNNM    = item.TRHTNNM;   //担当者
      this.hjTRHRDRV    = item.TRHRDRV;   //RV
    },
  } 
 }
</script>