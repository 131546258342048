<template>
 <v-row justify="center">
  <v-dialog v-model="dialogMSZ" width="700">
    <v-card>
      <v-toolbar flat color="grey lighten-2">
        <v-toolbar-title>{{TLNAME}}(BRMS)</v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="3" md="3">
          <v-text-field v-model="hjBRMSDCD" :counter="10" label="コード"  required></v-text-field>
        </v-col>
        <v-col cols="3" md="3">
          <v-text-field v-model="hjBRMSNM" :counter="10" label="名称" required></v-text-field>
        </v-col>
        <v-col cols="3" md="3" v-if="show >= 2">
          <v-text-field v-model="hjBRMSSK" :counter="10" label="数" required></v-text-field>
        </v-col>
        <v-col cols="3" md="3" v-if="show >= 3">
          <v-text-field v-model="hjBRMSIMG" :counter="10" label="イメージ" required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="8">
          <div >
            <v-btn x-large color="success" dark v-on:click="agreeok"><v-icon left>mdi-gavel</v-icon>{{TLNAME}}</v-btn>
          </div>
          </v-col>
          <v-col cols="4" md="4">
            <v-btn  fab dark small color="pink" v-on:click="close">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table :fixed-header="true" class="BRMS_DSP"  :dense="true" height="500">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">順</th>
                <th class="text-left">{{TLBRMSCD}}</th>
                <th class="text-left">{{TLBRMSNM}}</th>
                <th class="text-left" v-if="show >= 2">{{TLBRMSSK}}</th>
                <th class="text-left" v-if="show >= 3">{{TLBRMSIMG}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(BRMS,index) in BRMS" :key="index" @click="showAlert2(BRMS)">
                <td>{{ BRMS.BRMSJYUN }}</td>	
                <td>{{ BRMS.BRMSCD }}</td>
                <td>{{ BRMS.BRMSNM }}</td>
                <td v-if="show >= 2">{{ BRMS.BRMSSK }}</td>
                <td v-if="show >= 3">{{ BRMS.BRMSIMG }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from 'axios';
export default {
  name: "BRMS_DSP",
  data() {
    return {
      dialogMSZ:false,
      BRMS: [],
      show: 1,          //項目表示
      TLNAME:     "",	  //
      TLBRMSJYUN: "",	  //順           表示用
      TLBMSDCD:   "",   //分類コード    表示用
      TLBRMSCD:   "",   //分類コード    表示用
      TLBRMSNM:   "",   //分類名称      表示用
      TLBRMSSK:   "",   //分類数       表示用
      TLBRMSIMG:  "",   //分類イメージ  表示用
      hjBRMSDCD:  "",   //分類コード    選択
      hjBRMSCD:   "",   //分類コード    選択
      hjBRMSNM:   "",   //分類名称      選択
      hjBRMSSK:   "",   //分類数        選択
      hjBRMSIMG:  "",   //分類イメージ  選択
      PL_BRMSID:  "",   //グローバル大分類コード
      PLBRMSID:   ""     
    }
  },
 //-----------------------------------------------------------------
  //分類マスタ取得
  //-----------------------------------------------------------------
  methods: {
    async BRMSquery() {
        const fomego = { BRMSDCD: this.PL_BRMSID};
        axios.post('/api/BRMSALL',fomego)
        .then(response => {
          this.BRMS = response.data
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogMSZ = true;
    },
    //オープン時
    open() {
      this.PL_BRMSID = "005";
     //-----------------------------------------------------------------
     //005:続柄設定
     //----------------------------------------------------------------- 
        this.TLNAME    ="　続　柄　選　択　確　定　";
        this.limitsuu  = 200;
        this.show      = 1;
        this.TLBRMSCD  ="続柄コード";
        this.TLBRMSNM  ="続柄名称";
        this.TLBRMSJYUN="順";
        this.TLBRMSSK  ="";
        this.TLBRMSIMG ="";
   
      this.BRMSquery();     //分類マスタ取得
    },
    close() {
      this.$emit('result', {'res': false, 'BRMSDCD':'--','BRMSNM':'--','BRMSSK':'--','BRMSIMG':'--'});
      this.dialogMSZ = false;
    },
    agreeok() {
      this.$emit('result', {'res': true, 'BRMSDCD':this.hjBRMSDCD,'BRMSNM':this.hjBRMSNM,'BRMSSK':this.hjBRMSSK,'BRMSIMG':this.hjBRMSIMG});
      this.dialogMSZ = false;
     },
    showAlert2: function(item) {
      this.hjBRMSDCD  = item.BRMSCD;  //分類コード
      this.hjBRMSNM   = item.BRMSNM;  //分類名称
      this.hjBRMSSK   = item.BRMSSK;  //分類数
      this.hjBRMSIMG  = item.BRMSIMG; //分類イメージ
    },
  } 
} 
</script>