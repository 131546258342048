<template>
  <v-dialog v-model="dialogPL">
    <v-app id="inspire">
      <v-card width="100%">
        <v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
          <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
            <v-spacer>{{ message }}</v-spacer>
          </v-col>
          <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
            <v-btn v-on:click="ERRORConfirm">エラー確認</v-btn>
          </v-col>
      </v-alert>
      <v-toolbar flat color="grey lighten-3" height="30"><v-toolbar-title>施行プラン(SekoPLANinput)</v-toolbar-title></v-toolbar>
        <v-row>
            <v-col cols="4" md="4">
            <div class="my-2"><v-btn x-large color="success" dark block v-on:click="this.addMsTrn" ><v-icon left>mdi-pencil</v-icon>プラン登録更新</v-btn></div>
          </v-col>
          <v-col cols="4" md="4">
            <div class="my-2"><v-btn x-large color="error" dark  v-on:click="this.DELETEMsTrn" v-if="DELBOshow"><v-icon left>mdi-delete</v-icon>削除処理 </v-btn></div>
          </v-col>        
          <v-col cols="2" md="2">
            <v-btn  fab dark small color="pink" v-on:click="close">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1">
            <v-btn color="primary"  v-on:click="SMTKKDialogshow">提携</v-btn>
            <app-dialogSMTKK :text="text" ref="dialogSMTKK" @result="responseSMTKK"></app-dialogSMTKK>
          </v-col>
          <v-alert>{{ KoThCD }}</v-alert>
          <v-alert>{{ KoThNM }}</v-alert>
          <v-alert>{{ KoTCD }}</v-alert>
          <v-alert>{{ KoTNM }}</v-alert>
          <v-col sm="1" md="1">
            <v-btn color="primary"  v-on:click="SMKPlanDialogshow">PL</v-btn>
            <app-dialogSMKPlan :text="text" ref="dialogSMKPlan" @result="responseSMKPlan"></app-dialogSMKPlan>
          </v-col>
          <v-alert>{{ KoTPLCD }}</v-alert>
          <v-alert>{{ KoTPLNM }}</v-alert>
          <v-alert>{{ KoTPLGK }}</v-alert>
          <v-alert>円</v-alert>
        </v-row>
        <v-row>
          <v-card>
            <v-card-title>プラン詳細
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="商品検索" single-line hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="success" dark class="mb-2" v-on:click="this.HNMDialogshow" ><v-icon left>mdi-plus-box-outline</v-icon>新規品目追加</v-btn>
              <app-dialogHNM :text="text" ref="dialogHNM" @result="this.responseHNM"></app-dialogHNM>
            </v-card-title>
            <v-data-table :headers="headers" :items="MsTrn" :sort-by="['MsHIBJYUN']" :search="search" @click:row="clickRow">
              <template v-slot:[`item.MsIMG1`]="{ item }">
                <img :src="item.MsIMG1" style="width: 150px; height: 100px" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </v-card>
    </v-app>
  </v-dialog>
</template>
<script>
 //   Vue.use(VueMask.VueMaskPlugin);
</script>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import axiosJsonpAdapter  from 'axios-jsonp'
//import { listSmksets }    from "../graphql/queries";           //プラン明細マスタ
//import { updateKoTrn }    from "../graphql/mutations";         //施行ヘッダー更新
//import { listMsTrns }    from "../graphql/queries";            //祭事明細取得
//import { updateMsTrn }    from "../graphql/mutations";         //祭事明細データ更新
//import { createMsTrn }    from "../graphql/mutations";         //祭事明細データ追加
//import { deleteMsTrn }    from "../graphql/mutations";         //祭事明細データ削除
import moment from "moment";
import SMTKKDialog        from "./popTEIKEI.vue";   //提携企業マスタ
import SMKPlanDialog      from "./popPLAN.vue";     //プランマスタ
import HNMDialog          from "./popHin.vue";      //品目設定マスタ
export default { 
  data () {
     // テンプレート内で保有するデータ  
      return {
      dialogERROR:false,
      DELBOshow: false,
      MsTrn: [],
      WMsTrn:[],
      DMsTrn:[],
      SMsTrn:[],
      SMKSET: [],
      search: '',
      headers: [
        { text: '並順', align: 'start',value: 'MsHIBJYUN',width: '80'},
        { text: '分類名', value: 'MsSYBRNM',width: '100'},
        { text: 'S',  value: 'MsSETFG',width: '80' },
        { text: 'Image', value: 'MsIMG1', sortable: false,width: '150' },
        { text: '品目',  value: 'MsHICD',width: '80' },
        { text: '品目名', value: 'MsHINM',width: '120' },
        { text: '立替', value: 'MsTAKE',width: '80' },
        { text: '単位', value: 'MsTAN',width: '80' },
        { text: '単価', value: 'MsUNT',width: '90' },
        { text: '税区', value: 'MsSYOKB',width: '90' },
        { text: '数量', value: 'MsSuu',width: '90' },
        { text: '税抜', value: 'MsKin',width: '90' },
        { text: '値引', value: 'MsNebiki',width: '90' },
        { text: '消税', value: 'MsUCSYOG',width: '80' },
        { text: '税込', value: 'MsZKGK',width: '90' },
        { text: '備考', value: 'MsBIKO',width: '150' },
        { text: '操作', value: 'actions', sortable: false,width: '80' },
      ],
        PL_KIGYOUID:"",           //グローバル企業コード
        PL_Tanmtid:"",            //グローバル端末ID
        KoTNO:'',                 //受付№
        KoThCD:'',                //提携コード
        KoThNM:'',                //提携名称
        KoTCD:'',                 //提携取引先コード
        KoTNM:'',                 //提携取引先名称
        KoTPLCD:'',               //プランコード
        KoTPLNM :'',              //プラン名称
        KoTPLGK :'',              //プラン金額
        KoTURDT :'',              //売上日
        //品目マスタ受け取り
        MsHIBJYUN:'',          //品目大分類並び順
        MsHIBCD:'',            //品目大分類コード
        MsHIBNM:'',            //品目大分類名称
        MsSYBRJN:'',           //品目分類順
        MsSYBRCD:'',           //品目分類コード
        MsSYBRNM:'',           //品目分類名称
        MsHIJYUN:'',           //品目並び順
        MsTAKE:'',             //立替商品
        MsJSYA:'',             //発注商品
        MsHND:'',              //変動商品
        MsHICD:'',             //品目コード
        MsHINM:'',             //品目名称
        MsTAN:'',              //単位名称
        MsSETFG:'',            //セットフラグ
        MsUNT:'',              //販売単価
        MsSYOKB:'',            //売上消費税区分
        MsSIT:'',              //仕入単価
        MsSuu:'',              //売上数量
        MsKin:'',              //売上金額(税抜)
        MsNebiki:'',           //売上値引(税抜)
        MsGKing:'',            //売上額値引後(税抜)
        MsUCSYOG:'',           //売上消費税額
        MsZKGK:'',             //売上税込額(税込)
        MsBIKO:'',             //備考
        MsIMG1:'',             //イメージ1
        MsIMG2:'',             //イメージ2
        MsIMG3:'',             //イメージ3
        MsIMG4:'',             //イメージ4
        MsIMG5:'',             //イメージ5
        MsHCCD:'',             //発注先コード
        MsHCNM:'',             //発注先名称
        MsHCDTTM:'',	         //発注日時
        HINMIMG1:'',           //品目イメージ1
        HINMIMG2:'',           //品目イメージ2
        HINMIMG3:'',           //品目イメージ3
        HINMIMG4:'',           //品目イメージ4
        HINMIMG5:'',           //品目イメージ5
        dialogPL:false,
        date: new Date().toISOString().substr(0, 10),
        modal11:false,
        modal12:false,
        modal13:false,
        modal14:false,
        message:'',           //メッセージ
        text: '',
        //初期設定
        KgyCd:"",     //初期設定企業コード
        KgyNM:"",     //初期設定企業名称
        BLCKCD:"",    //初期設定部署コード
        BLCKNM:"",    //初期設定部署名称
        SynCd:"",     //初期設定社員名称
        SynNm:"",     //初期設定社員名称
        TANID:"",     //初期設定端末ID
        DENNO:"0",    //初期設定伝票番号
        GNZHJ:"",     //初期設定現在標準税
        GNZKG:"",     //初期設定現在軽減税
        KGZDT:"",     //初期設定消費税切替日
        KGZHJ:"",     //初期設定次期標準税
        KGZKG:"",     //初期設定次期軽減税
     }
    },
   methods: {
    open() {     
      this.dialogPL = true;
      this.PL_KIGYOUID = this.$store.getters.GET_kigyoucd;  //グローバル企業コード
      this.PL_Tanmtid  = this.$store.getters.GET_Tanmtid;   //グローバル端末ID

      if(localStorage.SOGI_KgyCd)   this.KgyCd  = localStorage.SOGI_KgyCd;    //初期設定企業コード
      if(localStorage.SOGI_KgyNM)   this.KgyNM  = localStorage.SOGI_KgyNM;    //初期設定企業名称
      if(localStorage.SOGI_BLCKCD)  this.BLCKCD = localStorage.SOGI_BLCKCD;   //初期設定部署コード
      if(localStorage.SOGI_BLCKNM)  this.BLCKNM = localStorage.SOGI_BLCKNM;   //初期設定部署名称
      if(localStorage.SOGI_SynCd)   this.SynCd  = localStorage.SOGI_SynCd;    //初期設定社員名称
      if(localStorage.SOGI_SynNm)   this.SynNm  = localStorage.SOGI_SynNm;    //初期設定社員名称
      if(localStorage.SOGI_TANID)   this.TANID  = localStorage.SOGI_TANID;    //初期設定端末ID
      if(localStorage.SOGI_DENNO)   this.DENNO  = localStorage.SOGI_SGDENNO;  //初期設定伝票番号
      if(localStorage.SOGI_GNZHJ)   this.GNZHJ  = localStorage.SOGI_GNZHJ;    //初期設定現在標準税
      if(localStorage.SOGI_GNZKG)   this.GNZKG  = localStorage.SOGI_GNZKG;    //初期設定現在軽減税
      if(localStorage.SOGI_KGZDT)   this.KGZDT  = localStorage.SOGI_KGZDT;    //初期設定消費税切替日
      if(localStorage.SOGI_KGZHJ)   this.KGZHJ  = localStorage.SOGI_KGZHJ;    //初期設定次期標準税
      if(localStorage.SOGI_KGZKG)   this.KGZKG  = localStorage.SOGI_KGZKG;    //初期設定次期軽減税
    
      if(localStorage.SOGI_KoTNO)   this.KoTNO  = localStorage.SOGI_KoTNO;    //受付№
      if(localStorage.SOGI_KoThCD)  this.KoThCD = localStorage.SOGI_KoThCD;   //顧客取引先コード
      if(localStorage.SOGI_KoThNM)  this.KoThNM = localStorage.SOGI_KoThNM;   //顧客取引先名称
      if(localStorage.SOGI_KoTCD)   this.KoTCD  = localStorage.SOGI_KoTCD;    //顧客提携区分コード
      if(localStorage.SOGI_KoTNM)   this.KoTNM  = localStorage.SOGI_KoTNM;    //顧客提携区分名称
      if(localStorage.SOGI_KoTPLCD) this.KoTPLCD= localStorage.SOGI_KoTPLCD;  //プランコード
      if(localStorage.SOGI_KoTPLNM) this.KoTPLNM= localStorage.SOGI_KoTPLNM;  //プラン名称
      if(localStorage.SOGI_KoTPLGK) this.KoTPLGK= localStorage.SOGI_KoTPLGK;  //プランセットコード
      if(localStorage.SOGI_KoTURDT) this.KoTURDT= localStorage.SOGI_KoTURDT;  //売上日
      //明細テーブル取得
      if(this.KoTPLCD !== ""){
        this.MsTrnquery();
        this.INUPBTN = '施行明細 更新 処理';
        this.DELBOshow = true; //削除ボタン表示
      }
    },
    //----------------------------------------
    //テーブルの行が押された popHin
    //----------------------------------------
    clickRow(row) {
        localStorage.SOGI_MsHIBJYUN = row.MsHIBJYUN;         //品目大分類並び順
        localStorage.SOGI_MsHIBCD   = row.MsHIBCD;           //品目大分類コード
        localStorage.SOGI_MsHIBNM   = row.MsHIBNM;           //品目大分類名称
        localStorage.SOGI_MsSYBRJN  = row.MsSYBRJN;          //品目分類順
        localStorage.SOGI_MsSYBRCD  = row.MsSYBRCD;          //品目分類コード
        localStorage.SOGI_MsSYBRNM  = row.MsSYBRNM;          //品目分類名称
        localStorage.SOGI_MsHIJYUN  = row.MsHIJYUN;          //品目並び順
        localStorage.SOGI_MsTAKE    = row.MsTAKE;            //立替商品
        localStorage.SOGI_MsJSYA    = row.MsJSYA;            //発注商品
        localStorage.SOGI_MsHND     = row.MsHND;             //変動商品
        localStorage.SOGI_MsHICD    = row.MsHICD;            //品目コード
        localStorage.SOGI_MsHINM    = row.MsHINM;            //品目名称
        localStorage.SOGI_MsTAN     = row.MsTAN;             //単位名称
        localStorage.SOGI_MsSETFG   = row.MsSETFG;           //セットフラグ
        localStorage.SOGI_MsUNT     = row.MsUNT;             //販売単価
        localStorage.SOGI_MsSYOKB   = row.MsSYOKB;           //売上消費税区分
        localStorage.SOGI_MsSIT     = row.MsSIT;             //仕入単価
        localStorage.SOGI_MsSuu     = row.MsSuu;             //売上数量
        localStorage.SOGI_MsKin     = row.MsKin;             //売上金額(税抜)
        localStorage.SOGI_MsNebiki  = row.MsNebiki;          //売上値引(税抜)
        localStorage.SOGI_MsGKing   = row.MsGKing;           //売上額値引後(税抜)
        localStorage.SOGI_MsUCSYOG  = row.MsUCSYOG;          //売上消費税額
        localStorage.SOGI_MsZKGK    = row.MsZKGK;            //売上税込額(税込)
        localStorage.SOGI_MsBIKO    = row.MsBIKO;            //備考
        localStorage.SOGI_MsIMG1    = row.MsIMG1;            //イメージ1
        localStorage.SOGI_MsIMG2    = row.MsIMG2;            //イメージ2
        localStorage.SOGI_MsIMG3    = row.MsIMG3;            //イメージ3
        localStorage.SOGI_MsIMG4    = row.MsIMG4;            //イメージ4
        localStorage.SOGI_MsIMG5    = row.MsIMG5;            //イメージ5
        localStorage.SOGI_MsHCCD    = row.MsHCCD;     	     //発注先コード
        localStorage.SOGI_MsHCNM    = row.MsHCNM;     	     //発注先名称
        localStorage.SOGI_MsHCDTTM  = row.MsHCDTTM;          //発注日時
        localStorage.SOGI_KoThCD    = this.KoThCD;           //提携コード
        localStorage.SOGI_KoThNM    = this.KoThNM;           //提携名称
        localStorage.SOGI_KoTCD     = this.KoTCD;            //提携取引先コード
        localStorage.SOGI_KoTNM     = this.KoTNM;            //提携取引先名称
 
        this.$refs.dialogHNM.open();
 },
   //----------------------------------------
   //明細テーブル取得
   //----------------------------------------
   async MsTrnquery() {

        const fomego = {KoTNO: this.KoTNO};
        axios.post('/api/MsTrnGeT',fomego)
           .then(response => {
                this.MsTrn = response.data;   //受信配列を格納
                this.SMsTrn = response.data;  //受信配列を格納 初期取得
            })
            .catch(error => {
            console.log(error)
        })
   },
   //----------------------------------------
   //明細テーブル削除
   //----------------------------------------
    deleteItem (item) {
        if(confirm('本当に品目を削除してもいいですか？')){
          this.editedIndex = this.MsTrn.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.MsTrn.splice(this.editedIndex, 1)

         this.G_MEISAI();
        }
    },
    //----------------------------------------
    //提携企業マスタ表示
    //----------------------------------------
    SMTKKDialogshow() {
     // this.$store.commit("increment_BRID","320") 
      this.$refs.dialogSMTKK.open();
    },
    //提携企業取得
    responseSMTKK(obj) {
      if(obj.TKKTCD != "--"){  //キャンセル時は何もしない
        this.KoThCD = obj.TKKTCD;  //取引先コード -> 顧客取引先コード
        this.KoThNM = obj.TKKTNM;  //取引先名称 -> 顧客取引先名称
        this.KoTCD  = obj.TKKCD;   //提携区分コード -> 顧客提携区分コード
        this.KoTNM  = obj.TKKNM;   //提携区分名称 -> 顧客提携区分名称
      }
      this.dialogSMTKK = false;
    },
    //----------------------------------------
    //プランマスタ表示
    //----------------------------------------
    SMKPlanDialogshow() {
     // this.$store.commit("increment_BRID","320") 
      this.$refs.dialogSMKPlan.open();
    },
    //プラン取得
    responseSMKPlan(obj) {
      if(obj.PlanCD != "--"){          //キャンセル時は何もしない
        this.KoTPLCD = obj.PlanCD;      //プランコード  -> プランコード
        this.KoTPLNM = obj.PlanNM;      //プラン名称    -> プラン名称
        this.KoTPLGK = obj.PlanURGK;    //販売金額      -> プラン金額
        this.SMKSETquery();
       }
      this.dialogSMKPlan = false;
    },
    //----------------------------------------
    //新規品目追加 popHin
    //----------------------------------------
    HNMDialogshow() {
        localStorage.SOGI_KoThCD  = this.KoThCD;               //提携コード
        localStorage.SOGI_KoThNM  = this.KoThNM;               //提携名称
        localStorage.SOGI_KoTCD   = this.KoTCD;                //提携取引先コード
        localStorage.SOGI_KoTNM   = this.KoTNM;                //提携取引先名称
        localStorage.SOGI_MsHICD  = "";                        //品目コード
        this.$refs.dialogHNM.open();
    },
    //新規品目追加取得
    responseHNM(obj) {
      if(obj.MsHICD != "--"){  //キャンセル時は何もしない
        this.MsNO     = obj.MsNO;       //受付番号
        this.MsHIBJYUN= obj.MsHIBJYUN;  //品目大分類並び順
        this.MsHIBCD  = obj.MsHIBCD;    //品目大分類コード
        this.MsHIBNM  = obj.MsHIBNM;    //品目大分類名称
        this.MsSYBRJN = obj.MsSYBRJN;   //品目分類順
        this.MsSYBRCD = obj.MsSYBRCD;   //品目分類コード
        this.MsSYBRNM = obj.MsSYBRNM;   //品目分類名称
        this.MsHIJYUN = obj.MsHIJYUN;   //品目並び順
        this.MsTAKE   = obj.MsTAKE;     //立替商品
        this.MsJSYA   = obj.MsJSYA;     //発注商品
        this.MsHND    = obj.MsHND;      //変動商品
        this.MsHICD   = obj.MsHICD;     //品目コード
        this.MsHINM   = obj.MsHINM;     //品目名称
        this.MsTAN    = obj.MsTAN;      //単位名称
        this.MsSETFG  = obj.MsSETFG;    //セットフラグ
        this.MsUNT    = obj.MsUNT;      //販売単価
        this.MsSYOKB  = obj.MsSYOKB;    //売上消費税区分
        this.MsSIT    = obj.MsSIT;      //仕入単価
        this.MsSuu    = obj.MsSuu;      //売上数量
        this.MsKin    = obj.MsKin;      //売上金額(税抜)
        this.MsNebiki = obj.MsNebiki;   //売上値引(税抜)
        this.MsGKing  = obj.MsGKing;    //売上額値引後(税抜)
        this.MsUCSYOG = obj.MsUCSYOG;   //売上消費税額
        this.MsZKGK   = obj.MsZKGK;     //売上税込額(税込)
        this.MsBIKO   = obj.MsBIKO;     //備考
        this.MsIMG1   = obj.MsIMG1;     //イメージ1
        this.MsIMG2   = obj.MsIMG2;     //イメージ2
        this.MsIMG3   = obj.MsIMG3;     //イメージ3
        this.MsIMG4   = obj.MsIMG4;     //イメージ4
        this.MsIMG5   = obj.MsIMG5;     //イメージ5
        this.MsHCCD   = obj.MsHCCD;     //発注先コード
        this.MsHCNM   = obj.MsHCNM;     //発注先名称
        this.MsHCDTTM = obj.MsHCDTTM;	  //発注日時

        this.WMsTrn.length = 0;
        for (let i = 0; i < this.MsTrn.length; i++) {
           this.WMsTrn.push({
            MsHIBJYUN:this.MsTrn[i].MsHIBJYUN,	//品目大分類並び順
            MsHIBCD:this.MsTrn[i].MsHIBCD,      //品目大分類コード
            MsHIBNM:this.MsTrn[i].MsHIBNM,	    //品目大分類名称
            MsSYBRJN:this.MsTrn[i].MsSYBRJN,	  //品目分類順
            MsSYBRCD:this.MsTrn[i].MsSYBRCD,	  //品目分類コード
            MsSYBRNM:this.MsTrn[i].MsSYBRNM,	  //品目分類名称
            MsHIJYUN:this.MsTrn[i].MsHIJYUN,	  //品目並び順
            MsTAKE:this.MsTrn[i].MsTAKE,	      //立替商品
            MsJSYA:this.MsTrn[i].MsJSYA,	      //発注商品
            MsHND:this.MsTrn[i].MsHND,	        //変動商品
            MsHICD:this.MsTrn[i].MsHICD,	      //品目コード
            MsHINM:this.MsTrn[i].MsHINM,	      //品目名称
            MsTAN:this.MsTrn[i].MsTAN,	        //単位名称
            MsSETFG:this.MsTrn[i].MsSETFG,	    //セットフラグ
            MsUNT:this.MsTrn[i].MsUNT,	        //販売単価
            MsSYOKB:this.MsTrn[i].MsSYOKB,	    //売上消費税区分
            MsSIT:this.MsTrn[i].MsSIT,	        //仕入単価
            MsSuu:this.MsTrn[i].MsSuu,		      //売上数量
            MsKin:this.MsTrn[i].MsKin,		      //売上金額(税抜)
            MsNebiki:this.MsTrn[i].MsNebiki,		//売上値引(税抜)
            MsGKing:this.MsTrn[i].MsGKing,		  //売上額値引後(税抜)
            MsUCSYOG:this.MsTrn[i].MsUCSYOG,		//売上消費税額
            MsZKGK:this.MsTrn[i].MsZKGK,		    //売上税込額(税込)
            MsBIKO:this.MsTrn[i].MsBIKO,		    //備考
            MsIMG1:this.MsTrn[i].MsIMG1,	      //品目イメージ1
            MsIMG2:this.MsTrn[i].MsIMG2,	      //品目イメージ2
            MsIMG3:this.MsTrn[i].MsIMG3,	      //品目イメージ3
            MsIMG4:this.MsTrn[i].MsIMG4,	      //品目イメージ4
            MsIMG5:this.MsTrn[i].MsIMG5,	      //品目イメージ5
            MsHCCD:this.MsTrn[i].MsHCCD,     	  //発注先コード
            MsHCNM:this.MsTrn[i].MsHCNM,     	  //発注先名称
            MsHCDTTM:this.MsTrn[i].MsHCDTTM,	  //発注日時
          });
       }
       this.MsTrn.length = 0;
       for (let i = 0; i < this.WMsTrn.length; i++) {
        if(this.WMsTrn[i].MsHICD != this.MsHICD){
          this.MsTrn.push({
            MsHIBJYUN:this.WMsTrn[i].MsHIBJYUN,	//品目大分類並び順
            MsHIBCD:this.WMsTrn[i].MsHIBCD,     //品目大分類コード
            MsHIBNM:this.WMsTrn[i].MsHIBNM,	    //品目大分類名称
            MsSYBRJN:this.WMsTrn[i].MsSYBRJN,	  //品目分類順
            MsSYBRCD:this.WMsTrn[i].MsSYBRCD,	  //品目分類コード
            MsSYBRNM:this.WMsTrn[i].MsSYBRNM,	  //品目分類名称
            MsHIJYUN:this.WMsTrn[i].MsHIJYUN,	  //品目並び順
            MsTAKE:this.WMsTrn[i].MsTAKE,	      //立替商品
            MsJSYA:this.WMsTrn[i].MsJSYA,	      //発注商品
            MsHND:this.WMsTrn[i].MsHND,	        //変動商品
            MsHICD:this.WMsTrn[i].MsHICD,	      //品目コード
            MsHINM:this.WMsTrn[i].MsHINM,	      //品目名称
            MsTAN:this.WMsTrn[i].MsTAN,	        //単位名称
            MsSETFG:this.WMsTrn[i].MsSETFG,	    //セットフラグ
            MsUNT:this.WMsTrn[i].MsUNT,	        //販売単価
            MsSYOKB:this.WMsTrn[i].MsSYOKB,	    //売上消費税区分
            MsSIT:this.WMsTrn[i].MsSIT,	        //仕入単価
            MsSuu:this.WMsTrn[i].MsSuu,		      //売上数量
            MsKin:this.WMsTrn[i].MsKin,		      //売上金額(税抜)
            MsNebiki:this.WMsTrn[i].MsNebiki,		//売上値引(税抜)
            MsGKing:this.WMsTrn[i].MsGKing,		  //売上額値引後(税抜)
            MsUCSYOG:this.WMsTrn[i].MsUCSYOG,		//売上消費税額
            MsZKGK:this.WMsTrn[i].MsZKGK,		    //売上税込額(税込)
            MsBIKO:this.WMsTrn[i].MsBIKO,		    //備考
            MsIMG1:this.WMsTrn[i].MsIMG1,	      //品目イメージ1
            MsIMG2:this.WMsTrn[i].MsIMG2,	      //品目イメージ1
            MsIMG3:this.WMsTrn[i].MsIMG3,	      //品目イメージ1
            MsIMG4:this.WMsTrn[i].MsIMG4,	      //品目イメージ1
            MsIMG5:this.WMsTrn[i].MsIMG5,	      //品目イメージ1
            MsHCCD:this.WMsTrn[i].MsHCCD,     	//発注先コード
            MsHCNM:this.WMsTrn[i].MsHCNM,     	//発注先名称
            MsHCDTTM:this.WMsTrn[i].MsHCDTTM	  //発注日時
          });
        }
      }
      this.MsTrn.push({
          MsHIBJYUN:this.MsHIBJYUN,   //品目大分類並び順
          MsHIBCD:this.MsHIBCD,       //品目大分類コード
          MsHIBNM:this.MsHIBNM,       //品目大分類名称
          MsSYBRJN:this.MsSYBRJN,     //品目分類順
          MsSYBRCD:this.MsSYBRCD,     //品目分類コード
          MsSYBRNM:this.MsSYBRNM,     //品目分類名称
          MsHIJYUN:this.MsHIJYUN,     //品目並び順
          MsTAKE:this.MsTAKE,         //立替商品
          MsJSYA:this.MsJSYA,         //発注商品
          MsHND:this.MsHND,           //変動商品
          MsHICD:this.MsHICD,         //品目コード
          MsHINM:this.MsHINM,         //品目名称
          MsTAN:this.MsTAN,           //単位名称
          MsSETFG:this.MsSETFG,       //セットフラグ
          MsUNT:this.MsUNT,           //販売単価
          MsSYOKB:this.MsSYOKB,       //売上消費税区分
          MsSIT:this.MsSIT,           //仕入単価
          MsSuu:this.MsSuu,           //売上数量
          MsKin:this.MsKin,           //売上金額(税抜)
          MsNebiki:this.MsNebiki,     //売上値引(税抜)
          MsGKing:this.MsGKing,       //売上額値引後(税抜)
          MsUCSYOG:this.MsUCSYOG,     //売上消費税額
          MsZKGK:this.MsZKGK,         //売上税込額(税込)
          MsBIKO:this.MsBIKO,         //備考
          MsIMG1:this.MsIMG1,         //イメージ1
          MsIMG2:this.MsIMG2,         //イメージ2
          MsIMG3:this.MsIMG3,         //イメージ3
          MsIMG4:this.MsIMG4,         //イメージ4
          MsIMG5:this.MsIMG5,         //イメージ5
          MsHCCD:this.MsHCCD,     	  //発注先コード
          MsHCNM:this.MsHCNM,     	  //発注先名称
          MsHCDTTM:this.MsHCDTTM	    //発注日時
        });
         this.G_MEISAI();
      }
      this.dialogHNM = false;
    },
    //----------------------------------------
    //明細合計処理
    //----------------------------------------
    G_MEISAI(){
      let KoTURSMST_dat = 0;      //祭事標準税売上(税抜)
      let KoTURSMKG_dat = 0;      //祭事軽減税売上(税抜)
      let KoTURSMHK_dat = 0;      //祭事非課税売上(税抜)
      let KoTSNEBST_dat = 0;      //祭事標準税値引
      let KoTSNEBKG_dat = 0;      //祭事軽減税値引
      let KoTSNEBHK_dat = 0;      //祭事非課税値引
      let KoTSKAZST_dat = 0;      //祭事標準税対象額計
      let KoTSKAZKG_dat = 0;      //祭事軽減税対象額計
      let KoTSKAZHK_dat = 0;      //祭事非課税対象額計
      let KoTSSYOST_dat = 0;      //祭事標準税消費税
      let KoTSSYOKG_dat = 0;      //祭事軽減税消費税
      let KoTSURKGK_dat = 0;      //祭事売上(税込み)
      let KoTURTMST_dat = 0;      //立替標準税売上(税抜)
      let KoTURTMKG_dat = 0;      //立替軽減税売上(税抜)
      let KoTURTMHK_dat = 0;      //立替非課税売上(税抜)
      let KoTTNEBST_dat = 0;      //立替標準税値引
      let KoTTNEBKG_dat = 0;      //立替軽減税値引
      let KoTTNEBHK_dat = 0;      //立替非課税値引
      let KoTTKAZST_dat = 0;      //立替標準税対象額計
      let KoTTKAZKG_dat = 0;      //立替軽減税対象額計
      let KoTTKAZHK_dat = 0;      //立替非課税対象額計
      let KoTTSYOST_dat = 0;      //立替標準税消費税
      let KoTTSYOKG_dat = 0;      //立替軽減税消費税
      let KoTTURKGK_dat = 0;      //立替売上(税込み)
      let KoTSGK_dat    = 0;      //売掛金総額
      let G_syohi_dat   = 0;      //消費税
      let G_nebiki_dat  = 0;      //値引計
      let G_zeinuki_dat = 0;      //売上(税抜)
      let KS_G_KoHJGK_dat = 0;    //標準原価計
      let KS_G_KoARRI_dat = 0;    //粗利益計

      for (let i = 0; i < this.MsTrn.length; i++) {
        KoTSGK_dat      += this.MsTrn[i].MsZKGK;            //売掛金総額
        G_syohi_dat     += this.MsTrn[i].MsUCSYOG;          //消費税
        G_nebiki_dat    += this.MsTrn[i].MsNebiki;          //値引計
        G_zeinuki_dat   += this.MsTrn[i].MsKin;             //売上(税抜)
        KS_G_KoHJGK_dat += this.MsTrn[i].MsSIT;             //標準原価計
        KS_G_KoARRI_dat = G_zeinuki_dat - KS_G_KoHJGK_dat;  //粗利益計

        if (this.MsTrn[i].MsTAKE != "1:立替")
        {
          KoTSURKGK_dat += this.MsTrn[i].MsZKGK;        //祭事売上(税込み)
          if (this.MsTrn[i].MsSYOKB == "標準税")
          {
            KoTURSMST_dat += this.MsTrn[i].MsKin;      //祭事標準税売上(税抜)
            KoTSNEBST_dat += this.MsTrn[i].MsNebiki;   //祭事標準税値引
            KoTSKAZST_dat += this.MsTrn[i].MsGKing;    //祭事標準税対象額計
            KoTSSYOST_dat += this.MsTrn[i].MsUCSYOG;   //祭事標準税消費税
          }
          if (this.MsTrn[i].MsSYOKB == "軽減税")
          {
            KoTURSMKG_dat += this.MsTrn[i].MsKin;      //祭事軽減税売上(税抜)
            KoTSNEBKG_dat += this.MsTrn[i].MsNebiki;   //祭事軽減税値引
            KoTSKAZKG_dat += this.MsTrn[i].MsGKing;    //祭事軽減税対象額計
            KoTSSYOKG_dat += this.MsTrn[i].MsUCSYOG;   //祭事軽減税消費税
          }
          if (this.MsTrn[i].MsSYOKB == "非課税")
          {
            KoTURSMHK_dat += this.MsTrn[i].MsKin;      //祭事非課税売上(税抜)
            KoTSNEBHK_dat += this.MsTrn[i].MsNebiki;   //祭事非課税値引
            KoTSKAZHK_dat += this.MsTrn[i].MsGKing;    //祭事非課税対象額計
          }
        }
        else
        {
          KoTTURKGK_dat += this.MsTrn[i].MsZKGK;      //立替売上(税込み)
          if (this.MsTrn[i].MsSYOKB == "標準税")
          {
            KoTURTMST_dat += this.MsTrn[i].MsKin;      //立替標準税売上(税抜)
            KoTTNEBST_dat += this.MsTrn[i].MsNebiki;   //立替標準税値引
            KoTTKAZST_dat += this.MsTrn[i].MsGKing;    //立替標準税対象額計
            KoTTSYOST_dat += this.MsTrn[i].MsUCSYOG;   //立替標準税消費税
          }
          if (this.MsTrn[i].MsSYOKB == "軽減税")
          {
            KoTURTMKG_dat += this.MsTrn[i].MsKin;      //立替軽減税売上(税抜)
            KoTTNEBKG_dat += this.MsTrn[i].MsNebiki;   //立替軽減税値引
            KoTTKAZKG_dat += this.MsTrn[i].MsGKing;    //立替軽減税対象額計
            KoTTSYOKG_dat += this.MsTrn[i].MsUCSYOG;   //立替軽減税消費税
          }
          if (this.MsTrn[i].MsSYOKB == "非課税")
          {
            KoTURTMHK_dat += this.MsTrn[i].MsKin;      //立替非課税売上(税抜)
            KoTTNEBHK_dat += this.MsTrn[i].MsNebiki;   //立替非課税値引
            KoTTKAZHK_dat += this.MsTrn[i].MsGKing;    //立替非課税対象額計
          }
       }
     }
      this.KoTURSMST =  KoTURSMST_dat;      //祭事標準税売上(税抜)
      this.KoTURSMKG =  KoTURSMKG_dat;      //祭事軽減税売上(税抜)
      this.KoTURSMHK =  KoTURSMHK_dat;      //祭事非課税売上(税抜)
      this.KoTSNEBST =  KoTSNEBST_dat;      //祭事標準税値引
      this.KoTSNEBKG =  KoTSNEBKG_dat;      //祭事軽減税値引
      this.KoTSNEBHK =  KoTSNEBHK_dat;      //祭事非課税値引
      this.KoTSKAZST =  KoTSKAZST_dat;      //祭事標準税対象額計
      this.KoTSKAZKG =  KoTSKAZKG_dat;      //祭事軽減税対象額計
      this.KoTSKAZHK =  KoTSKAZHK_dat;      //祭事非課税対象額計
      this.KoTSSYOST =  KoTSSYOST_dat;      //祭事標準税消費税
      this.KoTSSYOKG =  KoTSSYOKG_dat;      //祭事軽減税消費税
      this.KoTSURKGK =  KoTSURKGK_dat;      //祭事売上(税込み)
      this.KoTURTMST =  KoTURTMST_dat;      //立替標準税売上(税抜)
      this.KoTURTMKG =  KoTURTMKG_dat;      //立替軽減税売上(税抜)
      this.KoTURTMHK =  KoTURTMHK_dat;      //立替非課税売上(税抜)
      this.KoTTNEBST =  KoTTNEBST_dat;      //立替標準税値引
      this.KoTTNEBKG =  KoTTNEBKG_dat;      //立替軽減税値引
      this.KoTTNEBHK =  KoTTNEBHK_dat;      //立替非課税値引
      this.KoTTKAZST =  KoTTKAZST_dat;      //立替標準税対象額計
      this.KoTTKAZKG =  KoTTKAZKG_dat;      //立替軽減税対象額計
      this.KoTTKAZHK =  KoTTKAZHK_dat;      //立替非課税対象額計
      this.KoTTSYOST =  KoTTSYOST_dat;      //立替標準税消費税
      this.KoTTSYOKG =  KoTTSYOKG_dat;      //立替軽減税消費税
      this.KoTTURKGK =  KoTTURKGK_dat;      //立替売上(税込み)
      this.KoTSGK    =  KoTSGK_dat;         //売掛金総額
    },
    //----------------------------------------
    //プラン明細マスタ表示
    //----------------------------------------
    async SMKSETquery() {
       const fomego = {KoTPLCD: this.KoTPLCD};
        axios.post('/api/SMKSETGeT',fomego)
           .then(response => {
                this.SMKSET = response.data;   //受信配列を格納
            })
            .catch(error => {
            console.log(error)
        })


       let SMKSET_SUU = 1;
      this.MsTrn.length = 0; //配列クリア
      for (let i = 0; i < this.SMKSET.length; i++) {
        if(i == 0){SMKSET_SUU = 1}else{SMKSET_SUU = 0}
       this.MsTrn.push({
          MsHIBJYUN:this.SMKSET[i].SETDIBRJN,	  //品目大分類並び順
          MsHIBCD:this.SMKSET[i].SETDIBRCD,     //品目大分類コード
          MsHIBNM:this.SMKSET[i].SETDIBRNM,	    //品目大分類名称
          MsSYBRJN:this.SMKSET[i].SETSYBRJN,	  //品目分類順
          MsSYBRCD:this.SMKSET[i].SETSYBRCD,	  //品目分類コード
          MsSYBRNM:this.SMKSET[i].SETSYBRNM,	  //品目分類名称
          MsHIJYUN:this.SMKSET[i].SETHIJYUN,	  //品目並び順
          MsTAKE:this.SMKSET[i].SETTAKE,	      //立替商品
          MsJSYA:this.SMKSET[i].SETJSYA,	      //発注商品
          MsHND:this.SMKSET[i].SETHENDO,	      //変動商品
          MsHICD:this.SMKSET[i].SETHICD,	      //品目コード
          MsHINM:this.SMKSET[i].SETHINM,	      //品目名称
          MsTAN:this.SMKSET[i].SETTANNM,	      //単位名称
          MsSETFG:this.SMKSET[i].SETHINF,	      //セットフラグ
          MsUNT:this.SMKSET[i].SETUNT,	        //販売単価
          MsSYOKB:this.SMKSET[i].SETUSYKB,	    //売上消費税区分
          MsSIT:this.SMKSET[i].SETSNT,	        //仕入単価
          JYUN:this.SMKSET[i].SETDIBRJN+':'+this.SMKSET[i].SETSYBRJN+':'+this.SMKSET[i].SETHIJYUN,
          MsSuu:SMKSET_SUU,	                    //売上数量
          MsKin:"0",	                          //売上金額(税抜)
          MsNebiki:"0",	                        //売上値引(税抜)
          MsGKing:"0",	                        //売上額値引後(税抜)
          MsUCSYOG:"0",	                        //売上消費税額
          MsZKGK:"0",	                          //売上税込額(税込)
          MsBIKO:"",	                          //備考
          MsIMG1:this.SMKSET[i].SETIMG1,	      //品目イメージ1
          MsIMG2:this.SMKSET[i].SETIMG2,	      //品目イメージ1
          MsIMG3:this.SMKSET[i].SETIMG3,	      //品目イメージ1
          MsIMG4:this.SMKSET[i].SETIMG4,	      //品目イメージ1
          MsIMG5:this.SMKSET[i].SETIMG5,	      //品目イメージ1
          MsHCCD:this.SMKSET[i].STHCCD,         //発注先コード
          MsHCNM:this.SMKSET[i].STHCNM,   	    //発注先名称
          MsHCDTTM:this.SMKSET[i].STHCDTTM	    //発注日時
        });
      }
      this.G_MEISAI();
    },
    //----------------------------------------
    //テーブル検索マスタ表示
    //----------------------------------------
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    
    close() {
      this.$emit('result', {'res': false, 'KoMCD':'--','KoMNM':'--'})
      this.dialogPL = false;
    },
   //----------------------------------------
   //施行明細 テーブル更新
   //----------------------------------------
      async addMsTrn() {
        const fomeDLgo = {KoTNO: this.KoTNO};
        axios.post('/api/MsTrnDEL',fomeDLgo)
        .then(response => {   //更新後の処理
          this.dmitems = response
          this.dialogDelete = false;  
          this.initialize()
        })
        .catch(error => {
        console.log(error)
        })
  

       //for (let X = 0; X < this.SMsTrn.length; X++) { //受信配列を格納
       // let GOSKYOTrnDL = `mutation DeleteMsTrn {deleteMsTrn(input: {KgyCd: "`+this.KgyCd+`",DSPKEYB: "`+this.KoTNO+`/`+this.SMsTrn[X].MsHIBCD+`/`+	this.SMsTrn[X].MsSYBRCD+`/`+  this.SMsTrn[X].MsHICD+`"}){KgyCd,DSPKEYB}}`;
       // try {
       //   let SOUGISCDL = await API.graphql(graphqlOperation(GOSKYOTrnDL));
       // } catch (error) {
       //     console.log('error: ', error)
       // }
       //}

       let dateKYOU = moment(new Date).format('YYYY/MM/DD h:mm:ss');  //更新日時間
       
       let WSKYTDT = "";
       let WKoTURDT =  moment(this.KoTURDT).format('YYYY/MM/DD');
       let GOSMsTrnCMD = ``;
       for (let i = 0; i < this.MsTrn.length; i++) {
        GOSMsTrnCMD = ``;
        if (this.MsTrn[i].SKYTDT == null || this.MsTrn[i].SKYTDT == "") {
          WSKYTDT = "";
        }else{
          WSKYTDT = moment(this.MsTrn[i].SKYTDT).format('YYYY/MM/DD');    //施行日
        }
        if(this.MsTrn[i].MsIMG1 == null){this.MsTrn[i].MsIMG1 = "";}
        if(this.MsTrn[i].MsIMG2 == null){this.MsTrn[i].MsIMG2 = "";}
        if(this.MsTrn[i].MsIMG3 == null){this.MsTrn[i].MsIMG3 = "";}
        if(this.MsTrn[i].MsIMG4 == null){this.MsTrn[i].MsIMG4 = "";}
        if(this.MsTrn[i].MsIMG5 == null){this.MsTrn[i].MsIMG5 = "";}

        let  W_DSPKEYB = this.KoTNO+"/"+this.MsTrn[i].MsHIBCD+"/"+	this.MsTrn[i].MsSYBRCD+"/"+this.MsTrn[i].MsHICD;
        const fomego = {
        MsNO:this.KoTNO,KoTURDT:WKoTURDT,MsHIBJYUN:this.MsTrn[i].MsHIBJYUN,MsHIBCD:this.MsTrn[i].MsHIBCD,MsHIBNM:this.MsTrn[i].MsHIBNM,
        MsSYBRJN:this.MsTrn[i].MsSYBRJN,MsSYBRCD:this.MsTrn[i].MsSYBRCD,MsSYBRNM:this.MsTrn[i].MsSYBRNM,MsHIJYUN:this.MsTrn[i].MsHIJYUN,MsHICDMsHICD:this.MsTrn[i].MsHICD,MsHINM:this.MsTrn[i].MsHINM,MsTAN:this.MsTrn[i].MsTAN,
        MsTAKE:this.MsTrn[i].MsTAKE,MsSETFG:this.MsTrn[i].MsSETFG,MsUNT:this.MsTrn[i].MsUNT,MsSYOKB:this.MsTrn[i].MsSYOKB,MsSIT:this.MsTrn[i].MsSIT,MsSuu:this.MsTrn[i].MsSuu,MsKin:this.MsTrn[i].MsKin,MsNebiki:this.MsTrn[i].MsNebiki,
        MsGKing:this.MsTrn[i].MsGKing,MsUCSYOG:this.MsTrn[i].MsUCSYOG,MsZKGK:this.MsTrn[i].MsZKGK,MsBIKO:this.MsTrn[i].MsBIKO,
        MsIMG1:this.MsTrn[i].MsIMG1,MsIMG2:this.MsTrn[i].MsIMG2,MsIMG3:this.MsTrn[i].MsIMG3,MsIMG4:this.MsTrn[i].MsIMG4,MsIMG5:this.MsTrn[i].MsIMG5,
        MsHCCD:this.MsTrn[i].MsHCCD,MsHCNM:this.MsTrn[i].MsHCNM,MsHCDTTM:this.MsTrn[i].MsHCDTTM,InstDt:dateKYOU,InstId:this.SynNm,UpdtDt:dateKYOU,UpdtId:this.SynNm}

      axios.post('/api/MsTrnINS',fomego)
         .then(res => {
          })
          .catch(error => {
            console.log(error)
          })

       // const MsTrnUP = {MsNO:this.KoTNO,KoTURDT:WKoTURDT,MsHIBJYUN:this.MsTrn[i].MsHIBJYUN,MsHIBCD:this.MsTrn[i].MsHIBCD,MsHIBNM:this.MsTrn[i].MsHIBNM,
       //     MsSYBRJN:this.MsTrn[i].MsSYBRJN,MsSYBRCD:this.MsTrn[i].MsSYBRCD,MsSYBRNM:this.MsTrn[i].MsSYBRNM,MsHIJYUN:this.MsTrn[i].MsHIJYUN,MsTAKE:this.MsTrn[i].MsTAKE,MsJSYA:"",MsHND:"",MsHICD:this.MsTrn[i].MsHICD,
       //     MsHINM:this.MsTrn[i].MsHINM,MsTAN:this.MsTrn[i].MsTAN,MsSETFG:this.MsTrn[i].MsSETFG,MsUNT:this.MsTrn[i].MsUNT,MsSYOKB:this.MsTrn[i].MsSYOKB,MsSIT:this.MsTrn[i].MsSIT,MsSuu:this.MsTrn[i].MsSuu,MsKin:this.MsTrn[i].MsKin,
       //     MsNebiki:this.MsTrn[i].MsNebiki,MsGKing:this.MsTrn[i].MsGKing,MsUCSYOG:this.MsTrn[i].MsUCSYOG,MsZKGK:this.MsTrn[i].MsZKGK,MsBIKO:this.MsTrn[i].MsBIKO,MsIMG1:this.MsTrn[i].MsIMG1,MsIMG2:this.MsTrn[i].MsIMG2,
       //     MsIMG3:this.MsTrn[i].MsIMG3,MsIMG4:this.MsTrn[i].MsIMG4,MsIMG5:this.MsTrn[i].MsIMG5,InstDt:dateKYOU,InstId:this.SynNm,UpdtDt:dateKYOU,UpdtDy:this.SynNm}

       //await API.graphql(graphqlOperation(createMsTrn,{input: MsTrnUP})); //更新
 
       }
      //  施行ヘッダー更新
      let WKoTSSOGDT   = moment(this.KoTSSOGDT).format('YYYY/MM/DD');     //葬儀日
      let WKoTDESDT    = moment(this.KoTDESDT).format('YYYY/MM/DD');      //死亡日
      let WKoTTYSDT    = moment(this.KoTTYSDT).format('YYYY/MM/DD');      //通夜日
      let WKoTSJDT     = moment(this.KoTSJDT).format('YYYY/MM/DD');       //火葬日
      
      const KoTrnUP = {KoTNO:this.KoTNO,KoThCD:this.KoThCD,KoThNM:this.KoThNM,KoTCD:this.KoTCD,KoTNM:this.KoTNM,KoTPLCD:this.KoTPLCD,KoTPLNM:this.KoTPLNM,KoTPLGK:this.KoTPLGK,KoTURSMST:this.KoTURSMST,
            KoTURSMKG:this.KoTURSMKG,KoTURSMHK:this.KoTURSMHK,KoTSNEBST:this.KoTSNEBST,KoTSNEBKG:this.KoTSNEBKG,KoTSNEBHK:this.KoTSNEBHK,KoTSKAZST:this.KoTSKAZST,KoTSKAZKG:this.KoTSKAZKG,KoTSKAZHK:this.KoTSKAZHK,KoTSSYOST:this.
            KoTSSYOST,KoTSSYOKG:this.KoTSSYOKG,KoTSURKGK:this.KoTSURKGK,KoTURTMST:this.KoTURTMST,KoTURTMKG:this.KoTURTMKG,KoTURTMHK:this.KoTURTMHK,KoTTNEBST:this.KoTTNEBST,KoTTNEBKG:this.KoTTNEBKG,KoTTNEBHK:this.KoTTNEBHK,
            KoTTKAZST:this.KoTTKAZST,KoTTKAZKG:this.KoTTKAZKG,KoTTKAZHK:this.KoTTKAZHK,KoTTSYOST:this.KoTTSYOST,KoTTSYOKG:this.KoTTSYOKG,KoTTURKGK:this.KoTTURKGK,KoTSGK:this.KoTSGK,KoTSHNKG:this.KoTSHNKG,KoTSHZNG:this.KoTSHZNG,
            UpdtDt:dateKYOU,UpdtId:this.SynNm}
    
      axios.post('/api/KoTrnKUD',KoTrnUP)
         .then(res => {
          })
          .catch(error => {
            console.log(error)
          })
    //  await API.graphql(graphqlOperation(updateKoTrn,{input: KoTrnUP})); //更新
      
      this.INUPBTN = '施行明細 更新 処理';
      this.DELBOshow = true; //削除ボタン表示
  
      this.dialogPL = false;
    },
    //-----------------------------------------------------------------
    //施行明細削除処理
    //-----------------------------------------------------------------
    async DELETEMsTrn() {
    if(this.KoTSHNKG == 0){
       this.message  = "既にこの伝票は入金されています、明細の削除は行えません経理に相談して下さい",
       this.dialogERROR = true;
    }else{
       const fomeDLgo = {KoTNO: this.KoTNO};
        axios.post('/api/MsTrnDEL',fomeDLgo)
        .then(response => {   //更新後の処理
          this.dmitems = response
          this.dialogDelete = false;  
          this.initialize()
        })
        .catch(error => {
        console.log(error)
        })
    //let MsTrnQuery = `query ListMsTrns {listMsTrns(filter:{KgyCd:{eq: "`+this.KgyCd+`"},MsNO:{eq:"`+this.KoTNO+`"}},limit:200)
    // {items{KgyCd DSPKEYB MsNO KoTURDT MsHIBJYUN MsHIBCD MsHIBNM MsSYBRJN MsSYBRCD MsSYBRNM MsHIJYUN MsTAKE MsJSYA MsHND MsHICD MsHINM MsTAN MsSETFG MsUNT MsSYOKB MsSIT MsSuu MsKin MsNebiki MsGKing MsUCSYOG MsZKGK MsBIKO MsIMG1 MsIMG2 MsIMG3 MsIMG4 MsIMG5}}}`;
    // let MsTrn_GT = await API.graphql(graphqlOperation(MsTrnQuery));
    
    // this.DMsTrn   = MsTrn_GT.data.listMsTrns.items; //受信配列を格納
    //  for (let X = 0; X < this.DMsTrn.length; X++) { //受信配列を格納
    //    let GOSKYOTrnDL = `mutation DeleteMsTrn {deleteMsTrn(input: {KgyCd: "`+this.KgyCd+`",DSPKEYB: "`+this.KoTNO+`/`+this.SMsTrn[X].MsHIBCD+`/`+	this.SMsTrn[X].MsSYBRCD+`/`+  this.SMsTrn[X].MsHICD+`"}){KgyCd,DSPKEYB}}`;
    //    try {
    //      let SOUGISCDL = await API.graphql(graphqlOperation(GOSKYOTrnDL));
    //    } catch (error) {
    //        console.log('error: ', error)
    //    }
    //   }
      const KoTrnUP = {KoTNO:this.KoTNO,KoThCD:this.KoThCD,KoThNM:this.KoThNM,KoTCD:this.KoTCD,KoTNM:this.KoTNM,KoTPLCD:this.KoTPLCD,KoTPLNM:this.KoTPLNM,
        KoTPLGK:this.KoTPLGK,KoTURSMST:0,KoTURSMKG:0,KoTURSMHK:0,KoTSNEBST:0,KoTSNEBKG:0,KoTSNEBHK:0,KoTSKAZST:0,KoTSKAZKG:0,KoTSKAZHK:0,KoTSSYOST:0,KoTSSYOKG:0,KoTSURKGK:0,KoTURTMST:0,
        KoTURTMKG:0,KoTURTMHK:0,KoTTNEBST:0,KoTTNEBKG:0,KoTTNEBHK:0,KoTTKAZST:0,KoTTKAZKG:0,KoTTKAZHK:0,KoTTSYOST:0,KoTTSYOKG:0,KoTTURKGK:0,KoTSGK:0,KoTSHZNG:0,UpdtDt:dateKYOU,UpdtId:this.SynNm}
      
      axios.post('/api/KoTrnUPDT',KoTrnUP)
         .then(res => {
          })
          .catch(error => {
            console.log(error)
          })
      // await API.graphql(graphqlOperation(updateKoTrn,{input: KoTrnUP})); //更新
 
       this.dialogPL = false;
      }
    },
    //----------------------------------------
    //エラー確認
    //----------------------------------------
    ERRORConfirm () {
        this.dialogERROR = false;
    },
   },
  components: {
    appDialogSMTKK: SMTKKDialog,      //提携先企業選択
    appDialogSMKPlan: SMKPlanDialog,  //プランマスタ選択
    appDialogHNM: HNMDialog,          //品目選択
  }
}
</script>