<template>
 <v-dialog v-model="dialogKMSMM" fullscreen hide-overlay transition="dialog-bottom-transition">
   <v-app id="inspire">
  <v-card width="100%">
    <v-toolbar flat color="grey lighten-2" height="30">
      <v-toolbar-title>供物品目検索(popKMSMM)</v-toolbar-title>
    </v-toolbar> 
    <v-row>
      <v-subheader></v-subheader>
      <v-col cols="6"  md="6" sm="6" xsmd="6">
        <div ><v-btn x-large color="success" dark v-on:click="agreeok" block><v-icon left>mdi-gavel</v-icon>供物品目選択確定</v-btn></div>
      </v-col>
       <v-col cols="4"  md="4" sm="4" xsmd="4">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
        <v-row>
         <v-subheader></v-subheader>
          <v-subheader><strong>供物品目</strong></v-subheader>
          <v-subheader><v-alert dense>{{ HINMOCD }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ HINMONM }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ HINMOTK }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ HINMOTNNM }}</v-alert></v-subheader>
          <v-subheader><v-alert dense>{{ HINMOSHKG }}</v-alert></v-subheader>
        </v-row>
    </v-row>
    <v-card-title>プラン詳細
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="供物品目検索" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="KMSMM" :search="search" @click:row="clickRow"></v-data-table>
  </v-card>
  </v-app>
</v-dialog>
</template>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import { listKmsmms } from "../graphql/queries";
export default {
  name: "KMSMM_DSP",
  data() {
    return {
        search: '',
        dialogKMSMM:false,
        KMSMM: [],
        HINMOCD:  "", //品目コード
        HINMONM:  "", //品目名
        HINMOUK5: "", //自社フラグ
        HINMOTNNM:"", //単位名
        HINMOTK:  "", //単価
        HINMOSHKG:"", //消費税区分
        HINSITK:  "", //仕入単価
        KgyCd:    "",  //企業コード
        text:     "",
        headers: [
            { text: 'コード', align: 'start',value: 'HINMOCD',width: '80'},
            { text: '品目名称', value: 'HINMONM',width: '100'},
            { text: '単価',  value: 'HINMOTK',width: '80' },
            { text: '単位',  value: 'HINMOTNNM',width: '80' },
            { text: '税区',  value: 'HINMOSHKG',width: '80' },
      ],
     }
  },
  methods: {
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------
    open() {
     if(localStorage.SOGI_KgyCd) this.KgyCd = localStorage.SOGI_KgyCd;
     this.KMSMMShow();
     this.dialogKMSMM = true;
     this.$emit('result', {'res': false, 'HINMOCD':'--','HINMONM':'--'})
    },
    close() {
      this.$emit('result', {'res': false, 'HINMOCD':'--','HINMONM':'--'})
      this.dialogKMSMM = false;
    },
    agreeok() {
      this.$emit('result', {'res': true,
        'HINMOCD':this.HINMOCD,     //品目コード
        'HINMONM':this.HINMONM,     //品目名
        'HINMOUK5':this.HINMOUK5,   //自社フラグ
        'HINMOTNNM':this.HINMOTNNM, //単位名
        'HINMOTK':this.HINMOTK,     //単価
        'HINMOSHKG':this.HINMOSHKG, //消費税区分
        'HINSITK':this.HINSITK,     //仕入単価
      })
      this.dialogKMSMM = false;
     },
     async KMSMMShow() {
       axios.post('/api/SMHINMKUALL')
        .then(response => {
          this.KMSMM = response.data
        })
        .catch(error => {
        console.log(error)
      })
 },
    //----------------------------------------
    //行選択時
    //----------------------------------------
    clickRow(row) {
        this.HINMOCD	= row.HINMOCD;    //品目コード
        this.HINMONM	= row.HINMONM;    //品目名
        this.HINMOUK5	= row.HINMOUK5;   //自社フラグ
        this.HINMOTNNM	= row.HINMOTNNM;  //単位名
        this.HINMOTK	= row.HINMOTK;    //単価
        this.HINMOSHKG	= row.HINMOSHKG;  //消費税区分
        this.HINSITK	= row.HINSITK;   //仕入単価
      },
     //----------------------------------------
    //テーブル検索マスタ表示
    //----------------------------------------
    /* eslint-disable */
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  },
} 
</script>