<template>
  <v-dialog v-model="dialogIR" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar flat  height="20" dark ><v-toolbar-title>供物一覧(SekoKUMTICHR) 祭事伝票№： {{this.KoTNO}}</v-toolbar-title></v-toolbar>
    <v-app id="inspire">
      
      <v-card width="100%">
        <v-row>
          <v-col cols="10" md="10">
          </v-col>
          <v-col cols="2" md="2">
            <v-btn  fab dark small color="pink" v-on:click="close">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="供物検索" single-line hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" v-on:click="KMDialogshow" ><v-icon left>mdi-pencil</v-icon>新規供物追加</v-btn>
              <app-dialogKM :text="text" ref="dialogKM" @result="responseKM"></app-dialogKM>
           </v-card-title>
            <v-data-table :headers="headers" :items="KUMtrm" :search="search" @click:row="clickRow"></v-data-table>
          </v-card>
        </v-row>
      </v-card>
    </v-app>
  </v-dialog>
</template>
<script>
  //  Vue.use(VueMask.VueMaskPlugin);
</script>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import axiosJsonpAdapter  from 'axios-jsonp'
//import { listKuMtrms }    from "../graphql/queries";           //供物売上
import moment from "moment";
import KMDialog   from "../views/SekoKUMTinput.vue";   //供物入力
export default { 
  data () {
     // テンプレート内で保有するデータ  
      return {
      KUMtrm: [],
      search: '',
      headers: [
        { text: '伝票番号', align: 'start',value: 'KUMDNNO',width: '100'},
        { text: '受付日', value: 'KUUTDT',width: '150'},
        { text: 'ご当家請求',  value: 'KUTOUKE',width: '120' },
        { text: '請求先', value: 'KUMSKCD', width: '150' },
        { text: '請求先漢字',  value: 'KUMSNM1',width: '120' },
        { text: '請求先漢字', value: 'KUMSNM2',width: '120' },
        { text: '電話', value: 'KUMTEL',width: '150' },
        { text: '携帯', value: 'KUMTELK',width: '150' },
        { text: '商品名称', value: 'KUMSYNM',width: '150' },
        { text: '売上計', value: 'KUMUAGK',width: '90' },
        { text: '入金額', value: 'KUMNKG',width: '90' },
        { text: '売掛残', value: 'KUMZNG',width: '90' },
        { text: '操作', value: 'actions', sortable: false,width: '100' },
      ],
        PL_KIGYOUID:"",           //グローバル企業コード
        PL_Tanmtid:"",            //グローバル端末ID
        KoTNO:'',                 //受付№
        KoThCD:'',                //提携コード
        KoThNM:'',                //提携名称
        KoTCD:'',                 //提携取引先コード
        KoTNM:'',                 //提携取引先名称
        KoTPLCD:'',               //プランコード
        KoTPLNM :'',              //プラン名称
        KoTPLGK :'',              //プラン金額
        //供物一覧受け取り
        KUMDNNO	:'', //	伝票番号
        KUMUKNO	:'', //	受付番号
        KUMNODT	:'', //	売上日
        KUUTDT	:'', //	受付日
        KUMBLCD	:'', //	部署コード
        KUMBLNM	:'', //	部署名称
        KUMTNCD	:'', //	営業担当者コード
        KUMTNNM	:'', //	営業担当名称
        KUMSOKE	:'', //	葬家
        KUMKNM	:'', //	故人
        KUMMNM	:'', //	喪主
        KUTOUKE	:'', //	ご当家請求
        KUMSKCD	:'', //	請求先コード
        KUMSNM1	:'', //	請求先漢字1
        KUMSNM2	:'', //	請求先漢字2
        KUMTEL	:'', //	電話番号
        KUMTELK	:'', //	携帯番号
        KUMFAX	:'', //	ファックス
        KUMSYU	:'', //	請求先郵便番号
        KUMSJY1	:'', //	請求先住所1
        KUMSJY2	:'', //	請求先住所2
        KUMSJY3	:'', //	請求先住所3
        KUMYSYU	:'', //	送付先郵便番号
        KUMYSJY1:'', //	送付先住所1
        KUMYSJY2:'', //	送付先住所2
        KUMYSJY3:'', //	送付先住所3
        KUMNBI	:'', //	納品日
        KUMNTM	:'', //	納品時間
        KUMNBS	:'', //	納品場所
        KUMSYCD	:'', //	商品コード
        KUMSYNM	:'', //	商品名称
        KUMSYUG	:'', //	売上単価
        KUMTNI	:'', //	単位
        KUMSYKB	:'', //	売上消費税区分
        KUMSYSG	:'', //	仕入単価
        KUMSuu	:'', //	売上数量
        KUMZRGK	:'', //	売上金額(税抜)
        KUMNbk	:'', //	売上値引(税抜)
        KUMURI	:'', //	売上額値引後(税抜)
        KUMSZGK	:'', //	売上消費税計
        KUMUAGK	:'', //	売上合計(税込)
        KUMNKG	:'', //	売掛入金金額
        KUMZNG	:'', //	売掛残高
        KUMCHDT	:'', //	発注完了日
        KUMCHTM	:'', //	発注完了時間
        KUMSCSDT:'', //	請求完了日
        KUMCSTM	:'', //	請求完了時間
        KUMTKCD	:'', //	提携先コード
        KUMTKNM	:'', //	提携先名称
        KUMTKWR	:'', //	割引率
        KUMNA11	:'', //	名札11
        KUMNA12	:'', //	名札12
        KUMNA21	:'', //	名札21
        KUMNA22	:'', //	名札22
        URZHJ	  :'', //	標準税売上(税抜)
        URZKG	  :'', //	軽減税売上(税抜)
        URZHK	  :'', //	非課税売上(税抜)
        NEBHJ	  :'', //	標準税値引
        NEBKG	  :'', //	軽減税値引
        NEBHK	  :'', //	非課税値引
        TSGHJ	  :'', //	標準税対象額計
        TSGKG	  :'', //	軽減税対象額計
        TSGHK	  :'', //	非課税対象額計
        SHZHJ	  :'', //	標準税消費税
        SHZKG	  :'', //	軽減税消費税
        KUMHJGK :'', //標準原価計
        KUMARRI :'', //粗利益計
        KUMTELN :'', //供物電話番号CTI
        KUMTELKN:'', //供物携帯番号CTI
        KUHCCD  :'', //発注先コード
        KUHCNM  :'', //発注先名称
        KUHCDTTM:'', //発注日時
        KUHCENDF:'', //発注完了
        InstDt	:'', //	作成日時
        InstId	:'', //	作成者
        dialogIR:false,
        date: new Date().toISOString().substr(0, 10),
         text: '',
        //初期設定
        KgyCd:"",     //初期設定企業コード
        KgyNM:"",     //初期設定企業名称
        BLCKCD:"",    //初期設定部署コード
        BLCKNM:"",    //初期設定部署名称
        SynCd:"",     //初期設定社員名称
        SynNm:"",     //初期設定社員名称
        TANID:"",     //初期設定端末ID
        DENNO:"0",    //初期設定伝票番号
        GNZHJ:"",     //初期設定現在標準税
        GNZKG:"",     //初期設定現在軽減税
        KGZDT:"",     //初期設定消費税切替日
        KGZHJ:"",     //初期設定次期標準税
        KGZKG:"",     //初期設定次期軽減税
     }
    },
   methods: {
     //-----------------------------------------------------------------
     //オープン処理
     //-----------------------------------------------------------------
    open() {     
      this.dialogIR = true;
      this.PL_KIGYOUID = this.$store.getters.GET_kigyoucd;  //グローバル企業コード
      this.PL_Tanmtid  = this.$store.getters.GET_Tanmtid;   //グローバル端末ID

      if(localStorage.SOGI_KgyCd)   this.KgyCd      = localStorage.SOGI_KgyCd;    //初期設定企業コード
      if(localStorage.SOGI_KgyNM)   this.KgyNM      = localStorage.SOGI_KgyNM;    //初期設定企業名称
      if(localStorage.SOGI_BLCKCD)  this.BLCKCD     = localStorage.SOGI_BLCKCD;   //初期設定部署コード
      if(localStorage.SOGI_BLCKNM)  this.BLCKNM     = localStorage.SOGI_BLCKNM;   //初期設定部署名称
      if(localStorage.SOGI_SynCd)   this.SynCd      = localStorage.SOGI_SynCd;    //初期設定社員名称
      if(localStorage.SOGI_SynNm)   this.SynNm      = localStorage.SOGI_SynNm;    //初期設定社員名称
      if(localStorage.SOGI_TANID)   this.TANID      = localStorage.SOGI_TANID;    //初期設定端末ID
      if(localStorage.SOGI_DENNO)   this.DENNO      = localStorage.SOGI_SGDENNO;  //初期設定伝票番号
      if(localStorage.SOGI_GNZHJ)   this.GNZHJ      = localStorage.SOGI_GNZHJ;    //初期設定現在標準税
      if(localStorage.SOGI_GNZKG)   this.GNZKG      = localStorage.SOGI_GNZKG;    //初期設定現在軽減税
      if(localStorage.SOGI_KGZDT)   this.KGZDT      = localStorage.SOGI_KGZDT;    //初期設定消費税切替日
      if(localStorage.SOGI_KGZHJ)   this.KGZHJ      = localStorage.SOGI_KGZHJ;    //初期設定次期標準税
      if(localStorage.SOGI_KGZKG)   this.KGZKG      = localStorage.SOGI_KGZKG;    //初期設定次期軽減税
      if(localStorage.SOGI_KoTNO)   this.KoTNO      = localStorage.SOGI_KoTNO;  //受付番号
 
      if(localStorage.SOGI_KoTSSOGDT)this.KoTSSOGDT = localStorage.SOGI_KoTSSOGDT;//葬儀日
      if(localStorage.SOGI_KoTNO)   this.KUMUKNO     = localStorage.SOGI_KoTNO;  //受付番号
      if(localStorage.SOGI_KUMDNNO)  this.KUMDNNO   = localStorage.SOGI_KUMDNNO;  //伝票番号
      if(localStorage.SOGI_KUMKNM)   this.KUMKNM    = localStorage.SOGI_KUMKNM;	  //故人
      if(localStorage.SOGI_KUMMNM)   this.KUMMNM    = localStorage.SOGI_KUMMNM;	  //喪主
      if(localStorage.SOGI_KUMSOKE)  this.KUMSOKE   = localStorage.SOGI_KUMSOKE;	//喪家



      this.KUMtrmquery();  //供物一覧取得
    },
    //----------------------------------------
    //テーブルの行が押された
    //----------------------------------------
    clickRow(row) {
      localStorage.SOGI_KUMDNNO = row.KUMDNNO;	  //伝票番号
      localStorage.SOGI_KUMUKNO = row.KUMUKNO;	  //受付番号
      localStorage.SOGI_KUMNODT = row.KUMNODT;	  //売上日
      localStorage.SOGI_KUUTDT  = row.KUUTDT;	    //受付日
      localStorage.SOGI_KUMBLCD	= row.KUMBLCD;	  //部署コード
      localStorage.SOGI_KUMBLNM = row.KUMBLNM;	  //部署名称
      localStorage.SOGI_KUMTNCD = row.KUMTNCD;	  //営業担当者コード
      localStorage.SOGI_KUMTNNM = row.KUMTNNM;	  //営業担当名称
      localStorage.SOGI_KUMSOKE = row.KUMSOKE;	  //葬家
      localStorage.SOGI_KUMKNM	= row.KUMKNM;	    //故人
      localStorage.SOGI_KUMMNM	= row.KUMMNM;	    //喪主
      localStorage.SOGI_KUTOUKE	= row.KUTOUKE;	  //ご当家請求
      localStorage.SOGI_KUMSKCD	= row.KUMSKCD;	  //請求先コード
      localStorage.SOGI_KUMSNM1	= row.KUMSNM1;	  //請求先漢字1
      localStorage.SOGI_KUMSNM2	= row.KUMSNM2;	  //請求先漢字2
      localStorage.SOGI_KUMTEL	= row.KUMTEL;	    //電話番号
      localStorage.SOGI_KUMTELK	= row.KUMTELK;	  //携帯番号
      localStorage.SOGI_KUMFAX	= row.KUMFAX;	    //ファックス
      localStorage.SOGI_KUMSYU	= row.KUMSYU;	    //請求先郵便番号
      localStorage.SOGI_KUMSJY1	= row.KUMSJY1;	  //請求先住所1
      localStorage.SOGI_KUMSJY2	= row.KUMSJY2;	  //請求先住所2
      localStorage.SOGI_KUMSJY3	= row.KUMSJY3;	  //請求先住所3
      localStorage.SOGI_KUMYSYU	= row.KUMYSYU;	  //送付先郵便番号
      localStorage.SOGI_KUMYSJY1= row.KUMYSJY1;	  //送付先住所1
      localStorage.SOGI_KUMYSJY2= row.KUMYSJY2;	  //送付先住所2
      localStorage.SOGI_KUMYSJY3= row.KUMYSJY3;	  //送付先住所3
      localStorage.SOGI_KUMNBI	= row.KUMNBI;	    //納品日
      localStorage.SOGI_KUMNTM	= row.KUMNTM;	    //納品時間
      localStorage.SOGI_KUMNBS	= row.KUMNBS;	    //納品場所
      localStorage.SOGI_KUMSYCD	= row.KUMSYCD;	  //商品コード
      localStorage.SOGI_KUMSYNM	= row.KUMSYNM;	  //商品名称
      localStorage.SOGI_KUMSYUG	= row.KUMSYUG;	  //売上単価
      localStorage.SOGI_KUMTNI	= row.KUMTNI;	    //単位
      localStorage.SOGI_KUMSYKB	= row.KUMSYKB;	  //売上消費税区分
      localStorage.SOGI_KUMSYSG	= row.KUMSYSG;	  //仕入単価
      localStorage.SOGI_KUMSuu	= row.KUMSuu;	    //売上数量
      localStorage.SOGI_KUMZRGK	= row.KUMZRGK;	  //売上金額(税抜)
      localStorage.SOGI_KUMNbk	= row.KUMNbk;	    //売上値引(税抜)
      localStorage.SOGI_KUMURI	= row.KUMURI;	    //売上額値引後(税抜)
      localStorage.SOGI_KUMSZGK	= row.KUMSZGK;	  //売上消費税計
      localStorage.SOGI_KUMUAGK	= row.KUMUAGK;	  //売上合計(税込)
      localStorage.SOGI_KUMNKG	= row.KUMNKG;	    //売掛入金金額
      localStorage.SOGI_KUMZNG	= row.KUMZNG;	    //売掛残高
      localStorage.SOGI_KUMCHDT	= row.KUMCHDT;	  //発注完了日
      localStorage.SOGI_KUMCHTM	= row.KUMCHTM;	  //発注完了時間
      localStorage.SOGI_KUMSCSDT= row.KUMSCSDT;	  //請求完了日
      localStorage.SOGI_KUMCSTM	= row.KUMCSTM;	  //請求完了時間
      localStorage.SOGI_KUMTKCD	= row.KUMTKCD;	  //提携先コード
      localStorage.SOGI_KUMTKNM	= row.KUMTKNM;	  //提携先名称
      localStorage.SOGI_KUMTKWR	= row.KUMTKWR;	  //割引率
      localStorage.SOGI_KUMNA11	= row.KUMNA11;	  //名札11
      localStorage.SOGI_KUMNA12	= row.KUMNA12;	  //名札12
      localStorage.SOGI_KUMNA21	= row.KUMNA21;	  //名札21
      localStorage.SOGI_KUMNA22	= row.KUMNA22;	  //名札22
      localStorage.SOGI_URZHJ	  = row.URZHJ;	    //標準税売上(税抜)
      localStorage.SOGI_URZKG	  = row.URZKG;	    //軽減税売上(税抜)
      localStorage.SOGI_URZHK	  = row.URZHK;	    //非課税売上(税抜)
      localStorage.SOGI_NEBHJ	  = row.NEBHJ;	    //標準税値引
      localStorage.SOGI_NEBKG	  = row.NEBKG;	    //軽減税値引
      localStorage.SOGI_NEBHK	  = row.NEBHK;	    //非課税値引
      localStorage.SOGI_TSGHJ	  = row.TSGHJ;	    //標準税対象額計
      localStorage.SOGI_TSGKG	  = row.TSGKG;	    //軽減税対象額計
      localStorage.SOGI_TSGHK	  = row.TSGHK;	    //非課税対象額計
      localStorage.SOGI_SHZHJ	  = row.SHZHJ;	    //標準税消費税
      localStorage.SOGI_SHZKG	  = row.SHZKG;	    //軽減税消費税
      localStorage.SOGI_KUMHJGK	= row.KUMHJGK;    //標準原価計
      localStorage.SOGI_KUMARRI	= row.KUMARRI;    //粗利益計
      localStorage.SOGI_KUMTELN	= row.KUMTELN;    //供物電話番号CTI
      localStorage.SOGI_KUMTELKN= row.KUMTELKN;  //供物携帯番号CTI
      localStorage.SOGI_KUHCCD	= row.KUHCCD;     //発注先コード
      localStorage.SOGI_KUHCNM	= row.KUHCNM;     //発注先名称
      localStorage.SOGI_KUHCDTTM= row.KUHCDTTM;  //発注日時
      localStorage.SOGI_KUHCENDF= row.KUHCENDF;  //発注完了
      localStorage.SOGI_InstDt	= row.InstDt;	    //作成日時
      localStorage.SOGI_InstId	= row.InstId;	    //作成者
      localStorage.SOGICA_KoTNO	= row.KUMUKNO;	  //受付番号
      
      this.$refs.dialogKM.open();
 },
 //----------------------------------------
 //供物一覧表
 //----------------------------------------
 async KUMtrmquery() {
   this.KUMtrm.length = 0;
 
   const fomego = {KUMUKNO: this.KUMUKNO};
      axios.post('/api/KUMtrmDNGET',fomego)
           .then(response => {
                this.KUMtrm = response.data;
            })
            .catch(error => {
            console.log(error)
      })
 },
 //----------------------------------------
 //供物新規入力
 //----------------------------------------
 KMDialogshow() {
  localStorage.SOGI_KUMDNNO= "";	  //伝票番号
  this.$refs.dialogKM.open();
 },
  //供物入力
  responseKM(obj) {
      if(obj.KoMCD != "--"){  //キャンセル時は何もしない
          localStorage.SOGI_KoTNO      = this.KoTNO;           //受付番号
          localStorage.SOGI_KoTURDT    = this.KoTURDT;         //売上日
          localStorage.SOGI_KoTUKDT    = this.WKoTUKDT;        //受付日
          localStorage.SOGI_KoTUKJI    = this.WKoTUKJI;        //受付時
          localStorage.SOGI_KoTUKFN    = "";                   //受付分
          localStorage.SOGI_KoTBLOKCD  = this.KoTBLOKCD;       //部署コード
          localStorage.SOGI_KoTBLOKNM  = this.KoTBLOKNM;       //部署名称
          localStorage.SOGI_KoETNTNCD  = this.KoETNTNCD;       //営業担当コード
          localStorage.SOGI_KoETNTNNM  = this.KoETNTNNM;       //営業担当名称
          localStorage.SOGI_KoETNTNNMS = this.KoETNTNNMS;      //主担当略称
          localStorage.SOGI_KoTKANO    = this.KoTKANO;         //会員番号
          localStorage.SOGI_KoTKANM    = this.KoTKANM;         //会員名称
          localStorage.SOGI_KoTMOKE    = this.KoTMOKE;         //喪家
          localStorage.SOGI_KoThCD     = this.KoThCD;          //顧客取引先コード
          localStorage.SOGI_KoThNM     = this.KoThNM;          //顧客取引先名称
          localStorage.SOGI_KoTCD      = this.KoTCD;           //顧客提携区分コード
          localStorage.SOGI_KoTNM      = this.KoTNM;           //顧客提携区分名称
          localStorage.SOGI_KoTTKNM    = this.KoTTKNM;         //故人(カナ）
          localStorage.SOGI_KoTTNM     = this.KoTTNM;          //故人名前
          localStorage.SOGI_KoTTBSDTJ  = this.KoTTBSDTJ;       //故人生年月日(和暦）
          localStorage.SOGI_KoTTEG     = this.KoTTEG;          //故人年齢
          localStorage.SOGI_KoTTSEX    = this.KoTTSEX;         //故人性別
          localStorage.SOGI_KoTTNEX    = this.KoTTNEX;         //故人続柄
          localStorage.SOGI_KoTTYU     = this.KoTTYU;          //故人郵便番号
          localStorage.SOGI_KoTTJYU1   = this.KoTTJYU1;        //故人住所1
          localStorage.SOGI_KoTTJYU2   = this.KoTTJYU2;        //故人住所2
          localStorage.SOGI_KoTTJYU3   = this.KoTTJYU3;        //故人住所3
          localStorage.SOGI_KoTTTEL    = this.KoTTTEL;         //故人電話番号
          localStorage.SOGI_KoTMKNM    = this.KoTMKNM;         //喪主(カナ）
          localStorage.SOGI_KoTMNM     = this.KoTMNM;          //喪主名前
          localStorage.SOGI_KoTMNEX    = this.KoTMNEX;         //喪主続柄
          localStorage.SOGI_KoTMSEX    = this.KoTMSEX;         //喪主性別
          localStorage.SOGI_KoTMYU     = this.KoTMYU;          //喪主郵便番号
          localStorage.SOGI_KoTMJYU1   = this.KoTMJYU1;        //喪主住所1
          localStorage.SOGI_KoTMJYU2   = this.KoTMJYU2;        //喪主住所2
          localStorage.SOGI_KoTMJYU3   = this.KoTMJYU3;        //喪主住所3
          localStorage.SOGI_KoTMTEL    = this.KoTMTEL;         //喪主電話番号
          localStorage.SOGI_KoTMTELK   = this.KoTMTELK;        //喪主携帯
          localStorage.SOGI_KoTMTMail  = this.KoTMTMail;       //喪主Mail
          localStorage.SOGI_KoTMBIKO   = this.KoTMBIKO;        //喪主備考
          localStorage.SOGI_KoTSNM     = this.KoTSNM;          //お支払責任者名前
          localStorage.SOGI_KoTSKNM    = this.KoTSKNM;         //お支払カナ

          localStorage.SOGI_KoTSSOGDT = this.KoTSSOGDT;   //葬儀日
          localStorage.SOGI_KUMKNM    = this.KUMKNM;	    //故人
          localStorage.SOGI_KUMMNM    = this.KUMMNM;	    //喪主
          localStorage.SOGI_KUMSOKE   = this.KUMSOKE;	    //喪家
          localStorage.SOGI_KoTNO   = this.KoTNO;       //受付番号
          localStorage.SOGI_KUMDNNO	  = "";	              //伝票番号
          localStorage.SOGICA_KoTNO   = this.KoTNO;
          
          this.KUMtrmquery();
      }
      
       this.dialogKM = false;
    },
    //----------------------------------------
    //テーブル検索マスタ表示
    //----------------------------------------
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    //----------------------------------------
    //終了
    //----------------------------------------
    close() {
      this.$emit('result', {'res': false, 'KoMCD':'--','KoMNM':'--'})
      this.dialogIR = false;
    },
},
  components: {
          appDialogKM: KMDialog,       //供物入力
  }
}
</script>