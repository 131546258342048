<template>
 <v-row justify="center">
 <v-dialog v-model="dialogSYEIG" width="800">
  <v-card>
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>社員選択(SYAIN)</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="2" md="2">
        <v-text-field v-model="hjSynBLCKCD" :counter="10" label="部署コード" required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
       <v-text-field v-model="hjSynBLCKNM" :counter="10" label="部署名" required></v-text-field>
      </v-col>
      <v-col cols="2" md="2">
        <v-text-field v-model="hjSynCd" :counter="10" label="社員コード"  required></v-text-field>
      </v-col>
      <v-col cols="3" md="3">
        <v-text-field v-model="hjSynNm" :counter="10" label="社員名" required></v-text-field>
      </v-col>
      <v-col cols="2" md="2">
        <v-text-field v-model="hjSynNmS" :counter="10" label="略称" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" md="8">
        <div >
          <v-btn x-large color="success" dark v-on:click="agreeok"><v-icon left>mdi-pencil</v-icon>社員選択確定</v-btn>
        </div>
      </v-col>
       <v-col cols="4" md="4">
        <v-btn  fab dark small color="pink" v-on:click="close">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table :fixed-header="true" class="SYAIN_DSP"  :dense="true" height="500">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">部署コード</th>
            <th class="text-left">部署名</th>
            <th class="text-left">社員コード</th>
            <th class="text-left">社員名</th>
            <th class="text-left">略称</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(SYAIN,index) in SYAIN" :key="index" @click="showAlert2(SYAIN)">
            <td>{{ SYAIN.SynBLCKCD }}</td>
            <td>{{ SYAIN.SynBLCKNM }}</td>
            <td>{{ SYAIN.SynCd }}</td>	
            <td>{{ SYAIN.SynNm }}</td>
            <td>{{ SYAIN.SynNmS }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</v-dialog>
</v-row>
</template>
<script>
import axios from 'axios';

export default {
  name: "SYAIN_DSP",
  data() {
    return {
      dialogSYEIG:false,
      SYAIN: [],
      hjSynCd:      "",   //社員コード  選択
      hjSynNm:      "",   //社員名      選択
      hjSynBLCKCD:  "",   //部署コード  選択
      hjSynBLCKNM:  "",   //部署名      選択
      hjSynNmS:     "",   //社員名略称
      hjSynESK:     "",   //営業スキル
      hjSynSSK:     "",   //司会スキル
      hjSynKntJN:   "",   //勤怠並び順
      hjSynKntEG:   "",   //営業
      hjSynKntSK:   "",   //司会
      PL_BRMSID:    ""    
     }
  },
  //社員マスタ取得
  methods: {
    async SYAINquery() {
     //  const fomego = { BRMSDCD: this.PL_BRMSID};
        axios.post('/api/syainEIGGET')
        .then(response => {
          this.SYAIN = response.data;
        //  console.log(this.SYAIN);
        })
        .catch(error => {
        console.log(error)
      })
      this.dialogSYEIG = true;
    },
    //オープン時
    open() {
      this.dialogSYEIG = true;
      this.SYAINquery();     //社員マスタ取得
    },
    close() {
      this.$emit('result', {'res': false, 'SynCd':'--'})
      this.dialogSYEIG = false;
    },
    agreeok() {
      //hjSynCd      社員コード
      //hjSynNm      社員名
      //hjSynBLCKCD  部署コード
      //hjSynBLCKNM  部署名
      this.$emit('result', {
        'res': true,
        'SynCd':this.hjSynCd,
        'SynNm':this.hjSynNm,
        'SynBLCKCD':this.hjSynBLCKCD,
        'SynBLCKNM':this.hjSynBLCKNM,
        'SynNmS':this.hjSynNmS    //社員名略称
   //     'SynESK':this.hjSynESK,    //営業スキル
   //     'SynSSK':this.hjSynSSK,    //司会スキル
   //     'SynKntJN':this.hjSynKntJN,  //勤怠並び順
   //     'SynKntEG':this.hjSynKntEG,  //営業
   //     'SynKntSK':this.hjSynKntSK  //司会
       })
      this.dialogSYEIG = false;
     },
    showAlert2: function(item) {
      this.hjSynCd      = item.SynCd;     //社員コード
      this.hjSynNm      = item.SynNm;     //社員名
      this.hjSynBLCKCD  = item.SynBLCKCD; //部署コード
      this.hjSynBLCKNM  = item.SynBLCKNM; //部署名
      this.hjSynNmS     = item.SynNmS;    //社員名略称
 //     this.hjSynESK     = item.SynESK;    //営業スキル
 //     this.hjSynSSK     = item.SynSSK;    //司会スキル
 //     this.hjSynKntJN   = item.SynKntJN;  //勤怠並び順
 //     this.hjSynKntEG   = item.SynKntEG;  //営業
 //     this.hjSynKntSK   = item.SynKntSK;  //司会
    },
  },
 }
</script>