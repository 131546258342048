<template>
 <v-dialog v-model="dialogIN">
 <v-app id="inspire">
  <v-card width="100%">
	<v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
          <v-col  cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-spacer>{{ message }}</v-spacer>
          </v-col>
    </v-alert>
	<v-toolbar flat color="grey lighten-3" height="30"><v-toolbar-title>施行見積印刷(SekoInsatuiinp):{{ KoTNO }}</v-toolbar-title></v-toolbar>
		<v-row>
			<v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5"/>
			<v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5"/>
			<v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2">
				<v-btn  fab dark small color="pink" v-on:click="close">
					<v-icon dark>mdi-minus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6" xs="12" sm="6" md="6" lg="4" xl="4">
				<div class="my-2"><v-btn x-large color="success" v-on:click="BOmakeexcel" dark block><v-icon left>mdi-printer</v-icon>受付簿印刷</v-btn></div>
			</v-col>
			<v-col cols="4" xs="12" sm="4" md="4" lg="4" xl="4">
                <v-alert>{{ KoMUKBDT }}</v-alert>
			</v-col>
			<v-col cols="2" xs="12" sm="2" md="2" lg="4" xl="4">
                <v-alert>{{ KoMUKBTM }}</v-alert>
			</v-col>        
    </v-row>
    <v-row>
        <v-col cols="6"  xs="12" sm="6" md="6" lg="4" xl="4">
            <div class="my-2"><v-btn x-large color="success" v-on:click="SJmakeexcel" dark block><v-icon left>mdi-printer</v-icon>祭事見積書印刷</v-btn></div>
        </v-col>
		<v-col cols="4" xs="12" sm="4" md="4" lg="4" xl="4">
			<v-alert>{{ KoMSKSDT }}</v-alert>
		</v-col>
		<v-col cols="2" xs="12" sm="2" md="2" lg="4" xl="4">
            <v-alert>{{ KoMSKSTM }}</v-alert>
        </v-col>        
    </v-row>
    <v-row>
        <v-col cols="6"  xs="12" sm="6" md="6" lg="4" xl="4">
            <div class="my-2"><v-btn x-large color="success" v-on:click="TKmakeexcel" dark block><v-icon left>mdi-printer</v-icon>立替見積書印刷</v-btn></div>
        </v-col>
        <v-col cols="4" xs="12" sm="4" md="4" lg="4" xl="4">
            <v-alert>{{ KoMTKSDT }}</v-alert>
		</v-col>
		<v-col cols="2" xs="12" sm="2" md="2" lg="4" xl="4">
            <v-alert>{{ KoMTKSTM }}</v-alert>
        </v-col>        
    </v-row>
   </v-card>
</v-app>
</v-dialog>
</template>

<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import * as XlsxPopulate from "xlsx-populate";
//import XlsxPopulate from "xlsx-populate"; //XlsxPopulate
//import axiosJsonpAdapter from 'axios-jsonp'
//import { getSmkblck } 	from "../graphql/queries";     	//部署マスタ
//import { getKoTrn }   	from "../graphql/queries";     	//施行ヘッダー取得
//import { updateKoTrn } 	from "../graphql/mutations";   	//施行ヘッダー更新
//import { listMsTrns }   from "../graphql/queries";     	//施行明細取得
//import { listSkyoTrns } from "../graphql/queries";		//施行予定データ取得
//import { getExsjub } 	from "../graphql/queries";     	//祭事受付簿エクセル
//import { getExtkmm } 	from "../graphql/queries";     	//立替見積書エクセル
//import { getExsjmm } 	from "../graphql/queries";     	//祭事見積書エクセル
import moment from "moment";
export default { 
    data: () => ({
     // テンプレート内で保有するデータ  
   
		MsTrn: [],				//祭事明細
		SKYOTrn: [],			//施行予定データ
        PARAMT: [],
		PARAMT2: [],
        KgyCd       :"",        //企業コード
        PL_Tanmtid  :"",        //端末ID
        KoTNO       :'',        //受付№
		BLCKCD       :'',       //部署コード
		BLCKNM       :'',       //部署コード
        KoTUUKBDT   :'',        //受付簿印刷日
        KoTUUKBTM   :'',        //受付簿印刷時間
        KoTUMSKSDT  :'',        //祭事見積書印刷日
        KoTUMSKSTM  :'',        //祭事見積書印刷時間
     //   KoTUMSKSDT  :'',        //立替見積書印刷日
     //   KoTUMSKSTM  :'',        //立替見積書印刷時間
        KoTUTKSSDT  :"",        //立替見積書印刷
		KoTUTKSSTM  :"",        //立替見積書印刷
		dialogERROR:false,
		message  	:"",
        dialogIN    :false,
		date: new Date().toISOString().substr(0, 10),
		//祭事ヘッダー
		
		KoTURDT	 :'', //売上日
		KoTUKDT	 :'', //受付日
		KoTBLOKNM:'', //部署名称
		KoTBLOKCD:'', //部署コード
		KoTNTNCD :'', //担当者コード
		KoTNTNNM :'', //担当者名称
		KoETNTNCD:'', //営業担当コード
		KoETNTNNM:'', //営業担当名称
		KoTKANO	 :'', //会員番号
		KoTKANM	 :'', //会員名称
		KoTMOKE	 :'', //喪家
		KoThCD	 :'', //顧客取引先コード
		KoThNM	 :'', //顧客取引先名称
		KoTCD	 :'', //顧客提携区分コード
		KoTNM	 :'', //顧客提携区分名称
		KoTTKNM	 :'', //故人(カナ）
		KoTTNM	 :'', //故人名前
		KoTTNG	 :'', //故人生年月日年号
		KoTTBSYY :'', //故人生年月日年
		KoTTBSMM :'', //故人生年月日月
		KoTTBSDD :'', //故人生年月日日
		KoTTEG	 :'', //故人年齢
		KoTTSEX	 :'', //故人性別
		KoTTNEX	 :'', //故人続柄
		KoTTYU	 :'', //故人郵便番号
		KoTTJYU1 :'', //故人住所1
		KoTTJYU2 :'', //故人住所2
		KoTTJYU3 :'', //故人住所3
		KoTTTEL	 :'', //故人電話番号
		KoTMKNM	 :'', //喪主(カナ）
		KoTMNM	 :'', //喪主名前
		KoTMNEX	 :'', //喪主続柄
		KoTMSEX	 :'', //喪主性別
		KoTMYU	 :'', //喪主郵便番号
		KoTMJYU1 :'', //喪主住所1
		KoTMJYU2 :'', //喪主住所2
		KoTMJYU3 :'', //喪主住所3
		KoTMTEL	 :'', //喪主電話番号
		KoTMTELK :'', //喪主携帯
		KoTMTMail:'', //喪主Mail
		KoTMBIKO :'', //喪主備考
		KoTSNM	 :'', //お支払責任者名前
		KoTSHYODT:'', //お支払予定日
		KoTSPNM	 :'', //入金方法名
		KoTSYU	 :'', //支払責任者郵便番号
		KoTSJYU1 :'', //お支払責任者住所1
		KoTSJYU2 :'', //お支払責任者住所2
		KoTSJYU3 :'', //お支払責任者住所3
		KoTSTEL	 :'', //お支払責任者電話番号
		KoTSTELK :'', //お支払責任者携帯
		KoTSMail :'', //お支払責任者Mail
		KoTSBIKO :'', //お支払責任者備考
		KoTSSTRNM:'', //寺院名称
		KoTSSSONM:'', //宗旨名称
		KoTSSKSA1:'', //会葬挨拶1
		KoTSSKMNM:'', //家紋・紋章名称
		KoTSSBIKO:'', //引継備考
	//	KoTUUKBDT:'', //受付簿印刷日
	//	KoTUUKBTM:'', //受付簿印時間
	//	KoTUMSKSDT:'', //請求書印刷日
	//	KoTUMSKSTM:'', //請求書印刷時間
	//	KoTUTKSSDT:'', //立替請求書印刷日
	//	KoTUTKSSTM:'', //立替請求書印刷時間
		KoTPLCD	 :'', //プランコード
		KoTPLNM	 :'', //プラン名称
		KoTPLGK	 :'', //プラン金額
		KoTSTCD	 :'', //プランセットコード
		KoTKKCD	 :'', //会館CD
		KoTKKNM	 :'', //会館名
		KoTHRCD	 :'', //ホールCD
		KoTHRNM	 :'', //ホール名
		KoTSEYNM :'', //施行予定名
		KoTDESDT :'', //死亡日
		KoTTYSDT :'', //通夜日
		KoTSSOGDT:'', //葬儀日
		KoTSJDT	 :'', //火葬日
		KoTSJCD	 :'', //斎場コード
		KoTSJNM	 :'', //斎場名称
		KoTURSMST:'', //祭事標準税売上(税抜)
		KoTURSMKG:'', //祭事軽減税売上(税抜)
		KoTURSMHK:'', //祭事非課税売上(税抜)
		KoTSNEBST:'', //祭事標準税値引
		KoTSNEBKG:'', //祭事軽減税値引
		KoTSNEBHK:'', //祭事非課税値引
		KoTSKAZST:'', //祭事標準税対象額計
		KoTSKAZKG:'', //祭事軽減税対象額計
		KoTSKAZHK:'', //祭事非課税対象額計
		KoTSSYOST:'', //祭事標準税消費税
		KoTSSYOKG:'', //祭事軽減税消費税
		KoTSURKGK:'', //祭事売上(税込み)
		KoTURTMST:'', //立替標準税売上(税抜)
		KoTURTMKG:'', //立替軽減税売上(税抜)
		KoTURTMHK:'', //立替非課税売上(税抜)
		KoTTNEBST:'', //立替標準税値引
		KoTTNEBKG:'', //立替軽減税値引
		KoTTNEBHK:'', //立替非課税値引
		KoTTKAZST:'', //立替標準税対象額計
		KoTTKAZKG:'', //立替軽減税対象額計
		KoTTKAZHK:'', //立替非課税対象額計
		KoTTSYOST:'', //立替標準税消費税
		KoTTSYOKG:'', //立替軽減税消費税
		KoTTURKGK:'', //立替売上(税込み)
		KoTSGK	 :'', //売掛金総額
		KoTSHNKG :'', //祭事入金金額
		KoTSHZNG :'', //売掛残高
		KoSGICNM :'', //葬儀委員長名
		KoSGICKN :'', //葬儀委員長かな名
		KoSGICZK :'', //葬儀委員長属性
		KoDMKB	 :'', //DM区分
		KoHJGK	 :'', //標準原価計
		KoARRI	 :'', //粗利益計
		KoTJJK	 :'', //受注進捗状況
		KoMUKBDT :'', //見積受付簿印刷日
        KoMUKBTM :'', //見積受付簿印刷時間
        KoMSKSDT :'', //見積祭事印刷日
        KoMSKSTM :'', //見積祭事印刷時間
        KoMTKSDT :'', //見積立替印刷日
        KoMTKSTM :'', //見積立替印刷時間
		InstDt	 :'', //作成日時
		InstId	 :'', //作成者
		UpdtDt	 :'', //更新日時
		UpdtId	 :'', //更新者
        //部署マスタ
        BL_BLCKCD	:'', //部署コード
        BL_BLCKNM	:'', //部署名称
        BL_BLCKTEL	:'', //TEL
        BL_BLCKFAX	:'', //FAX
        BL_BLCKMAIL:'', //MAIL
        BL_BLCKSK1	:'', //請求先代表者
        BL_BLCKSK5	:'', //請求口座名義
        BL_BLCKSK4	:'', //請求取引銀行名
        BL_BLCKSK6	:'', //口座
        BL_BLCKSK2	:'', //請求住所
        BL_BLCKSK3	:'', //請求電話
        BL_BLCKYB1	:'', //郵便番号1
        BL_BLCKYB2	:'', //郵便番号2
        BL_BLCKJY1	:'', //住所1
        BL_BLCKJY2	:'', //住所2
        BL_BLCKJY3	:'', //住所3
        //祭事受付簿エクセル
        UBPR_KoTNO	    :'',    //受付番号
		UBPR_KoTUMSKSDT :'',    //印刷日
		UBPR_KoTUMSKSTM :'',    //印刷時間
		UBPR_BLCKCD	    :'',    //営業所コード
		UBPR_BLCKNM	    :'',    //営業所名
		UBPR_BLCKYB1	:'',    //郵便番号1
		UBPR_BLCKYB2	:'',    //郵便番号2
		UBPR_BLCKJY1	:'',    //住所1
		UBPR_BLCKJY2	:'',    //住所2
		UBPR_BLCKJY3	:'',    //住所3
		UBPR_BLCKTEL	:'',    //TEL
		UBPR_BLCKFAX	:'',    //FAX
		UBPR_BLCKMAIL   :'',    //MAIL
		UBPR_BLCKSK1	:'',    //請求先代表者
		UBPR_BLCKSK2	:'',    //請求住所
		UBPR_BLCKSK3	:'',    //請求電話
		UBPR_BLCKSK4	:'',    //請求取引銀行名
		UBPR_BLCKSK5	:'',    //請求口座名義
		UBPR_BLCKSK6	:'',    //口座
		UBPR_KoTUKDT	:'',    //受付日
		UBPR_KoTNTNCD   :'',    //担当者コード
		UBPR_KoTNTNNM   :'',    //担当者名称
		UBPR_KoETNTNCD  :'',    //営業担当コード
		UBPR_KoETNTNNM  :'',    //営業担当名称
		UBPR_KoTKANO	:'',    //会員番号
		UBPR_KoTKANM	:'',    //会員名称
		UBPR_KoTMOKE	:'',    //喪家
		UBPR_KoTCD	    :'',    //顧客提携先コード
		UBPR_KoTNM	    :'',    //顧客提携先名称
		UBPR_KoTTKNM	:'',    //故人(カナ）
		UBPR_KoTTNM	    :'',    //故人名前
		UBPR_KoTTNG	    :'',    //故人生年月日年号
		UBPR_KoTTBSYY   :'',    //故人生年月日年
		UBPR_KoTTBSMM   :'',    //故人生年月日月
		UBPR_KoTTBSDD   :'',    //故人生年月日日
		UBPR_KoTTEG	    :'',    //故人年齢
		UBPR_KoTTSEX	:'',    //故人性別
		UBPR_KoTTNEX	:'',    //故人続柄
		UBPR_KoTTYU	    :'',    //故人郵便番号
		UBPR_KoTTJYU1   :'',    //故人住所1
		UBPR_KoTTJYU2   :'',    //故人住所2
		UBPR_KoTTJYU3   :'',    //故人住所3
		UBPR_KoTTTEL	:'',    //故人電話番号
		UBPR_KoTMKNM	:'',    //喪主(カナ）
		UBPR_KoTMNM	    :'',    //喪主名前
		UBPR_KoTMNEX	:'',    //喪主続柄
		UBPR_KoTMSEX	:'',    //喪主性別
		UBPR_KoTMYU	    :'',    //喪主郵便番号
		UBPR_KoTMJYU1   :'',    //喪主住所1
		UBPR_KoTMJYU2   :'',    //喪主住所2
		UBPR_KoTMJYU3   :'',    //喪主住所3
		UBPR_KoTMTEL	:'',    //喪主電話番号
		UBPR_KoTMTELK   :'',    //喪主携帯
		UBPR_KoTMTMail  :'',    //喪主Mail
		UBPR_KoTMBIKO   :'',    //喪主備考
		UBPR_KoTSNM	    :'',    //お支払責任者名前
		UBPR_KoTSHYODT  :'',    //お支払予定日
		UBPR_KoTSPNM	:'',    //入金方法名
		UBPR_KoTSYU	    :'',    //支払責任者郵便番号
		UBPR_KoTSJYU1   :'',    //お支払責任者住所1
		UBPR_KoTSJYU2   :'',    //お支払責任者住所2
		UBPR_KoTSJYU3   :'',    //お支払責任者住所3
		UBPR_KoTSTEL	:'',    //お支払責任者電話番号
		UBPR_KoTSTELK   :'',    //お支払責任者携帯
		UBPR_KoTSMail   :'',    //お支払責任者Mail
		UBPR_KoTSBIKO   :'',    //お支払責任者備考
		UBPR_KoTSSTRNM  :'',    //寺院名称
		UBPR_KoTSSSONM  :'',    //宗旨名称
		UBPR_KoTSSSLBS  :'',    //墓石墓所
		UBPR_KoTSSSLBD  :'',    //仏壇
		UBPR_KoTSSKSA1  :'',    //会葬挨拶1
		UBPR_KoTSSKMNM  :'',    //家紋・紋章名称
		UBPR_KoTSSBIKO  :'',    //引継
		UBPR_KoTPLCD    :'',    //プランコード
		UBPR_KoTPLNM    :'',    //プラン名称
		UBPR_KoTKKCD    :'',    //会館コード
		UBPR_KoTKKNM    :'',    //会館名
		UBPR_KoTHRCD    :'',    //ホールコード
		UBPR_KoTHRNM    :'',    //ホール名
		UBPR_TBGO	    :0,     //テーブル開始行
		UBPR_SKYTGYO    :'',    //行
		UBPR_SKYTKB     :'',    //区分
		UBPR_SKYTNM     :'',    //施行名称
		UBPR_SKYTDATE   :'',    //施行日
		UBPR_SKYTHH     :'',    //開始時
		UBPR_SKYTMM     :'',    //開始分
		UBPR_SKYSHH     :'',    //終了時
		UBPR_SKYSMM     :'',    //終了分
		UBPR_SKYTFBNM   :'',    //場所名称
		UBPR_SKYTP1NM   :'',    //入力1名称
		UBPR_SKYTP1DT   :'',    //入力1値
		UBPR_SKYTP1TN   :'',    //入力1単位
		UBPR_SKYTP2NM   :'',    //入力2名称
		UBPR_SKYTP2DT   :'',    //入力2値
		UBPR_SKYTP2TN   :'',    //入力2単位
		UBPR_SKYTP3NM   :'',    //入力3名称
		UBPR_SKYTP3DT   :'',    //入力3値
		UBPR_SKYTP3TN   :'',    //入力3単位
		UBPR_SKYTNY     :'',    //備考
		UBPR_KoSGICNM   :'',    //葬儀委員長名
		UBPR_KoSGICKN   :'',    //葬儀委員長かな名
		UBPR_KoSGICZK   :'',    //葬儀委員長属性
		UBPR_KoDMKB     :'',    //DM区分
        //立替見積書エクセル
        TKPR_KoTNO	    :'',	//受付番号
        TKPR_KoTUMSKSDT	:'',	//印刷日
        TKPR_KoTUMSKSTM	:'',	//印刷時間
        TKPR_BLCKCD	    :'',	//営業所コード
        TKPR_BLCKNM	    :'',	//営業所名
        TKPR_BLCKYB1	:'',	//郵便番号1
        TKPR_BLCKYB2	:'',	//郵便番号2
        TKPR_BLCKJY1	:'',	//住所1
        TKPR_BLCKJY2	:'',	//住所2
        TKPR_BLCKJY3	:'',	//住所3
        TKPR_BLCKTEL	:'',	//TEL
        TKPR_BLCKFAX	:'',	//FAX
        TKPR_BLCKMAIL	:'',	//MAIL
        TKPR_BLCKSK1	:'',	//請求先代表者
        TKPR_BLCKSK2	:'',	//請求住所
        TKPR_BLCKSK3	:'',	//請求電話
        TKPR_BLCKSK4	:'',	//請求取引銀行名
        TKPR_BLCKSK5	:'',	//請求口座名義
        TKPR_BLCKSK6	:'',	//口座
        TKPR_KoTUKDT	:'',	//受付日
        TKPR_KoTNTNCD	:'',	//担当者コード
        TKPR_KoTNTNNM	:'',	//担当者名称
        TKPR_KoETNTNCD	:'',	//営業担当コード
        TKPR_KoETNTNNM	:'',	//営業担当名称
        TKPR_KoTSNM	    :'',	//名前
        TKPR_KoTSYU	    :'',	//住所〒
        TKPR_KoTSJYU1	:'',	//住所1
        TKPR_KoTSJYU2	:'',	//住所2
        TKPR_KoTSJYU3	:'',	//住所3
        TKPR_KoTSTEL	:'',	//℡
        TKPR_KoTSTELK	:'',	//携帯
        TKPR_KoTSMail	:'',	//メール
        TKPR_KoTTURKGK	:'',	//祭事売上(税込み)
        TKPR_KoTURTMST	:'',	//立替外税売上  (税抜)
        TKPR_KoTURTMKG	:'',	//立替軽減税売上(税抜)
        TKPR_KoTURTMHK	:'',	//立替非課税売上(税抜)
        TKPR_KoTTNEBST	:'',	//立替外税値引
        TKPR_KoTTNEBKG	:'',	//立替軽減税値引
        TKPR_KoTTNEBHK	:'',	//立替非課税値引
        TKPR_KoTTKAZST	:'',	//立替外税対象額計
        TKPR_KoTTKAZKG	:'',	//立替軽減税対象額計
        TKPR_KoTTKAZHK	:'',	//立替非課税対象額計
        TKPR_KoTTSYOST	:'',	//立替外税消費税
        TKPR_KoTTSYOKG	:'',	//立替軽減税消費税
        TKPR_TBGO	    :'',	//テーブル開始行
        TKPR_MsSETCD	:'',	//セットコード
        TKPR_MsSETNM	:'',	//セット名称
        TKPR_MsHIBJYUN	:'',	//品目大分類並び順
        TKPR_MsHIBCD	:'',	//品目大分類コード
        TKPR_MsHIBNM	:'',	//品目大分類名称
        TKPR_MsSYBRJN	:'',	//品目分類順
        TKPR_MsSYBRCD	:'',	//品目分類コード
        TKPR_MsSYBRNM	:'',	//品目分類名称
        TKPR_MsHIJYUN	:'',	//品目並び順
        TKPR_MsHICD	    :'',	//品目コード
        TKPR_MsHINM	    :'',	//品目名称
        TKPR_MsTAN	    :'',	//単位
        TKPR_MsSETFG	:'',	//セットフラグ
        TKPR_MsUNT	    :'',	//販売単価
        TKPR_MsSYOKB	:'',	//売上消費税区分
        TKPR_MsSuu	    :'',	//売上数量
        TKPR_MsKin	    :'',	//売上金額(税抜)
        TKPR_MsNebiki	:'',	//売上値引(税抜)
        TKPR_MsGKing	:'',	//売上額値引後(税抜)
        TKPR_MsUCSYOG	:'',	//売上消費税額
        TKPR_MsZKGK	    :'',	//売上税込額(税込)
		TKPR_MsBIKO	    :'',	//備考
		//祭事見積書エクセル
		SJPR_KoTNO	    :'',    //受付番号
		SJPR_KoTUMSKSDT	:'',    //印刷日
		SJPR_KoTUMSKSTM	:'',    //印刷時間
		SJPR_BLCKCD     :'',    //営業所コード
		SJPR_BLCKNM	    :'',    //営業所名
		SJPR_BLCKYB1	:'',    //郵便番号1
		SJPR_BLCKYB2	:'',    //郵便番号2
		SJPR_BLCKJY1	:'',    //住所1
		SJPR_BLCKJY2	:'',    //住所2
		SJPR_BLCKJY3	:'',    //住所3
		SJPR_BLCKTEL	:'',    //TEL
		SJPR_BLCKFAX	:'',    //FAX
		SJPR_BLCKMAIL	:'',    //MAIL
		SJPR_BLCKSK1	:'',    //請求先代表者
		SJPR_BLCKSK2	:'',    //請求住所
		SJPR_BLCKSK3	:'',    //請求電話
		SJPR_BLCKSK4	:'',    //請求取引銀行名
		SJPR_BLCKSK5	:'',    //請求口座名義
		SJPR_BLCKSK6	:'',    //口座
		SJPR_KoTUKDT	:'',    //受付日
		SJPR_KoTNTNCD	:'',    //担当者コード
		SJPR_KoTNTNNM	:'',    //担当者名称
		SJPR_KoETNTNCD	:'',    //営業担当コード
		SJPR_KoETNTNNM	:'',    //営業担当名称
		SJPR_KoTSNM	    :'',    //名前
		SJPR_KoTSYU	    :'',    //住所〒
		SJPR_KoTSJYU1	:'',    //住所1
		SJPR_KoTSJYU2	:'',    //住所2
		SJPR_KoTSJYU3	:'',    //住所3
		SJPR_KoTSTEL	:'',    //℡
		SJPR_KoTSTELK	:'',    //携帯
		SJPR_KoTSMail	:'',    //メール
		SJPR_KoTSURKGK	:'',    //祭事売上(税込み)
		SJPR_KoTURSMST	:'',    //祭事外税売上  (税抜)
		SJPR_KoTURSMKG	:'',    //祭事軽減税売上(税抜)
		SJPR_KoTURSMHK	:'',    //祭事非課税売上(税抜)
		SJPR_KoTSNEBST	:'',    //祭事外税値引
		SJPR_KoTSNEBKG	:'',    //祭事軽減税値引
		SJPR_KoTSNEBHK	:'',    //祭事非課税値引
		SJPR_KoTSKAZST	:'',    //祭事外税対象額計
		SJPR_KoTSKAZKG	:'',    //祭事軽減税対象額計
		SJPR_KoTSKAZHK	:'',    //祭事非課税対象額計
		SJPR_KoTSSYOST	:'',    //祭事外税消費税
		SJPR_KoTSSYOKG	:'',    //祭事軽減税消費税
		SJPR_TBGO	    :'',    //テーブル開始行
		SJPR_MsSETCD	:'',    //セットコード
		SJPR_MsSETNM	:'',    //セット名称
		SJPR_MsHIBJYUN	:'',    //品目大分類並び順
		SJPR_MsHIBCD	:'',    //品目大分類コード
		SJPR_MsHIBNM	:'',    //品目大分類名称
		SJPR_MsSYBRJN	:'',    //品目分類順
		SJPR_MsSYBRCD	:'',    //品目分類コード
		SJPR_MsSYBRNM	:'',    //品目分類名称
		SJPR_MsHIJYUN	:'',    //品目並び順
		SJPR_MsHICD	    :'',    //品目コード
		SJPR_MsHINM	    :'',    //品目名称
		SJPR_MsTAN	    :'',    //単位
		SJPR_MsSETFG	:'',    //セットフラグ
		SJPR_MsUNT	    :'',    //販売単価
		SJPR_MsSYOKB	:'',    //売上消費税区分
		SJPR_MsSuu	    :'',    //売上数量
		SJPR_MsKin	    :'',    //売上金額(税抜)
		SJPR_MsNebiki	:'',    //売上値引(税抜)
		SJPR_MsGKing	:'',    //売上額値引後(税抜)
		SJPR_MsUCSYOG	:'',    //売上消費税額
		SJPR_MsZKGK	    :'',    //売上税込額(税込)
		SJPR_MsBIKO	    :'',    //備考
		SJPR_KoTTSEX	:'',    //故人性別
		
		BOoutexcel:"受付帳簿.xlsx",
		BOKoteifile:"https://cors-anywhere.herokuapp.com/https://bslg.xsrv.jp/excel/受付帳簿.xlsx",
		//BOKoteifile:"https://bslg.xsrv.jp/excel/受付帳簿.xlsx",
		TAoutexcel:"立替見積書.xlsx",
		TAKoteifile:"https://cors-anywhere.herokuapp.com/https://bslg.xsrv.jp/excel/立替見積書.xlsx",
		SJoutexcel:"祭事見積書.xlsx",
		SJKoteifile:"https://cors-anywhere.herokuapp.com/https://bslg.xsrv.jp/excel/祭事見積書.xlsx",
  }),
   methods: {
	//-----------------------------------------------------------------
    //オープン処理
    //-----------------------------------------------------------------
    open() {
        this.dialogIN = true;
        if(localStorage.SOGI_KgyCd) 	this.KgyCd 		= localStorage.SOGI_KgyCd;
        if(localStorage.SOGI_KgyNM) 	this.KgyNM 		= localStorage.SOGI_KgyNM;
        if(localStorage.SOGI_BLCKCD) 	this.BLCKCD		= localStorage.SOGI_BLCKCD;
        if(localStorage.SOGI_BLCKNM) 	this.BLCKNM		= localStorage.SOGI_BLCKNM;
        if(localStorage.SOGI_SynCd) 	this.SynCd 		= localStorage.SOGI_SynCd;
        if(localStorage.SOGI_SynNm) 	this.SynNm 		= localStorage.SOGI_SynNm;
        if(localStorage.SOGI_TANID) 	this.TANID 		= localStorage.SOGI_TANID;
        if(localStorage.SOGI_DENNO) 	this.DENNO 		= localStorage.SOGI_SGDENNO;
    //  if(localStorage.SOGICA_KoTNO) 	this.PS_KoTNO 	= localStorage.SOGICA_KoTNO;
	//	if(localStorage.SOGICA_KoTNO) 	this.KoTNO 		= localStorage.SOGICA_KoTNO;
	
		this.PL_KIGYOUID = this.$store.getters.GET_kigyoucd;  //グローバル企業コード
		this.PL_Tanmtid  = this.$store.getters.GET_Tanmtid;   //グローバル端末ID
    
		if(localStorage.SOGI_KoTNO)       this.KoTNO      = localStorage.SOGI_KoTNO;           //受付番号
		if(localStorage.SOGI_KoTURDT)     this.KoTURDT    = localStorage.SOGI_KoTURDT;         //売上日
		if(localStorage.SOGI_KoTUKDT)     this.KoTUKDT    = localStorage.SOGI_KoTUKDT;         //受付日
		if(localStorage.SOGI_KoTUKJI)     this.KoTUKJI    = localStorage.SOGI_KoTUKJI;         //受付時
		if(localStorage.SOGI_KoTUKFN)     this.KoTUKFN    = localStorage.SOGI_KoTUKFN;         //受付分
		if(localStorage.SOGI_KoTBLOKCD)   this.KoTBLOKCD  = localStorage.SOGI_KoTBLOKCD;       //部署コード
		if(localStorage.SOGI_KoTBLOKNM)   this.KoTBLOKNM  = localStorage.SOGI_KoTBLOKNM;       //部署名称
		if(localStorage.SOGI_KoETNTNCD)   this.KoETNTNCD  = localStorage.SOGI_KoETNTNCD;       //営業担当コード
		if(localStorage.SOGI_KoETNTNNM)   this.KoETNTNNM  = localStorage.SOGI_KoETNTNNM;       //営業担当名称
		if(localStorage.SOGI_KoETNTNNMS)  this.KoETNTNNMS = localStorage.SOGI_KoETNTNNMS;      //主担当略称
		if(localStorage.SOGI_KoTKANO)     this.KoTKANO    = localStorage.SOGI_KoTKANO;         //会員番号
		if(localStorage.SOGI_KoTKANM)     this.KoTKANM    = localStorage.SOGI_KoTKANM;         //会員名称
		if(localStorage.SOGI_KoTMOKE)     this.KoTMOKE    = localStorage.SOGI_KoTMOKE;         //喪家
		if(localStorage.SOGI_KoThCD)      this.KoThCD     = localStorage.SOGI_KoThCD;          //顧客取引先コード
		if(localStorage.SOGI_KoThNM)      this.KoThNM     = localStorage.SOGI_KoThNM;          //顧客取引先名称
		if(localStorage.SOGI_KoTCD)       this.KoTCD      = localStorage.SOGI_KoTCD;           //顧客提携区分コード
		if(localStorage.SOGI_KoTNM)       this.KoTNM      = localStorage.SOGI_KoTNM;           //顧客提携区分名称
		if(localStorage.SOGI_KoTTKNM)     this.KoTTKNM    = localStorage.SOGI_KoTTKNM;         //故人(カナ）
		if(localStorage.SOGI_KoTTNM)      this.KoTTNM     = localStorage.SOGI_KoTTNM;          //故人名前
		if(localStorage.SOGI_KoTTBSDTJ)   this.KoTTBSDTJ  = localStorage.SOGI_KoTTBSDTJ;       //故人生年月日(和暦）
		if(localStorage.SOGI_KoTTEG)      this.KoTTEG     = localStorage.SOGI_KoTTEG;          //故人年齢
		if(localStorage.SOGI_KoTTSEX)     this.KoTTSEX    = localStorage.SOGI_KoTTSEX;         //故人性別
		if(localStorage.SOGI_KoTTNEX)     this.KoTTNEX    = localStorage.SOGI_KoTTNEX;         //故人続柄
		if(localStorage.SOGI_KoTTYU)      this.KoTTYU     = localStorage.SOGI_KoTTYU;          //故人郵便番号
		if(localStorage.SOGI_KoTTJYU1)    this.KoTTJYU1   = localStorage.SOGI_KoTTJYU1;        //故人住所1
		if(localStorage.SOGI_KoTTJYU2)    this.KoTTJYU2   = localStorage.SOGI_KoTTJYU2;        //故人住所2
		if(localStorage.SOGI_KoTTJYU3)    this.KoTTJYU3   = localStorage.SOGI_KoTTJYU3;        //故人住所3
		if(localStorage.SOGI_KoTTTEL)     this.KoTTTEL    = localStorage.SOGI_KoTTTEL;         //故人電話番号
		if(localStorage.SOGI_KoTMKNM)     this.KoTMKNM    = localStorage.SOGI_KoTMKNM;         //喪主(カナ）
		if(localStorage.SOGI_KoTMNM)      this.KoTMNM     = localStorage.SOGI_KoTMNM;          //喪主名前
		if(localStorage.SOGI_KoTMNEX)     this.KoTMNEX    = localStorage.SOGI_KoTMNEX;         //喪主続柄
		if(localStorage.SOGI_KoTMSEX)     this.KoTMSEX    = localStorage.SOGI_KoTMSEX;         //喪主性別
		if(localStorage.SOGI_KoTMYU)      this.KoTMYU     = localStorage.SOGI_KoTMYU;          //喪主郵便番号
		if(localStorage.SOGI_KoTMJYU1)    this.KoTMJYU1   = localStorage.SOGI_KoTMJYU1;        //喪主住所1
		if(localStorage.SOGI_KoTMJYU2)    this.KoTMJYU2   = localStorage.SOGI_KoTMJYU2;        //喪主住所2
		if(localStorage.SOGI_KoTMJYU3)    this.KoTMJYU3   = localStorage.SOGI_KoTMJYU3;        //喪主住所3
		if(localStorage.SOGI_KoTMTEL)     this.KoTMTEL    = localStorage.SOGI_KoTMTEL;         //喪主電話番号
		if(localStorage.SOGI_KoTMTELK)    this.KoTMTELK   = localStorage.SOGI_KoTMTELK;        //喪主携帯
		if(localStorage.SOGI_KoTMTMail)   this.KoTMTMail  = localStorage.SOGI_KoTMTMail;       //喪主Mail
		if(localStorage.SOGI_KoTMBIKO)    this.KoTMBIKO   = localStorage.SOGI_KoTMBIKO;        //喪主備考
		if(localStorage.SOGI_KoTSNM)      this.KoTSNM     = localStorage.SOGI_KoTSNM;          //お支払責任者名前
		if(localStorage.SOGI_KoTSKNM)     this.KoTSKNM    = localStorage.SOGI_KoTSKNM;         //お支払カナ
		if(localStorage.SOGI_KoTSHYODT)   this.KoTSHYODT  = localStorage.SOGI_KoTSHYODT;       //お支払予定日
		if(localStorage.SOGI_KoTSPNM)     this.KoTSPNM    = localStorage.SOGI_KoTSPNM;         //入金方法名
		if(localStorage.SOGI_KoTSYU)      this.KoTSYU     = localStorage.SOGI_KoTSYU;          //支払責任者郵便番号
		if(localStorage.SOGI_KoTSJYU1)    this.KoTSJYU1   = localStorage.SOGI_KoTSJYU1;        //お支払責任者住所1
		if(localStorage.SOGI_KoTSJYU2)    this.KoTSJYU2   = localStorage.SOGI_KoTSJYU2;        //お支払責任者住所2
		if(localStorage.SOGI_KoTSJYU3)    this.KoTSJYU3   = localStorage.SOGI_KoTSJYU3;        //お支払責任者住所3
		if(localStorage.SOGI_KoTSTEL)     this.KoTSTEL    = localStorage.SOGI_KoTSTEL;         //お支払責任者電話番号
		if(localStorage.SOGI_KoTSTELK)    this.KoTSTELK   = localStorage.SOGI_KoTSTELK;        //お支払責任者携帯
		if(localStorage.SOGI_KoTSMail)    this.KoTSMail   = localStorage.SOGI_KoTSMail;        //お支払責任者Mail
		if(localStorage.SOGI_KoTSBIKO)    this.KoTSBIKO   = localStorage.SOGI_KoTSBIKO;        //お支払責任者備考
		if(localStorage.SOGI_KoTSSTRNM)   this.KoTSSTRNM  = localStorage.SOGI_KoTSSTRNM;       //寺院名称
		if(localStorage.SOGI_KoTSSSONM)   this.KoTSSSONM  = localStorage.SOGI_KoTSSSONM;       //宗旨名称
		if(localStorage.SOGI_KoTSSKSA1)   this.KoTSSKSA1  = localStorage.SOGI_KoTSSKSA1;       //会葬挨拶1
		if(localStorage.SOGI_KoTSSKMNM)   this.KoTSSKMNM  = localStorage.SOGI_KoTSSKMNM;       //家紋・紋章名称
		if(localStorage.SOGI_KoTUUKBDT)   this.KoTUUKBDT  = localStorage.SOGI_KoTUUKBDT;       //受付簿印刷日
		if(localStorage.SOGI_KoTUUKBTM)   this.KoTUUKBTM  = localStorage.SOGI_KoTUUKBTM;       //受付簿印時間
		if(localStorage.SOGI_KoTUMSKSDT)  this.KoTUMSKSDT = localStorage.SOGI_KoTUMSKSDT;      //請求書印刷日
		if(localStorage.SOGI_KoTUMSKSTM)  this.KoTUMSKSTM = localStorage.SOGI_KoTUMSKSTM;      //請求書印刷時間
		if(localStorage.SOGI_KoTUTKSSDT)  this.KoTUTKSSDT = localStorage.SOGI_KoTUTKSSDT;      //立替請求書印刷日
		if(localStorage.SOGI_KoTUTKSSTM)  this.KoTUTKSSTM = localStorage.SOGI_KoTUTKSSTM;      //立替請求書印刷時間
		if(localStorage.SOGI_KoTPLCD)     this.KoTPLCD    = localStorage.SOGI_KoTPLCD;         //プランコード
		if(localStorage.SOGI_KoTPLNM)     this.KoTPLNM    = localStorage.SOGI_KoTPLNM;         //プラン名称
		if(localStorage.SOGI_KoTPLGK)     this.KoTPLGK    = localStorage.SOGI_KoTPLGK;         //プラン金額
		if(localStorage.SOGI_KoTKKCD)     this.KoTKKCD    = localStorage.SOGI_KoTKKCD;         //会館CD
		if(localStorage.SOGI_KoTKKNM)     this.KoTKKNM    = localStorage.SOGI_KoTKKNM;         //会館名
		if(localStorage.SOGI_KoTHRCD)     this.KoTHRCD    = localStorage.SOGI_KoTHRCD;         //ホールCD
		if(localStorage.SOGI_KoTHRNM)     this.KoTHRNM    = localStorage.SOGI_KoTHRNM;         //ホール名
		if(localStorage.SOGI_KoTHRJK)     this.KoTHRJK    = localStorage.SOGI_KoTHRJK;         //会館ホール略称
		if(localStorage.SOGI_KoTHRNMS)    this.KoTHRNMS   = localStorage.SOGI_KoTHRNMS;        //会館ホール略称
		if(localStorage.SOGI_KoTDESDTJ)   this.KoTDESDTJ  = localStorage.SOGI_KoTDESDTJ;       //死亡日(和暦
		if(localStorage.SOGI_KoTDESBS)    this.KoTDESBS   = localStorage.SOGI_KoTDESBS;        //死亡場所
		if(localStorage.SOGI_KoTDESJI)    this.KoTDESJI   = localStorage.SOGI_KoTDESJI;        //死亡時
		if(localStorage.SOGI_KoTDESFN)    this.KoTDESFN   = localStorage.SOGI_KoTDESFN;        //死亡分 
		if(localStorage.SOGI_KoTTYSDT)    this.KoTTYSDT   = localStorage.SOGI_KoTTYSDT;        //通夜日
		if(localStorage.SOGI_KoTTYSJI)    this.KoTTYSJI   = localStorage.SOGI_KoTTYSJI;        //通夜時
		if(localStorage.SOGI_KoTTYSFN)    this.KoTTYSFN   = localStorage.SOGI_KoTTYSFN;        //通夜分
		if(localStorage.SOGI_KoTTYEJI)    this.KoTTYEJI   = localStorage.SOGI_KoTTYEJI;        //通夜終了時
		if(localStorage.SOGI_KoTTYEFN)    this.KoTTYEFN   = localStorage.SOGI_KoTTYEFN;        //通夜終了分
		if(localStorage.SOGI_KoTTYSBS)    this.KoTTYSBS   = localStorage.SOGI_KoTTYSBS;        //通夜場所
		if(localStorage.SOGI_KoTTYSJK)    this.KoTTYSJK   = localStorage.SOGI_KoTTYSJK;        //通夜状況
		if(localStorage.SOGI_KoTTYSSK)    this.KoTTYSSK   = localStorage.SOGI_KoTTYSSK;        //通夜司会
		if(localStorage.SOGI_KoTSSOGDT)   this.KoTSSOGDT  = localStorage.SOGI_KoTSSOGDT;       //葬儀日
		if(localStorage.SOGI_KoTSSOGSJI)  this.KoTSSOGSJI = localStorage.SOGI_KoTSSOGSJI;      //葬儀時
		if(localStorage.SOGI_KoTSSOGSFN)  this.KoTSSOGSFN = localStorage.SOGI_KoTSSOGSFN;      //葬儀分
		if(localStorage.SOGI_KoTSSOGEJI)  this.KoTSSOGEJI = localStorage.SOGI_KoTSSOGEJI;      //出棺時
		if(localStorage.SOGI_KoTSSOGEFN)  this.KoTSSOGEFN = localStorage.SOGI_KoTSSOGEFN;      //出棺分
		if(localStorage.SOGI_KoTSSOSBS)   this.KoTSSOSBS  = localStorage.SOGI_KoTSSOSBS;       //葬儀場所
		if(localStorage.SOGI_KoTSSOSSK)   this.KoTSSOSSK  = localStorage.SOGI_KoTSSOSSK;       //葬儀司会
		if(localStorage.SOGI_KoTSSOGAS)   this.KoTSSOGAS  = localStorage.SOGI_KoTSSOGAS;       //葬儀アシスタント
		if(localStorage.SOGI_KoTSSOGJK)   this.KoTSSOGJK  = localStorage.SOGI_KoTSSOGJK;       //葬儀状況
		if(localStorage.SOGI_KoTSJDT)     this.KoTSJDT    = localStorage.SOGI_KoTSJDT;         //火葬日
		if(localStorage.SOGI_KoTSJCD)     this.KoTSJCD    = localStorage.SOGI_KoTSJCD;         //斎場コード
		if(localStorage.SOGI_KoTSJNM)     this.KoTSJNM    = localStorage.SOGI_KoTSJNM;         //斎場名称
		if(localStorage.SOGI_KoTSJNMS)    this.KoTSJNMS   = localStorage.SOGI_KoTSJNMS;        //斎場略称
		if(localStorage.SOGI_KoTSJJI)     this.KoTSJJI    = localStorage.SOGI_KoTSJJI;         //火葬時
		if(localStorage.SOGI_KoTSJFN)     this.KoTSJFN    = localStorage.SOGI_KoTSJFN;         //火葬分
		if(localStorage.SOGI_KoTSJEJI)    this.KoTSJEJI   = localStorage.SOGI_KoTSJEJI;        //火葬終了時
		if(localStorage.SOGI_KoTSJEFN)    this.KoTSJEFN   = localStorage.SOGI_KoTSJEFN;        //火葬終了分
		if(localStorage.SOGI_KoTSJDS)     this.KoTSJDS    = localStorage.SOGI_KoTSJDS;         //同行・導師
		if(localStorage.SOGI_KoTSJD7)     this.KoTSJD7    = localStorage.SOGI_KoTSJD7;         //初七日
		if(localStorage.SOGI_KoTSJHS)     this.KoTSJHS    = localStorage.SOGI_KoTSJHS;         //控室
		if(localStorage.SOGI_KoTHZJK)     this.KoTHZJK    = localStorage.SOGI_KoTHZJK;         //本膳状況
		if(localStorage.SOGI_KoTYOYK)     this.KoTYOYK    = localStorage.SOGI_KoTYOYK;         //斎場予約
		if(localStorage.SOGI_KoTURSMST)   this.KoTURSMST  = localStorage.SOGI_KoTURSMST;       //祭事標準税売上(税抜
		if(localStorage.SOGI_KoTURSMHK)   this.KoTURSMHK  = localStorage.SOGI_KoTURSMHK;       //祭事非課税売上(税抜
		if(localStorage.SOGI_KoTSNEBST)   this.KoTSNEBST  = localStorage.SOGI_KoTSNEBST;       //祭事標準税値引
		if(localStorage.SOGI_KoTSNEBKG)   this.KoTSNEBKG  = localStorage.SOGI_KoTSNEBKG;       //祭事軽減税値引
		if(localStorage.SOGI_KoTSNEBHK)   this.KoTSNEBHK  = localStorage.SOGI_KoTSNEBHK;       //祭事非課税値引
		if(localStorage.SOGI_KoTSKAZST)   this.KoTSKAZST  = localStorage.SOGI_KoTSKAZST;       //祭事標準税対象額計
		if(localStorage.SOGI_KoTSKAZKG)   this.KoTSKAZKG  = localStorage.SOGI_KoTSKAZKG;       //祭事軽減税対象額計
		if(localStorage.SOGI_KoTSKAZHK)   this.KoTSKAZHK  = localStorage.SOGI_KoTSKAZHK;       //祭事非課税対象額計
		if(localStorage.SOGI_KoTSSYOST)   this.KoTSSYOST  = localStorage.SOGI_KoTSSYOST;       //祭事標準税消費税
		if(localStorage.SOGI_KoTSSYOKG)   this.KoTSSYOKG  = localStorage.SOGI_KoTSSYOKG;       //祭事軽減税消費税
		if(localStorage.SOGI_KoTSURKGK)   this.KoTSURKGK  = localStorage.SOGI_KoTSURKGK;       //祭事売上(税込み
		if(localStorage.SOGI_KoTURTMST)   this.KoTURTMST  = localStorage.SOGI_KoTURTMST;       //立替標準税売上(税抜
		if(localStorage.SOGI_KoTURTMKG)   this.KoTURTMKG  = localStorage.SOGI_KoTURTMKG;       //立替軽減税売上(税抜
		if(localStorage.SOGI_KoTURTMHK)   this.KoTURTMHK  = localStorage.SOGI_KoTURTMHK;       //立替非課税売上(税抜
		if(localStorage.SOGI_KoTTNEBST)   this.KoTTNEBST  = localStorage.SOGI_KoTTNEBST;       //立替標準税値引
		if(localStorage.SOGI_KoTTNEBKG)   this.KoTTNEBKG  = localStorage.SOGI_KoTTNEBKG;       //立替軽減税値引
		if(localStorage.SOGI_KoTTNEBHK)   this.KoTTNEBHK  = localStorage.SOGI_KoTTNEBHK;       //立替非課税値引
		if(localStorage.SOGI_KoTTKAZST)   this.KoTTKAZST  = localStorage.SOGI_KoTTKAZST;       //立替標準税対象額計
		if(localStorage.SOGI_KoTTKAZKG)   this.KoTTKAZKG  = localStorage.SOGI_KoTTKAZKG;       //立替軽減税対象額計
		if(localStorage.SOGI_KoTTKAZHK)   this.KoTTKAZHK  = localStorage.SOGI_KoTTKAZHK;       //立替非課税対象額計
		if(localStorage.SOGI_KoTURSMKG)   this.KoTURSMKG  = localStorage.SOGI_KoTURSMKG;       //祭事軽減税売上(税抜
		if(localStorage.SOGI_KoTTSYOST)   this.KoTTSYOST  = localStorage.SOGI_KoTTSYOST;       //立替標準税消費税
		if(localStorage.SOGI_KoTTSYOKG)   this.KoTTSYOKG  = localStorage.SOGI_KoTTSYOKG;       //立替軽減税消費税
		if(localStorage.SOGI_KoTTURKGK)   this.KoTTURKGK  = localStorage.SOGI_KoTTURKGK;       //立替売上(税込み
		if(localStorage.SOGI_KoTSGK)      this.KoTSGK     = localStorage.SOGI_KoTSGK;          //売掛金総額
		if(localStorage.SOGI_KoTSHZNG)    this.KoTSHZNG   = localStorage.SOGI_KoTSHZNG;        //売掛残高
		if(localStorage.SOGI_KoTSHNKG)    this.KoTSHNKG   = localStorage.SOGI_KoTSHNKG;        //売掛金入金金額
		if(localStorage.SOGI_KoSGICNM)    this.KoSGICNM   = localStorage.SOGI_KoSGICNM;        //葬儀委員長名
		if(localStorage.SOGI_KoSGICKN)    this.KoSGICKN   = localStorage.SOGI_KoSGICKN;        //葬儀委員長かな名
		if(localStorage.SOGI_KoSGICZK)    this.KoSGICZK   = localStorage.SOGI_KoSGICZK;        //葬儀委員長属性
		if(localStorage.SOGI_KoDMKB)      this.KoDMKB     = localStorage.SOGI_KoDMKB;          //DM区分
		if(localStorage.SOGI_KoHJGK)      this.KoHJGK     = localStorage.SOGI_KoHJGK;          //標準原価計
		if(localStorage.SOGI_KoARRI)      this.KoARRI     = localStorage.SOGI_KoARRI;          //粗利益計
		if(localStorage.SOGI_KoTJJK)      this.KoTJJK     = localStorage.SOGI_KoTJJK;          //受注進捗状況
		if(localStorage.SOGI_KoTIRNM)     this.KoTIRNM    = localStorage.SOGI_KoTIRNM;         //依頼人
		if(localStorage.SOGI_KoTIRZK)     this.KoTIRZK    = localStorage.SOGI_KoTIRZK;         //依頼人続柄
		if(localStorage.SOGI_KoTIRTL)     this.KoTIRTL    = localStorage.SOGI_KoTIRTL;         //依頼人電話番号
		if(localStorage.SOGI_KoTIRTO)     this.KoTIRTO    = localStorage.SOGI_KoTIRTO;         //搬送先(
		if(localStorage.SOGI_KoTIRD)      this.KoTIRDT    = localStorage.SOGI_KoTIRDT;         //打合日
		if(localStorage.SOGI_KoTIRJI)     this.KoTIRJI    = localStorage.SOGI_KoTIRJI;         //打合時
		if(localStorage.SOGI_KoTIRFN)     this.KoTIRFN    = localStorage.SOGI_KoTIRFN;         //打合分
		if(localStorage.SOGI_KoTIRBK)     this.KoTIRBK    = localStorage.SOGI_KoTIRBK;         //その他･備考
		if(localStorage.SOGI_KoTIRKM)     this.KoTIRKM    = localStorage.SOGI_KoTIRKM;         //搬送・打合せ・その他（）
		if(localStorage.SOGI_KoTIRSH)     this.KoTIRSH    = localStorage.SOGI_KoTIRSH;         //使用品目
		if(localStorage.SOGI_KoTRDCD)     this.KoTRDCD    = localStorage.SOGI_KoTRDCD;         //リードコード
		if(localStorage.SOGI_KoTRDNM)     this.KoTRDNM    = localStorage.SOGI_KoTRDNM;         //リード名
		if(localStorage.SOGI_KoTRDNMS)    this.KoTRDNMS   = localStorage.SOGI_KoTRDNMS;        //リード略称
		if(localStorage.SOGI_KoTRDTN)     this.KoTRDTN    = localStorage.SOGI_KoTRDTN;         //リード担当名
		if(localStorage.SOGI_KoTRDRV)     this.KoTRDRV    = localStorage.SOGI_KoTRDRV;         //リードレベル
		if(localStorage.SOGI_KoSKICD)     this.KoSKICD    = localStorage.SOGI_KoSKICD;         //司会者コード
		if(localStorage.SOGI_KoSKINM)     this.KoSKINM    = localStorage.SOGI_KoSKINM;         //司会者名
		if(localStorage.SOGI_KoSKINMS)    this.KoSKINMS   = localStorage.SOGI_KoSKINMS;        //司会者略称
		if(localStorage.SOGI_KoSVTNCD)    this.KoSVTNCD   = localStorage.SOGI_KoSVTNCD;        //サブ担当コード
		if(localStorage.SOGI_KoSVNTNM)    this.KoSVNTNM   = localStorage.SOGI_KoSVNTNM;        //サブ担当名
		if(localStorage.SOGI_KoSVNTNMS)   this.KoSVNTNMS  = localStorage.SOGI_KoSVNTNMS;       //サブ担当略称
		if(localStorage.SOGI_KoKRTNCD)    this.KoKRTNCD   = localStorage.SOGI_KoKRTNCD;        //経理担当コード
		if(localStorage.SOGI_KoKRNTNM)    this.KoKRNTNM   = localStorage.SOGI_KoKRNTNM;        //経理担当名
		if(localStorage.SOGI_KoTNKDT)     this.KoTNKDT    = localStorage.SOGI_KoTNKDT;         //納棺日
		if(localStorage.SOGI_KoTNKJI)     this.KoTNKJI    = localStorage.SOGI_KoTNKJI;         //納棺時
		if(localStorage.SOGI_KoTNKFN)     this.KoTNKFN    = localStorage.SOGI_KoTNKFN;         //納棺分
		if(localStorage.SOGI_KoTNKMK)     this.KoTNKMK    = localStorage.SOGI_KoTNKMK;         //納棺メーク
		if(localStorage.SOGI_KoTNKJK)     this.KoTNKJK    = localStorage.SOGI_KoTNKJK;         //納棺状況
		if(localStorage.SOGI_KoTNKBS)     this.KoTNKBS    = localStorage.SOGI_KoTNKBS;         //納棺場所
		if(localStorage.SOGI_KoTISU1DT)   this.KoTISU1DT  = localStorage.SOGI_KoTISU1DT;       //移動1日
		if(localStorage.SOGI_KoTISU1JI)   this.KoTISU1JI  = localStorage.SOGI_KoTISU1JI;       //移動1時
		if(localStorage.SOGI_KoTISU1FN)   this.KoTISU1FN  = localStorage.SOGI_KoTISU1FN;       //移動1分
		if(localStorage.SOGI_KoTISU1FR)   this.KoTISU1FR  = localStorage.SOGI_KoTISU1FR;       //F移動1移動元
		if(localStorage.SOGI_KoTISU1BS)   this.KoTISU1BS  = localStorage.SOGI_KoTISU1BS;       //バス台数
		if(localStorage.SOGI_KoTISU1HY)   this.KoTISU1HY  = localStorage.SOGI_KoTISU1HY;       //ハイヤー台数
		if(localStorage.SOGI_KoTISU1JK)   this.KoTISU1JK  = localStorage.SOGI_KoTISU1JK;       //移動1状況
		if(localStorage.SOGI_KoTISU2DT)   this.KoTISU2DT  = localStorage.SOGI_KoTISU2DT;       //移動2日
		if(localStorage.SOGI_KoTISU2JI)   this.KoTISU2JI  = localStorage.SOGI_KoTISU2JI;       //移動2時
		if(localStorage.SOGI_KoTISU2FN)   this.KoTISU2FN  = localStorage.SOGI_KoTISU2FN;       //移動2分
		if(localStorage.SOGI_KoTISU2FR)   this.KoTISU2FR  = localStorage.SOGI_KoTISU2FR;       //F移動2移動元
		if(localStorage.SOGI_KoTISU2BS)   this.KoTISU2BS  = localStorage.SOGI_KoTISU2BS;       //バス台数
		if(localStorage.SOGI_KoTISU2HY)   this.KoTISU2HY  = localStorage.SOGI_KoTISU2HY;       //ハイヤー台数
		if(localStorage.SOGI_KoTISU2JK)   this.KoTISU2JK  = localStorage.SOGI_KoTISU2JK;       //移動2状況
		if(localStorage.SOGI_KoTDRY1DT)   this.KoTDRY1DT  = localStorage.SOGI_KoTDRY1DT;       //ドライ1
		if(localStorage.SOGI_KoTDRY2DT)   this.KoTDRY2DT  = localStorage.SOGI_KoTDRY2DT;       //ドライ2
		if(localStorage.SOGI_KoTDRY3DT)   this.KoTDRY3DT  = localStorage.SOGI_KoTDRY3DT;       //ドライ3
		if(localStorage.SOGI_KoTDRYJK)    this.KoTDRYJK   = localStorage.SOGI_KoTDRYJK;        //ドライ状況
		if(localStorage.SOGI_KoREIJK)     this.KoREIJK    = localStorage.SOGI_KoREIJK;         //礼状状況
		if(localStorage.SOGI_KoREIBK)     this.KoREIBK    = localStorage.SOGI_KoREIBK;         //礼状備考
		if(localStorage.SOGI_KoHENJK)     this.KoHENJK    = localStorage.SOGI_KoHENJK;         //返礼品状況
		if(localStorage.SOGI_KoRIHINM)    this.KoRIHINM   = localStorage.SOGI_KoRIHINM;        //返礼品品目
		if(localStorage.SOGI_KoRICJK)     this.KoRICJK    = localStorage.SOGI_KoRICJK;         //霊柩車状況
		if(localStorage.SOGI_KoRICTK)     this.KoRICTK    = localStorage.SOGI_KoRICTK;         //霊柩車立替
		if(localStorage.SOGI_KoTSSTRJK)   this.KoTSSTRJK  = localStorage.SOGI_KoTSSTRJK;       //寺院状況
		if(localStorage.SOGI_KoTSSTRBS)   this.KoTSSTRBS  = localStorage.SOGI_KoTSSTRBS;       //菩提寺
		if(localStorage.SOGI_KoTSSTRHM)   this.KoTSSTRHM  = localStorage.SOGI_KoTSSTRHM;       //戒(法名）
		if(localStorage.SOGI_KoTSSTROF)   this.KoTSSTROF  = localStorage.SOGI_KoTSSTROF;       //お布施
		if(localStorage.SOGI_KoTKUDAMJ)   this.KoTKUDAMJ  = localStorage.SOGI_KoTKUDAMJ;       //果物状況
		if(localStorage.SOGI_KoTHZBS)     this.KoTHZBS    = localStorage.SOGI_KoTHZBS;         //本膳場所
		if(localStorage.SOGI_KoTHZNY)     this.KoTHZNY    = localStorage.SOGI_KoTHZNY;         //枕飯・枕団子、供養膳
		if(localStorage.SOGI_KoTAZKPHJK)  this.KoTAZKPHJK = localStorage.SOGI_KoTAZKPHJK;      //写真
		if(localStorage.SOGI_KoTAZKPB)    this.KoTAZKPB   = localStorage.SOGI_KoTAZKPB;        //写真備考
		if(localStorage.SOGI_KoTAZKKKJK)  this.KoTAZKKKJK = localStorage.SOGI_KoTAZKKKJK;      //許可書
		if(localStorage.SOGI_KoTAZKKB)    this.KoTAZKKB   = localStorage.SOGI_KoTAZKKB;        //許可備考
		if(localStorage.SOGI_KoTHCDT)     this.KoTHCDT    = localStorage.SOGI_KoTHCDT;         //発注日
		if(localStorage.SOGI_KoTHCJI)     this.KoTHCJI    = localStorage.SOGI_KoTHCJI;         //発注時
		if(localStorage.SOGI_KoTHCFN)     this.KoTHCFN    = localStorage.SOGI_KoTHCFN;         //発注分
		if(localStorage.SOGI_KoTMGKT)     this.KoTMGKT    = localStorage.SOGI_KoTMGKT;         //担当者確定
		if(localStorage.SOGI_KoTBIKO1)    this.KoTBIKO1   = localStorage.SOGI_KoTBIKO1;        //備考1
		if(localStorage.SOGI_KoTBIKO2)    this.KoTBIKO2   = localStorage.SOGI_KoTBIKO2;        //備考2
		if(localStorage.SOGI_KoTBIKO3)    this.KoTBIKO3   = localStorage.SOGI_KoTBIKO3;        //備考3
		if(localStorage.SOGI_KoMUKBDT)    this.KoMUKBDT   = localStorage.SOGI_KoMUKBDT;	      //見積受付簿印刷日
		if(localStorage.SOGI_KoMUKBTM)    this.KoMUKBTM   = localStorage.SOGI_KoMUKBTM;	      //見積受付簿印刷時間
		if(localStorage.SOGI_KoMSKSDT)    this.KoMSKSDT   = localStorage.SOGI_KoMSKSDT;	      //見積祭事印刷日
		if(localStorage.SOGI_KoMSKSTM)    this.KoMSKSTM   = localStorage.SOGI_KoMSKSTM;	      //見積祭事印刷時間
		if(localStorage.SOGI_KoMTKSDT)    this.KoMTKSDT   = localStorage.SOGI_KoMTKSDT;	      //見積立替印刷日
		if(localStorage.SOGI_KoMTKSTM)    this.KoMTKSTM   = localStorage.SOGI_KoMTKSTM;	      //見積立替印刷時間
		if(localStorage.SOGI_InstDt)      this.InstDt     = localStorage.SOGI_InstDt;          //作成日時
		if(localStorage.SOGI_InstId)      this.InstId     = localStorage.SOGI_InstId;          //作成者
		if(localStorage.SOGI_UpdtDt)      this.UpdtDt     = localStorage.SOGI_UpdtDt;          //更新日時
		if(localStorage.SOGI_UpdtId)      this.UpdtId     = localStorage.SOGI_UpdtId;          //更新者

		if(localStorage.SOGI_KgyCd)       this.KgyCd      = localStorage.SOGI_KgyCd;
		if(localStorage.SOGI_KgyNM)       this.KgyNM      = localStorage.SOGI_KgyNM;
		if(localStorage.SOGI_BLCKCD)      this.BLCKCD     = localStorage.SOGI_BLCKCD;
		if(localStorage.SOGI_BLCKNM)      this.BLCKNM     = localStorage.SOGI_BLCKNM;
		if(localStorage.SOGI_SynCd)       this.SynCd      = localStorage.SOGI_SynCd;
		if(localStorage.SOGI_SynNm)       this.SynNm      = localStorage.SOGI_SynNm;
		if(localStorage.SOGI_TANID)       this.TANID      = localStorage.SOGI_TANID;
		if(localStorage.SOGI_DENNO)       this.DENNO      = localStorage.SOGI_SGDENNO;

	this.SMKBLCKquery();	//部署マスタの取得
		
    },
    //-----------------------------------------------------------------
    //部署マスタの取得
    //-----------------------------------------------------------------
    async SMKBLCKquery() {
        //ブロックマスタ
        this.PARAMT.length = 0;
        const P_PARAMT = {BLCKCD: this.KoTBLOKCD};
        axios.post('/api/SMKBLCKGET',P_PARAMT)
           .then(res => {
            this.PARAMT = res.data;
            this.BL_BLCKCD    = this.BLCKCD;    		  //伝部署コード
            this.BL_BLCKNM    = this.PARAMT[0].BLCKNM;    //部署名称
            this.BL_BLCKTEL   = this.PARAMT[0].BLCKTEL;   //TEL
            this.BL_BLCKFAX   = this.PARAMT[0].BLCKFAX;   //FAX
            this.BL_BLCKMAIL  = this.PARAMT[0].BLCKMAIL;  //MAIL
            this.BL_BLCKSK1   = this.PARAMT[0].BLCKSK1;   //請求先代表者
            this.BL_BLCKSK5   = this.PARAMT[0].BLCKSK5;   //請求口座名義
            this.BL_BLCKSK4   = this.PARAMT[0].BLCKSK4;   //請求取引銀行名
            this.BL_BLCKSK6   = this.PARAMT[0].BLCKSK6;   //口座
            this.BL_BLCKSK2   = this.PARAMT[0].BLCKSK2;   //請求住所
            this.BL_BLCKSK3   = this.PARAMT[0].BLCKSK3;   //請求電話
            this.BL_BLCKYB1   = this.PARAMT[0].BLCKYB1;   //郵便番号1
            this.BL_BLCKYB2   = this.PARAMT[0].BLCKYB2;   //郵便番号2
            this.BL_BLCKJY1   = this.PARAMT[0].BLCKJY1;   //住所1
            this.BL_BLCKJY2   = this.PARAMT[0].BLCKJY2;   //住所2
            this.BL_BLCKJY3   = this.PARAMT[0].BLCKJY3;   //住所3
        })
		this.EXSJUBquery();		//伝票内容
    },
    //-----------------------------------------------------------------
    //祭事受付簿エクセルマスタの取得更新
    //-----------------------------------------------------------------
    async EXSJUBquery() {
        this.PARAMT.length = 0;
        axios.post('/api/EXSJUBGet')
        .then(res => {
        this.PARAMT = res.data;
		//console.log(this.PARAMT);
		this.UBPR_KoTNO			=	this.PARAMT[0].KoTNO;		//受付番号
		this.UBPR_KoTUMSKSDT	=   this.PARAMT[0].KoTUMSKSDT;	//印刷日
		this.UBPR_KoTUMSKSTM	=   this.PARAMT[0].KoTUMSKSTM;	//印刷時間
		this.UBPR_BLCKCD        =   this.PARAMT[0].BLCKCD;	    //営業所コード
		this.UBPR_BLCKNM        =   this.PARAMT[0].BLCKNM;	    //営業所名
		this.UBPR_BLCKYB1       =   this.PARAMT[0].BLCKYB1;	    //郵便番号1
		this.UBPR_BLCKYB2       =   this.PARAMT[0].BLCKYB2;	    //郵便番号2
		this.UBPR_BLCKJY1       =   this.PARAMT[0].BLCKJY1;	    //住所1
		this.UBPR_BLCKJY2       =   this.PARAMT[0].BLCKJY2;	    //住所2
		this.UBPR_BLCKJY3       =   this.PARAMT[0].BLCKJY3;	    //住所3
		this.UBPR_BLCKTEL       =   this.PARAMT[0].BLCKTEL;	    //TEL
		this.UBPR_BLCKFAX       =   this.PARAMT[0].BLCKFAX;	    //FAX
		this.UBPR_BLCKMAIL	    =   this.PARAMT[0].BLCKMAIL;	//MAIL
		this.UBPR_BLCKSK1       =   this.PARAMT[0].BLCKSK1;	    //請求先代表者
		this.UBPR_BLCKSK2       =   this.PARAMT[0].BLCKSK2;	    //請求住所
		this.UBPR_BLCKSK3	    =   this.PARAMT[0].BLCKSK3;	    //請求電話
		this.UBPR_BLCKSK4	    =   this.PARAMT[0].BLCKSK4;	    //請求取引銀行名
		this.UBPR_BLCKSK5	    =   this.PARAMT[0].BLCKSK5;	    //請求口座名義
		this.UBPR_BLCKSK6	    =   this.PARAMT[0].BLCKSK6;	    //口座
		this.UBPR_KoTUKDT	    =   this.PARAMT[0].KoTUKDT;	    //受付日
		this.UBPR_KoTNTNCD	    =   this.PARAMT[0].KoTNTNCD;	//担当者コード
		this.UBPR_KoTNTNNM	    =   this.PARAMT[0].KoTNTNNM;	//担当者名称
		this.UBPR_KoETNTNCD	    =   this.PARAMT[0].KoETNTNCD;	//営業担当コード
		this.UBPR_KoETNTNNM	    =   this.PARAMT[0].KoETNTNNM;	//営業担当名称
		this.UBPR_KoTKANO	    =   this.PARAMT[0].KoTKANO;	    //会員番号
		this.UBPR_KoTKANM	    =   this.PARAMT[0].KoTKANM;	    //会員名称
		this.UBPR_KoTMOKE	    =   this.PARAMT[0].KoTMOKE;	    //喪家
		this.UBPR_KoTCD         =   this.PARAMT[0].KoTCD;	    //顧客提携先コード
		this.UBPR_KoTNM	        =   this.PARAMT[0].KoTNM;	    //顧客提携先名称
		this.UBPR_KoTTKNM	    =   this.PARAMT[0].KoTTKNM;	    //故人(カナ）
		this.UBPR_KoTTNM	    =   this.PARAMT[0].KoTTNM;	    //故人名前
		this.UBPR_KoTTNG	    =   this.PARAMT[0].KoTTNG;	    //故人生年月日年号
		this.UBPR_KoTTBSYY	    =   this.PARAMT[0].KoTTBSYY;	//故人生年月日年
		this.UBPR_KoTTBSMM	    =   this.PARAMT[0].KoTTBSMM;	//故人生年月日月
		this.UBPR_KoTTBSDD	    =   this.PARAMT[0].KoTTBSDD;	//故人生年月日日
		this.UBPR_KoTTEG	    =   this.PARAMT[0].KoTTEG;	    //故人年齢
		this.UBPR_KoTTSEX	    =   this.PARAMT[0].KoTTSEX;	    //故人性別
		this.UBPR_KoTTNEX	    =   this.PARAMT[0].KoTTNEX;	    //故人続柄
		this.UBPR_KoTTYU	    =   this.PARAMT[0].KoTTYU;	    //故人郵便番号
		this.UBPR_KoTTJYU1	    =   this.PARAMT[0].KoTTJYU1;	//故人住所1
		this.UBPR_KoTTJYU2	    =   this.PARAMT[0].KoTTJYU2;	//故人住所2
		this.UBPR_KoTTJYU3	    =   this.PARAMT[0].KoTTJYU3;	//故人住所3
		this.UBPR_KoTTTEL	    =   this.PARAMT[0].KoTTTEL;	    //故人電話番号
		this.UBPR_KoTMKNM	    =   this.PARAMT[0].KoTMKNM;	    //喪主(カナ）
		this.UBPR_KoTMNM	    =   this.PARAMT[0].KoTMNM;	    //喪主名前
		this.UBPR_KoTMNEX	    =   this.PARAMT[0].KoTMNEX;	    //喪主続柄
		this.UBPR_KoTMSEX	    =   this.PARAMT[0].KoTMSEX;	    //喪主性別
		this.UBPR_KoTMYU	    =   this.PARAMT[0].KoTMYU;	    //喪主郵便番号
		this.UBPR_KoTMJYU1	    =   this.PARAMT[0].KoTMJYU1;	//喪主住所1
		this.UBPR_KoTMJYU2	    =   this.PARAMT[0].KoTMJYU2;	//喪主住所2
		this.UBPR_KoTMJYU3	    =   this.PARAMT[0].KoTMJYU3;	//喪主住所3
		this.UBPR_KoTMTEL	    =   this.PARAMT[0].KoTMTEL;	    //喪主電話番号
		this.UBPR_KoTMTELK	    =   this.PARAMT[0].KoTMTELK;	//喪主携帯
		this.UBPR_KoTMTMail	    =   this.PARAMT[0].KoTMTMail;	//喪主Mail
		this.UBPR_KoTMBIKO	    =   this.PARAMT[0].KoTMBIKO;	//喪主備考
		this.UBPR_KoTSNM	    =   this.PARAMT[0].KoTSNM;	    //お支払責任者名前
		this.UBPR_KoTSHYODT	    =   this.PARAMT[0].KoTSHYODT;	//お支払予定日
		this.UBPR_KoTSPNM	    =   this.PARAMT[0].KoTSPNM;	    //入金方法名
		this.UBPR_KoTSYU	    =   this.PARAMT[0].KoTSYU;	    //支払責任者郵便番号
		this.UBPR_KoTSJYU1	    =   this.PARAMT[0].KoTSJYU1;	//お支払責任者住所1
		this.UBPR_KoTSJYU2	    =   this.PARAMT[0].KoTSJYU2;	//お支払責任者住所2
		this.UBPR_KoTSJYU3	    =   this.PARAMT[0].KoTSJYU3;	//お支払責任者住所3
		this.UBPR_KoTSTEL	    =   this.PARAMT[0].KoTSTEL;	    //お支払責任者電話番号
		this.UBPR_KoTSTELK	    =   this.PARAMT[0].KoTSTELK;	//お支払責任者携帯
		this.UBPR_KoTSMail	    =   this.PARAMT[0].KoTSMail;	//お支払責任者Mail
		this.UBPR_KoTSBIKO	    =   this.PARAMT[0].KoTSBIKO;	//お支払責任者備考
		this.UBPR_KoTSSTRNM	    =   this.PARAMT[0].KoTSSTRNM;	//寺院名称
		this.UBPR_KoTSSSONM	    =   this.PARAMT[0].KoTSSSONM;	//宗旨名称
		this.UBPR_KoTSSSLBS	    =   this.PARAMT[0].KoTSSSLBS;	//墓石墓所
		this.UBPR_KoTSSSLBD	    =   this.PARAMT[0].KoTSSSLBD;	//仏壇
		this.UBPR_KoTSSKSA1	    =   this.PARAMT[0].KoTSSKSA1;	//会葬挨拶1
		this.UBPR_KoTSSKMNM	    =   this.PARAMT[0].KoTSSKMNM;	//家紋・紋章名称
		this.UBPR_KoTSSBIKO	    =   this.PARAMT[0].KoTSSBIKO;	//引継
		this.UBPR_KoTPLCD	    =   this.PARAMT[0].KoTPLCD;	    //プランコード
		this.UBPR_KoTPLNM	    =   this.PARAMT[0].KoTPLNM;	    //プラン名称
		this.UBPR_KoTKKCD	    =   this.PARAMT[0].KoTKKCD;	    //会館コード
		this.UBPR_KoTKKNM	    =   this.PARAMT[0].KoTKKNM;	    //会館名
		this.UBPR_KoTHRCD	    =   this.PARAMT[0].KoTHRCD;	    //ホールコード
		this.UBPR_KoTHRNM	    =   this.PARAMT[0].KoTHRNM;	    //ホール名
		this.UBPR_TBGO	        =   this.PARAMT[0].TBGO;	    //テーブル開始行
		this.UBPR_SKYTGYO	    =   this.PARAMT[0].SKYTGYO;	    //行
		this.UBPR_SKYTKB	    =   this.PARAMT[0].SKYTKB;	    //区分
		this.UBPR_SKYTNM	    =   this.PARAMT[0].SKYTNM;	    //施行名称
		this.UBPR_SKYTDATE	    =   this.PARAMT[0].SKYTDATE;	//施行日
		this.UBPR_SKYTHH	    =   this.PARAMT[0].SKYTHH;	    //開始時
		this.UBPR_SKYTMM	    =   this.PARAMT[0].SKYTMM;	    //開始分
		this.UBPR_SKYSHH	    =   this.PARAMT[0].SKYSHH;	    //終了時
		this.UBPR_SKYSMM	    =   this.PARAMT[0].SKYSMM;	    //終了分
		this.UBPR_SKYTFBNM	    =   this.PARAMT[0].SKYTFBNM;	//場所名称
		this.UBPR_SKYTP1NM	    =   this.PARAMT[0].SKYTP1NM;	//入力1名称
		this.UBPR_SKYTP1DT	    =   this.PARAMT[0].SKYTP1DT;	//入力1値
		this.UBPR_SKYTP1TN	    =   this.PARAMT[0].SKYTP1TN;	//入力1単位
		this.UBPR_SKYTP2NM	    =   this.PARAMT[0].SKYTP2NM;	//入力2名称
		this.UBPR_SKYTP2DT	    =   this.PARAMT[0].SKYTP2DT;	//入力2値
		this.UBPR_SKYTP2TN	    =   this.PARAMT[0].SKYTP2TN;	//入力2単位
		this.UBPR_SKYTP3NM	    =   this.PARAMT[0].SKYTP3NM;	//入力3名称
		this.UBPR_SKYTP3DT	    =   this.PARAMT[0].SKYTP3DT;	//入力3値
		this.UBPR_SKYTP3TN	    =   this.PARAMT[0].SKYTP3TN;	//入力3単位
		this.UBPR_SKYTNY	    =   this.PARAMT[0].SKYTNY;	    //備考
		this.UBPR_KoSGICNM	    =   this.PARAMT[0].KoSGICNM;	//葬儀委員長名
		this.UBPR_KoSGICKN	    =   this.PARAMT[0].KoSGICKN;	//葬儀委員長かな名
		this.UBPR_KoSGICZK	    =   this.PARAMT[0].KoSGICZK;	//葬儀委員長属性
		this.UBPR_KoDMKB	    =   this.PARAMT[0].KoDMKB;	    //DM区分
        })

		if(this.UBPR_KgyCd	    == null){this.UBPR_KgyCd		= "";} 	//企業コード
		if(this.UBPR_KoTNO	    == null){this.UBPR_KoTNO		= "";} 	//受付番号
		if(this.UBPR_KoTUMSKSDT	== null){this.UBPR_KoTUMSKSDT	= "";} 	//印刷日
		if(this.UBPR_KoTUMSKSTM	== null){this.UBPR_KoTUMSKSTM	= "";} 	//印刷時間
		if(this.UBPR_BLCKCD	    == null){this.UBPR_BLCKCD		= "";} 	//営業所コード
		if(this.UBPR_BLCKNM	    == null){this.UBPR_BLCKNM		= "";} 	//営業所名
		if(this.UBPR_BLCKYB1	== null){this.UBPR_BLCKYB1		= "";} 	//郵便番号1
		if(this.UBPR_BLCKYB2	== null){this.UBPR_BLCKYB2		= "";} 	//郵便番号2
		if(this.UBPR_BLCKJY1	== null){this.UBPR_BLCKJY1		= "";} 	//住所1
		if(this.UBPR_BLCKJY2	== null){this.UBPR_BLCKJY2		= "";} 	//住所2
		if(this.UBPR_BLCKJY3	== null){this.UBPR_BLCKJY3		= "";} 	//住所3
		if(this.UBPR_BLCKTEL	== null){this.UBPR_BLCKTEL		= "";} 	//TEL
		if(this.UBPR_BLCKFAX	== null){this.UBPR_BLCKFAX		= "";} 	//FAX
		if(this.UBPR_BLCKMAIL	== null){this.UBPR_BLCKMAIL		= "";} 	//MAIL
		if(this.UBPR_BLCKSK1	== null){this.UBPR_BLCKSK1		= "";} 	//請求先代表者
		if(this.UBPR_BLCKSK2	== null){this.UBPR_BLCKSK2		= "";} 	//請求住所
		if(this.UBPR_BLCKSK3	== null){this.UBPR_BLCKSK3		= "";} 	//請求電話
		if(this.UBPR_BLCKSK4	== null){this.UBPR_BLCKSK4		= "";} 	//請求取引銀行名
		if(this.UBPR_BLCKSK5	== null){this.UBPR_BLCKSK5		= "";} 	//請求口座名義
		if(this.UBPR_BLCKSK6	== null){this.UBPR_BLCKSK6		= "";} 	//口座
		if(this.UBPR_KoTUKDT	== null){this.UBPR_KoTUKDT		= "";} 	//受付日
		if(this.UBPR_KoTNTNCD	== null){this.UBPR_KoTNTNCD		= "";} 	//担当者コード
		if(this.UBPR_KoTNTNNM	== null){this.UBPR_KoTNTNNM		= "";} 	//担当者名称
		if(this.UBPR_KoETNTNCD	== null){this.UBPR_KoETNTNCD	= "";} 	//営業担当コード
		if(this.UBPR_KoETNTNNM	== null){this.UBPR_KoETNTNNM	= "";} 	//営業担当名称
		if(this.UBPR_KoTKANO	== null){this.UBPR_KoTKANO		= "";} 	//会員番号
		if(this.UBPR_KoTKANM	== null){this.UBPR_KoTKANM		= "";} 	//会員名称
		if(this.UBPR_KoTMOKE	== null){this.UBPR_KoTMOKE		= "";} 	//喪家
		if(this.UBPR_KoTCD		== null){this.UBPR_KoTCD		= "";} 	//顧客提携先コード
		if(this.UBPR_KoTNM		== null){this.UBPR_KoTNM		= "";} 	//顧客提携先名称
		if(this.UBPR_KoTTKNM	== null){this.UBPR_KoTTKNM		= "";} 	//故人(カナ）
		if(this.UBPR_KoTTNM		== null){this.UBPR_KoTTNM		= "";} 	//故人名前
		if(this.UBPR_KoTTNG		== null){this.UBPR_KoTTNG		= "";} 	//故人生年月日年号
		if(this.UBPR_KoTTBSYY	== null){this.UBPR_KoTTBSYY		= "";} 	//故人生年月日年
		if(this.UBPR_KoTTBSMM	== null){this.UBPR_KoTTBSMM		= "";} 	//故人生年月日月
		if(this.UBPR_KoTTBSDD	== null){this.UBPR_KoTTBSDD		= "";} 	//故人生年月日日
		if(this.UBPR_KoTTEG		== null){this.UBPR_KoTTEG		= "";} 	//故人年齢
		if(this.UBPR_KoTTSEX	== null){this.UBPR_KoTTSEX		= "";} 	//故人性別
		if(this.UBPR_KoTTNEX	== null){this.UBPR_KoTTNEX		= "";} 	//故人続柄
		if(this.UBPR_KoTTYU		== null){this.UBPR_KoTTYU		= "";} 	//故人郵便番号
		if(this.UBPR_KoTTJYU1	== null){this.UBPR_KoTTJYU1		= "";} 	//故人住所1
		if(this.UBPR_KoTTJYU2	== null){this.UBPR_KoTTJYU2		= "";} 	//故人住所2
		if(this.UBPR_KoTTJYU3	== null){this.UBPR_KoTTJYU3		= "";} 	//故人住所3
		if(this.UBPR_KoTTTEL	== null){this.UBPR_KoTTTEL		= "";} 	//故人電話番号
		if(this.UBPR_KoTMKNM	== null){this.UBPR_KoTMKNM		= "";} 	//喪主(カナ）
		if(this.UBPR_KoTMNM		== null){this.UBPR_KoTMNM		= "";} 	//喪主名前
		if(this.UBPR_KoTMNEX	== null){this.UBPR_KoTMNEX		= "";} 	//喪主続柄
		if(this.UBPR_KoTMSEX	== null){this.UBPR_KoTMSEX		= "";} 	//喪主性別
		if(this.UBPR_KoTMYU		== null){this.UBPR_KoTMYU		= "";} 	//喪主郵便番号
		if(this.UBPR_KoTMJYU1	== null){this.UBPR_KoTMJYU1		= "";} 	//喪主住所1
		if(this.UBPR_KoTMJYU2	== null){this.UBPR_KoTMJYU2		= "";} 	//喪主住所2
		if(this.UBPR_KoTMJYU3	== null){this.UBPR_KoTMJYU3		= "";} 	//喪主住所3
		if(this.UBPR_KoTMTEL	== null){this.UBPR_KoTMTEL		= "";} 	//喪主電話番号
		if(this.UBPR_KoTMTELK	== null){this.UBPR_KoTMTELK		= "";} 	//喪主携帯
		if(this.UBPR_KoTMTMail	== null){this.UBPR_KoTMTMail	= "";} 	//喪主Mail
		if(this.UBPR_KoTMBIKO	== null){this.UBPR_KoTMBIKO		= "";} 	//喪主備考
		if(this.UBPR_KoTSNM		== null){this.UBPR_KoTSNM		= "";} 	//お支払責任者名前
		if(this.UBPR_KoTSHYODT	== null){this.UBPR_KoTSHYODT	= "";} 	//お支払予定日
		if(this.UBPR_KoTSPNM	== null){this.UBPR_KoTSPNM		= "";} 	//入金方法名
		if(this.UBPR_KoTSYU		== null){this.UBPR_KoTSYU		= "";} 	//支払責任者郵便番号
		if(this.UBPR_KoTSJYU1	== null){this.UBPR_KoTSJYU1		= "";} 	//お支払責任者住所1
		if(this.UBPR_KoTSJYU2	== null){this.UBPR_KoTSJYU2		= "";} 	//お支払責任者住所2
		if(this.UBPR_KoTSJYU3	== null){this.UBPR_KoTSJYU3		= "";} 	//お支払責任者住所3
		if(this.UBPR_KoTSTEL	== null){this.UBPR_KoTSTEL		= "";} 	//お支払責任者電話番号
		if(this.UBPR_KoTSTELK	== null){this.UBPR_KoTSTELK		= "";} 	//お支払責任者携帯
		if(this.UBPR_KoTSMail	== null){this.UBPR_KoTSMail		= "";} 	//お支払責任者Mail
		if(this.UBPR_KoTSBIKO	== null){this.UBPR_KoTSBIKO		= "";} 	//お支払責任者備考
		if(this.UBPR_KoTSSTRNM	== null){this.UBPR_KoTSSTRNM	= "";} 	//寺院名称
		if(this.UBPR_KoTSSSONM	== null){this.UBPR_KoTSSSONM	= "";} 	//宗旨名称
		if(this.UBPR_KoTSSSLBS	== null){this.UBPR_KoTSSSLBS	= "";} 	//墓石・墓所
		if(this.UBPR_KoTSSSLBD	== null){this.UBPR_KoTSSSLBD	= "";} 	//仏壇
		if(this.UBPR_KoTSSKSA1	== null){this.UBPR_KoTSSKSA1	= "";} 	//会葬挨拶1
		if(this.UBPR_KoTSSKMNM	== null){this.UBPR_KoTSSKMNM	= "";} 	//家紋・紋章名称
		if(this.UBPR_KoTSSBIKO	== null){this.UBPR_KoTSSBIKO	= "";} 	//引継
		if(this.UBPR_KoTPLCD	== null){this.UBPR_KoTPLCD		= "";} 	//プランコード
		if(this.UBPR_KoTPLNM	== null){this.UBPR_KoTPLNM		= "";} 	//プラン名称
		if(this.UBPR_KoTKKCD	== null){this.UBPR_KoTKKCD		= "";} 	//会館コード
		if(this.UBPR_KoTKKNM	== null){this.UBPR_KoTKKNM		= "";} 	//会館名
		if(this.UBPR_KoTHRCD	== null){this.UBPR_KoTHRCD		= "";} 	//ホールコード
		if(this.UBPR_KoTHRNM	== null){this.UBPR_KoTHRNM		= "";} 	//ホール名
		if(this.UBPR_TBGO		== null){this.UBPR_TBGO			= "";} 	//テーブル開始行
		if(this.UBPR_SKYTGYO	== null){this.UBPR_SKYTGYO		= "";} 	//行
		if(this.UBPR_SKYTKB		== null){this.UBPR_SKYTKB		= "";} 	//区分
		if(this.UBPR_SKYTNM		== null){this.UBPR_SKYTNM		= "";} 	//施行名称
		if(this.UBPR_SKYTDATE	== null){this.UBPR_SKYTDATE		= "";} 	//施行日
		if(this.UBPR_SKYTHH		== null){this.UBPR_SKYTHH		= "";} 	//開始時
		if(this.UBPR_SKYTMM		== null){this.UBPR_SKYTMM		= "";} 	//開始分
		if(this.UBPR_SKYSHH		== null){this.UBPR_SKYSHH		= "";} 	//終了時
		if(this.UBPR_SKYSMM		== null){this.UBPR_SKYSMM		= "";} 	//終了分
		if(this.UBPR_SKYTFBNM	== null){this.UBPR_SKYTFBNM		= "";} 	//場所名称
		if(this.UBPR_SKYTP1NM	== null){this.UBPR_SKYTP1NM		= "";} 	//入力1名称
		if(this.UBPR_SKYTP1DT	== null){this.UBPR_SKYTP1DT		= "";} 	//入力1値
		if(this.UBPR_SKYTP1TN	== null){this.UBPR_SKYTP1TN		= "";} 	//入力1単位
		if(this.UBPR_SKYTP2NM	== null){this.UBPR_SKYTP2NM		= "";} 	//入力2名称
		if(this.UBPR_SKYTP2DT	== null){this.UBPR_SKYTP2DT		= "";} 	//入力2値
		if(this.UBPR_SKYTP2TN	== null){this.UBPR_SKYTP2TN		= "";} 	//入力2単位
		if(this.UBPR_SKYTP3NM	== null){this.UBPR_SKYTP3NM		= "";} 	//入力3名称
		if(this.UBPR_SKYTP3DT	== null){this.UBPR_SKYTP3DT		= "";} 	//入力3値
		if(this.UBPR_SKYTP3TN	== null){this.UBPR_SKYTP3TN		= "";} 	//入力3単位
		if(this.UBPR_SKYTNY		== null){this.UBPR_SKYTNY		= "";} 	//備考
		if(this.UBPR_KoSGICNM	== null){this.UBPR_KoSGICNM		= "";} 	//葬儀委員長名
		if(this.UBPR_KoSGICKN	== null){this.UBPR_KoSGICKN		= "";} 	//葬儀委員長かな名
		if(this.UBPR_KoSGICZK	== null){this.UBPR_KoSGICZK		= "";} 	//葬儀委員長属性
		if(this.UBPR_KoDMKB		== null){this.UBPR_KoDMKB		= "";} 	//DM区分
		if(this.UBPR_InstDt		== null){this.UBPR_InstDt		= "";} 	//作成日時
		if(this.UBPR_InstId		== null){this.UBPR_InstId		= "";} 	//作成者
		if(this.UBPR_UpdtDt		== null){this.UBPR_UpdtDt		= "";} 	//更新日時
		if(this.UBPR_UpdtId		== null){this.UBPR_UpdtId		= "";} 	//更新者
		
		this.EXSJMMquery();		//祭事受付簿エクセルマスタ
    },
	//-----------------------------------------------------------------
    //祭事見積書エクセル
    //-----------------------------------------------------------------
    async EXSJMMquery() {
       this.PARAMT.length = 0;
        axios.post('/api/EXSJSKGet')
        .then(res => {
        this.PARAMT = res.data;
        this.SJPR_KoTNO         =   this.PARAMT[0].KoTNO;     	//受付番号
		this.SJPR_KoTUMSKSDT	=   this.PARAMT[0].KoTUMSKSDT;  //印刷日
		this.SJPR_KoTUMSKSTM	=   this.PARAMT[0].KoTUMSKSTM;	//印刷時間
		this.SJPR_BLCKCD        =   this.PARAMT[0].BLCKCD;     	//営業所コード
		this.SJPR_BLCKNM        =   this.PARAMT[0].BLCKNM;     	//営業所名
		this.SJPR_BLCKYB1       =   this.PARAMT[0].BLCKYB1;    	//郵便番号1
		this.SJPR_BLCKYB2       =   this.PARAMT[0].BLCKYB2;     //郵便番号2
		this.SJPR_BLCKJY1	    =   this.PARAMT[0].BLCKJY1;     //住所1
		this.SJPR_BLCKJY2	    =   this.PARAMT[0].BLCKJY2;     //住所2
		this.SJPR_BLCKJY3	    =   this.PARAMT[0].BLCKJY3;     //住所3
		this.SJPR_BLCKTEL	    =   this.PARAMT[0].BLCKTEL;     //TEL
		this.SJPR_BLCKFAX	    =   this.PARAMT[0].BLCKFAX;     //FAX
		this.SJPR_BLCKMAIL	    =   this.PARAMT[0].BLCKMAIL;    //MAIL
		this.SJPR_BLCKSK1	    =   this.PARAMT[0].BLCKSK1;     //請求先代表者
		this.SJPR_BLCKSK2	    =   this.PARAMT[0].BLCKSK2;     //請求住所
		this.SJPR_BLCKSK3	    =   this.PARAMT[0].BLCKSK3;     //請求電話
		this.SJPR_BLCKSK4	    =   this.PARAMT[0].BLCKSK4;     //請求取引銀行名
		this.SJPR_BLCKSK5	    =   this.PARAMT[0].BLCKSK5;     //請求口座名義
		this.SJPR_BLCKSK6	    =   this.PARAMT[0].BLCKSK6;     //口座
		this.SJPR_KoTUKDT	    =   this.PARAMT[0].KoTUKDT;     //受付日
		this.SJPR_KoTNTNCD	    =   this.PARAMT[0].KoTNTNCD;    //担当者コード
		this.SJPR_KoTNTNNM	    =   this.PARAMT[0].KoTNTNNM;    //担当者名称
		this.SJPR_KoETNTNCD	    =   this.PARAMT[0].KoETNTNCD;   //営業担当コード
		this.SJPR_KoETNTNNM	    =   this.PARAMT[0].KoETNTNNM;   //営業担当名称
		this.SJPR_KoTSNM	    =   this.PARAMT[0].KoTSNM;     	//名前
		this.SJPR_KoTSYU	    =   this.PARAMT[0].KoTSYU;     	//住所〒
		this.SJPR_KoTSJYU1	    =   this.PARAMT[0].KoTSJYU1;    //住所1
		this.SJPR_KoTSJYU2	    =   this.PARAMT[0].KoTSJYU2;    //住所2
		this.SJPR_KoTSJYU3	    =   this.PARAMT[0].KoTSJYU3;    //住所3
		this.SJPR_KoTSTEL	    =   this.PARAMT[0].KoTSTEL;     //℡
		this.SJPR_KoTSTELK	    =   this.PARAMT[0].KoTSTELK;    //携帯
		this.SJPR_KoTSMail	    =   this.PARAMT[0].KoTSMail;    //メール
		this.SJPR_KoTSURKGK	    =   this.PARAMT[0].KoTSURKGK;   //祭事売上(税込み)
		this.SJPR_KoTURSMST	    =   this.PARAMT[0].KoTURSMST;   //祭事外税売上  (税抜)
		this.SJPR_KoTURSMKG	    =   this.PARAMT[0].KoTURSMKG;   //祭事軽減税売上(税抜)
		this.SJPR_KoTURSMHK	    =   this.PARAMT[0].KoTURSMHK;   //祭事非課税売上(税抜)
		this.SJPR_KoTSNEBST	    =   this.PARAMT[0].KoTSNEBST;   //祭事外税値引
		this.SJPR_KoTSNEBKG	    =   this.PARAMT[0].KoTSNEBKG;   //祭事軽減税値引
		this.SJPR_KoTSNEBHK	    =   this.PARAMT[0].KoTSNEBHK;   //祭事非課税値引
		this.SJPR_KoTSKAZST	    =   this.PARAMT[0].KoTSKAZST;   //祭事外税対象額計
		this.SJPR_KoTSKAZKG	    =   this.PARAMT[0].KoTSKAZKG;   //祭事軽減税対象額計
		this.SJPR_KoTSKAZHK	    =   this.PARAMT[0].KoTSKAZHK;   //祭事非課税対象額計
		this.SJPR_KoTSSYOST	    =   this.PARAMT[0].KoTSSYOST;   //祭事外税消費税
		this.SJPR_KoTSSYOKG	    =   this.PARAMT[0].KoTSSYOKG;   //祭事軽減税消費税
		this.SJPR_TBGO	        =   this.PARAMT[0].TBGO;     	//テーブル開始行

		this.SJPR_MsSETCD	    =   this.PARAMT[0].MsSETCD;     //セットコード
		this.SJPR_MsSETNM	    =   this.PARAMT[0].MsSETNM;     //セット名称
		this.SJPR_MsHIBJYUN	    =   this.PARAMT[0].MsHIBJYUN;   //品目大分類並び順
		this.SJPR_MsHIBCD	    =   this.PARAMT[0].MsHIBCD;     //品目大分類コード
		this.SJPR_MsHIBNM	    =   this.PARAMT[0].MsHIBNM;     //品目大分類名称
		this.SJPR_MsSYBRJN	    =   this.PARAMT[0].MsSYBRJN;    //品目分類順
		this.SJPR_MsSYBRCD	    =   this.PARAMT[0].MsSYBRCD;    //品目分類コード
		this.SJPR_MsSYBRNM	    =   this.PARAMT[0].MsSYBRNM;    //品目分類名称
		this.SJPR_MsHIJYUN	    =   this.PARAMT[0].MsHIJYUN;    //品目並び順
		this.SJPR_MsHICD	    =   this.PARAMT[0].MsHICD;     	//品目コード
		this.SJPR_MsHINM	    =   this.PARAMT[0].MsHINM;     	//品目名称
		this.SJPR_MsTAN	        =   this.PARAMT[0].MsTAN;     	//単位
		this.SJPR_MsSETFG	    =   this.PARAMT[0].MsSETFG;     //セットフラグ
		this.SJPR_MsUNT	        =   this.PARAMT[0].MsUNT;     	//販売単価
		this.SJPR_MsSYOKB	    =   this.PARAMT[0].MsSYOKB;     //売上消費税区分
		this.SJPR_MsSuu	        =   this.PARAMT[0].MsSuu;     	//売上数量
		this.SJPR_MsKin	        =   this.PARAMT[0].MsKin;     	//売上金額(税抜)
		this.SJPR_MsNebiki	    =   this.PARAMT[0].MsNebiki;    //売上値引(税抜)
		this.SJPR_MsGKing	    =   this.PARAMT[0].MsGKing;     //売上額値引後(税抜)
		this.SJPR_MsUCSYOG	    =   this.PARAMT[0].MsUCSYOG;    //売上消費税額
		this.SJPR_MsZKGK	    =   this.PARAMT[0].MsZKGK;     	//売上税込額(税込)
		this.SJPR_MsBIKO	    =   this.PARAMT[0].MsBIKO;     	//備考

		this.SJPR_KoTTSEX	    =   this.PARAMT[0].KoTTSEX;     //故人性別
        })
		
		if(this.SJPR_KoTNO	    == null){this.SJPR_KoTNO        = "";}  //受付番号
		if(this.SJPR_KoTUMSKSDT	== null){this.SJPR_KoTUMSKSDT   = "";}  //印刷日
		if(this.SJPR_KoTUMSKSTM	== null){this.SJPR_KoTUMSKSTM   = "";}  //印刷時間
		if(this.SJPR_BLCKCD	    == null){this.SJPR_BLCKCD       = "";}  //営業所コード
		if(this.SJPR_BLCKNM	    == null){this.SJPR_BLCKNM       = "";}  //営業所名
		if(this.SJPR_BLCKYB1	== null){this.SJPR_BLCKYB1      = "";}  //郵便番号1
		if(this.SJPR_BLCKYB2	== null){this.SJPR_BLCKYB2      = "";}  //郵便番号2
		if(this.SJPR_BLCKJY1	== null){this.SJPR_BLCKJY1      = "";}  //住所1
		if(this.SJPR_BLCKJY2	== null){this.SJPR_BLCKJY2      = "";}  //住所2
		if(this.SJPR_BLCKJY3	== null){this.SJPR_BLCKJY3      = "";}  //住所3
		if(this.SJPR_BLCKTEL	== null){this.SJPR_BLCKTEL      = "";}  //TEL
		if(this.SJPR_BLCKFAX	== null){this.SJPR_BLCKFAX      = "";}  //FAX
		if(this.SJPR_BLCKMAIL	== null){this.SJPR_BLCKMAIL     = "";}  //MAIL
		if(this.SJPR_BLCKSK1	== null){this.SJPR_BLCKSK1      = "";}    //請求先代表者
		if(this.SJPR_BLCKSK2	== null){this.SJPR_BLCKSK2      = "";}  //請求住所
		if(this.SJPR_BLCKSK3	== null){this.SJPR_BLCKSK3      = "";}  //請求電話
		if(this.SJPR_BLCKSK4	== null){this.SJPR_BLCKSK4	    = "";}  //請求取引銀行名
		if(this.SJPR_BLCKSK5	== null){this.SJPR_BLCKSK5	    = "";}  //請求口座名義
		if(this.SJPR_BLCKSK6	== null){this.SJPR_BLCKSK6	    = "";}  //口座
		if(this.SJPR_KoTUKDT	== null){this.SJPR_KoTUKDT	    = "";}  //受付日
		if(this.SJPR_KoTNTNCD	== null){this.SJPR_KoTNTNCD	    = "";}  //担当者コード
		if(this.SJPR_KoTNTNNM	== null){this.SJPR_KoTNTNNM	    = "";}  //担当者名称
		if(this.SJPR_KoETNTNCD	== null){this.SJPR_KoETNTNCD	= "";}  //営業担当コード
		if(this.SJPR_KoETNTNNM	== null){this.SJPR_KoETNTNNM	= "";}  //営業担当名称
		if(this.SJPR_KoTSNM	    == null){this.SJPR_KoTSNM	    = "";}  //名前
		if(this.SJPR_KoTSYU	    == null){this.SJPR_KoTSYU	    = "";}  //住所〒
		if(this.SJPR_KoTSJYU1	== null){this.SJPR_KoTSJYU1	    = "";}  //住所1
		if(this.SJPR_KoTSJYU2	== null){this.SJPR_KoTSJYU2	    = "";}  //住所2
		if(this.SJPR_KoTSJYU3	== null){this.SJPR_KoTSJYU3	    = "";}  //住所3
		if(this.SJPR_KoTSTEL	== null){this.SJPR_KoTSTEL	    = "";}  //℡
		if(this.SJPR_KoTSTELK	== null){this.SJPR_KoTSTELK	    = "";}  //携帯
		if(this.SJPR_KoTSMail	== null){this.SJPR_KoTSMail	    = "";}  //メール
		if(this.SJPR_KoTSURKGK	== null){this.SJPR_KoTSURKGK	= "";}  //祭事売上(税込み)
		if(this.SJPR_KoTURSMST	== null){this.SJPR_KoTURSMST	= "";}  //祭事外税売上  (税抜)
		if(this.SJPR_KoTURSMKG	== null){this.SJPR_KoTURSMKG	= "";}  //祭事軽減税売上(税抜)
		if(this.SJPR_KoTURSMHK	== null){this.SJPR_KoTURSMHK	= "";}  //祭事非課税売上(税抜)
		if(this.SJPR_KoTSNEBST	== null){this.SJPR_KoTSNEBST	= "";}  //祭事外税値引
		if(this.SJPR_KoTSNEBKG	== null){this.SJPR_KoTSNEBKG	= "";}  //祭事軽減税値引
		if(this.SJPR_KoTSNEBHK	== null){this.SJPR_KoTSNEBHK	= "";}  //祭事非課税値引
		if(this.SJPR_KoTSKAZST	== null){this.SJPR_KoTSKAZST	= "";}  //祭事外税対象額計
		if(this.SJPR_KoTSKAZKG	== null){this.SJPR_KoTSKAZKG	= "";}  //祭事軽減税対象額計
		if(this.SJPR_KoTSKAZHK	== null){this.SJPR_KoTSKAZHK	= "";}  //祭事非課税対象額計
		if(this.SJPR_KoTSSYOST	== null){this.SJPR_KoTSSYOST	= "";}  //祭事外税消費税
		if(this.SJPR_KoTSSYOKG	== null){this.SJPR_KoTSSYOKG	= "";}  //祭事軽減税消費税
		if(this.SJPR_TBGO	    == null){this.SJPR_TBGO	        = "";}  //テーブル開始行
		if(this.SJPR_MsSETCD	== null){this.SJPR_MsSETCD	    = "";}  //セットコード
		if(this.SJPR_MsSETNM	== null){this.SJPR_MsSETNM	    = "";}  //セット名称
		if(this.SJPR_MsHIBJYUN	== null){this.SJPR_MsHIBJYUN	= "";}  //品目大分類並び順
		if(this.SJPR_MsHIBCD	== null){this.SJPR_MsHIBCD	    = "";}  //品目大分類コード
		if(this.SJPR_MsHIBNM	== null){this.SJPR_MsHIBNM	    = "";}  //品目大分類名称
		if(this.SJPR_MsSYBRJN	== null){this.SJPR_MsSYBRJN	    = "";}  //品目分類順
		if(this.SJPR_MsSYBRCD	== null){this.SJPR_MsSYBRCD	    = "";}  //品目分類コード
		if(this.SJPR_MsSYBRNM	== null){this.SJPR_MsSYBRNM	    = "";}  //品目分類名称
		if(this.SJPR_MsHIJYUN	== null){this.SJPR_MsHIJYUN	    = "";}  //品目並び順
		if(this.SJPR_MsHICD	    == null){this.SJPR_MsHICD	    = "";}  //品目コード
		if(this.SJPR_MsHINM	    == null){this.SJPR_MsHINM	    = "";}  //品目名称
		if(this.SJPR_MsTAN	    == null){this.SJPR_MsTAN	    = "";}  //単位
		if(this.SJPR_MsSETFG	== null){this.SJPR_MsSETFG	    = "";}  //セットフラグ
		if(this.SJPR_MsUNT	    == null){this.SJPR_MsUNT	    = "";}  //販売単価
		if(this.SJPR_MsSYOKB	== null){this.SJPR_MsSYOKB	    = "";}  //売上消費税区分
		if(this.SJPR_MsSuu	    == null){this.SJPR_MsSuu	    = "";}  //売上数量
		if(this.SJPR_MsKin	    == null){this.SJPR_MsKin	    = "";}  //売上金額(税抜)
		if(this.SJPR_MsNebiki	== null){this.SJPR_MsNebiki	    = "";}  //売上値引(税抜)
		if(this.SJPR_MsGKing	== null){this.SJPR_MsGKing	    = "";}  //売上額値引後(税抜)
		if(this.SJPR_MsUCSYOG	== null){this.SJPR_MsUCSYOG	    = "";}  //売上消費税額
		if(this.SJPR_MsZKGK	    == null){this.SJPR_MsZKGK       = "";}  //売上税込額(税込)
		if(this.SJPR_MsBIKO	    == null){this.SJPR_MsBIKO       = "";}  //備考
		if(this.SJPR_KoTTSEX	== null){this.SJPR_KoTTSEX	    = "";}  //故人性別
		if(this.SJPR_InstDt	    == null){this.SJPR_InstDt	    = "";}  //作成日時
		if(this.SJPR_InstId	    == null){this.SJPR_InstId	    = "";}  //作成者
		if(this.SJPR_UpdtDt	    == null){this.SJPR_UpdtDt	    = "";}  //更新日時
		if(this.SJPR_UpdtId	    == null){this.SJPR_UpdtId	    = "";}  //更新者
		
		this.EXTKMMquery();		//祭事立替エクセルマスタ
	},
    //-----------------------------------------------------------------
    //祭事立替エクセルマスタの取得更新
    //-----------------------------------------------------------------
    async EXTKMMquery() {
        this.PARAMT.length = 0;
        axios.post('/api/EXTKSKGet')
        .then(res => {
        this.PARAMT = res.data;
        this.TKPR_KoTNO	        = this.PARAMT[0].KoTNO;	        //受付番号
        this.TKPR_KoTUMSKSTM	= this.PARAMT[0].KoTUMSKSDT;	//印刷日
        this.TKPR_KoTUMSKSTM	= this.PARAMT[0].KoTUMSKSTM;	//印刷時間
        this.TKPR_BLCKCD        = this.PARAMT[0].BLCKCD;	    //営業所コード
        this.TKPR_BLCKNM	    = this.PARAMT[0].BLCKNM;	    //営業所名
        this.TKPR_BLCKYB1	    = this.PARAMT[0].BLCKYB1;	    //郵便番号1
        this.TKPR_BLCKYB2	    = this.PARAMT[0].BLCKYB2;	    //郵便番号2
        this.TKPR_BLCKJY1	    = this.PARAMT[0].BLCKJY1;	    //住所1
        this.TKPR_BLCKJY2	    = this.PARAMT[0].BLCKJY2;	    //住所2
        this.TKPR_BLCKJY3	    = this.PARAMT[0].BLCKJY3;	    //住所3
        this.TKPR_BLCKTEL	    = this.PARAMT[0].BLCKTEL;	    //TEL
        this.TKPR_BLCKFAX	    = this.PARAMT[0].BLCKFAX;	    //FAX
        this.TKPR_BLCKMAIL	    = this.PARAMT[0].BLCKMAIL;	    //MAIL
        this.TKPR_BLCKSK1	    = this.PARAMT[0].BLCKSK1;	    //請求先代表者
        this.TKPR_BLCKSK2	    = this.PARAMT[0].BLCKSK2;	    //請求住所
        this.TKPR_BLCKSK3	    = this.PARAMT[0].BLCKSK3;	    //請求電話
        this.TKPR_BLCKSK4	    = this.PARAMT[0].BLCKSK4;	    //請求取引銀行名
        this.TKPR_BLCKSK5	    = this.PARAMT[0].BLCKSK5;	    //請求口座名義
        this.TKPR_BLCKSK6	    = this.PARAMT[0].BLCKSK6;	    //口座
        this.TKPR_KoTUKDT	    = this.PARAMT[0].KoTUKDT;	    //受付日
        this.TKPR_KoTNTNCD	    = this.PARAMT[0].KoTNTNCD;	    //担当者コード
        this.TKPR_KoTNTNNM	    = this.PARAMT[0].KoTNTNNM;	    //担当者名称
        this.TKPR_KoETNTNCD	    = this.PARAMT[0].KoETNTNCD;	    //営業担当コード
        this.TKPR_KoETNTNNM	    = this.PARAMT[0].KoETNTNNM;	    //営業担当名称
        this.TKPR_KoTSNM	    = this.PARAMT[0].KoTSNM;	    //名前
        this.TKPR_KoTSYU	    = this.PARAMT[0].KoTSYU;	    //住所〒
        this.TKPR_KoTSJYU1	    = this.PARAMT[0].KoTSJYU1;	    //住所1
        this.TKPR_KoTSJYU2	    = this.PARAMT[0].KoTSJYU2;	    //住所2
        this.TKPR_KoTSJYU3	    = this.PARAMT[0].KoTSJYU3;	    //住所3
        this.TKPR_KoTSTEL	    = this.PARAMT[0].KoTSTEL;	    //℡
        this.TKPR_KoTSTELK	    = this.PARAMT[0].KoTSTELK;	    //携帯
        this.TKPR_KoTSMail	    = this.PARAMT[0].KoTSMail;	    //メール
        this.TKPR_KoTTURKGK	    = this.PARAMT[0].KoTTURKGK;	    //祭事売上(税込み)
        this.TKPR_KoTURTMST	    = this.PARAMT[0].KoTURTMST;	    //立替外税売上  (税抜)
        this.TKPR_KoTURTMKG	    = this.PARAMT[0].KoTURTMKG;	    //立替軽減税売上(税抜)
        this.TKPR_KoTURTMHK	    = this.PARAMT[0].KoTURTMHK;	    //立替非課税売上(税抜)
        this.TKPR_KoTTNEBST	    = this.PARAMT[0].KoTTNEBST;	    //立替外税値引
        this.TKPR_KoTTNEBKG	    = this.PARAMT[0].KoTTNEBKG;	    //立替軽減税値引
        this.TKPR_KoTTNEBHK	    = this.PARAMT[0].KoTTNEBHK;	    //立替非課税値引
        this.TKPR_KoTTKAZST	    = this.PARAMT[0].KoTTKAZST;	    //立替外税対象額計
        this.TKPR_KoTTKAZKG	    = this.PARAMT[0].KoTTKAZKG;	    //立替軽減税対象額計
        this.TKPR_KoTTKAZHK	    = this.PARAMT[0].KoTTKAZHK;	    //立替非課税対象額計
        this.TKPR_KoTTSYOST	    = this.PARAMT[0].KoTTSYOST;	    //立替外税消費税
        this.TKPR_KoTTSYOKG	    = this.PARAMT[0].KoTTSYOKG;	    //立替軽減税消費税
        this.TKPR_TBGO	        = this.PARAMT[0].TBGO;	        //テーブル開始行
        this.TKPR_MsSETCD	    = this.PARAMT[0].MsSETCD;	    //セットコード
        this.TKPR_MsSETNM	    = this.PARAMT[0].MsSETNM;	    //セット名称
        this.TKPR_MsHIBJYUN	    = this.PARAMT[0].MsHIBJYUN;	    //品目大分類並び順
        this.TKPR_MsHIBCD	    = this.PARAMT[0].MsHIBCD;	    //品目大分類コード
        this.TKPR_MsHIBNM	    = this.PARAMT[0].MsHIBNM;	    //品目大分類名称
        this.TKPR_MsSYBRJN      = this.PARAMT[0].MsSYBRJN;	    //品目分類順
        this.TKPR_MsSYBRCD	    = this.PARAMT[0].MsSYBRCD;	    //品目分類コード
        this.TKPR_MsSYBRNM	    = this.PARAMT[0].MsSYBRNM;	    //品目分類名称
        this.TKPR_MsHIJYUN	    = this.PARAMT[0].MsHIJYUN;	    //品目並び順
        this.TKPR_MsHICD	    = this.PARAMT[0].MsHICD;	    //品目コード
        this.TKPR_MsHINM	    = this.PARAMT[0].MsHINM;	    //品目名称
        this.TKPR_MsTAN	        = this.PARAMT[0].MsTAN;	        //単位
        this.TKPR_MsSETFG	    = this.PARAMT[0].MsSETFGV	    //セットフラグ
        this.TKPR_MsUNT	        = this.PARAMT[0].MsUNT;	        //販売単価
        this.TKPR_MsSYOKB	    = this.PARAMT[0].MsSYOKB;	    //売上消費税区分
        this.TKPR_MsSuu	        = this.PARAMT[0].MsSuu;	        //売上数量
        this.TKPR_MsKin	        = this.PARAMT[0].MsKin;	        //売上金額(税抜)
        this.TKPR_MsNebiki	    = this.PARAMT[0].MsNebiki;	    //売上値引(税抜)
        this.TKPR_MsGKing	    = this.PARAMT[0].MsGKing;	    //売上額値引後(税抜)
        this.TKPR_MsUCSYOG	    = this.PARAMT[0].MsUCSYOG;	    //売上消費税額
        this.TKPR_MsZKGK	    = this.PARAMT[0].MsZKGK;        //売上税込額(税込)
		this.TKPR_MsBIKO	    = this.PARAMT[0].MsBIKO;	    //備考
        })
		
        if(this.TKPR_KoTNO	    == null){this.TKPR_KoTNO		= "";}  //受付番号
		if(this.TKPR_KoTUMSKSDT	== null){this.TKPR_KoTUMSKSDT	= "";}  //印刷日
		if(this.TKPR_KoTUMSKSTM	== null){this.TKPR_KoTUMSKSTM	= "";}  //印刷時間
		if(this.TKPR_BLCKCD	    == null){this.TKPR_BLCKCD	    = "";}  //営業所コード
		if(this.TKPR_BLCKNM	    == null){this.TKPR_BLCKNM       = "";}  //営業所名
		if(this.TKPR_BLCKYB1	== null){this.TKPR_BLCKYB1	    = "";}  //郵便番号1
		if(this.TKPR_BLCKYB2	== null){this.TKPR_BLCKYB2	    = "";}  //郵便番号2
		if(this.TKPR_BLCKJY1	== null){this.TKPR_BLCKJY1	    = "";}  //住所1
		if(this.TKPR_BLCKJY2	== null){this.TKPR_BLCKJY2	    = "";}  //住所2
		if(this.TKPR_BLCKJY3	== null){this.TKPR_BLCKJY3	    = "";}  //住所3
		if(this.TKPR_BLCKTEL	== null){this.TKPR_BLCKTEL	    = "";}  //TEL
		if(this.TKPR_BLCKFAX	== null){this.TKPR_BLCKFAX	    = "";}  //FAX
		if(this.TKPR_BLCKMAIL	== null){this.TKPR_BLCKMAIL	    = "";}  //MAIL
		if(this.TKPR_BLCKSK1	== null){this.TKPR_BLCKSK1	    = "";}  //請求先代表者
		if(this.TKPR_BLCKSK2	== null){this.TKPR_BLCKSK2	    = "";}  //請求住所
		if(this.TKPR_BLCKSK3	== null){this.TKPR_BLCKSK3	    = "";}  //請求電話
		if(this.TKPR_BLCKSK4	== null){this.TKPR_BLCKSK4	    = "";}  //請求取引銀行名
		if(this.TKPR_BLCKSK5	== null){this.TKPR_BLCKSK5	    = "";}  //請求口座名義
		if(this.TKPR_BLCKSK6	== null){this.TKPR_BLCKSK6	    = "";}  //口座
		if(this.TKPR_KoTUKDT	== null){this.TKPR_KoTUKDT	    = "";}  //受付日
		if(this.TKPR_KoTNTNCD	== null){this.TKPR_KoTNTNCD	    = "";}  //担当者コード
		if(this.TKPR_KoTNTNNM	== null){this.TKPR_KoTNTNNM	    = "";}  //担当者名称
		if(this.TKPR_KoETNTNCD	== null){this.TKPR_KoETNTNCD	= "";}  //営業担当コード
		if(this.TKPR_KoETNTNNM	== null){this.TKPR_KoETNTNNM	= "";}  //営業担当名称
		if(this.TKPR_KoTSNM	    == null){this.TKPR_KoTSNM	    = "";}  //名前
		if(this.TKPR_KoTSYU	    == null){this.TKPR_KoTSYU	    = "";}  //住所〒
		if(this.TKPR_KoTSJYU1	== null){this.TKPR_KoTSJYU1	    = "";}  //住所1
		if(this.TKPR_KoTSJYU2	== null){this.TKPR_KoTSJYU2	    = "";}  //住所2
		if(this.TKPR_KoTSJYU3	== null){this.TKPR_KoTSJYU3	    = "";}  //住所3
		if(this.TKPR_KoTSTEL	== null){this.TKPR_KoTSTEL	    = "";}  //℡
		if(this.TKPR_KoTSTELK	== null){this.TKPR_KoTSTELK	    = "";}  //携帯
		if(this.TKPR_KoTSMail	== null){this.TKPR_KoTSMail	    = "";}  //メール
		if(this.TKPR_KoTTURKGK	== null){this.TKPR_KoTTURKGK	= "";}  //祭事売上(税込み)
		if(this.TKPR_KoTURTMST	== null){this.TKPR_KoTURTMST	= "";}  //立替外税売上  (税抜)
		if(this.TKPR_KoTURTMKG	== null){this.TKPR_KoTURTMKG	= "";}  //立替軽減税売上(税抜)
		if(this.TKPR_KoTURTMHK	== null){this.TKPR_KoTURTMHK	= "";}  //立替非課税売上(税抜)
		if(this.TKPR_KoTTNEBST	== null){this.TKPR_KoTTNEBST	= "";}  //立替外税値引
		if(this.TKPR_KoTTNEBKG	== null){this.TKPR_KoTTNEBKG	= "";}  //立替軽減税値引
		if(this.TKPR_KoTTNEBHK	== null){this.TKPR_KoTTNEBHK	= "";}  //立替非課税値引
		if(this.TKPR_KoTTKAZST	== null){this.TKPR_KoTTKAZST	= "";}  //立替外税対象額計
		if(this.TKPR_KoTTKAZKG	== null){this.TKPR_KoTTKAZKG	= "";}  //立替軽減税対象額計
		if(this.TKPR_KoTTKAZHK	== null){this.TKPR_KoTTKAZHK	= "";}  //立替非課税対象額計
		if(this.TKPR_KoTTSYOST	== null){this.TKPR_KoTTSYOST	= "";}  //立替外税消費税
		if(this.TKPR_KoTTSYOKG	== null){this.TKPR_KoTTSYOKG	= "";}  //立替軽減税消費税
		if(this.TKPR_TBGO	    == null){this.TKPR_TBGO	        = "";}  //テーブル開始行
		if(this.TKPR_MsSETCD	== null){this.TKPR_MsSETCD	    = "";}  //セットコード
		if(this.TKPR_MsSETNM	== null){this.TKPR_MsSETNM	    = "";}  //セット名称
		if(this.TKPR_MsHIBJYUN	== null){this.TKPR_MsHIBJYUN	= "";}  //品目大分類並び順
		if(this.TKPR_MsHIBCD	== null){this.TKPR_MsHIBCD	    = "";}  //品目大分類コード
		if(this.TKPR_MsHIBNM	== null){this.TKPR_MsHIBNM	    = "";}  //品目大分類名称
		if(this.TKPR_MsSYBRJN	== null){this.TKPR_MsSYBRJN	    = "";}  //品目分類順
		if(this.TKPR_MsSYBRCD	== null){this.TKPR_MsSYBRCD	    = "";}  //品目分類コード
		if(this.TKPR_MsSYBRNM	== null){this.TKPR_MsSYBRNM	    = "";}  //品目分類名称
		if(this.TKPR_MsHIJYUN	== null){this.TKPR_MsHIJYUN	    = "";}  //品目並び順
		if(this.TKPR_MsHICD	    == null){this.TKPR_MsHICD	    = "";}  //品目コード
		if(this.TKPR_MsHINM	    == null){this.TKPR_MsHINM	    = "";}  //品目名称
		if(this.TKPR_MsTAN	    == null){this.TKPR_MsTAN	    = "";}  //単位
		if(this.TKPR_MsSETFG	== null){this.TKPR_MsSETFG	    = "";}  //セットフラグ
		if(this.TKPR_MsUNT	    == null){this.TKPR_MsUNT	    = "";}  //販売単価
		if(this.TKPR_MsSYOKB	== null){this.TKPR_MsSYOKB	    = "";}  //売上消費税区分
		if(this.TKPR_MsSuu	    == null){this.TKPR_MsSuu	    = "";}  //売上数量
		if(this.TKPR_MsKin	    == null){this.TKPR_MsKin	    = "";}  //売上金額(税抜)
		if(this.TKPR_MsNebiki	== null){this.TKPR_MsNebiki	    = "";}  //売上値引(税抜)
		if(this.TKPR_MsGKing	== null){this.TKPR_MsGKing	    = "";}  //売上額値引後(税抜)
		if(this.TKPR_MsUCSYOG	== null){this.TKPR_MsUCSYOG	    = "";}  //売上消費税額
		if(this.TKPR_MsZKGK	    == null){this.TKPR_MsZKGK	    = "";}  //売上税込額(税込)
		if(this.TKPR_MsBIKO	    == null){this.TKPR_MsBIKO	    = "";}  //備考
		if(this.TKPR_InstDt	    == null){this.TKPR_InstDt	    = "";}  //作成日時
		if(this.TKPR_InstId	    == null){this.TKPR_InstId	    = "";}  //作成者
		if(this.TKPR_UpdtDt	    == null){this.TKPR_UpdtDt	    = "";}  //更新日時
		if(this.TKPR_UpdtId	    == null){this.TKPR_UpdtId	    = "";}  //更新者
		this.MsTrnquery();		//祭事見積書エクセルマスタ
	},


	//----------------------------------------
    //祭事明細取得
    //----------------------------------------
    async MsTrnquery() {

        const fomego = {KoTNO: this.KoTNO};
        axios.post('/api/MsTrnGeT',fomego)
           .then(response => {
                this.MsTrn = response.data;   //受信配列を格納
            })
            .catch(error => {
            console.log(error)
        })

		this.SKYOTrnquery();	//祭事予定取得
   },
	//----------------------------------------
    //施行予定データ取得
    //----------------------------------------
    SKYOTrnquery() {
            this.SKYOTrn.length = 0;

            if (this.KoTNKJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"納棺",           //施工名称
                    SKYTDT:this.KoTNKDT,     //施行順
                    SKYTHH:this.KoTNKJI,     //開始時  
                    SKYTMM:this.KoTNKFN,     //開始分 
                    SKYSHH: "",              //終了時  
                    SKYSMM:"",               //終了分
                    SKYTFBNM:this.KoTNKBS,   //納棺場所
                    SKYTP1NM:"メーク",       //名称1
                    SKYTP1DT:this.KoTNKMK,   //値1
                    SKYTP1TN:"式",           //単位1
                    SKYTP2NM:"",             //名称2
                    SKYTP2DT:"",             //値2
                    SKYTP2TN:"",             //単位2
                    SKYTP3NM:"",             //名称3
                    SKYTP3DT:"",             //値3
                    SKYTP3TN:"",             //単位3
                    SKYTNY:""                //備考
                });
            }
            if (this.KoTTYSJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"通夜",           //施工名称
                    SKYTDT:this.KoTTYSDT,    //施行日
                    SKYTHH:this.KoTTYSJI,    //施行時  
                    SKYTMM:this.KoTTYSFN,    //施行分 
                    SKYSHH:this.KoTTYEJI,    //終了時  
                    SKYSMM:this.KoTTYEFN,    //終了分
                    SKYTFBNM:this.KoTTYSBS,    //通夜場所
                    SKYTP1NM:"司会",           //名称1
                    SKYTP1DT:this.KoTTYSSK,    //値1
                    SKYTP1TN:"",               //単位1
                    SKYTP2NM:"アシ",           //名称2
                    SKYTP2DT:this.KoTTYSAS,    //値2
                    SKYTP2TN:"",               //単位2
                    SKYTP3NM:"",               //名称3
                    SKYTP3DT:"",               //値3
                    SKYTP3TN:"",               //単位3
                    SKYTNY:""                //備考
                });
            }
            if (this.KoTTYSJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"通夜移動",           //施工名称
                    SKYTDT:this.KoTISU1DT,       //施行日
                    SKYTHH:this.KoTISU1JI,       //施行時  
                 //   SKYTMM:this.KoTISU1FN,       //施行分 
                    SKYSHH:"",                   //終了時  
                 //   SKYSMM:"",                   //終了分
                    SKYTFBNM:"",                   //通夜場所
                    SKYTP1NM:"バス",               //名称1
                    SKYTP1DT:this.KoTISU1BS,       //値1
                    SKYTP1TN:"台",                 //単位1
                    SKYTP2NM:"ＴＸ",               //名称2
                    SKYTP2DT:this.KoTISU1HY,       //値2
                    SKYTP2TN:"台",                 //単位2
                    SKYTP3NM:"",                   //名称3
                    SKYTP3DT:"",                   //値3
                    SKYTP3TN:"",                   //単位3
                    SKYTNY:""                    //備考
                });
            }
            if (this.KoTHRJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"会館",                       //施工名称
                    SKYTDT:"",                           //施行日
                    SKYTFBNM:this.KoTKKNM,   //会館場所
                    SKYTNY:this.KoTHRNM    //会館備考
               });
            }
            if (this.KoTSSOGJK == "〇")
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"告別",                   //施工名称
                    SKYTDT:this.KoTSSOGDT,           //施行日
                    SKYTHH:this.KoTSSOGSJI,          //施行時  
                    SKYTMM:this.KoTSSOGSFN,          //施行分
                    SKYSHH:this.KoTSSOGEJI,          //終了時  
                    SKYSMM:this.KoTSSOGEFN,          //終了分
                    SKYTFBNM:this.KoTSSOSBS,          //納棺場所
                    SKYTP1NM:"司会",                  //名称1
                    SKYTP1DT:this.KoTSSOSSK,          //値1
                    SKYTP1TN:"",                      //単位1
                    SKYTP2NM:"アシ",                  //名称2
                    SKYTP2DT:this.KoTSSOGAS,          //値2
                    SKYTP2TN:"",                      //単位2
                    SKYTP3NM:"",                      //名称3
                    SKYTP3DT:"",                      //値3
                    SKYTP3TN:"",                      //単位3
                    SKYTNY:""                         //備考
               });
            }
            this.SKYOTrn.push({  // 追加する項目の内容を設定する
                SKYTNM:"火葬",                       //施工名称
                SKYTDT:this.KoTSJDT,                 //施行日
                SKYTHH:this.KoTSJJI,                 //施行時  
                SKYTMM:this.KoTSJFN,                 //施行分
                SKYSHH:this.KoTSJEJI,                //終了時  
                SKYSMM:this.KoTSJEFN,                //終了分
                SKYTFBNM:this.KoTSJNM,               //火葬場所
                SKYTP1NM:"同行",                      //名称1
                SKYTP1DT:this.KoTSJDS,                //値1
                SKYTP1TN:"",                          //単位1
                SKYTP2NM:"控室",                      //名称2
                SKYTP2DT:this.KoTSJHS,                //値2
                SKYTP2TN:"",                          //単位2
                SKYTP3NM:"",                          //名称3
                SKYTP3DT:"",                          //値3
                SKYTP3TN:"",                          //単位3
                SKYTNY:""                             //備考
            });
            if (this.KoTISU2JK == "〇")    //火葬移動
            {
                this.SKYOTrn.push({  // 追加する項目の内容を設定する
                    SKYTNM:"移動(出棺)",              //施工名称
                    SKYTDT:this.KoTISU2DT,              //施行日
                    SKYTHH:this.KoTISU2JI,             //施行時  
                    SKYTMM:this.KoTISU2JI,             //施行分
                    SKYSHH:"",                        //終了時  
                    SKYSMM:"",                        //終了分
                    SKYTFBNM:"",                        //火葬場所
                    SKYTP1NM:"霊柩",                    //名称1
                    SKYTP1DT:this.KoRICJK,              //値1
                    SKYTP1TN:"",                        //単位1
                    SKYTP2NM:"バス",                    //名称2
                    SKYTP2DT:this.KoTISU2BS,            //値2
                    SKYTP2TN:"台",                      //単位2
                    SKYTP3NM:"ＴＸ",                    //名称3
                    SKYTP3DT:this.KoTISU2HY,            //値3
                    SKYTP3TN:"台",                      //単位3
                    SKYTNY:""                         //備考
               });
            }
        },

	//-----------------------------------------------------------------
    //受付簿エクセルの作成
    //-----------------------------------------------------------------
	BOmakeexcel() {
		const XlsxPopulate = require( 'xlsx-populate' );
		const req = new XMLHttpRequest();
        const url = this.BOKoteifile;

		const wUBPR_KoTNO		= this.UBPR_KoTNO;			//受付番号
		const wUBPR_KoTUMSKSDT 	= this.UBPR_KoTUMSKSDT;		//印刷日
		const wUBPR_KoTUMSKSTM	= this.UBPR_KoTUMSKSTM;		//印刷時間
		const wUBPR_BLCKCD	  	= this.UBPR_BLCKCD;			//営業所コード
		const wUBPR_BLCKNM	  	= this.UBPR_BLCKNM;			//営業所名
		const wUBPR_BLCKYB1		= this.UBPR_BLCKYB1;		//郵便番号1
		const wUBPR_BLCKYB2		= this.UBPR_BLCKYB2;		//郵便番号2
		const wUBPR_BLCKJY1		= this.UBPR_BLCKJY1;		//住所1
		const wUBPR_BLCKJY2		= this.UBPR_BLCKJY2;		//住所2
		const wUBPR_BLCKJY3		= this.UBPR_BLCKJY3;		//住所3
		const wUBPR_BLCKTEL		= this.UBPR_BLCKTEL;		//TEL
		const wUBPR_BLCKFAX		= this.UBPR_BLCKFAX;		//FAX
		const wUBPR_BLCKMAIL	= this.UBPR_BLCKMAIL;		//MAIL
		const wUBPR_BLCKSK1		= this.UBPR_BLCKSK1;		//請求先代表者
		const wUBPR_BLCKSK2		= this.UBPR_BLCKSK2;		//請求住所
		const wUBPR_BLCKSK3		= this.UBPR_BLCKSK3;		//請求電話
		const wUBPR_BLCKSK4		= this.UBPR_BLCKSK4;		//請求取引銀行名
		const wUBPR_BLCKSK5		= this.UBPR_BLCKSK5;		//請求口座名義
		const wUBPR_BLCKSK6		= this.UBPR_BLCKSK6;		//口座
		const wUBPR_KoTUKDT		= this.UBPR_KoTUKDT;		//受付日
		const wUBPR_KoTNTNCD	= this.UBPR_KoTNTNCD;		//担当者コード
		const wUBPR_KoTNTNNM	= this.UBPR_KoTNTNNM;		//担当者名称
		const wUBPR_KoETNTNCD	= this.UBPR_KoETNTNCD;		//営業担当コード
		const wUBPR_KoETNTNNM	= this.UBPR_KoETNTNNM;		//営業担当名称
		const wUBPR_KoTKANO		= this.UBPR_KoTKANO;		//会員番号
		const wUBPR_KoTKANM		= this.UBPR_KoTKANM;		//会員名称
		const wUBPR_KoTMOKE		= this.UBPR_KoTMOKE;		//喪家
		const wUBPR_KoTCD	  	= this.UBPR_KoTCD;			//顧客提携先コード
		const wUBPR_KoTNM	  	= this.UBPR_KoTNM;			//顧客提携先名称
		const wUBPR_KoTTKNM		= this.UBPR_KoTTKNM;		//故人(カナ）
		const wUBPR_KoTTNM	  	= this.UBPR_KoTTNM;			//故人名前
		const wUBPR_KoTTEG	  	= this.UBPR_KoTTEG;			//故人年齢
		const wUBPR_KoTTSEX	  	= this.UBPR_KoTTSEX;		//故人性別
		const wUBPR_KoTTNEX	  	= this.UBPR_KoTTNEX;		//故人続柄
		const wUBPR_KoTTYU	  	= this.UBPR_KoTTYU;			//故人郵便番号
		const wUBPR_KoTTJYU1	= this.UBPR_KoTTJYU1;		//故人住所1
		const wUBPR_KoTTJYU2	= this.UBPR_KoTTJYU2;		//故人住所2
		const wUBPR_KoTTJYU3	= this.UBPR_KoTTJYU3;		//故人住所3
		const wUBPR_KoTTTEL	  	= this.UBPR_KoTTTEL;		//故人電話番号
		const wUBPR_KoTMKNM	  	= this.UBPR_KoTMKNM;		//喪主(カナ）
		const wUBPR_KoTMNM	  	= this.UBPR_KoTMNM;			//喪主名前
		const wUBPR_KoTMNEX	  	= this.UBPR_KoTMNEX;		//喪主続柄
		const wUBPR_KoTMSEX	  	= this.UBPR_KoTMSEX;		//喪主性別
		const wUBPR_KoTMYU	  	= this.UBPR_KoTMYU;			//喪主郵便番号
		const wUBPR_KoTMJYU1	= this.UBPR_KoTMJYU1;		//喪主住所1
		const wUBPR_KoTMJYU2	= this.UBPR_KoTMJYU2;		//喪主住所2
		const wUBPR_KoTMJYU3	= this.UBPR_KoTMJYU3;		//喪主住所3
		const wUBPR_KoTMTEL	  	= this.UBPR_KoTMTEL;		//喪主電話番号
		const wUBPR_KoTMTELK	= this.UBPR_KoTMTELK;		//喪主携帯
		const wUBPR_KoTMTMail	= this.UBPR_KoTMTMail;		//喪主Mail
		const wUBPR_KoTMBIKO	= this.UBPR_KoTMBIKO;		//喪主備考
		const wUBPR_KoTSNM	  	= this.UBPR_KoTSNM;			//お支払責任者名前
		const wUBPR_KoTSHYODT	= this.UBPR_KoTSHYODT;		//お支払予定日
		const wUBPR_KoTSPNM	  	= this.UBPR_KoTSPNM;		//入金方法名
		const wUBPR_KoTSYU	  	= this.UBPR_KoTSYU;			//支払責任者郵便番号
		const wUBPR_KoTSJYU1	= this.UBPR_KoTSJYU1;		//お支払責任者住所1
		const wUBPR_KoTSJYU2	= this.UBPR_KoTSJYU2;		//お支払責任者住所2
		const wUBPR_KoTSJYU3	= this.UBPR_KoTSJYU3;		//お支払責任者住所3
		const wUBPR_KoTSTEL	  	= this.UBPR_KoTSTEL;		//お支払責任者電話番号
		const wUBPR_KoTSTELK	= this.UBPR_KoTSTELK;		//お支払責任者携帯
		const wUBPR_KoTSMail	= this.UBPR_KoTSMail;		//お支払責任者Mail
		const wUBPR_KoTSBIKO	= this.UBPR_KoTSBIKO;		//お支払責任者備考
		const wUBPR_KoTSSTRNM	= this.UBPR_KoTSSTRNM;		//寺院名称
		const wUBPR_KoTSSSONM	= this.UBPR_KoTSSSONM;		//宗旨名称
		const wUBPR_KoTSSSLBS	= this.UBPR_KoTSSSLBS;		//墓石墓所
		const wUBPR_KoTSSSLBD	= this.UBPR_KoTSSSLBD;		//仏壇
		const wUBPR_KoTSSKSA1	= this.UBPR_KoTSSKSA1;		//会葬挨拶1
		const wUBPR_KoTSSKMNM	= this.UBPR_KoTSSKMNM;		//家紋・紋章名称
		const wUBPR_KoTSSBIKO	= this.UBPR_KoTSSBIKO;		//引継
		const wUBPR_KoTPLCD	  	= this.UBPR_KoTPLCD;		//プランコード
		const wUBPR_KoTPLNM	  	= this.UBPR_KoTPLNM;		//プラン名称
		const wUBPR_KoTKKCD	  	= this.UBPR_KoTKKCD;		//会館コード
		const wUBPR_KoTKKNM	  	= this.UBPR_KoTKKNM;		//会館名
		const wUBPR_KoTHRCD	  	= this.UBPR_KoTHRCD;		//ホールコード
		const wUBPR_KoTHRNM	  	= this.UBPR_KoTHRNM;		//ホール名
		const wUBPR_KoSGICNM	= this.UBPR_KoSGICNM;		//葬儀委員長名
		const wUBPR_KoSGICKN	= this.UBPR_KoSGICKN;		//葬儀委員長かな名
		const wUBPR_KoSGICZK	= this.UBPR_KoSGICZK;		//葬儀委員長属性
		const wUBPR_SKYTGYO		= this.UBPR_SKYTGYO;		//行
		const wUBPR_SKYTKB		= this.UBPR_SKYTKB;			//区分
		const wUBPR_SKYTNM		= this.UBPR_SKYTNM;			//施行名称
		const wUBPR_SKYTDATE	= this.UBPR_SKYTDATE;		//施行日
		const wUBPR_SKYTHH		= this.UBPR_SKYTHH;			//開始時
		const wUBPR_SKYTMM		= this.UBPR_SKYTMM;			//開始分
		const wUBPR_SKYSHH		= this.UBPR_SKYSHH;			//終了時
		const wUBPR_SKYSMM		= this.UBPR_SKYSMM;			//終了分
		const wUBPR_SKYTFBNM	= this.UBPR_SKYTFBNM;		//場所名称
		const wUBPR_SKYTP1NM	= this.UBPR_SKYTP1NM;		//入力1名称
		const wUBPR_SKYTP1DT	= this.UBPR_SKYTP1DT;		//入力1値
		const wUBPR_SKYTP1TN	= this.UBPR_SKYTP1TN;		//入力1単位
		const wUBPR_SKYTP2NM	= this.UBPR_SKYTP2NM;		//入力2名称
		const wUBPR_SKYTP2DT	= this.UBPR_SKYTP2DT;		//入力2値
		const wUBPR_SKYTP2TN	= this.UBPR_SKYTP2TN;		//入力2単位
		const wUBPR_SKYTP3NM	= this.UBPR_SKYTP3NM;		//入力3名称
		const wUBPR_SKYTP3DT	= this.UBPR_SKYTP3DT;		//入力3値
		const wUBPR_SKYTP3TN	= this.UBPR_SKYTP3TN;		//入力3単位
		const wUBPR_SKYTNY		= this.UBPR_SKYTNY;			//備考
		const wUBPR_TBGO		= this.UBPR_TBGO;			//行

		const wKoTNO 			= this.KoTNO;				//受付番号
		const wKoTUMSKSDT 		= this.KoTUMSKSDT;			//印刷日
		const wKoTUMSKSTM 		= this.KoTUMSKSTM;			//印刷時間
		const wBL_BLCKCD	   	= this.BL_BLCKCD;			//営業所コード
		const wBL_BLCKNM	   	= this.BL_BLCKNM;			//営業所名
		const wBL_BLCKYB1 		= this.BL_BLCKYB1;			//郵便番号1
		const wBL_BLCKYB2 		= this.BL_BLCKYB2;			//郵便番号2
		const wBL_BLCKJY1 		= this.BL_BLCKJY1;			//住所1
		const wBL_BLCKJY2 		= this.BL_BLCKJY2;			//住所2
		const wBL_BLCKJY3 		= this.BL_BLCKJY3;			//住所3
		const wBL_BLCKTEL 		= this.BL_BLCKTEL;			//TEL
		const wBL_BLCKFAX 		= this.BL_BLCKFAX;			//FAX
		const wBL_BLCKMAIL 		= this.BL_BLCKMAIL;			//MAIL
		const wBL_BLCKSK1 		= this.BL_BLCKSK1;			//請求先代表者
		const wBL_BLCKSK2 		= this.BL_BLCKSK2;			//請求住所
		const wBL_BLCKSK3 		= this.BL_BLCKSK3;			//請求電話
		const wBL_BLCKSK4 		= this.BL_BLCKSK4;			//請求取引銀行名
		const wBL_BLCKSK5 		= this.BL_BLCKSK5;			//請求口座名義
		const wBL_BLCKSK6 		= this.BL_BLCKSK6;			//口座
		const wKoTUKDT 			= this.KoTUKDT;				//受付日
		const wKoTNTNCD 		= this.KoTNTNCD;			//担当者コード
		const wKoTNTNNM 		= this.KoTNTNNM;			//担当者名称
		const wKoETNTNCD 		= this.KoETNTNCD;			//営業担当コード
		const wKoETNTNNM 		= this.KoETNTNNM;			//営業担当名称
		const wKoTKANO 			= this.KoTKANO;				//会員番号
		const wKoTKANM 			= this.KoTKANM;				//会員名称
		const wKoTMOKE 			= this.KoTMOKE;				//喪家
		const wKoTCD	   		= this.KoTCD;				//顧客提携先コード
		const wKoTNM	   		= this.KoTNM;				//顧客提携先名称
		const wKoTTKNM 			= this.KoTTKNM;				//故人(カナ）
		const wKoTTNM	   		= this.KoTTNM;				//故人名前
		const wKoTTEG	   		= this.KoTTEG;				//故人年齢
		const wKoTTSEX 			= this.KoTTSEX;				//故人性別
		const wKoTTNEX 			= this.KoTTNEX;				//故人続柄
		const wKoTTYU	   		= this.KoTTYU;				//故人郵便番号
		const wKoTTJYU1 		= this.KoTTJYU1;			//故人住所1
		const wKoTTJYU2 		= this.KoTTJYU2;			//故人住所2
		const wKoTTJYU3 		= this.KoTTJYU3;			//故人住所3
		const wKoTTTEL 			= this.KoTTTEL;				//故人電話番号
		const wKoTMKNM 			= this.KoTMKNM;				//喪主(カナ）
		const wKoTMNM	   		= this.KoTMNM;				//喪主名前
		const wKoTMNEX 			= this.KoTMNEX;				//喪主続柄
		const wKoTMSEX 			= this.KoTMSEX;				//喪主性別
		const wKoTMYU	   		= this.KoTMYU;				//喪主郵便番号
		const wKoTMJYU1 		= this.KoTMJYU1;			//喪主住所1
		const wKoTMJYU2 		= this.KoTMJYU2;			//喪主住所2
		const wKoTMJYU3 		= this.KoTMJYU3;			//喪主住所3
		const wKoTMTEL 			= this.KoTMTEL;				//喪主電話番号
		const wKoTMTELK 		= this.KoTMTELK;			//喪主携帯
		const wKoTMTMail 		= this.KoTMTMail;			//喪主Mail
		const wKoTMBIKO 		= this.KoTMBIKO;			//喪主備考
		const wKoTSNM	   		= this.KoTSNM;				//お支払責任者名前
		const wKoTSHYODT 		= this.KoTSHYODT;			//お支払予定日
		const wKoTSPNM 			= this.KoTSPNM;				//入金方法名
		const wKoTSYU	   		= this.KoTSYU;				//支払責任者郵便番号
		const wKoTSJYU1 		= this.KoTSJYU1;			//お支払責任者住所1
		const wKoTSJYU2 		= this.KoTSJYU2;			//お支払責任者住所2
		const wKoTSJYU3 		= this.KoTSJYU3;			//お支払責任者住所3
		const wKoTSTEL 			= this.KoTSTEL;				//お支払責任者電話番号
		const wKoTSTELK 		= this.KoTSTELK;			//お支払責任者携帯
		const wKoTSMail 		= this.KoTSMail;			//お支払責任者Mail
		const wKoTSBIKO 		= this.KoTSBIKO;			//お支払責任者備考
		const wKoTSSTRNM 		= this.KoTSSTRNM;			//寺院名称
		const wKoTSSSONM 		= this.KoTSSSONM;			//宗旨名称
		const wKoTSSSLBS 		= this.KoTSSSLBS;			//墓石墓所
		const wKoTSSSLBD 		= this.KoTSSSLBD;			//仏壇
		const wKoTSSKSA1 		= this.KoTSSKSA1;			//会葬挨拶1
		const wKoTSSKMNM 		= this.KoTSSKMNM;			//家紋・紋章名称
		const wKoTSSBIKO 		= this.KoTSSBIKO;			//引継
		const wKoTPLCD 			= this.KoTPLCD;				//プランコード
		const wKoTPLNM 			= this.KoTPLNM;				//プラン名称
		const wKoTKKCD 			= this.KoTKKCD;				//会館コード
		const wKoTKKNM 			= this.KoTKKNM;				//会館名
		const wKoTHRCD 			= this.KoTHRCD;				//ホールコード
		const wKoTHRNM 			= this.KoTHRNM;				//ホール名
		const wKoSGICNM 		= this.KoSGICNM;			//葬儀委員長名
		const wKoSGICKN 		= this.KoSGICKN;			//葬儀委員長かな名
		const wKoSGICZK 		= this.KoSGICZK;			//葬儀委員長属性

		const wSKYOTrn			= this.SKYOTrn;

        req.open("GET", url, true);
		req.responseType = "arraybuffer";
		req.onreadystatechange = function () {
		if (req.readyState === 4 && req.status === 200){
        XlsxPopulate.fromDataAsync(req.response)
        .then(function (workbook) {
		//workbook.outputAsync().then(data => resolve(data));
        const sheet2 = workbook.sheet("データ")
	
		if(wUBPR_KoTNO			!= ""){sheet2.cell(wUBPR_KoTNO).value(wKoTNO)}				//受付番号
		if(wUBPR_KoTUMSKSDT		!= ""){sheet2.cell(wUBPR_KoTUMSKSDT).value(wKoTUMSKSDT)}	//印刷日
		if(wUBPR_KoTUMSKSTM		!= ""){sheet2.cell(wUBPR_KoTUMSKSTM).value(wKoTUMSKSTM)}	//印刷時間
		if(wUBPR_BLCKCD	  		!= ""){sheet2.cell(wUBPR_BLCKCD).value(wBL_BLCKCD)}			//営業所コード
		if(wUBPR_BLCKNM	  		!= ""){sheet2.cell(wUBPR_BLCKNM).value(wBL_BLCKNM)}			//営業所名
		if(wUBPR_BLCKYB1		!= ""){sheet2.cell(wUBPR_BLCKYB1).value(wBL_BLCKYB1)}		//郵便番号1
		if(wUBPR_BLCKYB2		!= ""){sheet2.cell(wUBPR_BLCKYB2).value(wBL_BLCKYB2)}		//郵便番号2
		if(wUBPR_BLCKJY1		!= ""){sheet2.cell(wUBPR_BLCKJY1).value(wBL_BLCKJY1)}		//住所1
		if(wUBPR_BLCKJY2		!= ""){sheet2.cell(wUBPR_BLCKJY2).value(wBL_BLCKJY2)}		//住所2
		if(wUBPR_BLCKJY3		!= ""){sheet2.cell(wUBPR_BLCKJY3).value(wBL_BLCKJY3)}		//住所3
		if(wUBPR_BLCKTEL		!= ""){sheet2.cell(wUBPR_BLCKTEL).value(wBL_BLCKTEL)}		//TEL
		if(wUBPR_BLCKFAX		!= ""){sheet2.cell(wUBPR_BLCKFAX).value(wBL_BLCKFAX)}		//FAX
		if(wUBPR_BLCKMAIL		!= ""){sheet2.cell(wUBPR_BLCKMAIL).value(wBL_BLCKMAIL)}		//MAIL
		if(wUBPR_BLCKSK1		!= ""){sheet2.cell(wUBPR_BLCKSK1).value(wBL_BLCKSK1)}		//請求先代表者
		if(wUBPR_BLCKSK2		!= ""){sheet2.cell(wUBPR_BLCKSK2).value(wBL_BLCKSK2)}		//請求住所
		if(wUBPR_BLCKSK3		!= ""){sheet2.cell(wUBPR_BLCKSK3).value(wBL_BLCKSK3)}		//請求電話
		if(wUBPR_BLCKSK4		!= ""){sheet2.cell(wUBPR_BLCKSK4).value(wBL_BLCKSK4)}		//請求取引銀行名
		if(wUBPR_BLCKSK5		!= ""){sheet2.cell(wUBPR_BLCKSK5).value(wBL_BLCKSK5)}		//請求口座名義
		if(wUBPR_BLCKSK6		!= ""){sheet2.cell(wUBPR_BLCKSK6).value(wBL_BLCKSK6)}		//口座
		if(wUBPR_KoTUKDT		!= ""){sheet2.cell(wUBPR_KoTUKDT).value(wKoTUKDT)}			//受付日
		if(wUBPR_KoTNTNCD		!= ""){sheet2.cell(wUBPR_KoTNTNCD).value(wKoTNTNCD)}		//担当者コード
		if(wUBPR_KoTNTNNM		!= ""){sheet2.cell(wUBPR_KoTNTNNM).value(wKoTNTNNM)}		//担当者名称
		if(wUBPR_KoETNTNCD		!= ""){sheet2.cell(wUBPR_KoETNTNCD).value(wKoETNTNCD)}		//営業担当コード
		if(wUBPR_KoETNTNNM		!= ""){sheet2.cell(wUBPR_KoETNTNNM).value(wKoETNTNNM)}		//営業担当名称
		if(wUBPR_KoTKANO		!= ""){sheet2.cell(wUBPR_KoTKANO).value(wKoTKANO)}			//会員番号
		if(wUBPR_KoTKANM		!= ""){sheet2.cell(wUBPR_KoTKANM).value(wKoTKANM)}			//会員名称
		if(wUBPR_KoTMOKE		!= ""){sheet2.cell(wUBPR_KoTMOKE).value(wKoTMOKE)}			//喪家
		if(wUBPR_KoTCD	  		!= ""){sheet2.cell(wUBPR_KoTCD).value(wKoTCD)}				//顧客提携先コード
		if(wUBPR_KoTNM	  		!= ""){sheet2.cell(wUBPR_KoTNM).value(wKoTNM)}				//顧客提携先名称
		if(wUBPR_KoTTKNM		!= ""){sheet2.cell(wUBPR_KoTTKNM).value(wKoTTKNM)}			//故人(カナ）
		if(wUBPR_KoTTNM	  		!= ""){sheet2.cell(wUBPR_KoTTNM).value(wKoTTNM)}			//故人名前
	//??????	if(WUBPR_KoTTNG	  		!= ""){sheet2.cell(WUBPR_KoTTNG).value(W_KoTTNG)}			//故人生年月日年号
	//??????		if(WUBPR_KoTTBSYY		!= ""){sheet2.cell(WUBPR_KoTTBSYY).value(W_KoTTBSYY)}		//故人生年月日年
	//??????		if(WUBPR_KoTTBSMM		!= ""){sheet2.cell(WUBPR_KoTTBSMM).value(W_KoTTBSMM)}		//故人生年月日月
	//??????		if(WUBPR_KoTTBSDD		!= ""){sheet2.cell(WUBPR_KoTTBSDD).value(W_KoTTBSDD)}		//故人生年月日日
		if(wUBPR_KoTTEG	  		!= ""){sheet2.cell(wUBPR_KoTTEG).value(wKoTTEG)}			//故人年齢
		if(wUBPR_KoTTSEX		!= ""){sheet2.cell(wUBPR_KoTTSEX).value(wKoTTSEX)}			//故人性別
		if(wUBPR_KoTTNEX		!= ""){sheet2.cell(wUBPR_KoTTNEX).value(wKoTTNEX)}			//故人続柄
		if(wUBPR_KoTTYU	  		!= ""){sheet2.cell(wUBPR_KoTTYU).value(wKoTTYU)}			//故人郵便番号
		if(wUBPR_KoTTJYU1		!= ""){sheet2.cell(wUBPR_KoTTJYU1).value(wKoTTJYU1)}		//故人住所1
		if(wUBPR_KoTTJYU2		!= ""){sheet2.cell(wUBPR_KoTTJYU2).value(wKoTTJYU2)}		//故人住所2
		if(wUBPR_KoTTJYU3		!= ""){sheet2.cell(wUBPR_KoTTJYU3).value(wKoTTJYU3)}		//故人住所3
		if(wUBPR_KoTTTEL		!= ""){sheet2.cell(wUBPR_KoTTTEL).value(wKoTTTEL)}			//故人電話番号
		if(wUBPR_KoTMKNM		!= ""){sheet2.cell(wUBPR_KoTMKNM).value(wKoTMKNM)}			//喪主(カナ）
		if(wUBPR_KoTMNM	  		!= ""){sheet2.cell(wUBPR_KoTMNM).value(wKoTMNM)}			//喪主名前
		if(wUBPR_KoTMNEX		!= ""){sheet2.cell(wUBPR_KoTMNEX).value(wKoTMNEX)}			//喪主続柄
		if(wUBPR_KoTMSEX		!= ""){sheet2.cell(wUBPR_KoTMSEX).value(wKoTMSEX)}			//喪主性別
		if(wUBPR_KoTMYU	  		!= ""){sheet2.cell(wUBPR_KoTMYU).value(wKoTMYU)}			//喪主郵便番号
		if(wUBPR_KoTMJYU1		!= ""){sheet2.cell(wUBPR_KoTMJYU1).value(wKoTMJYU1)}		//喪主住所1
		if(wUBPR_KoTMJYU2		!= ""){sheet2.cell(wUBPR_KoTMJYU2).value(wKoTMJYU2)}		//喪主住所2
		if(wUBPR_KoTMJYU3		!= ""){sheet2.cell(wUBPR_KoTMJYU3).value(wKoTMJYU3)}		//喪主住所3
		if(wUBPR_KoTMTEL		!= ""){sheet2.cell(wUBPR_KoTMTEL).value(wKoTMTEL)}			//喪主電話番号
		if(wUBPR_KoTMTELK		!= ""){sheet2.cell(wUBPR_KoTMTELK).value(wKoTMTELK)}		//喪主携帯
		if(wUBPR_KoTMTMail		!= ""){sheet2.cell(wUBPR_KoTMTMail).value(wKoTMTMail)}		//喪主Mail
		if(wUBPR_KoTMBIKO		!= ""){sheet2.cell(wUBPR_KoTMBIKO).value(wKoTMBIKO)}		//喪主備考
		if(wUBPR_KoTSNM	  		!= ""){sheet2.cell(wUBPR_KoTSNM).value(wKoTSNM)}			//お支払責任者名前
		if(wUBPR_KoTSHYODT		!= ""){sheet2.cell(wUBPR_KoTSHYODT).value(wKoTSHYODT)}		//お支払予定日
		if(wUBPR_KoTSPNM		!= ""){sheet2.cell(wUBPR_KoTSPNM).value(wKoTSPNM)}			//入金方法名
		if(wUBPR_KoTSYU	  		!= ""){sheet2.cell(wUBPR_KoTSYU).value(wKoTSYU)}			//支払責任者郵便番号
		if(wUBPR_KoTSJYU1		!= ""){sheet2.cell(wUBPR_KoTSJYU1).value(wKoTSJYU1)}		//お支払責任者住所1
		if(wUBPR_KoTSJYU2		!= ""){sheet2.cell(wUBPR_KoTSJYU2).value(wKoTSJYU2)}		//お支払責任者住所2
		if(wUBPR_KoTSJYU3		!= ""){sheet2.cell(wUBPR_KoTSJYU3).value(wKoTSJYU3)}		//お支払責任者住所3
		if(wUBPR_KoTSTEL		!= ""){sheet2.cell(wUBPR_KoTSTEL).value(wKoTSTEL)}			//お支払責任者電話番号
		if(wUBPR_KoTSTELK		!= ""){sheet2.cell(wUBPR_KoTSTELK).value(wKoTSTELK)}		//お支払責任者携帯
		if(wUBPR_KoTSMail		!= ""){sheet2.cell(wUBPR_KoTSMail).value(wKoTSMail)}		//お支払責任者Mail
		if(wUBPR_KoTSBIKO		!= ""){sheet2.cell(wUBPR_KoTSBIKO).value(wKoTSBIKO)}		//お支払責任者備考
		if(wUBPR_KoTSSTRNM		!= ""){sheet2.cell(wUBPR_KoTSSTRNM).value(wKoTSSTRNM)}		//寺院名称
		if(wUBPR_KoTSSSONM		!= ""){sheet2.cell(wUBPR_KoTSSSONM).value(wKoTSSSONM)}		//宗旨名称
		if(wUBPR_KoTSSSLBS		!= ""){sheet2.cell(wUBPR_KoTSSSLBS).value(wKoTSSSLBS)}		//墓石墓所
		if(wUBPR_KoTSSSLBD		!= ""){sheet2.cell(wUBPR_KoTSSSLBD).value(wKoTSSSLBD)}		//仏壇
		if(wUBPR_KoTSSKSA1		!= ""){sheet2.cell(wUBPR_KoTSSKSA1).value(wKoTSSKSA1)}		//会葬挨拶1
		if(wUBPR_KoTSSKMNM		!= ""){sheet2.cell(wUBPR_KoTSSKMNM).value(wKoTSSKMNM)}		//家紋・紋章名称
		if(wUBPR_KoTSSBIKO		!= ""){sheet2.cell(wUBPR_KoTSSBIKO).value(wKoTSSBIKO)}		//引継
		if(wUBPR_KoTPLCD		!= ""){sheet2.cell(wUBPR_KoTPLCD).value(wKoTPLCD)}			//プランコード
		if(wUBPR_KoTPLNM		!= ""){sheet2.cell(wUBPR_KoTPLNM).value(wKoTPLNM)}			//プラン名称
		if(wUBPR_KoTKKCD		!= ""){sheet2.cell(wUBPR_KoTKKCD).value(wKoTKKCD)}			//会館コード
		if(wUBPR_KoTKKNM		!= ""){sheet2.cell(wUBPR_KoTKKNM).value(wKoTKKNM)}			//会館名
		if(wUBPR_KoTHRCD		!= ""){sheet2.cell(wUBPR_KoTHRCD).value(wKoTHRCD)}			//ホールコード
		if(wUBPR_KoTHRNM		!= ""){sheet2.cell(wUBPR_KoTHRNM).value(wKoTHRNM)}			//ホール名
		if(wUBPR_KoSGICNM		!= ""){sheet2.cell(wUBPR_KoSGICNM).value(wKoSGICNM)}		//葬儀委員長名
		if(wUBPR_KoSGICKN		!= ""){sheet2.cell(wUBPR_KoSGICKN).value(wKoSGICKN)}		//葬儀委員長かな名
		if(wUBPR_KoSGICZK		!= ""){sheet2.cell(wUBPR_KoSGICZK).value(wKoSGICZK)}		//葬儀委員長属性
		
		for (let i = 0; i < wSKYOTrn.length; i++) {
			let GYOU = parseInt(wUBPR_TBGO) + i;
			if(wUBPR_SKYTGYO	!= ""){sheet2.cell(wUBPR_SKYTGYO	+ GYOU).value(wSKYOTrn[i].SKYTGYO)}		//行
			if(wUBPR_SKYTKB		!= ""){sheet2.cell(wUBPR_SKYTKB 	+ GYOU).value(wSKYOTrn[i].SKYTKB)}		//区分
			if(wUBPR_SKYTNM		!= ""){sheet2.cell(wUBPR_SKYTNM 	+ GYOU).value(wSKYOTrn[i].SKYTNM)}		//施行名称
			if(wUBPR_SKYTDATE	!= ""){sheet2.cell(wUBPR_SKYTDATE	+ GYOU).value(wSKYOTrn[i].SKYTDATE)}	//施行日
			if(wUBPR_SKYTHH		!= ""){sheet2.cell(wUBPR_SKYTHH		+ GYOU).value(wSKYOTrn[i].SKYTHH)}		//開始時
			if(wUBPR_SKYTMM		!= ""){sheet2.cell(wUBPR_SKYTMM		+ GYOU).value(wSKYOTrn[i].SKYTMM)}		//開始分
			if(wUBPR_SKYSHH		!= ""){sheet2.cell(wUBPR_SKYSHH		+ GYOU).value(wSKYOTrn[i].SKYSHH)}		//終了時
			if(wUBPR_SKYSMM		!= ""){sheet2.cell(wUBPR_SKYSMM		+ GYOU).value(wSKYOTrn[i].SKYSMM)}		//終了分
			if(wUBPR_SKYTFBNM	!= ""){sheet2.cell(wUBPR_SKYTFBNM	+ GYOU).value(wSKYOTrn[i].SKYTFBNM)}	//場所名称
			if(wUBPR_SKYTP1NM	!= ""){sheet2.cell(wUBPR_SKYTP1NM	+ GYOU).value(wSKYOTrn[i].SKYTP1NM)}	//入力1名称
			if(wUBPR_SKYTP1DT	!= ""){sheet2.cell(wUBPR_SKYTP1DT	+ GYOU).value(wSKYOTrn[i].SKYTP1DT)}	//入力1値
			if(wUBPR_SKYTP1TN	!= ""){sheet2.cell(wUBPR_SKYTP1TN	+ GYOU).value(wSKYOTrn[i].SKYTP1TN)}	//入力1単位
			if(wUBPR_SKYTP2NM	!= ""){sheet2.cell(wUBPR_SKYTP2NM	+ GYOU).value(wSKYOTrn[i].SKYTP2NM)}	//入力2名称
			if(wUBPR_SKYTP2DT	!= ""){sheet2.cell(wUBPR_SKYTP2DT	+ GYOU).value(wSKYOTrn[i].SKYTP2DT)}	//入力2値
			if(wUBPR_SKYTP2TN	!= ""){sheet2.cell(wUBPR_SKYTP2TN	+ GYOU).value(wSKYOTrn[i].SKYTP2TN)}	//入力2単位
			if(wUBPR_SKYTP3NM	!= ""){sheet2.cell(wUBPR_SKYTP3NM	+ GYOU).value(wSKYOTrn[i].SKYTP3NM)}	//入力3名称
			if(wUBPR_SKYTP3DT	!= ""){sheet2.cell(wUBPR_SKYTP3DT	+ GYOU).value(wSKYOTrn[i].SKYTP3DT)}	//入力3値
			if(wUBPR_SKYTP3TN	!= ""){sheet2.cell(wUBPR_SKYTP3TN	+ GYOU).value(wSKYOTrn[i].SKYTP3TN)}	//入力3単位
			if(wUBPR_SKYTNY		!= ""){sheet2.cell(wUBPR_SKYTNY		+ GYOU).value(wSKYOTrn[i].SKYTNY)}		//備考
       }
		workbook.outputAsync()
        .then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(blob, "受付帳簿.xlsx");
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "受付帳簿.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
        });
		})		
        }
		};
		req.send();
		this.BOPRINT();	//祭事見積書エクセルの印刷更新
	},
	//-----------------------------------------------------------------
    //祭事ヘッダー受付簿の印刷更新
	//-----------------------------------------------------------------
	async BOPRINT(){
		let dateKYOU 	= moment(new Date).format('YYYY/MM/DD h:mm:ss');
		let dateIMADT 	= moment(new Date).format('YYYY/MM/DD');
		let dateIMATM 	= moment(new Date).format('hh:mm');
		
		this.KoMUKBDT = dateIMADT;
		this.KoMUKBTM = dateIMATM;
		
		var fomeUPgo = { 
        KoTNO:      this.KoTNO,         //受付番号
		KoMUKBDT:  	dateIMADT,			//受付簿印刷日
		KoMUKBTM:  	dateIMATM,			//受付簿印時間
        UpdtDt:     dateKYOU,           //更新日時
        UpdtId:     this.UpdtId         //更新者
        };
		axios.post('/api/KoTrnINSUP',fomeUPgo)
		.catch(error => {
            console.log(error)
        })

	},
	//-----------------------------------------------------------------
    //祭事見積書エクセルの作成
    //-----------------------------------------------------------------
	SJmakeexcel() {
		//const  Promise = XlsxPopulate.Promise;		
		const XlsxPopulate = require( 'xlsx-populate' );
		const req = new XMLHttpRequest();
        const url = this.SJKoteifile;

		const wSJPR_KoTNO		= this.SJPR_KoTNO;          //受付番号
		const wSJPR_KoTUMSKSDT	= this.SJPR_KoTUMSKSDT;   	//印刷日
		const wSJPR_KoTUMSKSTM	= this.SJPR_KoTUMSKSTM;   	//印刷時間
		const wSJPR_BLCKCD		= this.SJPR_BLCKCD;       	//営業所コード
		const wSJPR_BLCKNM		= this.SJPR_BLCKNM;       	//営業所名
		const wSJPR_BLCKYB1		= this.SJPR_BLCKYB1;      	//郵便番号1
		const wSJPR_BLCKYB2		= this.SJPR_BLCKYB2;      	//郵便番号2
		const wSJPR_BLCKJY1		= this.SJPR_BLCKJY1;      	//住所1
		const wSJPR_BLCKJY2		= this.SJPR_BLCKJY2;      	//住所2
		const wSJPR_BLCKJY3		= this.SJPR_BLCKJY3;      	//住所3
		const wSJPR_BLCKTEL		= this.SJPR_BLCKTEL;      	//TEL
		const wSJPR_BLCKFAX		= this.SJPR_BLCKFAX;      	//FAX
		const wSJPR_BLCKMAIL	= this.SJPR_BLCKMAIL;     	//MAIL
		const wSJPR_BLCKSK1		= this.SJPR_BLCKSK1;       	//請求先代表者
		const wSJPR_BLCKSK2		= this.SJPR_BLCKSK2;       	//請求住所
		const wSJPR_BLCKSK3		= this.SJPR_BLCKSK3;       	//請求電話
		const wSJPR_BLCKSK4		= this.SJPR_BLCKSK4;       	//請求取引銀行名
		const wSJPR_BLCKSK5		= this.SJPR_BLCKSK5;       	//請求口座名義
		const wSJPR_BLCKSK6		= this.SJPR_BLCKSK6;       	//口座
		const wSJPR_KoTUKDT		= this.SJPR_KoTUKDT;       	//受付日
		const wSJPR_KoTNTNCD	= this.SJPR_KoTNTNCD;      	//担当者コード
		const wSJPR_KoTNTNNM	= this.SJPR_KoTNTNNM;      	//担当者名称
		const wSJPR_KoETNTNCD	= this.SJPR_KoETNTNCD;     	//営業担当コード
		const wSJPR_KoETNTNNM	= this.SJPR_KoETNTNNM;     	//営業担当名称
		const wSJPR_KoTSNM		= this.SJPR_KoTSNM;        	//名前
		const wSJPR_KoTSYU		= this.SJPR_KoTSYU;        	//住所〒
		const wSJPR_KoTSJYU1	= this.SJPR_KoTSJYU1;      	//住所1
		const wSJPR_KoTSJYU2	= this.SJPR_KoTSJYU2;      	//住所2
		const wSJPR_KoTSJYU3	= this.SJPR_KoTSJYU3;      	//住所3
		const wSJPR_KoTSTEL		= this.SJPR_KoTSTEL;       	//℡
		const wSJPR_KoTSTELK	= this.SJPR_KoTSTELK;      	//携帯
		const wSJPR_KoTSMail	= this.SJPR_KoTSMail;      	//メール
		const wSJPR_KoTSURKGK	= this.SJPR_KoTSURKGK;     	//祭事売上(税込み)
		const wSJPR_KoTURSMST	= this.SJPR_KoTURSMST;     	//祭事外税売上  (税抜)
		const wSJPR_KoTURSMKG	= this.SJPR_KoTURSMKG;     	//祭事軽減税売上(税抜)
		const wSJPR_KoTURSMHK	= this.SJPR_KoTURSMHK;     	//祭事非課税売上(税抜)
		const wSJPR_KoTSNEBST	= this.SJPR_KoTSNEBST;     	//祭事外税値引
		const wSJPR_KoTSNEBKG	= this.SJPR_KoTSNEBKG;     	//祭事軽減税値引
		const wSJPR_KoTSNEBHK	= this.SJPR_KoTSNEBHK;     	//祭事非課税値引
		const wSJPR_KoTSKAZST	= this.SJPR_KoTSKAZST;     	//祭事外税対象額計
		const wSJPR_KoTSKAZKG	= this.SJPR_KoTSKAZKG;     	//祭事軽減税対象額計
		const wSJPR_KoTSKAZHK	= this.SJPR_KoTSKAZHK;     	//祭事非課税対象額計
		const wSJPR_KoTSSYOST	= this.SJPR_KoTSSYOST;     	//祭事外税消費税
		const wSJPR_KoTSSYOKG	= this.SJPR_KoTSSYOKG;     	//祭事軽減税消費税
	//	const wSJPR_KoTTSEX		= this.SJPR_KoTTSEX;       	//故人性別

		const wSJPR_MsSETCD		= this.SJPR_MsSETCD;  		//セットコード
		const wSJPR_MsSETNM		= this.SJPR_MsSETNM;  		//セット名称
		const wSJPR_MsHIBJYUN	= this.SJPR_MsHIBJYUN;		//品目大分類並び順
		const wSJPR_MsHIBCD		= this.SJPR_MsHIBCD;  		//品目大分類コード
		const wSJPR_MsHIBNM		= this.SJPR_MsHIBNM;  		//品目大分類名称
		const wSJPR_MsSYBRJN	= this.SJPR_MsSYBRJN; 		//品目分類順
		const wSJPR_MsSYBRCD	= this.SJPR_MsSYBRCD; 		//品目分類コード
		const wSJPR_MsSYBRNM	= this.SJPR_MsSYBRNM; 		//品目分類名称
		const wSJPR_MsHIJYUN	= this.SJPR_MsHIJYUN; 		//品目並び順
		const wSJPR_MsHICD		= this.SJPR_MsHICD;   		//品目コード
		const wSJPR_MsHINM		= this.SJPR_MsHINM;   		//品目名称
		const wSJPR_MsTAN		= this.SJPR_MsTAN;    		//単位
		const wSJPR_MsSETFG		= this.SJPR_MsSETFG;  		//セットフラグ
		const wSJPR_MsUNT		= this.SJPR_MsUNT;    		//販売単価
		const wSJPR_MsSYOKB		= this.SJPR_MsSYOKB;  		//売上消費税区分
		const wSJPR_MsSuu		= this.SJPR_MsSuu;    		//売上数量
		const wSJPR_MsKin		= this.SJPR_MsKin;    		//売上金額(税抜)
		const wSJPR_MsNebiki	= this.SJPR_MsNebiki; 		//売上値引(税抜)
		const wSJPR_MsGKing		= this.SJPR_MsGKing;  		//売上額値引後(税抜)
		const wSJPR_MsUCSYOG	= this.SJPR_MsUCSYOG; 		//売上消費税額
		const wSJPR_MsZKGK		= this.SJPR_MsZKGK;   		//売上税込額(税込)
		const wSJPR_MsBIKO		= this.SJPR_MsBIKO;   		//備考

		const wSJPR_TBGO		= this.SJPR_TBGO;
		const wMsTrn			= this.MsTrn;

		const wKoTNO			= this.KoTNO;             	//受付番号
		const wKoTUMSKSDT		= this.KoTUMSKSDT;   		//印刷日
		const wKoTUMSKSTM		= this.KoTUMSKSTM;   		//印刷時間
		const wBL_BLCKCD		= this.BL_BLCKCD;         	//営業所コード
		const wBL_BLCKNM		= this.BL_BLCKNM;         	//営業所名
		const wBL_BLCKYB1		= this.BL_BLCKYB1;       	//郵便番号1
		const wBL_BLCKYB2		= this.BL_BLCKYB2;       	//郵便番号2
		const wBL_BLCKJY1		= this.BL_BLCKJY1;       	//住所1
		const wBL_BLCKJY2		= this.BL_BLCKJY2;       	//住所2
		const wBL_BLCKJY3		= this.BL_BLCKJY3;       	//住所3
		const wBL_BLCKTEL		= this.BL_BLCKTEL;       	//TEL
		const wBL_BLCKFAX		= this.BL_BLCKFAX;       	//FAX
		const wBL_BLCKMAIL		= this.BL_BLCKMAIL;     	//MAIL
		const wBL_BLCKSK1		= this.BL_BLCKSK1;       	//請求先代表者
		const wBL_BLCKSK2		= this.BL_BLCKSK2;       	//請求住所
		const wBL_BLCKSK3		= this.BL_BLCKSK3;       	//請求電話
		const wBL_BLCKSK4		= this.BL_BLCKSK4;       	//請求取引銀行名
		const wBL_BLCKSK5		= this.BL_BLCKSK5;       	//請求口座名義
		const wBL_BLCKSK6		= this.BL_BLCKSK6;       	//口座
		const wKoTUKDT			= this.KoTUKDT;         	//受付日
		const wKoTNTNCD			= this.KoTNTNCD;       		//担当者コード
		const wKoTNTNNM			= this.KoTNTNNM;       		//担当者名称
		const wKoETNTNCD		= this.KoETNTNCD;     		//営業担当コード
		const wKoETNTNNM		= this.KoETNTNNM;     		//営業担当名称
		const wKoTSNM			= this.KoTSNM;           	//名前
		const wKoTSYU			= this.KoTSYU;           	//住所〒
		const wKoTSJYU1			= this.KoTSJYU1;       		//住所1
		const wKoTSJYU2			= this.KoTSJYU2;       		//住所2
		const wKoTSJYU3			= this.KoTSJYU3;       		//住所3
		const wKoTSTEL			= this.KoTSTEL;         	//℡
		const wKoTSTELK			= this.KoTSTELK;       		//携帯
		const wKoTSMail			= this.KoTSMail;       		//メール
		const wKoTSURKGK		= this.KoTSURKGK;     		//祭事売上(税込み)
		const wKoTURSMST		= this.KoTURSMST;     		//祭事外税売上  (税抜)
		const wKoTURSMKG		= this.KoTURSMKG;     		//祭事軽減税売上(税抜)
		const wKoTURSMHK		= this.KoTURSMHK;     		//祭事非課税売上(税抜)
		const wKoTSNEBST		= this.KoTSNEBST;     		//祭事外税値引
		const wKoTSNEBKG		= this.KoTSNEBKG;     		//祭事軽減税値引
		const wKoTSNEBHK		= this.KoTSNEBHK;     		//祭事非課税値引
		const wKoTSKAZST		= this.KoTSKAZST;     		//祭事外税対象額計
		const wKoTSKAZKG		= this.KoTSKAZKG;     		//祭事軽減税対象額計
		const wKoTSKAZHK		= this.KoTSKAZHK;     		//祭事非課税対象額計
		const wKoTSSYOST		= this.KoTSSYOST;     		//祭事外税消費税
		const wKoTSSYOKG		= this.KoTSSYOKG;     		//祭事軽減税消費税
	//	const wKoTTSEX			= this.KoTTSEX;         	//故人性別

        req.open("GET", url, true);
		req.responseType = "arraybuffer";
		req.onreadystatechange = function () {
		if (req.readyState === 4 && req.status === 200){
            XlsxPopulate.fromDataAsync(req.response)
            .then(function (workbook) {
			//workbook.outputAsync().then(data => this.resolve(data));
            const sheet2 = workbook.sheet("データ")
			if(wSJPR_KoTNO			!= ""){sheet2.cell(wSJPR_KoTNO).value(wKoTNO)}             //受付番号
			if(wSJPR_KoTUMSKSDT		!= ""){sheet2.cell(wSJPR_KoTUMSKSDT).value(wKoTUMSKSDT)}   //印刷日
			if(wSJPR_KoTUMSKSTM		!= ""){sheet2.cell(wSJPR_KoTUMSKSTM).value(wKoTUMSKSTM)}   //印刷時間
			if(wSJPR_BLCKCD			!= ""){sheet2.cell(wSJPR_BLCKCD).value(wBL_BLCKCD)}        //営業所コード
			if(wSJPR_BLCKNM			!= ""){sheet2.cell(wSJPR_BLCKNM).value(wBL_BLCKNM)}        //営業所名
			if(wSJPR_BLCKYB1		!= ""){sheet2.cell(wSJPR_BLCKYB1).value(wBL_BLCKYB1)}      //郵便番号1
			if(wSJPR_BLCKYB2		!= ""){sheet2.cell(wSJPR_BLCKYB2).value(wBL_BLCKYB2)}      //郵便番号2
			if(wSJPR_BLCKJY1		!= ""){sheet2.cell(wSJPR_BLCKJY1).value(wBL_BLCKJY1)}      //住所1
			if(wSJPR_BLCKJY2		!= ""){sheet2.cell(wSJPR_BLCKJY2).value(wBL_BLCKJY2)}      //住所2
			if(wSJPR_BLCKJY3		!= ""){sheet2.cell(wSJPR_BLCKJY3).value(wBL_BLCKJY3)}      //住所3
			if(wSJPR_BLCKTEL		!= ""){sheet2.cell(wSJPR_BLCKTEL).value(wBL_BLCKTEL)}      //TEL
			if(wSJPR_BLCKFAX		!= ""){sheet2.cell(wSJPR_BLCKFAX).value(wBL_BLCKFAX)}      //FAX
			if(wSJPR_BLCKMAIL		!= ""){sheet2.cell(wSJPR_BLCKMAIL).value(wBL_BLCKMAIL)}    //MAIL
			if(wSJPR_BLCKSK1		!= ""){sheet2.cell(wSJPR_BLCKSK1).value(wBL_BLCKSK1)}      //請求先代表者
			if(wSJPR_BLCKSK2		!= ""){sheet2.cell(wSJPR_BLCKSK2).value(wBL_BLCKSK2)}      //請求住所
			if(wSJPR_BLCKSK3		!= ""){sheet2.cell(wSJPR_BLCKSK3).value(wBL_BLCKSK3)}      //請求電話
			if(wSJPR_BLCKSK4		!= ""){sheet2.cell(wSJPR_BLCKSK4).value(wBL_BLCKSK4)}      //請求取引銀行名
			if(wSJPR_BLCKSK5		!= ""){sheet2.cell(wSJPR_BLCKSK5).value(wBL_BLCKSK5)}      //請求口座名義
			if(wSJPR_BLCKSK6		!= ""){sheet2.cell(wSJPR_BLCKSK6).value(wBL_BLCKSK6)}      //口座
			if(wSJPR_KoTUKDT		!= ""){sheet2.cell(wSJPR_KoTUKDT).value(wKoTUKDT)}         //受付日
			if(wSJPR_KoTNTNCD		!= ""){sheet2.cell(wSJPR_KoTNTNCD).value(wKoTNTNCD)}       //担当者コード
			if(wSJPR_KoTNTNNM		!= ""){sheet2.cell(wSJPR_KoTNTNNM).value(wKoTNTNNM)}       //担当者名称
			if(wSJPR_KoETNTNCD		!= ""){sheet2.cell(wSJPR_KoETNTNCD).value(wKoETNTNCD)}     //営業担当コード
			if(wSJPR_KoETNTNNM		!= ""){sheet2.cell(wSJPR_KoETNTNNM).value(wKoETNTNNM)}     //営業担当名称
			if(wSJPR_KoTSNM			!= ""){sheet2.cell(wSJPR_KoTSNM).value(wKoTSNM)}           //名前
			if(wSJPR_KoTSYU			!= ""){sheet2.cell(wSJPR_KoTSYU).value(wKoTSYU)}           //住所〒
			if(wSJPR_KoTSJYU1		!= ""){sheet2.cell(wSJPR_KoTSJYU1).value(wKoTSJYU1)}       //住所1
			if(wSJPR_KoTSJYU2		!= ""){sheet2.cell(wSJPR_KoTSJYU2).value(wKoTSJYU2)}       //住所2
			if(wSJPR_KoTSJYU3		!= ""){sheet2.cell(wSJPR_KoTSJYU3).value(wKoTSJYU3)}       //住所3
			if(wSJPR_KoTSTEL		!= ""){sheet2.cell(wSJPR_KoTSTEL).value(wKoTSTEL)}         //℡
			if(wSJPR_KoTSTELK		!= ""){sheet2.cell(wSJPR_KoTSTELK).value(wKoTSTELK)}       //携帯
			if(wSJPR_KoTSMail		!= ""){sheet2.cell(wSJPR_KoTSMail).value(wKoTSMail)}       //メール
			if(wSJPR_KoTSURKGK		!= ""){sheet2.cell(wSJPR_KoTSURKGK).value(wKoTSURKGK)}     //祭事売上(税込み)
			if(wSJPR_KoTURSMST		!= ""){sheet2.cell(wSJPR_KoTURSMST).value(wKoTURSMST)}     //祭事外税売上  (税抜)
			if(wSJPR_KoTURSMKG		!= ""){sheet2.cell(wSJPR_KoTURSMKG).value(wKoTURSMKG)}     //祭事軽減税売上(税抜)
			if(wSJPR_KoTURSMHK		!= ""){sheet2.cell(wSJPR_KoTURSMHK).value(wKoTURSMHK)}     //祭事非課税売上(税抜)
			if(wSJPR_KoTSNEBST		!= ""){sheet2.cell(wSJPR_KoTSNEBST).value(wKoTSNEBST)}     //祭事外税値引
			if(wSJPR_KoTSNEBKG		!= ""){sheet2.cell(wSJPR_KoTSNEBKG).value(wKoTSNEBKG)}     //祭事軽減税値引
			if(wSJPR_KoTSNEBHK		!= ""){sheet2.cell(wSJPR_KoTSNEBHK).value(wKoTSNEBHK)}     //祭事非課税値引
			if(wSJPR_KoTSKAZST		!= ""){sheet2.cell(wSJPR_KoTSKAZST).value(wKoTSKAZST)}     //祭事外税対象額計
			if(wSJPR_KoTSKAZKG		!= ""){sheet2.cell(wSJPR_KoTSKAZKG).value(wKoTSKAZKG)}     //祭事軽減税対象額計
			if(wSJPR_KoTSKAZHK		!= ""){sheet2.cell(wSJPR_KoTSKAZHK).value(wKoTSKAZHK)}     //祭事非課税対象額計
			if(wSJPR_KoTSSYOST		!= ""){sheet2.cell(wSJPR_KoTSSYOST).value(wKoTSSYOST)}     //祭事外税消費税
			if(wSJPR_KoTSSYOKG		!= ""){sheet2.cell(wSJPR_KoTSSYOKG).value(wKoTSSYOKG)}     //祭事軽減税消費税
	//		if(wSJPR_KoTTSEX		!= ""){sheet2.cell(wSJPR_KoTTSEX).value(wKoTTSEX)}         //故人性別

			if(wSJPR_TBGO	!= ""){
			if(wMsTrn.length.length != 0){
			for (let i = 0; i < wMsTrn.length; i++) {
				let GYOU = parseInt(wSJPR_TBGO) + i;
				if(wMsTrn[i].MsTAKE != "1:立替"){
					if(wSJPR_MsSETCD	!= ""){sheet2.cell(wSJPR_MsSETCD	+ GYOU).value(wMsTrn[i].MsSETCD)}   //セットコード
					if(wSJPR_MsSETNM	!= ""){sheet2.cell(wSJPR_MsSETNM	+ GYOU).value(wMsTrn[i].MsSETNM)}   //セット名称
					if(wSJPR_MsHIBJYUN	!= ""){sheet2.cell(wSJPR_MsHIBJYUN	+ GYOU).value(wMsTrn[i].MsHIBJYUN)} //品目大分類並び順
					if(wSJPR_MsHIBCD	!= ""){sheet2.cell(wSJPR_MsHIBCD	+ GYOU).value(wMsTrn[i].MsHIBCD)}   //品目大分類コード
					if(wSJPR_MsHIBNM	!= ""){sheet2.cell(wSJPR_MsHIBNM	+ GYOU).value(wMsTrn[i].MsHIBNM)}   //品目大分類名称
					if(wSJPR_MsSYBRJN	!= ""){sheet2.cell(wSJPR_MsSYBRJN	+ GYOU).value(wMsTrn[i].MsSYBRJN)}  //品目分類順
					if(wSJPR_MsSYBRCD	!= ""){sheet2.cell(wSJPR_MsSYBRCD	+ GYOU).value(wMsTrn[i].MsSYBRCD)}  //品目分類コード
					if(wSJPR_MsSYBRNM	!= ""){sheet2.cell(wSJPR_MsSYBRNM	+ GYOU).value(wMsTrn[i].MsSYBRNM)}  //品目分類名称
					if(wSJPR_MsHIJYUN	!= ""){sheet2.cell(wSJPR_MsHIJYUN	+ GYOU).value(wMsTrn[i].MsHIJYUN)}  //品目並び順
					if(wSJPR_MsHICD		!= ""){sheet2.cell(wSJPR_MsHICD		+ GYOU).value(wMsTrn[i].MsHICD)}    //品目コード
					if(wSJPR_MsHINM		!= ""){sheet2.cell(wSJPR_MsHINM		+ GYOU).value(wMsTrn[i].MsHINM)}    //品目名称
					if(wSJPR_MsTAN		!= ""){sheet2.cell(wSJPR_MsTAN		+ GYOU).value(wMsTrn[i].MsTAN)}     //単位
					if(wSJPR_MsSETFG	!= ""){sheet2.cell(wSJPR_MsSETFG	+ GYOU).value(wMsTrn[i].MsSETFG)}   //セットフラグ
					if(wSJPR_MsUNT		!= ""){sheet2.cell(wSJPR_MsUNT		+ GYOU).value(wMsTrn[i].MsUNT)}     //販売単価
					if(wSJPR_MsSYOKB	!= ""){sheet2.cell(wSJPR_MsSYOKB	+ GYOU).value(wMsTrn[i].MsSYOKB)}   //売上消費税区分
					if(wSJPR_MsSuu		!= ""){sheet2.cell(wSJPR_MsSuu		+ GYOU).value(wMsTrn[i].MsSuu)}     //売上数量
					if(wSJPR_MsKin		!= ""){sheet2.cell(wSJPR_MsKin		+ GYOU).value(wMsTrn[i].MsKin)}     //売上金額(税抜)
					if(wSJPR_MsNebiki	!= ""){sheet2.cell(wSJPR_MsNebiki	+ GYOU).value(wMsTrn[i].MsNebiki)}  //売上値引(税抜)
					if(wSJPR_MsGKing	!= ""){sheet2.cell(wSJPR_MsGKing	+ GYOU).value(wMsTrn[i].MsGKing)}   //売上額値引後(税抜)
					if(wSJPR_MsUCSYOG	!= ""){sheet2.cell(wSJPR_MsUCSYOG	+ GYOU).value(wMsTrn[i].MsUCSYOG)}  //売上消費税額
					if(wSJPR_MsZKGK		!= ""){sheet2.cell(wSJPR_MsZKGK		+ GYOU).value(wMsTrn[i].MsZKGK)}    //売上税込額(税込)
					if(wSJPR_MsBIKO		!= ""){sheet2.cell(wSJPR_MsBIKO		+ GYOU).value(wMsTrn[i].MsBIKO)}    //備考
				}
			}
        }
		workbook.outputAsync()
        .then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(blob, "祭事見積書.xlsx");
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "祭事見積書.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
		});
		}
		})		
        }
		};
		req.send();
		this.SJPRINT();
	},
	//-----------------------------------------------------------------
    //祭事ヘッダー祭事見積の印刷更新
	//-----------------------------------------------------------------
	async SJPRINT(){
		let dateKYOU 	= moment(new Date).format('YYYY/MM/DD h:mm:ss');
		let dateIMADT 	= moment(new Date).format('YYYY/MM/DD');
		let dateIMATM 	= moment(new Date).format('hh:mm');
	
		this.KoMSKSDT = dateIMADT;
		this.KoMSKSTM = dateIMATM;

		var fomeUPgo = { 
		KoTNO:      this.KoTNO,         //受付番号
		KoMSKSDT: 	dateIMADT,			//見積印刷日
		KoMSKSTM: 	dateIMATM,			//見積印時間
        UpdtDt:     dateKYOU,           //更新日時
        UpdtId:     this.UpdtId         //更新者
        };
		axios.post('/api/KoTrnSJP',fomeUPgo)
		.catch(error => {
            console.log(error)
        })
    },
	//-----------------------------------------------------------------
    //立替見積書エクセルの作成
    //-----------------------------------------------------------------
	TKmakeexcel() {
		const XlsxPopulate = require('xlsx-populate');
		const req = new XMLHttpRequest();
		const url = this.TAKoteifile;
		
		let dateIMADT 	= moment(new Date).format('YYYY/MM/DD');
		let dateIMATM 	= moment(new Date).format('hh:mm');

		const wTKPR_KoTNO      	= this.TKPR_KoTNO;        //受付番号
		const wTKPR_KoTUMSKSDT	= this.TKPR_KoTUMSKSDT;   //印刷日
		const wTKPR_KoTUMSKSTM	= this.TKPR_KoTUMSKSTM;   //印刷時間
		const wTKPR_BLCKCD	    = this.TKPR_BLCKCD;       //営業所コード
		const wTKPR_BLCKNM	    = this.TKPR_BLCKNM;       //営業所名
		const wTKPR_BLCKYB1		= this.TKPR_BLCKYB1;      //郵便番号1
		const wTKPR_BLCKYB2		= this.TKPR_BLCKYB2;      //郵便番号2
		const wTKPR_BLCKJY1		= this.TKPR_BLCKJY1;      //住所1
		const wTKPR_BLCKJY2		= this.TKPR_BLCKJY2;      //住所2
		const wTKPR_BLCKJY3		= this.TKPR_BLCKJY3;      //住所3
		const wTKPR_BLCKTEL		= this.TKPR_BLCKTEL;      //TEL
		const wTKPR_BLCKFAX		= this.TKPR_BLCKFAX;      //FAX
		const wTKPR_BLCKMAIL	= this.TKPR_BLCKMAIL;     //MAIL
		const wTKPR_BLCKSK1		= this.TKPR_BLCKSK1;      //請求先代表者
		const wTKPR_BLCKSK2		= this.TKPR_BLCKSK2;      //請求住所
		const wTKPR_BLCKSK3		= this.TKPR_BLCKSK3;      //請求電話
		const wTKPR_BLCKSK4		= this.TKPR_BLCKSK4;      //請求取引銀行名
		const wTKPR_BLCKSK5		= this.TKPR_BLCKSK5;      //請求口座名義
		const wTKPR_BLCKSK6		= this.TKPR_BLCKSK6;      //口座
		const wTKPR_KoTUKDT		= this.TKPR_KoTUKDT;      //受付日
		const wTKPR_KoTNTNCD	= this.TKPR_KoTNTNCD;     //担当者コード
		const wTKPR_KoTNTNNM	= this.TKPR_KoTNTNNM;     //担当者名称
		const wTKPR_KoETNTNCD	= this.TKPR_KoETNTNCD;    //営業担当コード
		const wTKPR_KoETNTNNM	= this.TKPR_KoETNTNNM;    //営業担当名称
		const wTKPR_KoTSNM     	= this.TKPR_KoTSNM;       //名前
		const wTKPR_KoTSYU     	= this.TKPR_KoTSYU;       //住所〒
		const wTKPR_KoTSJYU1	= this.TKPR_KoTSJYU1;     //住所1
		const wTKPR_KoTSJYU2	= this.TKPR_KoTSJYU2;     //住所2
		const wTKPR_KoTSJYU3	= this.TKPR_KoTSJYU3;     //住所3
		const wTKPR_KoTSTEL		= this.TKPR_KoTSTEL;      //℡
		const wTKPR_KoTSTELK	= this.TKPR_KoTSTELK;     //携帯
		const wTKPR_KoTSMail	= this.TKPR_KoTSMail;     //メール
		const wTKPR_KoTTURKGK	= this.TKPR_KoTTURKGK;    //祭事売上(税込み)
		const wTKPR_KoTURTMST	= this.TKPR_KoTURTMST;    //立替外税売上  (税抜)
		const wTKPR_KoTURTMKG	= this.TKPR_KoTURTMKG;    //立替軽減税売上(税抜)
		const wTKPR_KoTURTMHK	= this.TKPR_KoTURTMHK;    //立替非課税売上(税抜)
		const wTKPR_KoTTNEBST	= this.TKPR_KoTTNEBST;    //立替外税値引
		const wTKPR_KoTTNEBKG	= this.TKPR_KoTTNEBKG;    //立替軽減税値引
		const wTKPR_KoTTNEBHK	= this.TKPR_KoTTNEBHK;    //立替非課税値引
		const wTKPR_KoTTKAZST	= this.TKPR_KoTTKAZST;    //立替外税対象額計
		const wTKPR_KoTTKAZKG	= this.TKPR_KoTTKAZKG;    //立替軽減税対象額計
		const wTKPR_KoTTKAZHK	= this.TKPR_KoTTKAZHK;    //立替非課税対象額計
		const wTKPR_KoTTSYOST	= this.TKPR_KoTTSYOST;    //立替外税消費税
		const wTKPR_KoTTSYOKG	= this.TKPR_KoTTSYOKG;    //立替軽減税消費税
		
		const wTKPR_MsSETCD		= this.TKPR_MsSETCD;   	  //セットコード
		const wTKPR_MsSETNM		= this.TKPR_MsSETNM;   	  //セット名称
		const wTKPR_MsHIBJYUN	= this.TKPR_MsHIBJYUN;	  //品目大分類並び順
		const wTKPR_MsHIBCD		= this.TKPR_MsHIBCD;	  //品目大分類コード
		const wTKPR_MsHIBNM		= this.TKPR_MsHIBNM;  	  //品目大分類名称
		const wTKPR_MsSYBRJN	= this.TKPR_MsSYBRJN;  	  //品目分類順
		const wTKPR_MsSYBRCD	= this.TKPR_MsSYBRCD;  	  //品目分類コード
		const wTKPR_MsSYBRNM	= this.TKPR_MsSYBRNM;  	  //品目分類名称
		const wTKPR_MsHIJYUN	= this.TKPR_MsHIJYUN;     //品目並び順
		const wTKPR_MsHICD	    = this.TKPR_MsHICD;    	  //品目コード
		const wTKPR_MsHINM      = this.TKPR_MsHINM;       //品目名称
		const wTKPR_MsTAN       = this.TKPR_MsTAN;     	  //単位
		const wTKPR_MsSETFG		= this.TKPR_MsSETFG;  	  //セットフラグ
		const wTKPR_MsUNT	    = this.TKPR_MsUNT;     	  //販売単価
		const wTKPR_MsSYOKB		= this.TKPR_MsSYOKB;   	  //売上消費税区分
		const wTKPR_MsSuu	    = this.TKPR_MsSuu;     	  //売上数量
		const wTKPR_MsKin	    = this.TKPR_MsKin;    	  //売上金額(税抜)
		const wTKPR_MsNebiki	= this.TKPR_MsNebiki;  	  //売上値引(税抜)
		const wTKPR_MsGKing		= this.TKPR_MsGKing;   	  //売上額値引後(税抜)
		const wTKPR_MsUCSYOG	= this.TKPR_MsUCSYOG;  	  //売上消費税額
		const wTKPR_MsZKGK	    = this.TKPR_MsZKGK;    	  //売上税込額(税込)
		const wTKPR_MsBIKO	    = this.TKPR_MsBIKO;    	  //備考
		
		const wTKPR_TBGO		= this.TKPR_TBGO;
		const wMsTrn			= this.MsTrn;
		const wKoTNO      		= this.KoTNO;             //受付番号
	//	const wKoTUMSKMDT		= this.KoTUMSKMDT;   	  //印刷日
	//	const wKoTUMSKMTM		= this.KoTUMSKMTM;   	  //印刷時間
		const wBL_BLCKCD		= this.BL_BLCKCD;         //営業所コード
		const wBL_BLCKNM		= this.BL_BLCKNM;         //営業所名
		const wBL_BLCKYB1		= this.BL_BLCKYB1;        //郵便番号1
		const wBL_BLCKYB2		= this.BL_BLCKYB2;        //郵便番号2
		const wBL_BLCKJY1		= this.BL_BLCKJY1;        //住所1
		const wBL_BLCKJY2		= this.BL_BLCKJY2;        //住所2
		const wBL_BLCKJY3		= this.BL_BLCKJY3;        //住所3
		const wBL_BLCKTEL		= this.BL_BLCKTEL;        //TEL
		const wBL_BLCKFAX		= this.BL_BLCKFAX;        //FAX
		const wBL_BLCKMAIL		= this.BL_BLCKMAIL;       //MAIL
		const wBL_BLCKSK1		= this.BL_BLCKSK1;        //請求先代表者
		const wBL_BLCKSK2		= this.BL_BLCKSK2;        //請求住所
		const wBL_BLCKSK3		= this.BL_BLCKSK3;        //請求電話
		const wBL_BLCKSK4		= this.BL_BLCKSK4;        //請求取引銀行名
		const wBL_BLCKSK5		= this.BL_BLCKSK5;        //請求口座名義
		const wBL_BLCKSK6		= this.BL_BLCKSK6;        //口座
		const wKoTUKDT			= this.KoTUKDT;           //受付日
		const wKoTNTNCD			= this.KoTNTNCD;          //担当者コード
		const wKoTNTNNM			= this.KoTNTNNM;          //担当者名称
		const wKoETNTNCD		= this.KoETNTNCD;         //営業担当コード
		const wKoETNTNNM		= this.KoETNTNNM;         //営業担当名称
		const wKoTSNM     		= this.KoTSNM;            //名前
		const wKoTSYU     		= this.KoTSYU;            //住所〒
		const wKoTSJYU1			= this.KoTSJYU1;          //住所1
		const wKoTSJYU2			= this.KoTSJYU2;          //住所2
		const wKoTSJYU3			= this.KoTSJYU3;          //住所3
		const wKoTSTEL			= this.KoTSTEL;           //℡
		const wKoTSTELK			= this.KoTSTELK;          //携帯
		const wKoTSMail			= this.KoTSMail;          //メール
		const wKoTTURKGK		= this.KoTTURKGK;         //祭事売上(税込み)
		const wKoTURTMST		= this.KoTURTMST;         //立替外税売上  (税抜)
		const wKoTURTMKG		= this.KoTURTMKG;     	  //立替軽減税売上(税抜)
		const wKoTURTMHK		= this.KoTURTMHK;     	  //立替非課税売上(税抜)
		const wKoTTNEBST		= this.KoTTNEBST;     	  //立替外税値引
		const wKoTTNEBKG		= this.KoTTNEBKG;     	  //立替軽減税値引
		const wKoTTNEBHK		= this.KoTTNEBHK;         //立替非課税値引
		const wKoTTKAZST		= this.KoTTKAZST;         //立替外税対象額計
		const wKoTTKAZKG		= this.KoTTKAZKG;         //立替軽減税対象額計
		const wKoTTKAZHK		= this.KoTTKAZHK;         //立替非課税対象額計
		const wKoTTSYOST		= this.KoTTSYOST;         //立替外税消費税
		const wKoTTSYOKG		= this.KoTTSYOKG;         //立替軽減税消費税

		req.open("GET", url, true);
		req.responseType = "arraybuffer";
		req.onreadystatechange = function () {
		if (req.readyState === 4 && req.status === 200){
			XlsxPopulate.fromDataAsync(req.response)
			.then(function (workbook) {
		//	workbook.outputAsync().then(data => this.resolve(data));
			const sheet2 = workbook.sheet("データ")
			
			if(wTKPR_KoTNO      != ""){sheet2.cell(wTKPR_KoTNO).value(wKoTNO)}             //受付番号
			if(wTKPR_KoTUMSKSDT	!= ""){sheet2.cell(wTKPR_KoTUMSKSDT).value(dateIMADT)}   //印刷日
			if(wTKPR_KoTUMSKSTM	!= ""){sheet2.cell(wTKPR_KoTUMSKSTM).value(dateIMATM)}   //印刷時間
			if(wTKPR_BLCKCD	    != ""){sheet2.cell(wTKPR_BLCKCD).value(wBL_BLCKCD)}         //営業所コード
			if(wTKPR_BLCKNM	    != ""){sheet2.cell(wTKPR_BLCKNM).value(wBL_BLCKNM)}         //営業所名
			if(wTKPR_BLCKYB1	!= ""){sheet2.cell(wTKPR_BLCKYB1).value(wBL_BLCKYB1)}       //郵便番号1
			if(wTKPR_BLCKYB2	!= ""){sheet2.cell(wTKPR_BLCKYB2).value(wBL_BLCKYB2)}       //郵便番号2
			if(wTKPR_BLCKJY1	!= ""){sheet2.cell(wTKPR_BLCKJY1).value(wBL_BLCKJY1)}       //住所1
			if(wTKPR_BLCKJY2	!= ""){sheet2.cell(wTKPR_BLCKJY2).value(wBL_BLCKJY2)}       //住所2
			if(wTKPR_BLCKJY3	!= ""){sheet2.cell(wTKPR_BLCKJY3).value(wBL_BLCKJY3)}       //住所3
			if(wTKPR_BLCKTEL	!= ""){sheet2.cell(wTKPR_BLCKTEL).value(wBL_BLCKTEL)}       //TEL
			if(wTKPR_BLCKFAX	!= ""){sheet2.cell(wTKPR_BLCKFAX).value(wBL_BLCKFAX)}       //FAX
			if(wTKPR_BLCKMAIL	!= ""){sheet2.cell(wTKPR_BLCKMAIL).value(wBL_BLCKMAIL)}     //MAIL
			if(wTKPR_BLCKSK1	!= ""){sheet2.cell(wTKPR_BLCKSK1).value(wBL_BLCKSK1)}       //請求先代表者
			if(wTKPR_BLCKSK2	!= ""){sheet2.cell(wTKPR_BLCKSK2).value(wBL_BLCKSK2)}       //請求住所
			if(wTKPR_BLCKSK3	!= ""){sheet2.cell(wTKPR_BLCKSK3).value(wBL_BLCKSK3)}       //請求電話
			if(wTKPR_BLCKSK4	!= ""){sheet2.cell(wTKPR_BLCKSK4).value(wBL_BLCKSK4)}       //請求取引銀行名
			if(wTKPR_BLCKSK5	!= ""){sheet2.cell(wTKPR_BLCKSK5).value(wBL_BLCKSK5)}       //請求口座名義
			if(wTKPR_BLCKSK6	!= ""){sheet2.cell(wTKPR_BLCKSK6).value(wBL_BLCKSK6)}       //口座
			if(wTKPR_KoTUKDT	!= ""){sheet2.cell(wTKPR_KoTUKDT).value(wKoTUKDT)}         //受付日
			if(wTKPR_KoTNTNCD	!= ""){sheet2.cell(wTKPR_KoTNTNCD).value(wKoTNTNCD)}       //担当者コード
			if(wTKPR_KoTNTNNM	!= ""){sheet2.cell(wTKPR_KoTNTNNM).value(wKoTNTNNM)}       //担当者名称
			if(wTKPR_KoETNTNCD	!= ""){sheet2.cell(wTKPR_KoETNTNCD).value(wKoETNTNCD)}     //営業担当コード
			if(wTKPR_KoETNTNNM	!= ""){sheet2.cell(wTKPR_KoETNTNNM).value(wKoETNTNNM)}     //営業担当名称
			if(wTKPR_KoTSNM     != ""){sheet2.cell(wTKPR_KoTSNM).value(wKoTSNM)}           //名前
			if(wTKPR_KoTSYU     != ""){sheet2.cell(wTKPR_KoTSYU).value(wKoTSYU)}           //住所〒
			if(wTKPR_KoTSJYU1	!= ""){sheet2.cell(wTKPR_KoTSJYU1).value(wKoTSJYU1)}       //住所1
			if(wTKPR_KoTSJYU2	!= ""){sheet2.cell(wTKPR_KoTSJYU2).value(wKoTSJYU2)}       //住所2
			if(wTKPR_KoTSJYU3	!= ""){sheet2.cell(wTKPR_KoTSJYU3).value(wKoTSJYU3)}       //住所3
			if(wTKPR_KoTSTEL	!= ""){sheet2.cell(wTKPR_KoTSTEL).value(wKoTSTEL)}         //℡
			if(wTKPR_KoTSTELK	!= ""){sheet2.cell(wTKPR_KoTSTELK).value(wKoTSTELK)}       //携帯
			if(wTKPR_KoTSMail	!= ""){sheet2.cell(wTKPR_KoTSMail).value(wKoTSMail)}       //メール
			if(wTKPR_KoTTURKGK	!= ""){sheet2.cell(wTKPR_KoTTURKGK).value(wKoTTURKGK)}     //祭事売上(税込み)
			if(wTKPR_KoTURTMST	!= ""){sheet2.cell(wTKPR_KoTURTMST).value(wKoTURTMST)}     //立替外税売上  (税抜)
			if(wTKPR_KoTURTMKG	!= ""){sheet2.cell(wTKPR_KoTURTMKG).value(wKoTURTMKG)}     //立替軽減税売上(税抜)
			if(wTKPR_KoTURTMHK	!= ""){sheet2.cell(wTKPR_KoTURTMHK).value(wKoTURTMHK)}     //立替非課税売上(税抜)
			if(wTKPR_KoTTNEBST	!= ""){sheet2.cell(wTKPR_KoTTNEBST).value(wKoTTNEBST)}     //立替外税値引
			if(wTKPR_KoTTNEBKG	!= ""){sheet2.cell(wTKPR_KoTTNEBKG).value(wKoTTNEBKG)}     //立替軽減税値引
			if(wTKPR_KoTTNEBHK	!= ""){sheet2.cell(wTKPR_KoTTNEBHK).value(wKoTTNEBHK)}     //立替非課税値引
			if(wTKPR_KoTTKAZST	!= ""){sheet2.cell(wTKPR_KoTTKAZST).value(wKoTTKAZST)}     //立替外税対象額計
			if(wTKPR_KoTTKAZKG	!= ""){sheet2.cell(wTKPR_KoTTKAZKG).value(wKoTTKAZKG)}     //立替軽減税対象額計
			if(wTKPR_KoTTKAZHK	!= ""){sheet2.cell(wTKPR_KoTTKAZHK).value(wKoTTKAZHK)}     //立替非課税対象額計
			if(wTKPR_KoTTSYOST	!= ""){sheet2.cell(wTKPR_KoTTSYOST).value(wKoTTSYOST)}     //立替外税消費税
			if(wTKPR_KoTTSYOKG	!= ""){sheet2.cell(wTKPR_KoTTSYOKG).value(wKoTTSYOKG)}     //立替軽減税消費税
			
			if(wTKPR_TBGO != ""){
			
			for (let i = 0; i < wMsTrn.length; i++) {
				let GYOU = parseInt(wTKPR_TBGO) + i;
				if(wMsTrn[i].MsTAKE == "1:立替"){
                    if(wTKPR_MsSETCD	!= ""){sheet2.cell(wTKPR_MsSETCD	+ GYOU).value(wMsTrn[i].MsSETCD)}   //セットコード
					if(wTKPR_MsSETNM	!= ""){sheet2.cell(wTKPR_MsSETNM	+ GYOU).value(wMsTrn[i].MsSETNM)}   //セット名称
					if(wTKPR_MsHIBJYUN	!= ""){sheet2.cell(wTKPR_MsHIBJYUN	+ GYOU).value(wMsTrn[i].MsHIBJYUN)} //品目大分類並び順
					if(wTKPR_MsHIBCD	!= ""){sheet2.cell(wTKPR_MsHIBCD	+ GYOU).value(wMsTrn[i].MsHIBCD)}   //品目大分類コード
					if(wTKPR_MsHIBNM	!= ""){sheet2.cell(wTKPR_MsHIBNM	+ GYOU).value(wMsTrn[i].MsHIBNM)}   //品目大分類名称
					if(wTKPR_MsSYBRJN	!= ""){sheet2.cell(wTKPR_MsSYBRJN	+ GYOU).value(wMsTrn[i].MsSYBRJN)}  //品目分類順
					if(wTKPR_MsSYBRCD	!= ""){sheet2.cell(wTKPR_MsSYBRCD	+ GYOU).value(wMsTrn[i].MsSYBRCD)}  //品目分類コード
					if(wTKPR_MsSYBRNM	!= ""){sheet2.cell(wTKPR_MsSYBRNM	+ GYOU).value(wMsTrn[i].MsSYBRNM)}  //品目分類名称
					if(wTKPR_MsHIJYUN	!= ""){sheet2.cell(wTKPR_MsHIJYUN	+ GYOU).value(wMsTrn[i].MsHIJYUN)}  //品目並び順
					if(wTKPR_MsHICD	    != ""){sheet2.cell(wTKPR_MsHICD		+ GYOU).value(wMsTrn[i].MsHICD)}    //品目コード
					if(wTKPR_MsHINM     != ""){sheet2.cell(wTKPR_MsHINM		+ GYOU).value(wMsTrn[i].MsHINM)}    //品目名称
					if(wTKPR_MsTAN      != ""){sheet2.cell(wTKPR_MsTAN		+ GYOU).value(wMsTrn[i].MsTAN)}     //単位
					if(wTKPR_MsSETFG	!= ""){sheet2.cell(wTKPR_MsSETFG	+ GYOU).value(wMsTrn[i].MsSETFG)}   //セットフラグ
					if(wTKPR_MsUNT	    != ""){sheet2.cell(wTKPR_MsUNT		+ GYOU).value(wMsTrn[i].MsUNT)}     //販売単価
					if(wTKPR_MsSYOKB	!= ""){sheet2.cell(wTKPR_MsSYOKB	+ GYOU).value(wMsTrn[i].MsSYOKB)}   //売上消費税区分
					if(wTKPR_MsSuu	    != ""){sheet2.cell(wTKPR_MsSuu		+ GYOU).value(wMsTrn[i].MsSuu)}     //売上数量
					if(wTKPR_MsKin	    != ""){sheet2.cell(wTKPR_MsKin		+ GYOU).value(wMsTrn[i].MsKin)}     //売上金額(税抜)
					if(wTKPR_MsNebiki	!= ""){sheet2.cell(wTKPR_MsNebiki	+ GYOU).value(wMsTrn[i].MsNebiki)}  //売上値引(税抜)
					if(wTKPR_MsGKing	!= ""){sheet2.cell(wTKPR_MsGKing	+ GYOU).value(wMsTrn[i].MsGKing)}   //売上額値引後(税抜)
					if(wTKPR_MsUCSYOG	!= ""){sheet2.cell(wTKPR_MsUCSYOG	+ GYOU).value(wMsTrn[i].MsUCSYOG)}  //売上消費税額
					if(wTKPR_MsZKGK	    != ""){sheet2.cell(wTKPR_MsZKGK		+ GYOU).value(wMsTrn[i].MsZKGK)}    //売上税込額(税込)
					if(wTKPR_MsBIKO	    != ""){sheet2.cell(wTKPR_MsBIKO		+ GYOU).value(wMsTrn[i].MsBIKO)}    //備考
				}
            }
			workbook.outputAsync()
            .then(function (blob) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, "立替見積書.xlsx");
                } else {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = "立替見積書.xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
            }
		});
		}
		})		
        }
		};
		req.send();
		this.TKPRINT();
	},
	//-----------------------------------------------------------------
    //祭事立替ヘッダー祭事見積の印刷更新
	//-----------------------------------------------------------------
	async TKPRINT(){
		let dateKYOU 	= moment(new Date).format('YYYY/MM/DD h:mm:ss');
		let dateIMADT 	= moment(new Date).format('YYYY/MM/DD');
		let dateIMATM 	= moment(new Date).format('hh:mm');

		this.KoMTKSDT = dateIMADT;
		this.KoMTKSTM = dateIMATM;

		var fomeUPgo = { 
        KoTNO:      this.KoTNO,     //受付番号
		KoMTKSDT: dateIMADT,		//立替請求書印刷日			
		KoMTKSTM: dateIMATM,		//立替請求書印刷時間
        UpdtDt:     dateKYOU,       //更新日時
        UpdtId:     this.UpdtId     //更新者
        };
		axios.post('/api/KoTrnTKP',fomeUPgo)
			.catch(error => {
            console.log(error)
        })
	},	
	//-----------------------------------------------------------------
    //
	//-----------------------------------------------------------------
    close() {
      this.$emit('result', {'res': false, 'KoMCD':'--','KoMNM':'--'})
      this.dialogIN = false;
	},
	//----------------------------------------
    //エラー確認
    //----------------------------------------
    ERRORConfirm () {
        this.dialogERROR = false;
    },
   },
}
</script>