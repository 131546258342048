<template>
 <v-dialog v-model="dialogKM" fullscreen hide-overlay transition="dialog-bottom-transition">
   <v-app id="inspire">
     <v-card width="100%">
        <v-alert v-model="dialogERROR" border="right" colored-border type="error" elevation="2">
          <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
            <v-spacer>{{ message }}</v-spacer>
          </v-col>
          <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
            <v-btn v-on:click="ERRORConfirm">エラー確認</v-btn>
          </v-col>
        </v-alert>
      <v-row no-gutters>
        <v-col cols="12">
           <v-card class="pa-2" outlined  tile color="blue ">
            <strong class="white--text">供物新規入力(SekoKUMTinput)【施行伝票№:{{ KUMUKNO }} 供物伝票№：{{ KUMDNNO }}】</strong>
             <v-alert dense>伝票:{{ KUMDNNO }}: 部署:{{ KUMBLNM }} 社員:{{ KUMTNNM }}</v-alert>
          </v-card>
      </v-col>
      </v-row>
      <v-row no-gutters>
       <v-col  cols="3" lg="3" md="3" sm="3" xsmd="12">
          <v-menu ref="menu" v-model="menuzz"  :return-value.sync="date"  persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="KUUTDT" label="受付日" prepend-icon="mdi-calendar-multiple-check" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="KUUTDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" lg="3" md="2" sm="3" xsmd="12">
          <v-menu    :return-value.sync="date" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="KUMNODT" label="売上日" prepend-icon="mdi-calendar-today" readonly v-bind="attrs" v-on="on" @change="KEISAN"></v-text-field>
            </template>
            <v-date-picker v-model="KUMNODT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4" xsmd="6">
          <v-btn  v-on:click="SYADialogshow"><v-icon left>mdi-account-multiple-plus</v-icon>担当</v-btn>
          <app-dialogSY :text="text" ref="dialogSY" @result="responseSY"></app-dialogSY>
        </v-col>
         <v-col  cols="1" lg="1" md="1" sm="1" xsmd="6">
          <v-btn  fab dark small color="pink" v-on:click="close">
            <v-icon dark>mdi-minus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
         <v-col cols="3" lg="3" md="3" sm="3" xsmd="12">
          <v-icon left>mdi-bank</v-icon><v-btn  v-on:click="SMTKKDialogshow">提携先</v-btn>
          <app-dialogSMTKK :text="text" ref="dialogSMTKK" @result="responseSMTKK"></app-dialogSMTKK>
        </v-col>
        <v-col cols="3" lg="3" md="3" sm="3" xsmd="6">
          <v-alert>{{ KUMTKNM }}:{{ KUMTKWR }}%</v-alert>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="6">
          <v-alert>{{ KUTOUKE }}</v-alert>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="6">
          <v-btn  v-on:click="KUTOUKE_ON">当家請求</v-btn>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="6">
          <v-btn  v-on:click="KUTOUKE_OFF">CL</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
          <template>
            <div class="blue-grey darken-3 text-center">
              <span class="white--text">請求先</span>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row no-gutters style="height: 60px;">
        <v-col cols="6" lg="6" md="6" sm="6" xsmd="12"><v-text-field type="text" clearable outlined v-model="KUMSNM1" label="請求先名1"/></v-col>
        <v-col cols="6" lg="6" md="6" sm="6" xsmd="12"><v-text-field type="text" clearable outlined v-model="KUMSNM2" label="請求先名2"/></v-col>
      </v-row>
      <v-row no-gutters style="height: 60px;">
        <v-col cols="6" lg="6" md="6" sm="6" xsmd="12"><v-text-field type="text" clearable outlined v-model="KUMSNM1K" label="請求ふりがな1"/></v-col>
        <v-col cols="6" lg="6" md="6" sm="6" xsmd="12"><v-text-field type="text" clearable outlined v-model="KUMSNM2K" label="請求ふりがな2"/></v-col>
      </v-row>
      <v-row no-gutters  style="height: 60px;">
        <v-col  cols="4" lg="4" md="4" sm="4" xsmd="12">
          <v-text-field type="email" clearable outlined v-model="KUMSKCD" label="請求先コード"/>
        </v-col>
        <v-col  cols="4" lg="4" md="4" sm="4" xsmd="10">
          <v-text-field  type="tel" v-mask="'(###)###-####'" v-model="KUMTEL" label="電話番号" clearable required prepend-icon="mdi-phone-in-talk"/>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="2">
          <v-btn  v-on:click="KUMTEL_ON">請求</v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters  style="height: 60px;">
        <v-col  cols="4" lg="4" md="4" sm="4" xsmd="10">
          <v-text-field  type="tel" v-mask="'(###)####-####'" v-model="KUMTELK" label="携帯番号" clearable required prepend-icon="mdi-cards-playing-outline"/>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="2">
          <v-btn   v-on:click="KUMTELK_ON">請求</v-btn>
        </v-col>
        <v-col  cols="4" lg="4" md="4" sm="4" xsmd="10">
          <v-text-field  type="tel" v-mask="'(###)####-####'" v-model="KUMFAX" label="ファックス" clearable required prepend-icon="mdi-fax"/>
        </v-col>
       </v-row>
      <v-row no-gutters>
        <v-col cols="3" lg="3" md="3" sm="3" xsmd="12">
          <v-text-field type="tel" v-mask="'#######'" v-model="KUMSYU" outlined  label="請求先〒" clearable required></v-text-field>
        </v-col>
        <v-col cols="2" md="2" sm="2" xsmd="6">
              <v-btn  v-on:click="KUMSYUAddress" >〒</v-btn>
        </v-col>
        <v-col cols="7" lg="7" md="7" sm="7" xsmd="12">
              <v-text-field v-model="KUMSJY1" label="請求先都道府県" clearable  required></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
         <v-col cols="6"  lg="6" md="6" sm="6" xsmd="12">
          <v-text-field  v-model="KUMSJY2" label="請求先市町村区" clearable  required></v-text-field>
        </v-col>
        <v-col cols="6"  lg="6" md="6" sm="6" xsmd="12">
          <v-text-field  v-model="KUMSJY3" label="請求先町域" clearable required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
          <template>
            <div class="blue-grey darken-3 text-center">
              <span class="white--text">納入先</span>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3" lg="3" md="3" sm="3" xsmd="12">
          <v-text-field type="number" v-mask="'#######'" v-model="KUMYSYU" label="納入先〒" clearable required></v-text-field>
        </v-col>
        <v-col cols="2" md="2" sm="2" xsmd="6">
          <v-btn v-on:click="KUMYSYUAddress">〒</v-btn>
        </v-col>
        <v-col cols="7" lg="7" md="7" sm="7" xsmd="12">
          <v-text-field v-model="KUMYSJY1" label="納入先都道府県" clearable  required></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6"  lg="6" md="6" sm="6" xsmd="12">
          <v-text-field  v-model="KUMYSJY2" label="納入先市町村区" clearable  required></v-text-field>
        </v-col>
        <v-col cols="6"  lg="6" md="6" sm="6" xsmd="12">
          <v-text-field  v-model="KUMYSJY3" label="納入先町域" clearable required></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3"  lg="3" md="3" sm="3" xsmd="12">
          <v-menu :return-value.sync="date" persistent >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="KUMNBI" label="納入日" prepend-icon="mdi-calendar-text" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="KUMNBI" scrollable locale="jp-ja" :day-format="date => new Date(date).getDate()"/>
          </v-menu>
        </v-col>
        <v-col cols="2"  lg="2" md="2" sm="2" xsmd="6">
          <v-text-field v-model="KUMNTM" label="納入時刻" clearable  required prepend-icon="mdi-alarm" ></v-text-field>
        </v-col>
        <v-col cols="7"  lg="7" md="7" sm="7" xsmd="12">
          <v-text-field v-model="KUMNBS"  label="納入場所" clearable required prepend-icon="mdi-bus-side"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
          <template>
            <div class="blue-grey darken-3 text-center">
              <span class="white--text">商品供物</span>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3"  lg="3" md="3" sm="3" xsmd="3">
          <v-btn color="primary" dark class="mb-2" v-on:click="KMSMMDialogshow" ><v-icon left>mdi-pencil</v-icon>新規品目追加</v-btn>
          <app-dialogKMSMM :text="text" ref="dialogKMSMM" @result="responseKMSMM"></app-dialogKMSMM>
        </v-col>
        <v-col cols="4"  lg="4" md="4" sm="4" xsmd="4">
          <v-alert>{{ KUMSYCD }}:{{KUMSYNM}}</v-alert>
        </v-col>
        <v-col cols="4"  lg="4" md="4" sm="4" xsmd="4">
          <v-alert>{{ KUTOUKE }}</v-alert>
        </v-col>  
      </v-row>
      <v-row no-gutters>
        <v-col cols="3"  lg="3" md="3" sm="3" xsmd="6">  
            <v-text-field type="number"  v-mask="'########'"  v-model="KUMSYUG" label="単価(税抜)" @change="KEISAN"/>
        </v-col>
        <v-col cols="1"  lg="1" md="1" sm="1" xsmd="1"></v-col>

        <v-col cols="2"  lg="2" md="2" sm="2" xsmd="6">  
            <v-text-field type="number"  v-mask="'###'"  v-model="KUMSuu" label="数" @change="KEISAN"/>
        </v-col>
        <v-col cols="2" lg="2" md="2" sm="2" xsmd="2">
          <v-alert><strong>税抜:</strong>{{KUMZRGK }}</v-alert>
        </v-col>
        <v-col cols="3" lg="3" md="3" sm="3" xsmd="3">
          <v-alert>{{ KUMTKWR }}</v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4"  lg="4" md="4" sm="4" xsmd="6">
          <v-text-field type="number"  v-mask="'#######'" v-model="KUMNbk" label="値引" @change="KEISAN"/>
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4" xsmd="3">
          <v-alert><strong>値引後</strong>{{ KUMNbk }}</v-alert>
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4" xsmd="3">
          <v-alert><strong>税区</strong>{{ KUMSYKB }}</v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3" lg="3" md="3" sm="3" xsmd="3"> 
              <v-alert><strong>消費税</strong>{{ KUMSZGK }}</v-alert>
          </v-col>
           <v-col cols="3" lg="3" md="3" sm="3" xsmd="3"> 
              <v-alert><strong>税込</strong>{{ KUMUAGK }}</v-alert>
          </v-col>
            <v-col cols="2" lg="2" md="2" sm="2" xsmd="2"> 
              <v-btn v-on:click="SHZBHJ">標</v-btn>
           </v-col>
           <v-col cols="2" lg="2" md="2" sm="2" xsmd="2"> 
              <v-btn v-on:click="SHZBKG">軽</v-btn>
            </v-col>
           <v-col cols="2" lg="2" md="2" sm="2" xsmd="2"> 
              <v-btn v-on:click="SHZBHK">非</v-btn>
           </v-col>
        </v-row>
        <v-row>
        <v-col  cols="12" lg="12" md="12" sm="12" xsmd="12">
          <template>
            <div class="blue-grey darken-3 text-center">
              <span class="white--text">名札</span>
            </div>
          </template>
        </v-col>
      </v-row>
        <v-row no-gutters>
          <v-col cols="2" lg="2" md="2" sm="2" xsmd="2"> 
             <v-alert>名札1</v-alert>
          </v-col>
          <v-col cols="5" lg="5" md="5" sm="5" xsmd="5"> 
             <v-text-field v-model="KUMNA11" outlined clearable label="名札1-1"/>
          </v-col>
          <v-col cols="5" lg="5" md="5" sm="5" xsmd="5"> 
             <v-text-field v-model="KUMNA12" outlined clearable label="名札1-2"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2" lg="2" md="2" sm="2" xsmd="2"> 
             <v-alert>名札2</v-alert>
          </v-col>
           <v-col cols="5" lg="5" md="5" sm="5" xsmd="5"> 
             <v-text-field v-model="KUMNA21" outlined clearable label="名札2-1"/>
          </v-col>
           <v-col cols="5" lg="5" md="5" sm="5" xsmd="5"> 
             <v-text-field v-model="KUMNA22" outlined clearable label="名札2-2"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4"  md="4" sm="4" xsmd="4">
            <div class="my-2"><v-btn x-large color="success" v-on:click="addKUMtrmdata" dark block><v-icon left>mdi-pencil</v-icon>{{ INUPBTN }}</v-btn></div>
            </v-col>
            <v-col cols="3"  md="3" sm="3" xsmd="3">
            <div class="my-2"><v-btn x-large color="error" v-on:click="DELETEKUMtrmdata" dark v-if="DELBOshow"><v-icon left>mdi-delete</v-icon> 削除処理 </v-btn></div>
            </v-col>
            <v-col cols="3"  md="3" sm="3" xsmd="3">
              <div class="my-2"><v-btn x-large  dark block v-on:click="INDialogshow"><v-icon left>mdi-pencil</v-icon>供物見積印刷</v-btn></div>
              <app-dialogIN :text="text" ref="dialogIN" @result="responseIN"></app-dialogIN>
            </v-col>
          </v-row>   
      </v-card>
  </v-app>
  </v-dialog>
</template>
<script>
 //   Vue.use(VueMask.VueMaskPlugin);
</script>
<script>
import axios from 'axios';
//import Vue from "vue";
//import { API, graphqlOperation } from "aws-amplify";
//import { getSmkcntm } from "../graphql/queries";      //供物カウントマスタ取得
//import { updateSmkcntm } from "../graphql/mutations"; //供物カウントマスタ更新
//import { createKuMtrm } from "../graphql/mutations";   //供物更新
//import { updateKuMtrm } from "../graphql/mutations";   //供物追加
//import { deleteKuMtrm } from "../graphql/mutations";   //供物削除

//import axiosJsonpAdapter from 'axios-jsonp'
import moment from "moment";
import SYAINDialog from "./popSYAINALL.vue";    //社員マスタ
import INSTDialog  from "./SekoKUMTprint.vue";  //供物印刷
import SMTKKDialog from "./popTEIKEI.vue";      //提携企業マスタ
import KMSMMDialog from "./popKMSMM.vue";       //供物品目設定マスタ
export default { 
  data () {
    // テンプレート内で保有するデータ  
    return {
      dialogKM:false,
      dialogERROR:false,
     //初期設定
        KgyCd:"",     //初期設定企業コード
        KgyNM:"",     //初期設定企業名称
        BLCKCD:"",    //初期設定部署コード
        BLCKNM:"",    //初期設定部署名称
        SynCd:"",     //初期設定社員名称
        SynNm:"",     //初期設定社員名称
        TANID:"",     //初期設定端末ID
        DENNO:"0",    //初期設定伝票番号
        GNZHJ:"",     //初期設定現在標準税
        GNZKG:"",     //初期設定現在軽減税
        KGZDT:"",     //初期設定消費税切替日
        KGZHJ:"",     //初期設定次期標準税
        KGZKG:"",     //初期設定次期軽減税
        KoTURDT:"",   //売上日
        KoThCD:"",    //提携コード
        KoThNM:"",    //提携名称
        KoTCD:"",     //提携取引先コード
        KoTNM:"",     //提携取引先名称
        ksGNZHJ:"",   //計算標準税
        ksGNZKG:"",   //計算軽減税
        //供物
        DSPKEYA:'',	  //アクセスキーA
        KUMDNNO:'',	  //伝票番号
        KUMUKNO:'',	  //受付番号
        KUMNODT:'',	  //売上日
        KUUTDT:'',	  //受付日
        KUMBLCD:'',	  //部署コード
        KUMBLNM:'',	  //部署名称
        KUMTNCD:'',	  //営業担当者コード
        KUMTNNM:'',	  //営業担当名称
        KUMSOKE:'',	  //葬家
        KUMKNM:'',	  //故人
        KUMMNM:'',	  //喪主
        KUTOUKE:'',	  //ご当家請求
        KUMSKCD:'',	  //請求先コード
        KUMSNM1:'',	  //請求先漢字1
        KUMSNM2:'',	  //請求先漢字2
        KUMSNM1K:'',  //請求先カナ1
        KUMSNM2K:'',  //請求先カナ2
        KUMTEL:'',	  //電話番号
        KUMTELK:'',	  //携帯番号
        KUMFAX	:'',	//ファックス
        KUMSYU	:'',	//請求先郵便番号
        KUMSJY1	:'',	//請求先住所1
        KUMSJY2	:'',	//請求先住所2
        KUMSJY3	:'',	//請求先住所3
        KUMYSYU	:'',	//送付先郵便番号
        KUMYSJY1:'',	//送付先住所1
        KUMYSJY2:'',	//送付先住所2
        KUMYSJY3:'',	//送付先住所3
        KUMNBI	:'',	//納品日
        KUMNTM	:'',	//納品時間
        KUMNBS	:'',	//納品場所
        KUMSYCD	:'',	//商品コード
        KUMSYNM	:'',	//商品名称
        KUMSYUG	:'',	//売上単価
        KUMTNI	:'',	//単位
        KUMSYKB	:'',	//売上消費税区分
        KUMSYSG	:'',	//仕入単価
        KUMSuu	:'',	//売上数量
        KUMZRGK	:'',	//売上金額(税抜)
        KUMNbk	:'',	//売上値引(税抜)
        KUMURI	:'',	//売上額値引後(税抜)
        KUMSZGK	:'',	//売上消費税計
        KUMUAGK	:'',	//売上合計(税込)
        KUMNKG	:'',	//売掛入金金額
        KUMZNG	:'',	//売掛残高
        KUMCHDT	:'',	//発注完了日
        KUMCHTM	:'',	//発注完了時間
        KUMSCSDT:'',	//請求完了日
        KUMCSTM	:'',	//請求完了時間
        KUMTKCD	:'',	//提携先コード
        KUMTKNM	:'',	//提携先名称
        KUMTKWR	:'',	//割引率
        KUMNA11	:'',	//名札11
        KUMNA12	:'',	//名札12
        KUMNA21	:'',	//名札21
        KUMNA22	:'',	//名札22
        URZHJ	  :'',	//標準税売上(税抜)
        URZKG	  :'',	//軽減税売上(税抜)
        URZHK	  :'',	//非課税売上(税抜)
        NEBHJ	  :'',	//標準税値引
        NEBKG	  :'',	//軽減税値引
        NEBHK	  :'',	//非課税値引
        TSGHJ	  :'',	//標準税対象額計
        TSGKG	  :'',	//軽減税対象額計
        TSGHK	  :'',	//非課税対象額計
        SHZHJ	  :'',	//標準税消費税
        SHZKG	  :'',	//軽減税消費税
        KUMHJGK	:'',  //標準原価計
        KUMARRI	:'',  //粗利益計
        KUMTELN	:'',  //供物電話番号CTI
        KUMTELKN:'',  //供物携帯番号CTI
        KUHCCD 	:'',  //発注先コード
        KUHCNM 	:'',  //発注先名称
        KUHCDTTM:'',  //発注日時
        KUHCENDF:'',  //発注完了
        InstDt	:'',	//作成日時
        InstId	:'',	//作成者
        NEP	    :'',	//値引き%
        zipcode: '',
        prefecture: '',
        address1: '',
        address2: '',
        address3:'',
        error_msg:'',
        kojintel:'',
        date: new Date().toISOString().substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        menu11: false,
        menu12: false,
        menu13: false,
        menu14: false,
        menuzz:false,
        modal: false,
        moda2:false,
        moda3:false,
        modal11:false,
        modal12:false,
        modal13:false,
        modal14:false,
        title: '',
        TANNM: '',
        Kojinnm: '',
        NENsuuji:'',
        TUKIsuuji:'',
        HIsuuji:'',
        TOSOsuuji:'',
        search: '',
        select: null,
        NENGOU:'S',
        MSGTEST:'',
       message:'',
       starttm: false,
       endtm: false,
       text: "",
       GT_CNTNO:0,
      UP_CNTNO:0,
      KgyCd:'',
      KgyNM:'',
      BLCKCD:'',
      BLCKNM:'',
      SynCd:'',
      SynNm:'',
      TANID:'',
      DENNO:'',
      INUPFLG:'IN',
      INUPBTN:'供物新規登録 処理',
      DELBOshow: false,
      ziptb: [],
    }
   },
 
created() {

  },
  methods: {
      //オープン時
    open() {
     
    this.dialogKM = true;
   
    if(localStorage.SOGI_KgyCd) this.KgyCd          = localStorage.SOGI_KgyCd;      //初期設定企業コード
    if(localStorage.SOGI_KgyNM) this.KgyNM          = localStorage.SOGI_KgyNM;      //初期設定企業名称
    if(localStorage.SOGI_BLCKCD) this.BLCKCD        = localStorage.SOGI_BLCKCD;     //初期設定部署コード
    if(localStorage.SOGI_BLCKNM) this.BLCKNM        = localStorage.SOGI_BLCKNM;     //初期設定部署名称
    if(localStorage.SOGI_SynCd) this.SynCd          = localStorage.SOGI_SynCd;      //初期設定社員名称
    if(localStorage.SOGI_SynNm) this.SynNm          = localStorage.SOGI_SynNm;      //初期設定社員名称
    if(localStorage.SOGI_TANID) this.TANID          = localStorage.SOGI_TANID;      //初期設定端末ID
    if(localStorage.SOGI_DENNO) this.DENNO          = localStorage.SOGI_SGDENNO;    //初期設定伝票番号
    if(localStorage.SOGI_GNZHJ) this.GNZHJ          = localStorage.SOGI_GNZHJ;      //初期設定現在標準税
    if(localStorage.SOGI_GNZKG) this.GNZKG          = localStorage.SOGI_GNZKG;      //初期設定現在軽減税
    if(localStorage.SOGI_KGZDT) this.KGZDT          = localStorage.SOGI_KGZDT;      //初期設定消費税切替日
    if(localStorage.SOGI_KGZHJ) this.KGZHJ          = localStorage.SOGI_KGZHJ;      //初期設定次期標準税
    if(localStorage.SOGI_KGZKG) this.KGZKG          = localStorage.SOGI_KGZKG;      //初期設定次期軽減税

    if(localStorage.SOGI_KoTNO)  this.KUMUKNO     = localStorage.SOGI_KoTNO;    //受付番号
    if(localStorage.SOGI_KUMDNNO)  this.KUMDNNO     = localStorage.SOGI_KUMDNNO;    //伝票番号
    if(localStorage.SOGI_KUMKNM)   this.KUMKNM      = localStorage.SOGI_KUMKNM;	    //故人
    if(localStorage.SOGI_KUMMNM)   this.KUMMNM      = localStorage.SOGI_KUMMNM;	    //喪主
    if(localStorage.SOGI_KUMSOKE)  this.KUMSOKE     = localStorage.SOGI_KUMSOKE;	  //喪家
    if(this.KUMDNNO != ""){
        if(localStorage.SOGI_KUMNODT) this.KUMNODT  = localStorage.SOGI_KUMNODT;	  //売上日
        if(localStorage.SOGI_KUUTDT)  this.KUUTDT   = localStorage.SOGI_KUUTDT;	    //受付日
        if(localStorage.SOGI_KUMBLCD) this.KUMBLCD  = localStorage.SOGI_KUMBLCD;	  //部署コード
        if(localStorage.SOGI_KUMBLNM) this.KUMBLNM  = localStorage.SOGI_KUMBLNM;	  //部署名称
        if(localStorage.SOGI_KUMTNCD) this.KUMTNCD  = localStorage.SOGI_KUMTNCD;	  //営業担当者コード
        if(localStorage.SOGI_KUMTNNM) this.KUMTNNM  = localStorage.SOGI_KUMTNNM;	  //営業担当名称
        if(localStorage.SOGI_KUTOUKE) this.KUTOUKE  = localStorage.SOGI_KUTOUKE;	  //ご当家請求
        if(localStorage.SOGI_KUMSKCD) this.KUMSKCD  = localStorage.SOGI_KUMSKCD;	  //請求先コード
        if(localStorage.SOGI_KUMSNM1) this.KUMSNM1  = localStorage.SOGI_KUMSNM1;	  //請求先漢字1
        if(localStorage.SOGI_KUMSNM2) this.KUMSNM2  = localStorage.SOGI_KUMSNM2;	  //請求先漢字2
        if(localStorage.SOGI_KUMTEL)  this.KUMTEL   = localStorage.SOGI_KUMTEL;	    //電話番号
        if(localStorage.SOGI_KUMTELK) this.KUMTELK  = localStorage.SOGI_KUMTELK;	  //携帯番号
        if(localStorage.SOGI_KUMFAX)  this.KUMFAX   = localStorage.SOGI_KUMFAX;	    //ファックス
        if(localStorage.SOGI_KUMSYU)  this.KUMSYU   = localStorage.SOGI_KUMSYU;	    //請求先郵便番号
        if(localStorage.SOGI_KUMSJY1) this.KUMSJY1  = localStorage.SOGI_KUMSJY1;	  //請求先住所1
        if(localStorage.SOGI_KUMSJY2) this.KUMSJY2  = localStorage.SOGI_KUMSJY2;	  //請求先住所2
        if(localStorage.SOGI_KUMSJY3) this.KUMSJY3  = localStorage.SOGI_KUMSJY3;	  //請求先住所3
        if(localStorage.SOGI_KUMYSYU) this.KUMYSYU  = localStorage.SOGI_KUMYSYU;	  //送付先郵便番号
        if(localStorage.SOGI_KUMYSJY1)this.KUMYSJY1 = localStorage.SOGI_KUMYSJY1;	  //送付先住所1
        if(localStorage.SOGI_KUMYSJY2)this.KUMYSJY2 = localStorage.SOGI_KUMYSJY2;	  //送付先住所2
        if(localStorage.SOGI_KUMYSJY3)this.KUMYSJY3 = localStorage.SOGI_KUMYSJY3;	  //送付先住所3
        if(localStorage.SOGI_KUMNBI)  this.KUMNBI   = localStorage.SOGI_KUMNBI;	    //納品日
        if(localStorage.SOGI_KUMNTM)  this.KUMNTM   = localStorage.SOGI_KUMNTM;	    //納品時間
        if(localStorage.SOGI_KUMNBS)  this.KUMNBS   = localStorage.SOGI_KUMNBS;	    //納品場所
        if(localStorage.SOGI_KUMSYCD) this.KUMSYCD  = localStorage.SOGI_KUMSYCD;	  //商品コード
        if(localStorage.SOGI_KUMSYNM) this.KUMSYNM  = localStorage.SOGI_KUMSYNM;	  //商品名称
        if(localStorage.SOGI_KUMSYUG) this.KUMSYUG  = localStorage.SOGI_KUMSYUG;	  //売上単価
        if(localStorage.SOGI_KUMTNI) this.KUMTNI    = localStorage.SOGI_KUMTNI;	    //単位
        if(localStorage.SOGI_KUMSYKB) this.KUMSYKB  = localStorage.SOGI_KUMSYKB;	  //売上消費税区分
        if(localStorage.SOGI_KUMSYSG) this.KUMSYSG  = localStorage.SOGI_KUMSYSG;	  //仕入単価
        if(localStorage.SOGI_KUMSuu) this.KUMSuu    = localStorage.SOGI_KUMSuu;	    //売上数量
        if(localStorage.SOGI_KUMZRGK) this.KUMZRGK  = localStorage.SOGI_KUMZRGK;	  //売上金額(税抜)
        if(localStorage.SOGI_KUMNbk) this.KUMNbk    = localStorage.SOGI_KUMNbk;	    //売上値引(税抜)
        if(localStorage.SOGI_KUMURI) this.KUMURI    = localStorage.SOGI_KUMURI;	    //売上額値引後(税抜)
        if(localStorage.SOGI_KUMSZGK) this.KUMSZGK  = localStorage.SOGI_KUMSZGK;	  //売上消費税計
        if(localStorage.SOGI_KUMUAGK) this.KUMUAGK  = localStorage.SOGI_KUMUAGK;	  //売上合計(税込)
        if(localStorage.SOGI_KUMNKG) this.KUMNKG    = localStorage.SOGI_KUMNKG;	    //売掛入金金額
        if(localStorage.SOGI_KUMZNG) this.KUMZNG    = localStorage.SOGI_KUMZNG;	    //売掛残高
        if(localStorage.SOGI_KUMCHDT) this.KUMCHDT  = localStorage.SOGI_KUMCHDT;	  //発注完了日
        if(localStorage.SOGI_KUMCHTM) this.KUMCHTM  = localStorage.SOGI_KUMCHTM;	  //発注完了時間
        if(localStorage.SOGI_KUMSCSDT) this.KUMSCSDT= localStorage.SOGI_KUMSCSDT;	  //請求完了日
        if(localStorage.SOGI_KUMCSTM) this.KUMCSTM  = localStorage.SOGI_KUMCSTM;	  //請求完了時間
        if(localStorage.SOGI_KUMTKCD) this.KUMTKCD  = localStorage.SOGI_KUMTKCD;	  //提携先コード
        if(localStorage.SOGI_KUMTKNM) this.KUMTKNM  = localStorage.SOGI_KUMTKNM;	  //提携先名称
        if(localStorage.SOGI_KUMTKWR) this.KUMTKWR  = localStorage.SOGI_KUMTKWR;	  //割引率
        if(localStorage.SOGI_KUMNA11) this.KUMNA11  = localStorage.SOGI_KUMNA11;	  //名札11
        if(localStorage.SOGI_KUMNA12) this.KUMNA12  = localStorage.SOGI_KUMNA12;	  //名札12
        if(localStorage.SOGI_KUMNA21) this.KUMNA21  = localStorage.SOGI_KUMNA21;	  //名札21
        if(localStorage.SOGI_KUMNA22) this.KUMNA22  = localStorage.SOGI_KUMNA22;	  //名札22
        if(localStorage.SOGI_URZHJ) this.URZHJ      = localStorage.SOGI_URZHJ;	    //標準税売上(税抜)
        if(localStorage.SOGI_URZKG) this.URZKG      = localStorage.SOGI_URZKG;	    //軽減税売上(税抜)
        if(localStorage.SOGI_URZHK) this.URZHK      = localStorage.SOGI_URZHK;	    //非課税売上(税抜)
        if(localStorage.SOGI_NEBHJ) this.NEBHJ      = localStorage.SOGI_NEBHJ;	    //標準税値引
        if(localStorage.SOGI_NEBKG) this.NEBKG      = localStorage.SOGI_NEBKG;	    //軽減税値引
        if(localStorage.SOGI_NEBHK) this.NEBHK      = localStorage.SOGI_NEBHK;	    //非課税値引
        if(localStorage.SOGI_TSGHJ) this.TSGHJ      = localStorage.SOGI_TSGHJ;	    //標準税対象額計
        if(localStorage.SOGI_TSGKG) this.TSGKG      = localStorage.SOGI_TSGKG;	    //軽減税対象額計
        if(localStorage.SOGI_TSGHK) this.TSGHK      = localStorage.SOGI_TSGHK;	    //非課税対象額計
        if(localStorage.SOGI_SHZHJ) this.SHZHJ      = localStorage.SOGI_SHZHJ;	    //標準税消費税
        if(localStorage.SOGI_SHZKG) this.SHZKG      = localStorage.SOGI_SHZKG;	    //軽減税消費税
        if(localStorage.SOGI_KUMHJGK)this.KUMHJGK   = localStorage.SOGI_KUMHJGK;    //標準原価計
        if(localStorage.SOGI_KUMARRI)this.KUMARRI   = localStorage.SOGI_KUMARRI;    //粗利益計
        if(localStorage.SOGI_KUMTELN)this.KUMTELN   = localStorage.SOGI_KUMTELN;    //供物電話番号CTI
        if(localStorage.SOGI_KUMTELKN)this.KUMTELKN = localStorage.SOGI_KUMTELKN;   //供物携帯番号CTI
        if(localStorage.SOGI_KUHCCD)this.KUHCCD     = localStorage.SOGI_KUHCCD;     //発注先コード
        if(localStorage.SOGI_KUHCNM) this.KUHCNM    = localStorage.SOGI_KUHCNM;     //発注先名称
        if(localStorage.SOGI_KUHCDTTM)this.KUHCDTTM = localStorage.SOGI_KUHCDTTM;   //発注日時
        if(localStorage.SOGI_KUHCENDF)this.KUHCENDF = localStorage.SOGI_KUHCENDF;   //発注完了
        if(localStorage.SOGI_InstDt) this.InstDt    = localStorage.SOGI_InstDt;	    //作成日時
        if(localStorage.SOGI_InstId) this.InstId    = localStorage.SOGI_InstId;	    //作成者
        this.DELBOshow = true;
        this.INUPFLG = 'UP';
    }else{
        this.KUMNODT  = "";	  //売上日
        this.KUUTDT   = "";	  //受付日
        this.KUMBLCD  = "";	  //部署コード
        this.KUMBLNM  = "";	  //部署名称
        this.KUMTNCD  = "";	  //営業担当者コード
        this.KUMTNNM  = "";	  //営業担当名称
        this.KUTOUKE  = "";	  //ご当家請求
        this.KUMSKCD  = "";	  //請求先コード
        this.KUMSNM1  = "";	  //請求先漢字1
        this.KUMSNM2  = "";	  //請求先漢字2
        this.KUMTEL   = "";	  //電話番号
        this.KUMTELK  = "";	  //携帯番号
        this.KUMFAX   = "";	  //ファックス
        this.KUMSYU   = "";	  //請求先郵便番号
        this.KUMSJY1  = "";	  //請求先住所1
        this.KUMSJY2  = "";	  //請求先住所2
        this.KUMSJY3  = "";	  //請求先住所3
        this.KUMYSYU  = "";	  //送付先郵便番号
        this.KUMYSJY1 = "";	  //送付先住所1
        this.KUMYSJY2 = "";	  //送付先住所2
        this.KUMYSJY3 = "";	  //送付先住所3
        this.KUMNBI   = "";	  //納品日
        this.KUMNTM   = "";	  //納品時間
        this.KUMNBS   = "";	  //納品場所
        this.KUMSYCD  = "";	  //商品コード
        this.KUMSYNM  = "";	  //商品名称
        this.KUMSYUG  = 0;	  //売上単価
        this.KUMTNI   = "";	  //単位
        this.KUMSYKB  = "";	  //売上消費税区分
        this.KUMSYSG  = 0;	  //仕入単価
        this.KUMSuu   = 0;	  //売上数量
        this.KUMZRGK  = 0;	  //売上金額(税抜)
        this.KUMNbk   = 0;	  //売上値引(税抜)
        this.KUMURI   = 0;	  //売上額値引後(税抜)
        this.KUMSZGK  = 0;	  //売上消費税計
        this.KUMUAGK  = 0;	  //売上合計(税込)
        this.KUMNKG   = 0;	  //売掛入金金額
        this.KUMZNG   = 0;	  //売掛残高
        this.KUMCHDT  = "";	  //発注完了日
        this.KUMCHTM  = "";	  //発注完了時間
        this.KUMSCSDT = "";	  //請求完了日
        this.KUMCSTM  = "";	  //請求完了時間
        this.KUMTKCD  = "";	  //提携先コード
        this.KUMTKNM  = "";	  //提携先名称
        this.KUMTKWR  = 0;	  //割引率
        this.KUMNA11  = "";	  //名札11
        this.KUMNA12  = "";	  //名札12
        this.KUMNA21  = "";	  //名札21
        this.KUMNA22  = "";	  //名札22
        this.URZHJ    = 0;	  //標準税売上(税抜)
        this.URZKG    = 0;	  //軽減税売上(税抜)
        this.URZHK    = 0;	  //非課税売上(税抜)
        this.NEBHJ    = 0;	  //標準税値引
        this.NEBKG    = 0;	  //軽減税値引
        this.NEBHK    = 0;	  //非課税値引
        this.TSGHJ    = 0;	  //標準税対象額計
        this.TSGKG    = 0;	  //軽減税対象額計
        this.TSGHK    = 0;	  //非課税対象額計
        this.SHZHJ    = 0;	  //標準税消費税
        this.SHZKG    = 0;	  //軽減税消費税
        this.KUMHJGK  = 0;	  //標準原価計
        this.KUMARRI  = 0;	  //粗利益計
        this.KUMTELN  = "";   //供物電話番号CTI
        this.KUMTELKN = "";   //供物携帯番号CTI
        this.KUHCCD   = "";   //発注先コード
        this.KUHCNM   = "";   //発注先名称
        this.KUHCDTTM = "";   //発注日時
        this.KUHCENDF = "";   //発注完了
        this.InstDt   = "";	  //作成日時
        this.InstId   = "";	  //作成者
        this.INUPFLG  = "IN";
        this.SMKCNTMquery();
      }
    },
    close() {
      this.$emit('result', {'res': false, 'KUMDNNO':'--','KUMUKNO':'--'})
      this.dialogKM = false;
    },
    agreeok() {
      this.$emit('result', {'res': true, 'KUMDNNO':this.KUMDNNO,'KUMUKNO':this.KUMUKNO})
      this.dialogKM = false;
     },
    //--------------------------------------------------------
    //供物伝票番号の取得更新
    //--------------------------------------------------------
    async SMKCNTMquery() {
      const fomeUPgo = { cntID: this.TANID,denID:"02"};
      axios.post('/api/contUP',fomeUPgo)
           .then(res => {
          //   console.log(res)
             var wKoTNO  = res.data;
             var now = new Date();
             var year = now.getYear().toString();
             year = year.substr( 1,2 );
             var MM2 = now.getMonth() + 1;
             var MM2w = String(MM2);
             if(MM2w.length == 1){MM2w = "0"+ MM2w;}
             var DD2 = now.getDate();
             var DD2w = String(DD2);
             if(DD2w.length == 1){DD2w = "0"+ DD2w;}
             var Hizuke = year + MM2w + DD2w;
              this.KUMDNNO = Hizuke + this.TANID + wKoTNO;//今日の日付YYMMDD(6桁) + 端末ID(2桁) + 伝票番号(3桁) 
            })
            .catch(error => {
            console.log(error)
            })

      localStorage.SOGI_SGDENNO = this.KoTNO;
    },
    //--------------------------------------------------------
    //供物データ更新
    //--------------------------------------------------------
    async addKUMtrmdata() {
      if(this.KUUTDT == "" || this.KUUTDT == null){
       this.message  = "受付日が設定されていませんを設定してください。【設定されていません】";
       this.dialogERROR = true;
      }else{
       if(this.KUMNODT == "" || this.KUMNODT == null){
        this.message  = "売上日が設定されていませんを設定してください。【設定されていません】";
        this.dialogERROR = true;
      }else{
      let WKUMNODT  = "";
      let WKUUTDT   = "";
      if(this.KUUTDT  !=""){
        WKUMNODT  = moment(this.KUMNODT).format('YYYY/MM/DD');       //売上日
      }
      if(this.KUMNODT  !=""){
        WKUUTDT   = moment(this.KUUTDT).format('YYYY/MM/DD');        //受付日
      }
      let dateKYOU  = moment(new Date).format('YYYY/MM/DD h:mm:ss');   //現在時刻
      if(this.InstDt == "" || this.InstDt == null){
        this.InstDt = dateKYOU;
        this.InstId = this.SynNm;
      }
      let W_DSPKEYA   = this.KUMUKNO+"/"+this.KUMDNNO;

      const KUMtrmUP  = {     
        KUMDNNO:this.KUMDNNO,    //伝票番号
        KUMUKNO:this.KUMUKNO,    //受付番号
        KUMNODT:WKUMNODT,        //売上日
        KUUTDT :WKUUTDT,         //受付日
        KUMBLCD:this.KUMBLCD,    //部署コード
        KUMBLNM:this.KUMBLNM,    //部署名称
        KUMTNCD:this.KUMTNCD,    //営業担当者コード
        KUMTNNM:this.KUMTNNM,    //営業担当名称
        KUMSOKE:this.KUMSOKE,    //葬家
        KUMKNM :this.KUMKNM,     //故人
        KUMMNM :this.KUMMNM,     //喪主
        KUTOUKE:this.KUTOUKE,    //ご当家請求
        KUMSKCD:this.KUMSKCD,    //請求先コード
        KUMSNM1:this.KUMSNM1,    //請求先漢字1
        KUMSNM2:this.KUMSNM2,    //請求先漢字2
        KUMSNM1K:this.KUMSNM1K,  //請求先カナ1
        KUMSNM2K:this.KUMSNM2K,  //請求先カナ2
        KUMTEL :this.KUMTEL,     //電話番号
        KUMTELK:this.KUMTELK,    //携帯番号
        KUMFAX :this.KUMFAX,     //ファックス
        KUMSYU :this.KUMSYU,     //請求先郵便番号
        KUMSJY1:this.KUMSJY1,    //請求先住所1
        KUMSJY2:this.KUMSJY2,    //請求先住所2
        KUMSJY3:this.KUMSJY3,    //請求先住所3
        KUMYSYU:this.KUMYSYU,    //送付先郵便番号
        KUMYSJY1:this.KUMYSJY1,  //送付先住所1
        KUMYSJY2:this.KUMYSJY2,  //送付先住所2
        KUMYSJY3:this.KUMYSJY3,  //送付先住所3
        KUMNBI :this.KUMNBI,     //納品日
        KUMNTM :this.KUMNTM,     //納品時間
        KUMNBS :this.KUMNBS,     //納品場所
        KUMSYCD:this.KUMSYCD,    //商品コード
        KUMSYNM:this.KUMSYNM,    //商品名称
        KUMSYUG:this.KUMSYUG,    //売上単価
        KUMTNI :this.KUMTNI,     //単位
        KUMSYKB:this.KUMSYKB,    //売上消費税区分
        KUMSYSG:this.KUMSYSG,    //仕入単価
        KUMSuu :this.KUMSuu,     //売上数量
        KUMZRGK:this.KUMZRGK,    //売上額値引後(税抜)
        KUMNbk :this.KUMNbk,     //売上値引(税抜)
        KUMURI :this.KUMURI,     //売上消費税計
        KUMUAGK:this.KUMUAGK,    //売上合計(税込)
        KUMNKG :this.KUMNKG,     //入金金額
        KUMSZGK:this.KUMSZGK,    //消費税
        KUMZNG :this.KUMZNG,     //売掛残高
        KUMCHDT:this.KUMCHDT,    //発注完了日
        KUMCHTM:this.KUMCHTM,    //発注完了時間
        KUMSCSDT:this.KUMSCSDT,  //請求完了日
        KUMCSTM:this.KUMCSTM,    //請求完了時間
        KUMTKCD:this.KUMTKCD,    //提携先コード
        KUMTKNM:this.KUMTKNM,    //提携先名称
        KUMTKWR:this.KUMTKWR,    //割引率
        KUMNA11:this.KUMNA11,    //名札11
        KUMNA12:this.KUMNA12,    //名札12
        KUMNA21:this.KUMNA21,    //名札21
        KUMNA22:this.KUMNA22,    //名札22
        URZHJ  :this.URZHJ,      //標準税売上(税抜)
        URZKG  :this.URZKG,      //軽減税売上(税抜)
        URZHK  :this.URZHK,      //非課税売上(税抜)
        NEBHJ  :this.NEBHJ,      //標準税値引
        NEBHK  :this.NEBHK,      //非課税値引
        NEBKG  :this.NEBKG,      //軽減税値引
        TSGHJ  :this.TSGHJ,      //標準税対象額計
        TSGKG  :this.TSGKG,      //軽減税対象額計
        TSGHK  :this.TSGHK,      //非課税対象額計
        SHZHJ  :this.SHZHJ,      //標準税消費税
        SHZKG  :this.SHZKG,      //軽減税消費税
        KUMHJGK:this.KUMHJGK,    //標準原価計
        KUMARRI:this.KUMARRI,    //粗利益計
        KUMTELN:this.KUMTELN,    //供物電話番号CTI
        KUMTELKN:this.KUMTELKN,  //供物携帯番号CTI
        KUHCCD :this.KUHCCD,     //発注先コード
        KUHCNM :this.KUHCNM,     //発注先名称
        KUHCDTTM:this.KUHCDTTM,  //発注日時
        KUHCENDF:this.KUHCENDF,  //発注完了
        InstDt:this.InstDt,
        InstId:this.InstId,
        UpdtDt:dateKYOU,
        UpdtId:this.SynNm
        }

      axios.post('/api/KUMtrmUPDT',KUMtrmUP)
         .then(res => {
          })
          .catch(error => {
            console.log(error)
          })
       }
      }
    },
    //-----------------------------------------------------------------
    //削除処理
    //-----------------------------------------------------------------
    async DELETEKUMtrmdata() {
      if(this.KUMNKG != 0){
        this.message      = "この供物伝票は入金済です、削除は行えません経理に相談して下さい";
        this.dialogERROR  = true;
      }else{
      const fomeDLgo = {KUMUKNO: this.KUMUKNO,KUMDNNO:this.KUMDNNO};
      axios.post('/api/KUMtrmDEL',fomeDLgo)
      .then(response => {   //更新後の処理
         })
        .catch(error => {
        console.log(error)
        })
      }
    },
    //-----------------------------------------------------------------
    //郵便番号
    //-----------------------------------------------------------------
    //--------------------------------------------------------
    //請求先住所
    //--------------------------------------------------------
    KUMSYUAddress() {
     const zipcd = {zipCD: this.KUMSYU};
     axios.post('/api/zipNO',zipcd)
      .then(res => {
      this.ziptb = res.data;
      this.KUMSJY1 = this.ziptb[0].address1;
      this.KUMSJY2 = this.ziptb[0].address2;
      this.KUMSJY3 = this.ziptb[0].address3;
     })
     .catch(error => {
      this.KUMSJY1 = "";
      this.KUMSJY2 = "";
      this.KUMSJY3 = "";
      this.error_msg  = "郵便番号エラー";
      console.log(error)
      })
    },
    //--------------------------------------------------------
    //送付先住所
    //--------------------------------------------------------
     KUMYSYUAddress() {
     const zipcd = {zipCD: this.KUMYSYU};
     axios.post('/api/zipNO',zipcd)
      .then(res => {
      this.ziptb = res.data;
      this.KUMYSJY1 = this.ziptb[0].address1;
      this.KUMYSJY2 = this.ziptb[0].address2;
      this.KUMYSJY3 = this.ziptb[0].address3;
     })
     .catch(error => {
      this.KUMYSJY1 = "";
      this.KUMYSJY2 = "";
      this.KUMYSJY3 = "";
      this.error_msg  = "郵便番号エラー";
      })
    },
    //----------------------------------------
    //供物品目設定表示
    //----------------------------------------
    KMSMMDialogshow() {
       this.$refs.dialogKMSMM.open();
    },
    //供物品目設定取得
    responseKMSMM(obj) {
      if(obj.HINMOCD != "--"){  //キャンセル時は何もしない
        this.KUMSYCD  = obj.HINMOCD;    //品目コード
        this.KUMSYNM  = obj.HINMONM;    //品目名
        this.KUMTNI   = obj.HINMOTNNM;  //単位名
        this.KUMSYUG  = obj.HINMOTK;    //単価
        this.KUMSYKB  = obj.HINMOSHKG;  //消費税区分
        this.KUMSYSG  = obj.HINSITK;    //仕入単価
        this.KUMSuu   = 1;              //売上数量
        this.KEISAN();
      }
      this.dialogKMSMM = false;
    },
    //----------------------------------------
    //供物印刷
    //----------------------------------------
    INDialogshow() {
      this.$refs.dialogIN.open();
    },
    //供物予定
    responseIN(obj) {
        this.dialogIN = false;
    },
    //----------------------------------------
    //提携企業マスタ表示
    //----------------------------------------
    SMTKKDialogshow() {
      this.$refs.dialogSMTKK.open();
    },
    //提携企業取得
    responseSMTKK(obj) {
      if(obj.TKKTCD != "--"){  //キャンセル時は何もしない
        this.KUMTKCD  = obj.TRHTKCD;   //提携区分コード -> 顧客提携区分コード
        this.KUMTKNM  = obj.TRHTKNM;   //提携区分名称 -> 顧客提携区分名称
        this.KUMTKWR  = obj.TRHTKKWA;  //割引率
      }
      this.dialogSMTKK = false;
    },

    //----------------------------------------
    //社員マスタ表示
    //----------------------------------------
    SYADialogshow() {
      this.$refs.dialogSY.open();
    },
    //社員マスタ取得
    responseSY(obj) {
      if(obj.SynCd != "--"){  //キャンセル時は何もしない
        this.KUMTNCD = obj.SynCd;     //社員コード
        this.KUMTNNM = obj.SynNm;     //社員名
        this.KUMBLCD = obj.SynBLCKCD; //部署コード
        this.KUMBLNM = obj.SynBLCKNM; //部署名称
      }
        this.dialogSY = false;
    },
    //----------------------------------------
    //標準税ボタン
    //----------------------------------------
    SHZBHJ() {
        this.KUMSYKB    = "標準税";
         this.KEISAN();
    },
    //----------------------------------------
    //軽減税ボタン
    //----------------------------------------
    SHZBKG() {
        this.KUMSYKB    = "軽減税";
        this.KEISAN();
    },
    //----------------------------------------
    //非課税ボタン
    //----------------------------------------
    SHZBHK() {
        this.KUMSYKB    = "非課税";
        this.KEISAN();
    },
    //----------------------------------------
    //当家請求ボタン
    //----------------------------------------
    KUTOUKE_ON() {
        this.KUTOUKE = "当家請求";
    },
    //----------------------------------------
    //当家請求クリアボタン
    //----------------------------------------
    KUTOUKE_OFF() {
        this.KUTOUKE = "";
    },
    //----------------------------------------
    //電話請求先にコピーボタン
    //----------------------------------------
    KUMTEL_ON() {
        this.KUMSKCD = this.KUMTEL;
    },
    //----------------------------------------
    //携帯番号請求先にコピーボタン
    //----------------------------------------
    KUMTELK_ON() {
        this.KUMSKCD = this.KUMTELK;
    },
    //----------------------------------------
   //売上額計算
   //----------------------------------------
   KEISAN() {
   //売上日＞＝初期設定消費税切替日
    if(this.KUMNODT >= this.KGZDT){
        this.ksGNZHJ    =   this.KGZHJ;   //計算標準税
        this.ksGNZKG    =   this.KGZKG;   //計算軽減税

    }else{
        this.ksGNZHJ    =   this.GNZHJ;   //計算標準税
        this.ksGNZKG    =   this.GNZKG;   //計算軽減税
    }
    if(this.KUMSYUG  == "" || this.KUMSYUG   == null){this.KUMSYUG   = 0}    //単価(税抜)
    if(this.KUMSuu   == "" || this.KUMSuu    == null){this.KUMSuu    = 0}    //数量
    if(this.KUMNbk   == "" || this.KUMNbk    == null){this.KUMNbk    = 0}    //値引額
    if(this.KUMTKWR  == "" || this.KUMTKWR   == null){this.KUMTKWR   = 0}    //割引率
    this.KUMZRGK     =   this.KUMSYUG  *   this.KUMSuu;                      //税抜金額
    this.KUMNbk      =   parseInt(this.KUMZRGK * this.KUMTKWR    /100);      //値引金額計算
    this.KUMURI      =   this.KUMZRGK  -   this.KUMNbk;                      //値引後額

    if(this.KUMSYKB  ==  "非課税"){
      this.KUMSZGK   =   "0";
    }else{
      if(this.KUMSYKB ==  "標準税"){
        this.KUMSZGK   =    parseInt(this.KUMURI * this.ksGNZHJ    /100);  //計算標準税
      }else{
        this.KUMSZGK   =    parseInt(this.KUMURI * this.ksGNZKG    /100);  //計算軽減税
      }
    }
    this.KUMUAGK =   this.KUMURI +    this.KUMSZGK;                        //税込金額

    this.KUMNKG   = "0";	        //売掛入金金額
    this.KUMZNG   = this.KUMUAGK; //売掛残高
    this.KUMCHDT  = "";	        	//発注完了日
    this.KUMCHTM  = "";	        	//発注完了時間
    this.KUMSCSDT = "";	        	//請求完了日
    this.KUMCSTM  = "";	        	//請求完了時間

    this.URZHK    = "0";	        //非課税売上(税抜)
    this.NEBHK    = "0";	        //非課税値引
    this.TSGHK	  = "0";	        //非課税対象額計
    this.URZHJ    = "0";	        //標準税売上(税抜)
    this.NEBHJ    = "0";	        //標準税値引
    this.TSGHJ	  = "0";	        //標準税対象額計
    this.SHZHJ    = "0";	        //標準税消費税
    this.URZKG    = "0";	        //軽減税売上(税抜)
    this.NEBKG    = "0";	        //軽減税値引
    this.TSGKG	  = "0";	        //軽減税対象額計
    this.SHZKG    = "0";	        //軽減税消費税
    this.KUMHJGK  = "0";          //標準原価計
    this.KUMARRI  = "0";          //粗利益計

    if(this.KUMSYKB  ==  "非課税"){
      this.URZHK  = this.KUMZRGK;	//非課税売上(税抜)
      this.NEBHK  = this.KUMNbk;	//非課税値引
      this.TSGHK	= this.KUMURI;  //非課税対象額計
    }
    if(this.KUMSYKB ==  "標準税"){
      this.URZHJ  = this.KUMZRGK	//標準税売上(税抜)
      this.NEBHJ  = this.KUMNbk;	//標準税値引
      this.TSGHJ	= this.KUMURI;  //標準税対象額計
      this.SHZHJ  = this.KUMSZGK; //標準税消費税
    }
    if(this.KUMSYKB ==  "軽減税"){
      this.URZKG  = this.KUMZRGK	//軽減税売上(税抜)
      this.NEBKG  = this.KUMNbk;	//軽減税値引
      this.TSGKG	= this.KUMURI;  //軽減税対象額計
      this.SHZKG  = this.KUMSZGK; //軽減税消費税
    }
  },
   //----------------------------------------
   //供物伝票印刷
   //----------------------------------------
   print() {
      localStorage.SOGI_KUMDNNO = this.KUMDNNO;           //伝票番号
      localStorage.SOGI_KUMUKNO = this.KUMUKNO;           //受付番号
      localStorage.SOGI_KUMNODT = this.KUMNODT;           //売上日
      localStorage.SOGI_KUUTDT  = this.KUUTDT;           //受付日
      localStorage.SOGI_KUMBLCD = this.KUMBLCD;           //部署コード
      localStorage.SOGI_KUMBLNM = this.KUMBLNM;           //部署名称
      localStorage.SOGI_KUMTNCD = this.KUMTNCD;           //営業担当者コード
      localStorage.SOGI_KUMTNNM = this.KUMTNNM;           //営業担当名称
      localStorage.SOGI_KUMSOKE = this.KUMSOKE;           //葬家
      localStorage.SOGI_KUMKNM  = this.KUMKNM;           //故人
      localStorage.SOGI_KUMMNM  = this.KUMMNM;           //喪主
      localStorage.SOGI_KUTOUKE = this.KUTOUKE;           //ご当家請求
      localStorage.SOGI_KUMSKCD = this.KUMSKCD;           //請求先コード
      localStorage.SOGI_KUMSNM1 = this.KUMSNM1;           //請求先漢字1
      localStorage.SOGI_KUMSNM2 = this.KUMSNM2;           //請求先漢字2
      localStorage.SOGI_KUMTEL  = this.KUMTEL;           //電話番号
      localStorage.SOGI_KUMTELK = this.KUMTELK;           //携帯番号
      localStorage.SOGI_KUMFAX  = this.KUMFAX;           //ファックス
      localStorage.SOGI_KUMSYU  = this.KUMSYU;           //請求先郵便番号
      localStorage.SOGI_KUMSJY1 = this.KUMSJY1;           //請求先住所1
      localStorage.SOGI_KUMSJY2 = this.KUMSJY2;           //請求先住所2
      localStorage.SOGI_KUMSJY3 = this.KUMSJY3;           //請求先住所3
      localStorage.SOGI_KUMYSYU = this.KUMYSYU;           //送付先郵便番号
      localStorage.SOGI_KUMYSJY1= this.KUMYSJY1;           //送付先住所1
      localStorage.SOGI_KUMYSJY2= this.KUMYSJY2;           //送付先住所2
      localStorage.SOGI_KUMYSJY3= this.KUMYSJY3;           //送付先住所3
      localStorage.SOGI_KUMNBI  = this.KUMNBI;           //納品日
      localStorage.SOGI_KUMNTM  = this.KUMNTM;           //納品時間
      localStorage.SOGI_KUMNBS  = this.KUMNBS;           //納品場所
      localStorage.SOGI_KUMSYCD = this.KUMSYCD;           //商品コード
      localStorage.SOGI_KUMSYNM = this.KUMSYNM;           //商品名称
      localStorage.SOGI_KUMSYUG = this.KUMSYUG;           //売上単価
      localStorage.SOGI_KUMTNI  = this.KUMTNI;           //単位
      localStorage.SOGI_KUMSYKB = this.KUMSYKB;           //売上消費税区分
      localStorage.SOGI_KUMSYSG = this.KUMSYSG;           //仕入単価
      localStorage.SOGI_KUMSuu  = this.KUMSuu;           //売上数量
      localStorage.SOGI_KUMZRGK = this.KUMZRGK;           //売上金額(税抜)
      localStorage.SOGI_KUMNbk  = this.KUMNbk;           //売上値引(税抜)
      localStorage.SOGI_KUMURI  = this.KUMURI;           //売上額値引後(税抜)
      localStorage.SOGI_KUMSZGK = this.KUMSZGK;           //売上消費税計
      localStorage.SOGI_KUMUAGK = this.KUMUAGK;           //売上合計(税込)
      localStorage.SOGI_KUMNKG  = this.KUMNKG;           //売掛入金金額
      localStorage.SOGI_KUMZNG  = this.KUMZNG;           //売掛残高
      localStorage.SOGI_KUMCHDT = this.KUMCHDT;           //発注完了日
      localStorage.SOGI_KUMCHTM = this.KUMCHTM;           //発注完了時間
      localStorage.SOGI_KUMSCSDT= this.KUMSCSDT;           //請求完了日
      localStorage.SOGI_KUMCSTM = this.KUMCSTM;           //請求完了時間
      localStorage.SOGI_KUMTKCD = this.KUMTKCD;           //提携先コード
      localStorage.SOGI_KUMTKNM = this.KUMTKNM;           //提携先名称
      localStorage.SOGI_KUMTKWR = this.KUMTKWR;           //割引率
      localStorage.SOGI_KUMNA11 = this.KUMNA11;           //名札11
      localStorage.SOGI_KUMNA12 = this.KUMNA12;           //名札12
      localStorage.SOGI_KUMNA21 = this.KUMNA21;           //名札21
      localStorage.SOGI_KUMNA22 = this.KUMNA22;           //名札22
      localStorage.SOGI_URZHJ   = this.URZHJ;             //標準税売上(税抜)
      localStorage.SOGI_URZKG   = this.URZKG;             //軽減税売上(税抜)
      localStorage.SOGI_URZHK   = this.URZHK;             //非課税売上(税抜)
      localStorage.SOGI_NEBHJ   = this.NEBHJ;             //標準税値引
      localStorage.SOGI_NEBKG   = this.NEBKG;             //軽減税値引
      localStorage.SOGI_NEBHK   = this.NEBHK;             //非課税値引
      localStorage.SOGI_TSGHJ   = this.TSGHJ;             //標準税対象額計
      localStorage.SOGI_TSGKG   = this.TSGKG;             //軽減税対象額計
      localStorage.SOGI_TSGHK   = this.TSGHK;             //非課税対象額計
      localStorage.SOGI_SHZHJ   = this.SHZHJ;             //標準税消費税
      localStorage.SOGI_SHZKG   = this.SHZKG;             //軽減税消費税
      localStorage.SOGI_KUMHJGK = this.KUMHJGK;           //標準原価計
      localStorage.SOGI_KUMARRI = this.KUMARRI;           //粗利益計
      localStorage.SOGI_KUMTELN = this.KUMTELN;           //供物電話番号CTI
      localStorage.SOGI_KUMTELKN= this.KUMTELKN;          //供物携帯番号CTI
      localStorage.SOGI_KUHCCD  = this.KUHCCD;            //発注先コード
      localStorage.SOGI_KUHCNM  = this.KUHCNM;            //発注先名称
      localStorage.SOGI_KUHCDTTM= this.KUHCDTTM;          //発注日時
      localStorage.SOGI_KUHCENDF= this.KUHCENDF;          //発注完了
      localStorage.SOGI_InstDt  = this.InstDt;            //作成日時
      localStorage.SOGI_InstId  = this.InstId;            //作成者

      this.$refs.dialogIN.open();
    },
    //供物予定
    responseIN(obj) {
        this.dialogIN = false;
    },
    //----------------------------------------
    //エラー確認
    //----------------------------------------
    ERRORConfirm () {
        this.dialogERROR = false;
    },
   },
  components: {
    appDialogSY: SYAINDialog,         //社員マスタ
    appDialogSMTKK: SMTKKDialog,      //提携先企業選択
    appDialogIN: INSTDialog,          //施行印刷マスタ
    appDialogKMSMM: KMSMMDialog,      //品目選択
  }
}
</script>